import axios from 'axios';

const createWebhook = params => axios.post(`webhooks`, params);

const deleteWebhook = id => axios.delete(`webhooks/${id}`);

const editWebhook = ({ id, ...params }) => {
  return axios.put(`webhooks/${id}`, params);
};

export { createWebhook, deleteWebhook, editWebhook };

import axios from 'axios';

const getEmbedSettings = () => axios.get('embed/settings');

const updateEmbedSettings = (settings = {}) => {
  return axios.put('embed/settings', settings);
};

const updateEmbedLogo = logo => {
  return axios.put('embed/settings/images', {
    logo_id: logo.id,
    source_id: logo.source.id
  });
};

export { getEmbedSettings, updateEmbedSettings, updateEmbedLogo };

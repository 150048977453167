import axios from 'axios';

const getEndpoints = () => axios.get('endpoints');

const createEndpoint = (integrationId, params) => {
  return axios.post('endpoints', {
    integration_id: integrationId,
    ...params
  });
};

const editEndpoint = (endpointId, integrationId, params) => {
  return axios.put(`endpoints/${endpointId}`, {
    integration_id: integrationId,
    ...params
  });
};

const deleteEndpoint = endpointId => axios.delete(`endpoints/${endpointId}`);

export { getEndpoints, createEndpoint, editEndpoint, deleteEndpoint };

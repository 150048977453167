import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import { DeliveryContext } from '@ubisend/pulse-volt';
import { useAuth } from '@ubisend/pulse-auth';
import { useSubscription } from '@ubisend/pulse-subscribe';

const DeliveryProvider = ({ children, token }) => {
  const { client } = useAuth();

  const handleAgentSubscribe = useCallback(() => {
    //
  }, []);

  const agentSubscribe = useSubscription(
    'agent_subscribe',
    handleAgentSubscribe
  );

  useEffect(() => {
    agentSubscribe({
      client_id: client.identifier,
      token
    });
  }, [agentSubscribe, client, token]);

  const context = {
    resubscribe: () => {}
  };

  return (
    <DeliveryContext.Provider value={context}>
      {children}
    </DeliveryContext.Provider>
  );
};

DeliveryProvider.propTypes = {
  token: PropTypes.string.isRequired
};

const AgentDeliveryProvider = ({ children }) => {
  const query = useQuery(`tickets/ubidesk/agents/me/channels`);

  if (query.isLoading) {
    return null;
  }

  return (
    <DeliveryProvider token={query.data.data.token}>
      {children}
    </DeliveryProvider>
  );
};

export default AgentDeliveryProvider;

import React from 'react';
import PropTypes from 'prop-types';

import { Select } from '@ubisend/pulse-components';

import { useConditionalTypes } from '../hooks/index';
import { group, format, CHECK_TYPES } from '../utils/index';

const ConditionalSelect = ({ value, types = CHECK_TYPES, ...rest }) => {
  const allTypes = useConditionalTypes();

  const options = allTypes.filter(({ type }) => types.includes(type));

  return (
    <Select
      aria-label="conditional-names"
      options={group(options)}
      value={
        options &&
        format(options.find(conditional => conditional.name === value))
      }
      {...rest}
    />
  );
};

ConditionalSelect.propTypes = {
  value: PropTypes.string,
  types: PropTypes.arrayOf(PropTypes.string)
};

export default ConditionalSelect;

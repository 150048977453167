import React from 'react';

import { TextInput, Label, FormGroup } from '@ubisend/pulse-components';

import { useGandalf } from '../../../hooks/index';

const ConfigureSitemap = () => {
  const { settings, dispatch } = useGandalf();

  const handleUrlChange = event => {
    const value = event.target.value;
    dispatch({ type: 'UPDATE_SETTINGS', settings: { url: value } });
  };

  return (
    <FormGroup>
      <Label htmlFor="url">Sitemap URL</Label>
      <TextInput
        id="url"
        placeholder="https://my-website.com/sitemap.xml"
        value={settings.url}
        onChange={handleUrlChange}
      />
    </FormGroup>
  );
};

export default ConfigureSitemap;

import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useMutation } from '@ubisend/pulse-hooks';
import { AnimateSharedLayout } from '@ubisend/framer-motion';
import {
  Flex,
  FormGroup,
  Label,
  TextInput,
  Button,
  Checkbox,
  Tooltip,
  useNotification,
  TabButton,
  Divider,
  LeftHalfLayout,
  RightHalfLayout
} from '@ubisend/pulse-components';
import {
  MultiComposer,
  useMultiComposerReducer,
  MultiComposerSettings,
  MultiComposerWidget,
  MultiComposerSubmitButton,
  MultiComposerLanguageSelect,
  ComposerContext
} from '@ubisend/pulse-composer';

import Modal from '../../../Modal';
import ComposerWidget from '../../../ComposerWidget';
import { useBuilder } from '../../../../hooks/index';
import { createStep } from '../../../../api/index';
import { composers } from '../../../../../../subjects/index';

const AddStepNode = ({ handleClose, onSave }) => {
  const [step, setStep] = useState('DETAILS');
  const [title, setTitle] = useState('');
  const [sensitive, setSensitive] = useState(false);

  const { showSuccess } = useNotification();
  const { conversation, dispatch } = useBuilder();
  const composer = useMultiComposerReducer({
    responses: [],
    subjects: composers
  });
  const { isLoading, mutate } = useMutation(createStep, {
    onSuccess: ({ data }) => {
      dispatch({
        type: 'ADD_STEP',
        stepDetails: data.data
      });
      onSave(data.data);
      showSuccess('Successfully created a conversation step');
    }
  });

  const valid = useMemo(() => {
    return title.length > 0 && composer.valid;
  }, [title, composer.valid]);

  const handleTitleChange = event => {
    setTitle(event.target.value);
  };

  const handleSensitiveChange = () => {
    setSensitive(sensitive => !sensitive);
  };

  const hadleSubmit = event => {
    event.preventDefault();

    if (valid && !isLoading) {
      mutate({
        conversationId: conversation.id,
        step: {
          title,
          sensitive,
          ...composer.form,
          previous_transition: null,
          next_transition: null
        }
      });
    }
  };

  return (
    <Modal header="Add step" handleClose={handleClose}>
      <Flex center xSpace mt ml mr>
        <AnimateSharedLayout>
          <TabButton
            active={step === 'DETAILS'}
            onClick={() => setStep('DETAILS')}>
            Details
          </TabButton>
          <TabButton
            active={step === 'RESPONSE'}
            onClick={() => setStep('RESPONSE')}>
            Response
          </TabButton>
        </AnimateSharedLayout>
      </Flex>
      <Divider />
      <form onSubmit={hadleSubmit}>
        <Flex pl pr pb fat col>
          {step === 'DETAILS' && (
            <>
              <FormGroup>
                <Label htmlFor="name">Name</Label>
                <TextInput
                  id="name"
                  value={title}
                  placeholder="My steps name"
                  onChange={handleTitleChange}
                />
              </FormGroup>
              <FormGroup>
                <Flex center>
                  <Checkbox
                    checked={sensitive}
                    onChange={handleSensitiveChange}
                    label="Sensitive information at this step"
                  />
                  <Tooltip
                    position={Tooltip.POSITIONS.TOP}
                    tooltip={
                      <Tooltip.Content>
                        If a step is marked as sensitive, no responses from the
                        subscriber will be stored.
                      </Tooltip.Content>
                    }>
                    <Tooltip.Icon>?</Tooltip.Icon>
                  </Tooltip>
                </Flex>
              </FormGroup>
              <Flex right>
                <Button onClick={() => setStep('RESPONSE')}>Continue</Button>
              </Flex>
            </>
          )}
          {step === 'RESPONSE' && (
            <Flex>
              <ComposerContext.Provider value={composer}>
                <LeftHalfLayout>
                  <Flex between center mb>
                    <Label mb={false}>Language</Label>
                    <Flex>
                      <MultiComposerLanguageSelect />
                    </Flex>
                  </Flex>
                  <MultiComposer />
                  <Flex xSpace>
                    <MultiComposerSettings />
                    <MultiComposerSubmitButton
                      type="submit"
                      disabled={isLoading || !valid}>
                      Save
                    </MultiComposerSubmitButton>
                  </Flex>
                </LeftHalfLayout>
                <RightHalfLayout>
                  <Flex fat middle>
                    <MultiComposerWidget as={ComposerWidget} />
                  </Flex>
                </RightHalfLayout>
              </ComposerContext.Provider>
            </Flex>
          )}
        </Flex>
      </form>
    </Modal>
  );
};

AddStepNode.propTypes = {
  handleClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

export default AddStepNode;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Button } from '@ubisend/pulse-components';

const SectionScope = ({ content }) => {
  return (
    <Button
      variant="inline"
      as={Link}
      to={`/knowledge-bases/${content.import_id}/sections/${content.id}/content`}>
      {content.name}
    </Button>
  );
};

SectionScope.propTypes = {
  content: PropTypes.shape({
    import_id: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
};

export default SectionScope;

import axios from 'axios';

const createImport = params => axios.post('imports', { import: params });

const updateImport = (importId, params) => {
  return axios.put(`imports/${importId}`, params);
};

const deleteImport = importId => axios.delete(`imports/${importId}`);

const exportImport = id => axios.post(`imports/${id}/export`);

const exportAllImports = () => axios.post('imports/export');

const updateImportOrderApi = ({ ...params }) => {
  return axios.put(`imports/order`, params);
};

export {
  createImport,
  updateImport,
  deleteImport,
  exportImport,
  exportAllImports,
  updateImportOrderApi
};

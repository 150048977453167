import React from 'react';

import { Button } from '@ubisend/pulse-components';

import { useTemplating } from '../../../../../hooks/index';

const SNIPPET = `{% for variable in variables %}
    {{variable}}
  {% endfor %}`;

const AddForTag = () => {
  const templating = useTemplating();

  const handleAddForTag = () => {
    templating.dispatch({
      type: templating.TYPES.ADD_SNIPPET,
      snippet: SNIPPET
    });
  };

  return (
    <Button variant="secondary" icon="code" onClick={handleAddForTag}>
      For tag
    </Button>
  );
};

export default AddForTag;

import query from '../query';
import ValueMetric from './ValueMetric';

const value = {
  name: 'Value',
  Component: ValueMetric,
  columns: 2,
  rows: 1,
  query
};

export default value;

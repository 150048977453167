import { useState, useCallback, useEffect } from 'react';

const defaultParams = { timeout: 1000 };

const useCopyToClipboard = ({ timeout = 1000 } = defaultParams) => {
  const [copied, setCopied] = useState(null);

  const copy = useCallback(
    value => {
      const hiddenTextArea = document.createElement('textarea');

      hiddenTextArea.value = value;
      hiddenTextArea.style.border = 'none';
      hiddenTextArea.style.position = 'fixed';
      hiddenTextArea.style.background = 'transparent';
      hiddenTextArea.style.left = '-99999px';
      hiddenTextArea.style.width = '0px';
      hiddenTextArea.style.height = '0px';
      document.body.appendChild(hiddenTextArea);

      hiddenTextArea.focus();
      hiddenTextArea.select();
      document.execCommand('copy');

      hiddenTextArea.remove();

      setCopied(value);
    },
    [setCopied]
  );

  useEffect(() => {
    let ref;

    if (timeout && copied) {
      ref = setTimeout(() => setCopied(null), timeout);
    }

    return () => {
      if (ref) {
        clearTimeout(ref);
      }
    };
  }, [timeout, copied, setCopied]);

  return { copied, copy };
};

export default useCopyToClipboard;

import { useEffect } from 'react';

import { useQueryClient } from '@ubisend/pulse-hooks';

import { useBuilder } from '../hooks/index';

const BuilderQueryCache = () => {
  const { conversation } = useBuilder();

  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.setQueryData(`conversations/builder/${conversation.id}`, {
      data: conversation
    });
  }, [conversation, queryClient]);

  return null;
};

export default BuilderQueryCache;

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  DeliveryContext,
  useDelivery,
  useDelayedBot,
  formatMessages
} from '@ubisend/pulse-volt';
import { useSubscription } from '@ubisend/pulse-subscribe';

const LiveChatDeliveryProvider = ({ children, ticket }) => {
  const context = useDelivery();
  const { setQueuedMessages } = useDelayedBot();

  const handleMessage = useCallback(
    ({ responses }) => {
      // Message recieved is for another channel.
      if (responses[0].subscriber.id !== ticket.subscriber.id) {
        return;
      }

      setQueuedMessages(queued => {
        return queued.concat(formatMessages(responses));
      });
    },
    [setQueuedMessages, ticket.subscriber.id]
  );

  useSubscription('message', handleMessage);

  const sendMessage = () => {
    // All message sending done in AgentComposer.
  };

  return (
    <DeliveryContext.Provider value={{ ...context, sendMessage }}>
      {children}
    </DeliveryContext.Provider>
  );
};

LiveChatDeliveryProvider.propTypes = {
  ticket: PropTypes.shape({
    id: PropTypes.number.isRequired,
    is_active: PropTypes.bool.isRequired,
    subscriber: PropTypes.shape({
      id: PropTypes.number.isRequired
    }).isRequired
  }).isRequired
};

export default LiveChatDeliveryProvider;

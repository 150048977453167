import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useQuery, useMutation } from '@ubisend/pulse-hooks';
import {
  Flex,
  Label,
  Button,
  useNotification,
  Explainer
} from '@ubisend/pulse-components';
import { GroupSelect } from '@ubisend/pulse-groups';

import { updateGroups } from '../api/index';

const AgentGroups = ({ query, disabled }) => {
  const [groups, setGroups] = useState();

  const { showSuccess } = useNotification();

  const { data } = useQuery(query);
  const { mutate } = useMutation(updateGroups, {
    onSuccess: () => {
      showSuccess(`Successfully updated specialities.`);
    }
  });

  useEffect(() => {
    if (!groups && data) {
      setGroups(data.data.map(group => group.id));
    }
  }, [groups, data]);

  const handleGroupChange = options => {
    setGroups(options ? options.map(option => option.value) : []);
  };

  const handleSubmit = () => {
    mutate({ url: query, groups });
  };

  return (
    <Flex col>
      <Flex mb>
        <Explainer>
          Use specialities to get auto assigned tickets that are suitable.
        </Explainer>
      </Flex>
      <Flex col mb>
        <Label htmlFor="tags">Tags</Label>
        <GroupSelect
          id="tags"
          value={groups}
          isDisabled={disabled}
          onChange={handleGroupChange}
        />
      </Flex>
      {!disabled && (
        <Flex right>
          <Button variant="secondary" onClick={handleSubmit} icon="save">
            Save
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

AgentGroups.propTypes = {
  query: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default AgentGroups;

import { useRef, useEffect, useCallback } from 'react';

const useDocument = () => {
  const oldTitle = useRef(document.title);
  const interval = useRef();

  const setTitle = useCallback(title => {
    if (interval.current) {
      clearInterval(interval.current);
    }

    document.title = title;

    // Make the title flash to get the users attention.
    interval.current = setInterval(() => {
      document.title =
        document.title === oldTitle.current ? title : oldTitle.current;
    }, 2000);
  }, []);

  const restoreTitle = useCallback(() => {
    const title = oldTitle.current;

    if (interval.current) {
      clearInterval(interval.current);
    }

    document.title = title;
  }, []);

  /**
   * Restore title on unmount.
   */
  useEffect(() => {
    return () => restoreTitle();
  }, [restoreTitle]);

  /**
   * Restore title when tab is focussed
   */
  useEffect(() => {
    window.addEventListener('focus', restoreTitle);
    window.addEventListener('blur', restoreTitle);

    return () => {
      window.removeEventListener('focus', restoreTitle);
      window.removeEventListener('blur', restoreTitle);
    };
  });

  return { setTitle, restoreTitle };
};

export default useDocument;

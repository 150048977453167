import React, { useState } from 'react';

import { useQuery } from '@ubisend/pulse-hooks';

import { SheetsContext } from '../Contexts/index';

const SheetsProvider = ({ children }) => {
  const [create, setCreate] = useState(false);
  const [edit, setEdit] = useState(null);
  const [viewLookups, setViewLookups] = useState(null);

  const { data, isSuccess } = useQuery('spreadsheets');

  const getSheet = id => {
    return data.data.find(sheet => sheet.id === id);
  };

  const context = {
    sheets: isSuccess ? data.data : null,
    create,
    setCreate,
    edit,
    setEdit,
    viewLookups: viewLookups ? getSheet(viewLookups) : null,
    setViewLookups
  };

  return (
    <SheetsContext.Provider value={context}>{children}</SheetsContext.Provider>
  );
};

export default SheetsProvider;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import DayjsUtils from '@date-io/dayjs';

import { useTheme } from '../hooks/index';

const DateProvider = ({ children, primary }) => {
  const theme = useTheme();

  const defaultMaterialTheme = useMemo(() => {
    return createMuiTheme({
      typography: {
        fontFamily: ['Poppins', 'Open Sans']
      },
      palette: {
        primary: { main: primary || theme.gradient.from }
      },
      overrides: {
        MuiButton: {
          textPrimary: {
            color: '#000000'
          }
        }
      }
    });
  }, [theme, primary]);

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <MuiPickersUtilsProvider utils={DayjsUtils}>
        {children}
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

DateProvider.propTypes = {
  primary: PropTypes.string
};

export default DateProvider;

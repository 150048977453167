import React, { useState } from 'react';

import { useQuery, useQueryClient } from '@ubisend/pulse-hooks';
import {
  PageWrapper,
  OneHalfLayout,
  Flex,
  useNotification,
  Placeholder,
  Panel
} from '@ubisend/pulse-components';
import { FacebookProvider } from '@ubisend/pulse-facebook';

import {
  InstagramSettings,
  SetupInstagramComponent
} from '../Components/index';
import { updateSettings } from '../api/settings';

const InstagramLink = () => {
  const [pageSettings, setPageSettings] = useState();
  const { showSuccess, showError } = useNotification();
  const queryClient = useQueryClient();

  const { isLoading } = useQuery('instagram/settings', {
    onSuccess: data => {
      setPageSettings(data.data);
    }
  });

  const handlePageSelect = async page => {
    const {
      value: { id, access_token },
      label
    } = page;

    const response = await updateSettings({
      page_id: id,
      auth_token: access_token,
      page_name: label
    });

    if (response?.status === 201) {
      showSuccess(`Linked Facebook page ${label}`);
      queryClient.invalidateQueries('instagram/settings');
    } else {
      showError(`Something went wrong whilst selecting a Facebook page`);
    }
  };

  const handleUnlinkPage = () => setPageSettings(null);

  const hasSettings = Boolean(pageSettings?.auth_token);

  return (
    <FacebookProvider>
      <PageWrapper center header="Instagram">
        <Flex col center>
          <OneHalfLayout>
            {isLoading ? (
              <Panel>
                <Placeholder items={1} subitems={3} center />
              </Panel>
            ) : (
              <Flex mr>
                {!hasSettings && (
                  <SetupInstagramComponent
                    handlePageSelect={handlePageSelect}
                  />
                )}
                {hasSettings && (
                  <InstagramSettings
                    settings={pageSettings}
                    handleUnlinkPage={handleUnlinkPage}
                  />
                )}
              </Flex>
            )}
          </OneHalfLayout>
        </Flex>
      </PageWrapper>
    </FacebookProvider>
  );
};

export default InstagramLink;

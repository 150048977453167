import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  Flex,
  Label,
  Span,
  Accordion,
  Explainer,
  Button
} from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';

import { useQrCode } from '../hooks/index';
import QrCode from './QrCode';
import DataInput from './DataInput';
import FormatsSelect from './FormatsSelect';
import ScaleInput from './ScaleInput';
import MarginInput from './MarginInput';
import DarkColourInput from './DarkColourInput';
import LightColourInput from './LightColourInput';

const CreateQrCode = ({
  handleSave: passedHandleSave,
  submitText = 'Save'
}) => {
  const qr = useQrCode();

  const handleSave = async () => {
    const file = await qr.exportQrCode();

    passedHandleSave(file);
  };

  return (
    <Flex fat yScroll>
      <Flex col ySpace fat yScroll style={{ maxHeight: '30rem' }} pad>
        <Flex col>
          <Label htmlFor="data">Data</Label>
          <DataInput id="data" />
        </Flex>
        <Flex col>
          <Label htmlFor="format">Format</Label>
          <FormatsSelect id="format" />
        </Flex>
        {qr.format !== 'svg' && (
          <Accordion header="Size">
            <Flex mb>
              <Explainer>
                The size and margin settings are used to set the dimensions of
                the QR code when exporting to PNG, JPG or WebP formats.
              </Explainer>
            </Flex>
            <Flex col ySpace>
              <Flex col>
                <Label htmlFor="scale">Size</Label>
                <ScaleInput id="scale" />
              </Flex>
              <Flex col>
                <Label htmlFor="light">Margin</Label>
                <MarginInput id="dark" />
              </Flex>
            </Flex>
          </Accordion>
        )}
        <Accordion header="Colours">
          <Flex col ySpace>
            <Flex col>
              <Label htmlFor="dark">Foreground</Label>
              <DarkColourInput id="dark" />
            </Flex>
            <Flex col>
              <Label htmlFor="margin">Background</Label>
              <LightColourInput id="margin" />
            </Flex>
          </Flex>
        </Accordion>
        <Flex xSpace right>
          <PermissionFilter can="view documentation">
            <Button
              variant="secondary"
              as={Link}
              to="/docs/1386938492/1912602627"
              icon="questionMarkCircle">
              vCard Help
            </Button>
          </PermissionFilter>
          <Button disabled={!qr.valid} onClick={handleSave}>
            {submitText}
          </Button>
        </Flex>
      </Flex>
      <Flex fat middle center col xHidden pad>
        {!qr.valid && (
          <Flex>
            <Span light>Enter valid data to generate a QR Code</Span>
          </Flex>
        )}
        <QrCode />
      </Flex>
    </Flex>
  );
};

CreateQrCode.propTypes = {
  handleSave: PropTypes.func.isRequired,
  submitText: PropTypes.string
};

export default CreateQrCode;

import styled from 'styled-components';

const PreviewConditions = styled.div`
  ${tw`text-grey-dark my-2 flex-col items-center flex`}
  & div p {
    ${tw`m-0`}
  }
  & div > p:not(:last-child) {
    ${tw`mb-2`}
  }
`;

export default PreviewConditions;

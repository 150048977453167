import { useReducer, useMemo } from 'react';

import drivers from '../Components/FileDrivers/index';

const TYPES = {
  UPDATE_NAME: 'UPDATE_NAME',
  UPDATE_DRIVER_NAME: 'UPDATE_DRIVER_NAME',
  UPDATE_CONTENT: 'UPDATE_CONTENT'
};

const reducer = (state, { type, ...params }) => {
  switch (type) {
    // General file source details
    case TYPES.UPDATE_NAME:
      return { ...state, name: params.name };
    case TYPES.UPDATE_DRIVER_NAME:
      return {
        ...state,
        file_driver_name: params.fileDriverName,
        content: drivers[params.fileDriverName].setInitialContent()
      };

    // Per driver details
    case TYPES.UPDATE_CONTENT:
      return { ...state, content: { ...state.content, ...params.content } };

    // Errors
    default:
      throw new Error(`No event defined in useFileSourceReducer for ${type}`);
  }
};

const defaultFileSource = {
  file_driver_name: null,
  name: '',
  content: null
};

const useFileSourceReducer = (inititalFileSource = defaultFileSource) => {
  const [source, dispatch] = useReducer(reducer, {
    ...defaultFileSource,
    ...inititalFileSource
  });

  const valid = useMemo(() => {
    return (
      source.name &&
      source.file_driver_name &&
      drivers[source.file_driver_name].isValidContent(source.content)
    );
  }, [source]);

  const form = { source };

  return { source, dispatch, valid, form };
};

export default useFileSourceReducer;

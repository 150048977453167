import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { AnimateSharedLayout } from '@ubisend/framer-motion';
import { Flex, TabButton } from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';

import { useImports } from '../hooks/index';

const SectionNav = () => {
  const { importId, sectionId, section } = useImports();
  const location = useLocation();

  const buildLink = (path = '') => {
    return `/knowledge-bases/${importId}/sections/${sectionId}${path}`;
  };

  const checkActive = path => {
    return location.pathname.includes(buildLink(path));
  };

  return (
    <Flex xSpace mb shiftUp>
      <AnimateSharedLayout>
        <TabButton active={checkActive('/content')}>
          <Link to={buildLink('/content')}>Content</Link>
        </TabButton>
        <TabButton active={checkActive('/preview')}>
          <Link to={buildLink('/preview')}>Preview</Link>
        </TabButton>
        {section?.other_content?.length > 0 && (
          <TabButton active={checkActive('/versions')}>
            <Link to={buildLink('/versions')}>Versions</Link>
          </TabButton>
        )}
        <TabButton active={checkActive('/resources')}>
          <Link to={buildLink('/resources')}>Resources</Link>
        </TabButton>
        <TabButton active={checkActive('/metadata')}>
          <Link to={buildLink('/metadata')}>Metadata</Link>
        </TabButton>
        <PermissionFilter can="view faqs">
          <TabButton active={checkActive('/faqs')}>
            <Link to={buildLink('/faqs')}>FAQs</Link>
          </TabButton>
        </PermissionFilter>
      </AnimateSharedLayout>
    </Flex>
  );
};

export default SectionNav;

import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useBot } from '../../../hooks/index';

const KeyboardControls = () => {
  const history = useHistory();

  const { showClose } = useBot();

  const handleClose = useCallback(
    ({ key }) => {
      if (!showClose) {
        return;
      }

      if (key === 'Escape') {
        history.push('/');
      }
    },
    [history, showClose]
  );

  useEffect(() => {
    document.addEventListener('keyup', handleClose);

    return () => document.removeEventListener('keyup', handleClose);
  }, [handleClose]);

  return null;
};

export default KeyboardControls;

import React from 'react';

import CoreDeliveryProvider from '../../../Providers/DeliveryProvider';

const DeliveryProvider = ({ children, ...rest }) => (
  <CoreDeliveryProvider {...rest} demo={false} channelDriverName="Embedded">
    {children}
  </CoreDeliveryProvider>
);

export default DeliveryProvider;

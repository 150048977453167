import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      enabled: true,
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false
    },
    mutations: {
      enabled: true
    }
  }
});

const isInTest = process.env.NODE_ENV === 'test';

const DemoQueryProvider = ({ children }) => {
  // Let the test query provider manage queries.
  if (isInTest) {
    return children;
  }

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default DemoQueryProvider;

import useBuilder from './useBuilder';

const useStep = stepId => {
  const { conversation } = useBuilder();

  const step = conversation.steps.find(step => {
    return step.id === stepId;
  });

  return step;
};

export default useStep;

import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';

const Container = styled.h2`
  ${tw`font-poppins text-base md:text-2xl leading-tight font-semibold text-center whitespace-pre-wrap`}
  ${props =>
    props.children.length < 50
      ? tw`text-lg md:text-xl`
      : tw`text-base md:text-2xl`}
  @media(max-width: 320px) {
    ${props => (props.children.length < 50 ? tw`text-base` : tw`text-sm`)}
  }
  overflow-wrap: break-word;
`;

// How long to wait in ms before displaying each character
const characterDuration = 15;

const SplitText = ({ children }) => {
  const [charIndex, setCharIndex] = useState(0);
  const charInterval = useRef(null);

  const incrementChar = useCallback(() => setCharIndex(index => index + 1), []);

  useEffect(() => {
    if (!charInterval.current) {
      charInterval.current = setInterval(incrementChar, characterDuration);
    }
    return () => {
      if (charIndex === children.length) {
        clearInterval(charInterval.current);
      }
    };
  }, [incrementChar, charIndex, children.length]);

  return (
    <Container aria-label={children}>{children.slice(0, charIndex)}</Container>
  );
};

export default SplitText;

const conversation = [
  {
    direction: 'toClient',
    composer: {
      type: 'hidden'
    },
    content: {
      text: 'Sorry, there was an issue, one of our humans will take a look.',
      responses: [
        ...(process.env.REACT_APP_HELPDESK_URL
          ? [
              {
                label: 'submit an issue',
                action: process.env.REACT_APP_HELPDESK_URL,
                type: 'url'
              }
            ]
          : []),
        {
          label: 'take me home',
          action: '/',
          type: 'url'
        }
      ]
    },
    type: 'action'
  }
];

export { conversation };

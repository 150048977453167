import React from 'react';
import PackageSelect from 'react-select';
import PackageCreatableSelect from 'react-select/creatable';
import PackageAsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import chroma from 'chroma-js';

import useTheme from '../../hooks/useTheme';

const formatSelectOption = ({ id, name }) => ({
  value: id,
  label: name
});

const getSelectValue = (value, options) => {
  if (!value) {
    return null;
  }

  // Is object { value. label }
  if (value.value) {
    return value;
  }

  const option = options
    .map(formatSelectOption)
    .find(option => option.value === value);

  // Is just value.
  if (option) {
    return option;
  }

  return null;
};

const customStyles = {
  dropdownIndicator: () => tw`flex text-slate pl-2`,
  indicatorSeparator: () => tw`bg-grey-dark w-px self-stretch`,
  clearIndicator: styles => ({ ...styles, ...tw`px-2 py-0` }),
  multiValue: styles => ({ ...styles, ...tw`-my-1 mr-1 bg-red` }),
  input: () => tw`p-0`,
  menu: styles => ({ ...styles }),
  menuPortal: styles => ({ ...styles, zIndex: 9999 }),
  valueContainer: styles => ({ ...styles, ...tw`p-0 overflow-y-auto` }),
  container: styles => ({ ...styles, ...tw`flex-grow` })
};

const formatProps = ({ id, styles, ...props }) => ({
  ...(process.env.NODE_ENV !== 'test' && { menuPortalTarget: document.body }),
  styles: { ...customStyles, ...styles },
  menuPlacement: 'auto',
  ...(id && { inputId: id }),
  ...props
});

const Wrapper = ({ Component, isMulti, ...props }) => {
  const theme = useTheme();

  const styles = {
    multiValueLabel: styles => ({
      ...styles,
      ...tw`font-poppins uppercase leading-none text-xs`,
      color: theme.primary
    }),
    multiValue: styles => ({
      ...styles,
      ...tw`font-poppins uppercase leading-none text-xs my-0`,
      backgroundColor: chroma(theme.primary).alpha(0.25).css()
    }),
    multiValueRemove: styles => ({ ...styles, color: theme.primary }),
    option: (styles, state) => {
      return {
        ...styles,
        boxShadow: 'none',
        background: state.isSelected
          ? theme.primary
          : state.isFocused
          ? chroma(theme.primary).alpha(0.25).css()
          : 'none',
        color: state.isSelected || state.isFocuesed ? 'white' : state.color,
        '&:hover': {
          background:
            state.isSelected || state.isFocuesed
              ? theme.primary
              : chroma(theme.primary).alpha(0.25).css(),
          color: state.isSelected || state.isFocuesed ? 'white' : 'black'
        }
      };
    },
    control: (base, state) => {
      return {
        ...tw`w-full py-2 px-3 flex text-sm rounded bg-grey-light`,
        maxHeight: '64',
        boxSizing: 'border-box',
        minWidth: '8rem',
        transition: `border-color 0.3s cubic-bezier(0.16, 1, 0.3, 1),
        box-shadow 0.3s cubic-bezier(0.16, 1, 0.3, 1),
        background-color 0.3s cubic-bezier(0.16, 1, 0.3, 1)`,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: state.isFocused
          ? `${chroma(theme.primary).alpha(0.5).css()}`
          : `transparent`,
        boxShadow: state.isFocused
          ? `0 0 0 0.2rem ${chroma(theme.primary).alpha(0.25)}`
          : 0,
        background: state.isFocused ? 'white' : tw`bg-grey-light`,
        '&:hover': {
          boxShadow: `0 0 0 0.2rem ${chroma(theme.primary).alpha(0.25).css()}`
        },
        '&:active': {
          boxShadow: 'none'
        }
      };
    }
  };

  return <Component {...formatProps({ styles, isMulti, ...props })} />;
};

Wrapper.propTypes = {
  Component: PropTypes.func,
  isMulti: PropTypes.bool
};

const Select = props => <Wrapper Component={PackageSelect} {...props} />;

const CreatableSelect = props => (
  <Wrapper Component={PackageCreatableSelect} {...props} />
);

const AsyncSelect = props => (
  <Wrapper Component={PackageAsyncSelect} {...props} />
);

export {
  Select,
  CreatableSelect,
  AsyncSelect,
  customStyles,
  formatSelectOption,
  getSelectValue
};

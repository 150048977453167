import { useRef, useEffect, useCallback } from 'react';

const SAVE_REORDER_DEBOUNCE = 2000;

const orderByIndex = (a, b) => a.index - b.index;

const SaveSidebarOrder = ({ payload, handleReorder }) => {
  const orderRef = useRef(null);

  const formatPayload = payload => {
    return payload.sort(orderByIndex).map(({ id }) => id);
  };

  const checkIfPayloadHasBeenReordered = useCallback(payload => {
    return formatPayload(payload).join() !== orderRef.current.join();
  }, []);

  /**
   * Initialise ref with first value of imports order.
   */
  useEffect(() => {
    if (!orderRef.current) {
      orderRef.current = formatPayload(payload);
    }
  }, [payload]);

  /**
   * Everytime the order of the payload changes, start a timer to save the new
   * order and debounce any future changes.
   */
  useEffect(() => {
    let timeout;

    if (orderRef.current && checkIfPayloadHasBeenReordered(payload)) {
      timeout = setTimeout(() => {
        orderRef.current = formatPayload(payload);
        handleReorder(orderRef.current);
      }, SAVE_REORDER_DEBOUNCE);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [payload, checkIfPayloadHasBeenReordered, handleReorder]);

  return null;
};

export default SaveSidebarOrder;
export { orderByIndex };

import { useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { useNotification } from '@ubisend/pulse-components';

/**
 * Handle rendering a route and performing tasks once the route is loaded.
 * - Notifications: Show a notification when the route has been rendered.
 */
const useRouteEffect = () => {
  const { showNotification } = useNotification();

  const history = useHistory();
  const location = useLocation();

  const replaceState = useCallback(
    state => {
      // Remove the state from the history. This will prevent the state persisting
      // on page refresh.
      history.replace({ state });
    },
    [history]
  );

  // Show a notification
  useEffect(() => {
    if (location.state?.notification) {
      const { notification, ...state } = location.state;

      showNotification(location.state.notification);

      replaceState(state);
    }
  }, [showNotification, replaceState, location]);
};

export default useRouteEffect;

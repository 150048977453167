import React from 'react';

import { useQuerySearch } from '@ubisend/pulse-hooks';
import { Flex } from '@ubisend/pulse-components';

import { Demo } from '../Components/index';

const DEFAULT_BOT = 'converse';

const PublicDemo = ({ location }) => {
  const bot = useQuerySearch(location, 'channel') || DEFAULT_BOT;
  const hash = useQuerySearch(location, 'hash');
  const client_id = useQuerySearch(location, 'client');

  if (hash !== process.env.REACT_APP_DEMO_HASH) {
    return null;
  }

  if (!client_id || !bot) {
    return null;
  }

  return (
    <Flex yScreen middle>
      <Demo bot={bot} client={client_id} isPublic />
    </Flex>
  );
};

export default PublicDemo;

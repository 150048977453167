import { useQuery as reactQueryUseQuery } from 'react-query';

const formatQuery = query => {
  const hasResults =
    query.isSuccess && query.data.data && query.data.data.length > 0;
  const showTable = query.isLoading || hasResults;
  const showPagination = showTable && query.isSuccess;
  const showNoResultsMessage = !showTable;

  return {
    ...query,
    showTable,
    hasResults,
    showPagination,
    showNoResultsMessage
  };
};

const useQuery = (...params) => {
  const query = reactQueryUseQuery(...params);

  return formatQuery(query);
};

export default useQuery;
export { formatQuery };

import S3Content from './S3Content';

const s3 = {
  features: {
    // Filters
    search: false,
    // Fields
    groups: false,
    description: false,
    // Actions
    edit: false
  },
  Content: S3Content,
  setInitialContent: (content = { prefixes: {} }) => {
    return {
      region: content.region || null,
      bucket: content.bucket || '',
      access_key_id: content.access_key_id || '',
      secret_access_key: content.secret_access_key || '',
      prefixes: {
        image: content.prefixes.image || '/image',
        document: content.prefixes.document || '/document',
        pdf: content.prefixes.pdf || '/pdf',
        spreadsheet: content.prefixes.spreadsheet || '/spreadsheet',
        'qr-code': content.prefixes['qr-code'] || '/qr-code',
        json: content.prefixes.json || '/json'
      }
    };
  },
  isValidContent: content => {
    return Boolean(
      content.region &&
        content.bucket &&
        content.access_key_id &&
        content.secret_access_key
    );
  }
};

export default s3;

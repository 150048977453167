import React from 'react';

import { FormGroup, Label, TextInput, Flex } from '@ubisend/pulse-components';

import { useVariable } from '../../../../hooks/index';
import { IntegrationSelect, EndpointSelect } from '../../../Integrations/index';

const SaveComposer = () => {
  const { variable, dispatch } = useVariable();

  const handleIntegrationChange = option => {
    dispatch({
      type: 'CHANGE_SUBJECT',
      newSubject: {
        integration_id: option ? option.value : null,
        endpoint_id: null
      }
    });
  };

  const handleEndpointChange = ({ value }) => {
    dispatch({
      type: 'CHANGE_SUBJECT',
      newSubject: { endpoint_id: value }
    });
  };

  const handleTargetChange = event => {
    const target = event.target.value;

    dispatch({
      type: 'CHANGE_SUBJECT',
      newSubject: { target }
    });
  };

  return (
    <Flex col pl pr pt>
      <FormGroup>
        <Label htmlFor="integration">Integration</Label>
        <IntegrationSelect
          id={'integration'}
          value={variable.subject.integration_id}
          onChange={handleIntegrationChange}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="endpoint">Endpoint</Label>
        <EndpointSelect
          id="endpoint"
          integration={variable.subject.integration_id}
          value={variable.subject.endpoint_id}
          onChange={handleEndpointChange}
        />
      </FormGroup>
      <Flex col>
        <Label htmlFor="target">Target</Label>
        <TextInput
          id="target"
          placeholder="response.body.0.value"
          value={variable.subject.target}
          onChange={handleTargetChange}
        />
      </Flex>
    </Flex>
  );
};

export default SaveComposer;

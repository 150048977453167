import styled from 'styled-components';

const HeaderSvg = styled.svg`
  ${tw`w-4 h-4`};
  @media only screen and (max-width: 420px) {
    ${tw`w-6 h-6`};
  }
  color: ${props => props.theme.bot.textColour};
`;

export default HeaderSvg;

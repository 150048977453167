import React from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import { Flex, Select, TextInput, Button } from '@ubisend/pulse-components';

import { useTriggerStep } from '../../hooks/index';

const VariablesSelect = ({ value, ...props }) => {
  const { data, isLoading, isSuccess } = useQuery('variables/all');

  const format = value => ({
    value: value.id,
    label: value.name
  });

  return (
    <Select
      value={
        isSuccess &&
        value &&
        format(data.data.find(variable => variable.id === value))
      }
      options={isSuccess ? data.data.map(format) : []}
      isLoading={isLoading}
      {...props}
    />
  );
};

VariablesSelect.propTypes = {
  value: PropTypes.number
};

const VariableRow = ({ variable, onChange }) => {
  const handleVariableChange = option => {
    onChange({
      id: option ? option.value : null,
      value: variable.value
    });
  };

  const handleValueChange = event => {
    const value = event.target.value;

    onChange({
      value,
      id: variable.id
    });
  };

  return (
    <Flex between xSpace fat>
      <Flex fat>
        <VariablesSelect
          value={variable.id}
          onChange={handleVariableChange}
          aria-label="variable"
        />
      </Flex>
      <TextInput
        value={variable.value}
        onChange={handleValueChange}
        placeholder="My variable value"
      />
    </Flex>
  );
};

VariableRow.propTypes = {
  variable: PropTypes.shape({
    id: PropTypes.number,
    value: PropTypes.string
  }).isRequired,
  onChange: PropTypes.func.isRequired
};

const VariableDetails = () => {
  const { trigger, dispatch } = useTriggerStep();

  const handleEditVariable = index => variable => {
    dispatch({ type: 'UPDATE_VARIABLE', index, variable });
  };

  const handleAddVariable = () => {
    dispatch({ type: 'ADD_VARIABLE' });
  };

  const handleDeleteVariable = index => () => {
    dispatch({ type: 'DELETE_VARIABLE', index });
  };

  return (
    <Flex col ySpace fat>
      {trigger.variables.map((variable, index) => (
        <Flex xSpace fat key={index}>
          <VariableRow
            variable={variable}
            onChange={handleEditVariable(index)}
          />
          <Button
            variant="secondary"
            colour="danger"
            icon="trash"
            onClick={handleDeleteVariable(index)}>
            Delete
          </Button>
        </Flex>
      ))}
      <Flex>
        <Button variant="secondary" onClick={handleAddVariable}>
          + variable
        </Button>
      </Flex>
    </Flex>
  );
};

export default VariableDetails;

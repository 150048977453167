/* eslint-disable */
/*! rxp-js - v1.3.1 - 2018-08-30
 * The official Realex Payments JS Library
 * https://github.com/realexpayments/rxp-js
 * Licensed MIT
 */
const RealexHpp = function () {
  'use strict';

  var hppUrl = 'https://pay.realexpayments.com/pay';

  var setHppUrl = function (url) {
    hppUrl = url;
  };

  var redirectUrl;

  var internal = {
    createFormHiddenInput: function (name, value) {
      var el = document.createElement('input');
      el.setAttribute('type', 'hidden');
      el.setAttribute('name', name);
      el.setAttribute('value', value);
      return el;
    },

    createForm: function (doc, token) {
      var form = document.createElement('form');
      form.setAttribute('method', 'POST');
      form.setAttribute('action', hppUrl);

      var versionSet = false;

      for (var key in token) {
        if (key === 'HPP_VERSION') {
          versionSet = true;
        }
        form.appendChild(internal.createFormHiddenInput(key, token[key]));
      }

      if (versionSet === false) {
        form.appendChild(internal.createFormHiddenInput('HPP_VERSION', '2'));
      }

      var parser = internal.getUrlParser(window.location.href);
      var hppOriginParam = parser.protocol + '//' + parser.host;

      form.appendChild(
        internal.createFormHiddenInput('HPP_POST_RESPONSE', hppOriginParam)
      );
      form.appendChild(
        internal.createFormHiddenInput('HPP_POST_DIMENSIONS', hppOriginParam)
      );
      return form;
    },

    getUrlParser: function (url) {
      var parser = document.createElement('a');
      parser.href = url;
      return parser;
    }
  };

  var RxpEmbedded = (function () {
    var instance;

    function init() {
      var iFrame;
      var token;

      return {
        embedded: function () {
          var form = internal.createForm(document, token);

          if (iFrame) {
            if (iFrame.contentWindow.document.body) {
              iFrame.contentWindow.document.body.appendChild(form);
            } else {
              iFrame.contentWindow.document.appendChild(form);
            }
            form.submit();
            iFrame.style.display = 'inherit';
          }
        },
        setToken: function (hppToken) {
          token = hppToken;
        },
        setIframe: function (iframeId) {
          iFrame = document.getElementById(iframeId);
        },
        getIframe: function () {
          return iFrame;
        }
      };
    }

    return {
      // Get the Singleton instance if one exists
      // or create one if it doesn't
      getInstance: function (hppToken) {
        if (!instance) {
          instance = init();
        }

        //Set the hpp token
        instance.setToken(hppToken);

        return instance;
      },
      init: function (frameId, json) {
        var embeddedInstance = RxpEmbedded.getInstance(json);

        embeddedInstance.setIframe(frameId);
        embeddedInstance.embedded(true);
      }
    };
  })();

  return { init: RxpEmbedded.init, setHppUrl: setHppUrl };
};

export default RealexHpp;

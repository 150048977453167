import axios from 'axios';

const createUtterance = ({ faqId, utterance }) =>
  axios.post(`faqs/${faqId}/utterances`, utterance);

const updateUtterance = ({ faqId, utteranceId, utterance }) =>
  axios.put(`faqs/${faqId}/utterances/${utteranceId}`, utterance);

const deleteUtterance = ({ faqId, utteranceId }) =>
  axios.delete(`faqs/${faqId}/utterances/${utteranceId}`);

export { createUtterance, updateUtterance, deleteUtterance };

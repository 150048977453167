import React from 'react';
import PropTypes from 'prop-types';

const ConditionalPreview = ({ subjects, ...props }) => {
  const { Preview } = subjects.find(
    ({ subject }) => subject.type === (props.subject?.type || 'message')
  );

  if (!Preview) {
    return null;
  }

  return <Preview {...props} />;
};

ConditionalPreview.propTypes = {
  subject: PropTypes.shape({ type: PropTypes.string }),
  subjects: PropTypes.arrayOf(
    PropTypes.shape({ type: PropTypes.string, Preview: PropTypes.elementType })
  )
};

export default ConditionalPreview;

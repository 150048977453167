import integration from './Integration/index';
import lookup from './Lookup/index';
import custom from './Custom/index';
import created from './Created/index';
import defaultGroup from './DefaultGroup/index';

const groups = {
  integration,
  lookup,
  custom,
  created,
  default: defaultGroup
};

export default groups;

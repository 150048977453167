import React, { useState } from 'react';

import {
  Button,
  FormGroup,
  InputOverlay,
  Flex,
  TextInput,
  Label,
  InputButton
} from '@ubisend/pulse-components';
import Icon from '@ubisend/pulse-icons';
import { useMutation } from '@ubisend/pulse-hooks';

import { login } from '../../../api/index';
import { useAuth } from '../../../hooks/index';
import AuthLink from '../../AuthLink';

const useEmail = () => {
  return useState(
    process.env.NODE_ENV === 'development' ? 'admin@test.com' : ''
  );
};

const usePassword = () => {
  return useState(process.env.NODE_ENV === 'development' ? 'password' : '');
};

const EmailPasswordLogin = () => {
  const [email, setEmail] = useEmail();
  const [password, setPassword] = usePassword();
  const [showPassword, setShowPassword] = useState(false);

  const { loginUser } = useAuth();

  const mutation = useMutation(login, {
    onSuccess: ({ data }) => {
      const { token, client, ...user } = data.data;
      loginUser({ user, token, client });
    }
  });

  const handleEmailChange = event => setEmail(event.target.value);

  const handlePasswordChange = event => setPassword(event.target.value);

  const handleShowPassword = event => {
    event.preventDefault();
    setShowPassword(showPassword => !showPassword);
  };

  const valid = Boolean(email && password);

  const handleSubmit = event => {
    event.preventDefault();

    if (!valid) {
      return;
    }

    mutation.mutate({ email, password });
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormGroup>
        <Label htmlFor="email">Email</Label>
        <Flex relative>
          <TextInput
            id="email"
            aria-label="email-input"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
          />
          <InputOverlay right>
            <Icon
              size="1.25rem"
              width="1.25rem"
              height="1.25rem"
              type={'mail'}
            />
          </InputOverlay>
        </Flex>
      </FormGroup>
      <FormGroup>
        <Label htmlFor="password">Password</Label>
        <Flex relative>
          <TextInput
            id="password"
            aria-label="password-input"
            type={showPassword ? 'text' : 'password'}
            placeholder="Password"
            value={password}
            onChange={handlePasswordChange}
          />
          <InputButton
            type="button"
            aria-label={`${showPassword ? 'hide' : 'show'} password`}
            onClick={handleShowPassword}>
            <Icon
              size="1.25rem"
              width="1.25rem"
              height="1.25rem"
              type={showPassword ? 'eye' : 'eyeOff'}
            />
          </InputButton>
        </Flex>
      </FormGroup>
      <Flex ySpace center col>
        <Button
          variant="primary"
          fat
          mt
          middle
          disabled={!valid}
          type="submit"
          loading={mutation.isLoading}>
          {mutation.isLoading ? 'Logging in' : 'Log in'}
        </Button>
        <Button variant="inline" as={AuthLink} to="/password/request">
          Forgot your password?
        </Button>
      </Flex>
    </form>
  );
};

export default EmailPasswordLogin;

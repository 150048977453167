import axios from 'axios';

const createTrigger = ({ conversationId, trigger }) => {
  return axios.post(
    `conversations/builder/${conversationId}/triggers`,
    trigger
  );
};

const updateTrigger = ({ conversationId, triggerId, trigger }) => {
  return axios.put(
    `conversations/builder/${conversationId}/triggers/${triggerId}`,
    trigger
  );
};

const deleteTrigger = ({ conversationId, triggerId }) => {
  return axios.delete(
    `conversations/builder/${conversationId}/triggers/${triggerId}`
  );
};

const createStepTrigger = ({ stepId, trigger }) => {
  return axios.post(`conversations/builder/steps/${stepId}/triggers`, trigger);
};

const updateStepTrigger = ({ stepId, triggerId, trigger }) => {
  return axios.put(
    `conversations/builder/steps/${stepId}/triggers/${triggerId}`,
    trigger
  );
};

const updateStepTriggerPosition = ({ stepId, triggerId, position }) => {
  return axios.put(
    `conversations/builder/steps/${stepId}/triggers/${triggerId}/position`,
    position
  );
};

const deleteStepTrigger = ({ stepId, triggerId }) => {
  return axios.delete(
    `conversations/builder/steps/${stepId}/triggers/${triggerId}`
  );
};

export {
  createTrigger,
  updateTrigger,
  deleteTrigger,
  createStepTrigger,
  updateStepTrigger,
  updateStepTriggerPosition,
  deleteStepTrigger
};

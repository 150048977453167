import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { useComposer } from '../hooks/index';

const DEBOUNCE_TIME = 3000;

const RecordTyping = ({ enabled = false, startTyping, stopTyping }) => {
  const [typing, setTyping] = useState(false);
  const hasTyped = useRef(false);

  const { reply } = useComposer();

  useEffect(() => {
    if (!enabled) {
      return;
    }

    if (typing) {
      startTyping();
    } else if (hasTyped.current) {
      stopTyping();
    }
  }, [typing, startTyping, stopTyping, enabled]);

  useEffect(() => {
    let timeout;

    if (hasTyped.current) {
      setTyping(true);

      timeout = setTimeout(() => {
        setTyping(false);
      }, DEBOUNCE_TIME);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [reply]);

  useEffect(() => {
    if (enabled && reply.length > 0) {
      hasTyped.current = true;
    }
  }, [reply, enabled]);

  return null;
};

RecordTyping.propTypes = {
  enabled: PropTypes.bool.isRequired,
  startTyping: PropTypes.func.isRequired,
  stopTyping: PropTypes.func.isRequired
};

export default RecordTyping;

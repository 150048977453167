import React, { useState, useEffect } from 'react';

import loadGlobalPay from '@ubisend/global-pay';

import GlobalPayContext from './GlobalPayContext';

const GlobalPayProvider = ({ children }) => {
  const [pay, setPay] = useState();

  useEffect(() => {
    setPay(loadGlobalPay());

    return () => {
      if (window.RealexHpp) {
        delete window.RealexHpp;
      }

      if (window.RealexRemote) {
        delete window.RealexRemote;
      }
    };
  }, []);

  if (!pay) {
    return null;
  }

  return (
    <GlobalPayContext.Provider value={pay}>
      {children}
    </GlobalPayContext.Provider>
  );
};

export default GlobalPayProvider;

import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import { useAuth } from '../hooks/index';
import {
  UnauthenticatedRedirect,
  UnauthorizedRedirect
} from './Redirects/index';

const PrivateRoute = ({ canAccess, ...rest }) => {
  const auth = useAuth();

  if (!auth.authenticated) {
    return <UnauthenticatedRedirect />;
  }

  if (!canAccess(auth)) {
    return <UnauthorizedRedirect />;
  }

  return <Route {...rest} />;
};

PrivateRoute.propTypes = {
  canAccess: PropTypes.func
};

export default PrivateRoute;

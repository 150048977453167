import React from 'react';
import { Redirect } from 'react-router-dom';

const LoggedOutRedirect = () => {
  return (
    <Redirect
      to={{
        pathname: '/',
        state: {
          notification: {
            type: 'success',
            message: 'You have been successfully logged out.'
          }
        }
      }}
    />
  );
};

export default LoggedOutRedirect;

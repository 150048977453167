import { useEffect } from 'react';

import useNotifications from './useNotifications';
import { readNotifications } from '../api/index';
import useMutation from './useMutation';

const useReadNotifications = () => {
  const { notifications } = useNotifications();

  const { mutate, isEnabled } = useMutation(readNotifications);

  useEffect(() => {
    const unread = notifications.filter(({ read_at }) => !read_at);

    if (unread.length > 0 && isEnabled) {
      mutate({
        notification_ids: unread.map(unread => unread.id)
      });
    }
  }, [notifications, mutate, isEnabled]);
};

export default useReadNotifications;

import { hasPermission } from '@ubisend/pulse-auth';

import {
  Tickets,
  Ticket,
  ArchivedTickets,
  Me,
  Agent,
  Agents,
  BotTickets,
  TicketDrivers
} from './Pages/index';

const routes = [
  {
    path: '/tickets',
    component: Tickets,
    name: 'Tickets',
    canAccess: hasPermission('view tickets'),
    actions: [
      {
        title: 'Manage your tickets',
        description:
          'View and pickup support tickets raised by your subscribers.',
        icon: 'ticket'
      }
    ]
  },
  {
    path: '/tickets/archived',
    component: ArchivedTickets,
    name: 'Archived Tickets',
    canAccess: hasPermission('view tickets')
  },
  {
    path: '/tickets/bot',
    component: BotTickets,
    name: 'Bot Tickets',
    canAccess: hasPermission('view tickets')
  },
  {
    path: '/tickets/agents',
    component: Agents,
    name: 'Agents',
    canAccess: hasPermission('view agents'),
    actions: [
      {
        title: 'Manage your team',
        description: 'Monitor individual agent performances.',
        icon: 'users'
      }
    ]
  },
  {
    path: '/tickets/agents/me',
    component: Me,
    name: 'Agent Overview',
    canAccess: hasPermission('view tickets'),
    actions: [
      {
        title: 'View your support tickets',
        description: 'Monitor your personal performance.',
        icon: 'ticket'
      }
    ]
  },
  {
    path: '/tickets/agents/:id',
    component: Agent,
    name: 'Agent Overview',
    canAccess: hasPermission('view agents')
  },
  {
    path: '/tickets/drivers',
    component: TicketDrivers,
    name: 'Ticket Drivers',
    canAccess: hasPermission('view ticket providers')
  },
  {
    path: '/tickets/:id',
    component: Ticket,
    name: 'Ticket Details',
    canAccess: hasPermission('view tickets')
  }
];

export default routes;

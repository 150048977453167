import { useContext } from 'react';

import { DeliveryContext } from '../Contexts/index';

const useDelivery = () => {
  const delivery = useContext(DeliveryContext);

  return delivery;
};

export default useDelivery;

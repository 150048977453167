import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Span } from '@ubisend/pulse-components';

const GlobalRoleDebug = ({ role, children, hasGlobalRole }) => {
  return (
    <Flex style={{ background: 'red', color: 'white' }}>
      <Span>
        {role} ({hasGlobalRole ? 'has' : "don't have"})
      </Span>
      {children}
    </Flex>
  );
};

GlobalRoleDebug.propTypes = {
  role: PropTypes.string.isRequired,
  hasGlobalRole: PropTypes.bool.isRequired
};

export default GlobalRoleDebug;

import React from 'react';

import types from './Types/index';
import { useVariable } from '../../hooks/index';

const VariableDefaultValueInput = () => {
  const { variable } = useVariable();

  const { DefaultValueInput } = types.all.find(
    type => type.key === variable.type
  );

  return <DefaultValueInput />;
};

export default VariableDefaultValueInput;

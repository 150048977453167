import React from 'react';

import { GroupContext } from '../Contexts/index';
import { useGroupReducer } from '../reducers/index';

const GroupProvider = ({ children }) => {
  const reducer = useGroupReducer();

  return (
    <GroupContext.Provider value={reducer}>{children}</GroupContext.Provider>
  );
};

export default GroupProvider;

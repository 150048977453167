import React from 'react';

import { TextInput, Flex } from '@ubisend/pulse-components';

const NoAuth = () => {
  return (
    <Flex bottom>
      <TextInput readOnly disabled value="No Credentials Needed" />
    </Flex>
  );
};

export default NoAuth;

import React from 'react';

import { motion } from '@ubisend/framer-motion';

const AnimatedAuthContainer = ({ children }) => (
  <motion.div
    col
    initial={{ opacity: 0, x: -20 }}
    animate={{ opacity: 1, x: 0 }}
    exit={{ opacity: 0, x: 20 }}
    transition={{
      x: {
        type: 'spring',
        stiffness: 400,
        damping: 60
      }
    }}>
    {children}
  </motion.div>
);

export default AnimatedAuthContainer;

/**
 * hey-there to Hey There
 */
const titleCase = string =>
  string
    .toLowerCase()
    .replace(/-/g, ' ')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

/**
 * Hey There to hey-there.
 */
const dashCase = string => string.replace(/\s+/g, '-').toLowerCase();

export { titleCase, dashCase };

import useQuery from './useQuery';

const formatSettings = settings => {
  const bot = {
    name: settings.name,
    title: settings.banner_title,
    subtitle: settings.banner_subtitle,
    showBurgerIcon: settings.has_buttons,
    buttons: settings.buttons,
    showName: settings.name_enabled,
    showLogo: settings.logo_enabled,
    logoLink: settings.logo_link,
    logo: settings.logo,
    showAvatars: settings.avatars_enabled,
    humanAvatarUrl: settings.human_avatar_url,
    botAvatarUrl: settings.bot_avatar_url,
    resetOnClose: settings.reset_on_close,
    initialShowCta: settings.call_to_action_enabled,
    ctaText: settings.call_to_action_text,
    showPoweredByUbisend: settings.styles?.showPoweredByUbisend,
    showReset: settings.reset_enabled,
    showChangeLanguage: settings.change_subscriber_language_enabled,
    showExportTranscript: settings.export_transcript_enabled,
    showExportSubscriber: settings.export_subscriber_enabled,
    showDeleteSubscriber: settings.delete_subscriber_enabled
  };

  return {
    bot,
    styles: { ...settings.styles, logo: settings.logo }
  };
};

const useSettings = (query, options = {}) => {
  const { data, ...rest } = useQuery(query, options);

  return {
    ...(rest.isSuccess && formatSettings(data.data)),
    ...rest
  };
};

export { formatSettings };
export default useSettings;

import { useCallback } from 'react';

const checkNotificationAccess = async () => {
  if (!('Notification' in window)) {
    return false;
  }

  if (Notification.permission === 'granted') {
    return true;
  }

  if (Notification.permission === 'denied') {
    return false;
  }

  const permission = await Notification.requestPermission();

  if (permission === 'deinied') {
    return false;
  }

  return true;
};

const useBrowser = () => {
  const notify = useCallback(async ({ title, body }) => {
    try {
      const hasAccess = await checkNotificationAccess();

      if (!hasAccess) {
        return;
      }

      new Notification(title, {
        body,
        icon: 'https://pulse-common-assets.s3.eu-west-2.amazonaws.com/pulse.png'
      });
    } catch {
      //
    }
  }, []);

  return { notify };
};

export default useBrowser;

import query from '../query';
import LineGraphMultiMetric from './LineGraphMultiMetric';

const lineGraphMulti = {
  name: 'Configurable line graph',
  Component: LineGraphMultiMetric,
  columns: 6,
  rows: 3,
  query
};

export default lineGraphMulti;

import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  Heading2,
  Label,
  LeftHalfLayout,
  RightHalfLayout,
  Paragraph,
  Flex,
  Panel,
  TextInput,
  Button
} from '@ubisend/pulse-components';
import { TopSection } from '@ubisend/pulse-tickets';
import { PermissionFilter } from '@ubisend/pulse-auth';

import { getName } from '../LogName';
import LogLevel from '../LogLevel';

const SemanticSearchContent = ({
  subscriber,
  created_at,
  description,
  content,
  level,
  type
}) => (
  <Panel>
    <Flex col mb>
      <TopSection center>
        <LogLevel level={level} />
        <Heading2>{getName({ level, type })}</Heading2>
      </TopSection>
      <Label secondary>
        {dayjs(created_at).from(dayjs())} by{' '}
        {subscriber ? `Subscriber #${subscriber.id}` : 'N/A'}
      </Label>
    </Flex>
    <Flex>
      <LeftHalfLayout>
        <Label>Description</Label>
        <Paragraph>{description}</Paragraph>
      </LeftHalfLayout>
      <RightHalfLayout>
        <div>
          <Label>Message</Label>
          <Flex xSpace>
            <TextInput value={content.message} disabled />
            <PermissionFilter can="view messages">
              <Button
                as={Link}
                variant="secondary"
                icon="eye"
                to={`/conversations/${subscriber.id}`}>
                View
              </Button>
            </PermissionFilter>
          </Flex>
        </div>
      </RightHalfLayout>
    </Flex>
  </Panel>
);

SemanticSearchContent.propTypes = {
  subscriber: PropTypes.shape({
    id: PropTypes.number
  }),
  created_at: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  content: PropTypes.shape({
    message: PropTypes.string
  })
};

export default SemanticSearchContent;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import {
  Flex,
  FormGroup,
  Label,
  TextInput,
  DateTimePicker,
  Button,
  TextArea
} from '@ubisend/pulse-components';
import { GroupSelect } from '@ubisend/pulse-groups';

const LinkDetails = ({
  initialName = '',
  initialUrl = '',
  initialExpiry = null,
  initialGroups = [],
  initialNotes = '',
  initialExpiredLink = null,
  handleSubmit,
  loading
}) => {
  const [name, setName] = useState(initialName);
  const [url, setUrl] = useState(initialUrl);
  const [expiry, setExpiry] = useState(initialExpiry);
  const [expiredLink, setExpiredLink] = useState(initialExpiredLink);
  const [groups, setGroups] = useState(initialGroups);
  const [notes, setNotes] = useState(initialNotes);

  const handleNameChange = event => setName(event.target.value);

  const handleUrlChange = event => setUrl(event.target.value);

  const handleExpiryChange = ([dateTime]) => setExpiry(dateTime);

  const handleExpiredLinkChange = event => setExpiredLink(event.target.value);

  const handleNotesChange = event => setNotes(event.target.value);

  const handleGroupsChange = groups => {
    setGroups(groups ? groups.map(group => group.value) : []);
  };

  const onSubmit = event => {
    event.preventDefault();

    handleSubmit({
      name,
      url,
      expiry: expiry ? dayjs(expiry).format('YYYY-MM-DD HH:mm:ss') : null,
      groups,
      notes,
      expiry_link: expiredLink
    });
  };

  const isValid = Boolean(url);

  return (
    <form onSubmit={onSubmit} method="post">
      <FormGroup>
        <Label htmlFor="name">Name</Label>
        <TextInput
          type="name"
          name="name"
          id="name"
          placeholder="My Link"
          value={name}
          onChange={handleNameChange}
        />
      </FormGroup>
      <FormGroup col mt>
        <Label htmlFor="url">URL to track</Label>
        <TextInput
          type="url"
          name="url"
          id="url"
          placeholder="https://example.com"
          value={url}
          onChange={handleUrlChange}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="tags">Tags</Label>
        <GroupSelect
          id="tags"
          for="links"
          value={groups}
          onChange={handleGroupsChange}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="name">Notes</Label>
        <TextArea
          name="notes"
          id="notes"
          value={notes}
          onChange={handleNotesChange}
        />
      </FormGroup>
      <FormGroup col mt>
        <Label htmlFor="url">Expires at</Label>
        <Flex xSpace>
          <DateTimePicker
            value={expiry}
            placeholder="Never"
            onChange={handleExpiryChange}
          />
          <Flex>
            <Button variant="secondary" onClick={() => setExpiry(null)}>
              Clear
            </Button>
          </Flex>
        </Flex>
      </FormGroup>
      <FormGroup col mt>
        <Label htmlFor="expired-link">Redirect url when link has expired</Label>
        <TextInput
          type="url"
          name="expired-link"
          id="expired-link"
          placeholder="https://example.com/expired"
          value={expiredLink}
          onChange={handleExpiredLinkChange}
        />
      </FormGroup>
      <Flex mt right>
        <Button type="submit" icon="save" disabled={loading || !isValid}>
          Save
        </Button>
      </Flex>
    </form>
  );
};

LinkDetails.propTypes = {
  initialName: PropTypes.string,
  initialUrl: PropTypes.string,
  initialExpiry: PropTypes.instanceOf(Date),
  initialGroups: PropTypes.arrayOf(PropTypes.number),
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  initialExpiredLink: PropTypes.string,
  initialNotes: PropTypes.string
};

export default LinkDetails;

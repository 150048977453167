import React, { useState } from 'react';

import { Flex, Button, Heading3, Divider } from '@ubisend/pulse-components';

import { useComposer } from '../../../../hooks/index';
import CarouselItem from './CarouselItem';

const MIN_ITEMS = 1;
const MAX_ITEMS = 10;

const general = {
  title: '',
  subtitle: '',
  image: 'http://placehold.it/400x200',
  image_description: '',
  url: '',
  responses: []
};

const CarouselInput = () => {
  const [activeItem, setActiveItem] = useState(0);

  const {
    response: { content },
    dispatch
  } = useComposer();

  const updateItem = newDetails => {
    dispatch({
      type: 'UPDATE_CONTENT',
      newContent: {
        items: content.items.map((item, key) => {
          if (key === activeItem) {
            return { ...item, ...newDetails };
          }

          return item;
        })
      }
    });
  };

  const handleImageChange = value => {
    updateItem({ image: value });
  };

  const handleUrlChange = value => {
    updateItem({ url: value });
  };

  const handleTitleChange = value => {
    updateItem({ title: value });
  };

  const handleSubtitleChange = value => {
    updateItem({ subtitle: value });
  };

  const handleImageDescriptionChange = value => {
    updateItem({ image_description: value });
  };

  const handleResponsesChange = responses => {
    updateItem({ responses });
  };

  const handleLeft = () => setActiveItem(activeItem - 1);

  const handleRight = () => setActiveItem(activeItem + 1);

  const handleNewItem = () => {
    dispatch({
      type: 'UPDATE_CONTENT',
      newContent: {
        items: content.items.concat(general)
      }
    });
  };

  const handleDeleteItem = () => {
    const itemToDelete = activeItem;

    setActiveItem(0);

    dispatch({
      type: 'UPDATE_CONTENT',
      newContent: {
        items: content.items.filter((_, key) => key !== itemToDelete)
      }
    });
  };

  return (
    <div>
      <Flex mb between center>
        <Heading3>Actions</Heading3>
        <Flex right xSpace>
          <Button
            disabled={content.items.length >= MAX_ITEMS}
            onClick={handleNewItem}>
            Add
          </Button>
          <Button
            variant="secondary"
            colour="danger"
            icon="trash"
            disabled={content.items.length === MIN_ITEMS}
            onClick={handleDeleteItem}>
            Delete
          </Button>
        </Flex>
      </Flex>
      <Flex between center>
        <Button onClick={handleLeft} disabled={activeItem === 0}>
          Prev
        </Button>
        <Flex>
          <span>Editing Item {activeItem + 1}</span>
        </Flex>
        <Flex>
          <Button
            onClick={handleRight}
            disabled={
              (activeItem === 0 && content.items.length === 1) ||
              activeItem + 1 === content.items.length
            }>
            Next
          </Button>
        </Flex>
      </Flex>
      <Divider />
      <CarouselItem
        {...content.items[activeItem]}
        handleImageChange={handleImageChange}
        handleUrlChange={handleUrlChange}
        handleTitleChange={handleTitleChange}
        handleSubtitleChange={handleSubtitleChange}
        handleResponsesChange={handleResponsesChange}
        handleImageDescriptionChange={handleImageDescriptionChange}
      />
    </div>
  );
};

export default CarouselInput;

import React from 'react';
import { useTranslation } from 'react-i18next';

import BaseComposer from '../../BaseComposer';

const PasswordComposer = props => {
  const { t } = useTranslation('bots');

  return (
    <BaseComposer
      showBurgerIcon={false}
      aria-label={t('auth_password_composer_label')}
      placeholder={t('auth_password_composer_label')}
      {...props}
    />
  );
};

export default PasswordComposer;

import React from 'react';

const AuthContext = React.createContext({
  user: null,
  client: null,
  token: null,
  authenticated: false,
  hasPermission: permission => permission,
  loginUser: () => {},
  logoutUser: () => {}
});

export default AuthContext;

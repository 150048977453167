import React from 'react';
import { Redirect } from 'react-router-dom';

import methods from '../Components/LoginMethods/index';

const LoginMethod = ({ match }) => {
  const Settings = methods[match.params.name]?.Settings;

  if (!Settings) {
    return (
      <Redirect
        to={{
          pathname: '/owner/logins',
          state: {
            notification: {
              type: 'error',
              message: 'That login method does not exist'
            }
          }
        }}
      />
    );
  }

  return <Settings />;
};

export default LoginMethod;

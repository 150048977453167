import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Whoops from './Whoops';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null
    };
  }

  componentDidMount() {
    this.props.history.listen(() => {
      if (this.state.hasError) {
        this.setState({ hasError: false, error: null });
      }
    });
  }

  componentDidCatch(...error) {
    // Show error UI.
    this.setState({ hasError: true, error });

    // Hit error tracking service.
    if (this.props.handleError) {
      this.props.handleError(...error);
    }
  }

  render() {
    if (this.state.hasError) {
      return <Whoops error={this.state.error} />;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  handleError: PropTypes.func
};

export default withRouter(ErrorBoundary);

import DocumentPreview from './DocumentPreview';
import CreateDocument from './CreateDocument';

const document = {
  type: 'document',
  name: 'Documents',
  icon: 'document',
  Preview: DocumentPreview,
  Create: CreateDocument
};

export default document;

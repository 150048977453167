import React from 'react';

import { Flex } from '@ubisend/pulse-components';

import { ImportsProvider } from '../Providers/index';
import { SortableSidebar } from '../Components/index';
import { ImportRouter } from '../Routers/index';

const Imports = () => {
  return (
    <ImportsProvider>
      <Flex grow xSpace yScreen>
        <SortableSidebar />
        <ImportRouter />
      </Flex>
    </ImportsProvider>
  );
};

export default Imports;

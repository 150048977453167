import React, { useState } from 'react';

import {
  TextInput,
  Button,
  InputButton,
  FormGroup,
  Label,
  Flex,
  Heading1,
  InputOverlay
} from '@ubisend/pulse-components';
import Icon from '@ubisend/pulse-icons';
import { useQuerySearch, useMutation } from '@ubisend/pulse-hooks';

import { resetPassword } from '../../../api/index';
import { useAuth } from '../../../hooks/index';
import {
  AuthLink,
  AuthErrorRedirect,
  AnimatedAuthContainer,
  AuthenticatedRedirect,
  ResetPasswordRedirect
} from '../../../Components/index';

const EmailPasswordResetPassword = ({ location }) => {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const { authenticated } = useAuth();

  const mutation = useMutation(resetPassword);

  const token = useQuerySearch(location, 'token');
  const email = useQuerySearch(location, 'email');

  const handlePasswordChange = event => setPassword(event.target.value);

  const handleShowPassword = () => {
    setShowPassword(showPassword => !showPassword);
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (password && !mutation.isLoading) {
      mutation.mutate({ email, password, token });
    }
  };

  if (authenticated) {
    return <AuthenticatedRedirect />;
  }

  if (!token || !email || mutation.isError) {
    return <AuthErrorRedirect />;
  }

  if (mutation.isSuccess) {
    return <ResetPasswordRedirect />;
  }

  return (
    <AnimatedAuthContainer>
      <Heading1 style={{ fontSize: '2rem' }} mb textCenter>
        Reset Password
      </Heading1>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Label id="email">Email</Label>
          <Flex relative>
            <TextInput
              htmlFor="email"
              placeholder="Email"
              readOnly
              value={email}
            />
            <InputOverlay right>
              <Icon
                size="1.25rem"
                height="1.25rem"
                width="1.25rem"
                type="mail"
              />
            </InputOverlay>
          </Flex>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="password">Password</Label>
          <Flex relative>
            <TextInput
              id="password"
              type={showPassword ? 'text' : 'password'}
              placeholder="New Password"
              value={password}
              onChange={handlePasswordChange}
            />
            <InputButton
              aria-label={`${showPassword ? 'hide' : 'show'} password`}
              onClick={handleShowPassword}>
              <Icon
                size="1.25rem"
                width="1.25rem"
                height="1.25rem"
                type={showPassword ? 'eye' : 'eyeOff'}
              />
            </InputButton>
          </Flex>
        </FormGroup>
        <Flex col ySpace center>
          <Button
            mt
            fat
            middle
            variant="primary"
            loading={mutation.isLoading}
            disabled={!password}
            type="submit">
            {mutation.isLoading ? 'Submitting' : 'Submit'}
          </Button>
          <Button variant="inline" as={AuthLink} to="/">
            Go to login
          </Button>
        </Flex>
      </form>
    </AnimatedAuthContainer>
  );
};

export default EmailPasswordResetPassword;

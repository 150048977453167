import React from 'react';

import { Button } from '@ubisend/pulse-components';

const NodeButton = props => {
  return (
    <Button
      onMouseDown={event => event.stopPropagation()}
      onMouseUp={event => event.stopPropagation()}
      {...props}
    />
  );
};

export default NodeButton;

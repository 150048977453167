import React, { useState } from 'react';

import {
  Panel,
  Flex,
  LeftHalfLayout,
  TextInput,
  Box,
  Button,
  useNotification
} from '@ubisend/pulse-components';
import { FileSelect } from '@ubisend/pulse-files';
import { PermissionFilter } from '@ubisend/pulse-auth';

import { SectionActions } from '../Components/index';
import { useImports } from '../hooks/index';
import { createResource, deleteResource } from '../api/index';

const Resources = () => {
  const { item, section, dispatch } = useImports();
  const { showSuccess } = useNotification();

  const [upload, setUpload] = useState(false);

  const showUpload = () => setUpload(true);

  const hideUpload = () => setUpload(false);

  const handleNewResource = async file => {
    const { data } = await createResource(item.id, section.id, {
      resource_id: file.id,
      source_id: file.source.id
    });
    dispatch({
      type: 'ADD_RESOURCE',
      import: item,
      section,
      resource: data.data
    });
    showSuccess('Successfully added a new resource');
    hideUpload();
  };

  const handleDeleteResource = async resource => {
    await deleteResource(item.id, section.id, resource.id, resource.source.id);
    dispatch({
      type: 'REMOVE_RESOURCE',
      import: item,
      section,
      resource
    });
    showSuccess('Successfully removed resource');
  };

  return (
    <>
      <SectionActions />
      <Flex>
        <LeftHalfLayout>
          <Panel header="Resources">
            {section.resources.length > 0 && (
              <Flex col ySpace mb>
                {section.resources.map((resource, key) => (
                  <Flex xSpace key={key}>
                    <TextInput
                      value={`File ${resource.id}`}
                      disabled
                      readonly
                    />
                    <Flex xSpace middle>
                      <Button
                        variant="secondary"
                        as={'a'}
                        href={resource.link}
                        rel="noreferrer"
                        target="_blank"
                        icon="download">
                        Download
                      </Button>
                      <PermissionFilter can="delete knowledge bases">
                        <Button
                          variant="secondary"
                          colour="danger"
                          icon="trash"
                          onClick={() => handleDeleteResource(resource)}>
                          Delete
                        </Button>
                      </PermissionFilter>
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            )}
            <PermissionFilter can="create knowledge bases">
              <Box border="dashed">
                <Flex middle center>
                  <Button onClick={showUpload}>Add Resource</Button>
                </Flex>
              </Box>
              {upload && (
                <FileSelect
                  handleFileSelect={handleNewResource}
                  handleCancel={hideUpload}
                />
              )}
            </PermissionFilter>
          </Panel>
        </LeftHalfLayout>
      </Flex>
    </>
  );
};

export default Resources;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import {
  PageWrapper,
  OneHalfLayout,
  TextInput,
  Panel,
  Label,
  Flex,
  FormGroup,
  Button,
  useNotification,
  Placeholder
} from '@ubisend/pulse-components';
import { LocationsConsumer, useQuery, useMutation } from '@ubisend/pulse-hooks';
import { GroupSelect } from '@ubisend/pulse-groups';

import { LocationSelect, FaqSelect } from '../Components/index';
import { duplicateFaq as duplicatorApi, syncGroupsForFaq } from '../api/index';

const DuplicateFaq = ({ match }) => {
  const [faq, setFaq] = useState();

  const { isLoading } = useQuery(`faqs/${match.params.faqId}`, {
    onSuccess: data => {
      setFaq({
        ...data.data,
        sharedFaq: data.data.shared_faqs[0]?.id,
        groups: data.data.groups ? data.data.groups.map(group => group.id) : []
      });
    }
  });

  const { showSuccess } = useNotification();

  const handleNameChange = event => {
    setFaq({ ...faq, name: event.target.value });
  };

  const handleGroupSelect = groups => {
    setFaq({ ...faq, groups: groups ? groups.map(group => group.value) : [] });
  };

  const handleLocationSelect = location => {
    setFaq({
      ...faq,
      location: location ? { id: location.value, name: location.label } : null
    });
  };

  const handleAddSharedFaq = faqToShare => {
    setFaq({ ...faq, sharedFaq: faqToShare ? faqToShare.value : null });
  };

  const { mutate: syncGroups } = useMutation(syncGroupsForFaq);

  const { mutate: duplicate } = useMutation(duplicatorApi, {
    onSuccess: ({ data }) => {
      // Add any FAQ groups.
      if (faq.groups.length !== 0) {
        syncGroups({ faqId: data.data.id, groupIds: faq.groups });
      }

      // Redirect them to the create a response page.
      setFaq({ ...faq, created: data.data.id });
      showSuccess(`Successfully duplicated "${faq.name}"`);
    }
  });

  const handleSubmit = async e => {
    e.preventDefault();

    await duplicate({
      faqId: faq.id,
      name: faq.name,
      randomResponse: faq.should_use_random_response,
      sharedFaqId: faq.sharedFaq,
      locationId: faq.location ? faq.location?.id : null,
      utterances: faq.intents
        .reduce(
          (utterances, intent) => utterances.concat(intent.utterances),
          []
        )
        .map(({ language, ...utterance }) => ({
          language_id: language.id,
          ...utterance
        }))
    });
  };

  return (
    <PageWrapper header="Duplicate an FAQ">
      <OneHalfLayout>
        <>
          <Panel>
            {faq && faq.created && (
              <Redirect to={`/faqs/${faq.created}/responses`} />
            )}
            {isLoading && <Placeholder items={1} />}
            {!isLoading && faq && (
              <form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label htmlFor="name">Name</Label>
                  <TextInput
                    placeholder="Book Annual Leave"
                    name="name"
                    value={faq.name}
                    onChange={handleNameChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="tags">Tags</Label>
                  <GroupSelect
                    id="tags"
                    value={faq.groups}
                    onChange={handleGroupSelect}
                    for="faqs"
                  />
                </FormGroup>
                <LocationsConsumer>
                  <FormGroup>
                    <Label htmlFor="location">Location</Label>
                    <LocationSelect
                      value={faq.location ? faq.location?.id : null}
                      onChange={handleLocationSelect}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>Share questions with</Label>
                    <FaqSelect
                      value={faq.sharedFaq}
                      onChange={handleAddSharedFaq}
                    />
                  </FormGroup>
                </LocationsConsumer>
                <Flex xSpace right>
                  <Button type="submit" icon="save">
                    Save
                  </Button>
                </Flex>
              </form>
            )}
          </Panel>
        </>
      </OneHalfLayout>
    </PageWrapper>
  );
};

DuplicateFaq.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      faqId: PropTypes.number.isRequired
    }).isRequired
  }).isRequired
};

export default DuplicateFaq;

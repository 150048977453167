import React from 'react';
import { useHistory } from 'react-router-dom';

import { useQuery, useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import {
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalClose,
  Label,
  ModalPortal,
  Flex,
  useNotification,
  Placeholder
} from '@ubisend/pulse-components';

import { CustomMetricDetails } from '../Components/index';
import { editCustomMetric } from '../api/index';

const EditCustomMetric = ({ match }) => {
  const { showSuccess } = useNotification();

  const history = useHistory();

  const queryClient = useQueryClient();
  const query = useQuery(`custom-metrics/${match.params.id}`);
  const mutation = useMutation(editCustomMetric, {
    onSuccess: () => {
      queryClient.invalidateQueries('custom-metrics');
      queryClient.invalidateQueries('custom-metrics/all');
      queryClient.invalidateQueries(`custom-metrics/${match.params.id}`);
      showSuccess('Successfully updated custom metric');
      history.push('/custom-metrics');
    }
  });

  const handleClose = () => {
    history.push('/custom-metrics');
  };

  const handleEditCustomMetric = form => {
    mutation.mutate({ id: match.params.id, ...form });
  };

  return (
    <ModalPortal>
      <ModalContainer
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.1 }}>
        <ModalContent>
          <ModalHeader>
            <Label mb={false}>Edit custom metric</Label>
            <Flex xSpace>
              <ModalClose onClick={handleClose} />
            </Flex>
          </ModalHeader>
          <Flex pad fat>
            {query.isLoading && <Placeholder items={1} />}
            {query.isSuccess && (
              <CustomMetricDetails
                initialCustomMetric={{
                  name: query.data.data.name
                }}
                loading={mutation.isLoading}
                handleSubmit={handleEditCustomMetric}
              />
            )}
          </Flex>
        </ModalContent>
      </ModalContainer>
    </ModalPortal>
  );
};

export default EditCustomMetric;

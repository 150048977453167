import { hasPermission } from '@ubisend/pulse-auth';

import { FacebookLink } from './Pages/index';

const routes = [
  {
    name: 'Facebook Messenger',
    path: '/facebook-link',
    component: FacebookLink,
    canAccess: hasPermission('view facebook messenger settings'),
    actions: [
      {
        title: 'Manage your Messenger channel',
        description:
          'Connect, disconnect, or edit your Facebook Messenger integration.',
        icon: 'lightningBolt'
      }
    ]
  }
];

export default routes;

import styled from 'styled-components';

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${({ columns = 4, minColumnWidth = 0 }) =>
    `repeat(${columns}, minmax(${minColumnWidth}, 1fr))`};
  row-gap: ${props => props.rowGap || '1rem'};
  column-gap: ${props => props.columnGap || '1rem'};
`;

export default Grid;

const getPickerProps = (type, { setOpen, handleChange }) => {
  const props = {
    static: {
      variant: 'static',
      hideTabs: true
    },
    dialog: {
      variant: 'dialog',
      hideTabs: true,
      open: true,
      onAccept: date => {
        handleChange(date);
        setOpen(false);
      },
      onClose: () => setOpen(false),
      TextFieldComponent: () => null
    }
  };

  return props[type];
};

export default getPickerProps;

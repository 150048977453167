import action from './action';

/**
 * Function to draw a content message based on on a node object
 *
 * @param   {CanvasRenderingContext2D}  ctx current canvas rendering context
 * @param   {number}  x     x position of node
 * @param   {number}  y     y position of node
 * @param   {string}  fill  hex, rgba, or string preset colour value to fill the background
 * @param   {object}  response  response object
 *
 * @return  {number}        height of resulting draw object
 */
const content = (ctx, x, y, fill, response) => {
  return action(ctx, x, y, fill, {
    content: { ...response.content, responses: [{ label: 'view' }] }
  });
};

export default content;

import React from 'react';

const BulletPoints = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <line
      x1="8"
      y1="6"
      x2="22"
      y2="6"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="8"
      y1="12"
      x2="22"
      y2="12"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="8"
      y1="18"
      x2="22"
      y2="18"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <circle cx="2.5" cy="6" r="1.5" fill="black" />
    <circle cx="2.5" cy="12" r="1.5" fill="black" />
    <circle cx="2.5" cy="18" r="1.5" fill="black" />
  </svg>
);

export default BulletPoints;

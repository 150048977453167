import React from 'react';
import PropTypes from 'prop-types';

import { Select } from '@ubisend/pulse-components';

import responses from './responses';

const format = response => {
  return {
    label: response.name,
    value: response.id
  };
};

const TypeSelect = ({ value, ...props }) => (
  <Select
    options={responses.map(format)}
    value={
      value ? format(responses.find(response => response.id === value)) : null
    }
    {...props}
  />
);

TypeSelect.propTypes = {
  value: PropTypes.oneOf(responses.map(({ id }) => id))
};

export default TypeSelect;

import { useContext } from 'react';

import { MetricContext } from '../Contexts/index';

const useMetric = () => {
  const context = useContext(MetricContext);

  return context;
};

export default useMetric;

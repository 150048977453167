import SpreadsheetPreview from './SpreadsheetPreview';
import CreateSpreadsheet from './CreateSpreadsheet';

const spreadsheet = {
  type: 'spreadsheet',
  name: 'Spreadsheets',
  icon: 'documentReport',
  Preview: SpreadsheetPreview,
  Create: CreateSpreadsheet
};

export default spreadsheet;

import React from 'react';
import PropTypes from 'prop-types';

import { TextInput, Label } from '@ubisend/pulse-components';

const NumerFormatInput = ({ content, onChange }) => {
  const handleChange = event => {
    const value = parseInt(event.target.value);

    onChange({ decimal_places: value });
  };

  return (
    <div>
      <Label htmlFor="format">Decimal places</Label>
      <TextInput
        type="number"
        min={0}
        max={10}
        id="format"
        placeholder="name"
        value={content.decimal_places}
        onChange={handleChange}
      />
    </div>
  );
};

NumerFormatInput.propTypes = {
  content: PropTypes.shape({
    decimal_places: PropTypes.number.isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired
};

export default NumerFormatInput;

import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import {
  Flex,
  UserIcon,
  Timeline,
  Profile,
  Label
} from '@ubisend/pulse-components';
import Icon from '@ubisend/pulse-icons';

const ActivationPeriod = ({ period, mb = false }) => (
  <Flex>
    <Flex mrSm col center>
      <UserIcon background="positive" colour="positive">
        <Flex middle center>
          <Icon type="check" size="30px" width="30px" height="30px" />
        </Flex>
      </UserIcon>
      <Timeline />
    </Flex>
    <Flex col>
      <Profile col middle>
        <Label mb={false}>License First Activated</Label>
        <Label mb={false} secondary>
          {dayjs(period.start).format('DD/MM/YYYY')}
        </Label>
      </Profile>
      <Flex mb={mb}></Flex>
    </Flex>
  </Flex>
);

ActivationPeriod.propTypes = {
  period: PropTypes.shape({
    start: PropTypes.string.isRequired
  }).isRequired,
  mb: PropTypes.bool
};

const ExpiredPeriod = ({ period, mb = false }) => (
  <Flex>
    <Flex mrSm col center>
      <UserIcon background="danger" colour="danger">
        <Flex middle center>
          <Icon
            type="exclamation"
            outline
            size="30px"
            width="30px"
            height="30px"
          />
        </Flex>
      </UserIcon>
      <Timeline />
    </Flex>
    <Flex col>
      <Profile col middle>
        <Label mb={false}>License Expired</Label>
        <Label mb={false} secondary>
          {dayjs(period.end).format('DD/MM/YYYY')}
        </Label>
      </Profile>
      <Flex mb={mb}></Flex>
    </Flex>
  </Flex>
);

ExpiredPeriod.propTypes = {
  period: PropTypes.shape({
    end: PropTypes.string.isRequired
  }).isRequired,
  mb: PropTypes.bool
};

const RenewalPeriod = ({ period, mb = false }) => (
  <Flex>
    <Flex mrSm col center>
      <UserIcon background="positive" colour="positive">
        <Flex middle center>
          <Icon type="creditCard" size="30px" width="30px" height="30px" />
        </Flex>
      </UserIcon>
      <Timeline />
    </Flex>
    <Flex col>
      <Profile col middle>
        <Label mb={false}>License Renewed</Label>
        <Label mb={false} secondary>
          {dayjs(period.start).format('DD/MM/YYYY')}
        </Label>
      </Profile>
      <Flex mb={mb}></Flex>
    </Flex>
  </Flex>
);

RenewalPeriod.propTypes = {
  period: PropTypes.shape({
    start: PropTypes.string.isRequired
  }).isRequired,
  mb: PropTypes.bool
};

const LicensePeriod = ({ period, periods, index }) => {
  // 1 Period and it lasts for ever.
  if (periods.length === 1 && !period.end) {
    return <ActivationPeriod period={period} />;
  }

  // 1 Period and it's expired.
  if (periods.length === 1 && dayjs(period.end).isBefore(dayjs())) {
    return (
      <>
        <ExpiredPeriod period={period} mb />
        <ActivationPeriod period={period} />
      </>
    );
  }

  // 1 Period and it's not expired.
  if (periods.length === 1 && dayjs(period.end).isAfter(dayjs())) {
    return <ActivationPeriod period={period} />;
  }

  // First period within periods of more than 1 length.
  if (index === periods.length - 1) {
    return <ActivationPeriod period={period} />;
  }

  // First period within periods of more than 1 length and is currently active.
  if (index === 0 && dayjs(period.end).isAfter(dayjs())) {
    return <RenewalPeriod period={period} mb />;
  }

  // First period within periods of more than 1 length and is currently expired.
  if (index === 0 && dayjs(period.end).isBefore(dayjs())) {
    return (
      <>
        <ExpiredPeriod period={period} mb />
        <RenewalPeriod period={period} mb />
      </>
    );
  }

  // Between the first period and last period in a list of periods of greater length than 2.
  return (
    <>
      <ExpiredPeriod period={period} mb />
      <RenewalPeriod period={period} mb />
    </>
  );
};

LicensePeriod.propTypes = {
  period: PropTypes.object.isRequired,
  periods: PropTypes.arrayOf(PropTypes.object).isRequired,
  index: PropTypes.number.isRequired
};

export default LicensePeriod;

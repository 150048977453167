import React from 'react';
import { Link } from 'react-router-dom';

const uniqueLocations = (allLocations, { id, location }) => {
  if (allLocations.find(({ name }) => name === location.name)) {
    return allLocations;
  }

  return allLocations.concat({ id, name: location.name });
};

const SharedFaqLinks = ({ shared_faqs: faqs }) => {
  return faqs.reduce(uniqueLocations, []).map(({ id, name }, key) => (
    <div key={key}>
      <Link to={`/faqs/${id}/questions`}>{name}</Link>
      {key !== faqs.length - 1 && ', '}
    </div>
  ));
};

export default SharedFaqLinks;

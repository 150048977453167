import { useContext } from 'react';

import { PaymentContext } from '../Contexts/index';

const usePayment = () => {
  const payment = useContext(PaymentContext);

  return payment;
};

export default usePayment;

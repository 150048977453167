import React from 'react';
import styled from 'styled-components';

import OneHalfLayout from './OneHalfLayout';

const Spacer = styled.div`
  ${tw`ml-4`}
`;

const RightHalfLayout = ({ children }) => (
  <OneHalfLayout>
    <Spacer>{children}</Spacer>
  </OneHalfLayout>
);

export default RightHalfLayout;

import React from 'react';
import PropTypes from 'prop-types';

import { Select, Flex, Label } from '@ubisend/pulse-components';

import ApiKey from './ApiKey';
import BasicAuth from './BasicAuth';
import BearerToken from './BearerToken';
import NoAuth from './NoAuth';
import { types } from '../../../hooks/useAuth';

const components = {
  'API Key': ApiKey,
  'Basic Auth': BasicAuth,
  'Bearer Token': BearerToken,
  'No Auth': NoAuth
};

const options = types.map(type => ({ value: type, label: type }));

const Auth = ({ type, handleChangeType, ...rest }) => {
  const Component = components[type] || NoAuth;

  return (
    <Flex xSpace growChildren>
      <Flex col>
        <Label htmlFor="auth-select">Type</Label>
        <Select
          id="auth-select"
          options={options}
          value={options.find(option => option.value === type)}
          onChange={handleChangeType}
        />
      </Flex>
      <Component {...rest} />
    </Flex>
  );
};

Auth.propTypes = {
  type: PropTypes.oneOf(types),
  handleChangeType: PropTypes.func.isRequired
};

export default Auth;

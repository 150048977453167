import React from 'react';
import PropTypes from 'prop-types';

import { Flex } from '@ubisend/pulse-components';
import Icon from '@ubisend/pulse-icons';

import StepPreview from '../Step/StepPreview';

const TransitionPreview = ({ startNode, destinationNode }) => (
  <Flex pad ySpace col center style={{ background: '#f3f3f4' }}>
    <StepPreview node={startNode} />
    <Icon type="arrowNarrowDown" outline />
    <StepPreview node={destinationNode} />
  </Flex>
);

TransitionPreview.propTypes = {
  startNode: PropTypes.object,
  destinationNode: PropTypes.object
};

export default TransitionPreview;

import React from 'react';

const ComposerContext = React.createContext({
  message: { text: 'hey' },
  type: 'standard',
  dispatch: () => {},
  handleSubmit: (type = '', message = {}) => type || message,
  resolvers: [],
  setResolvers: [],
  valid: true
});

export default ComposerContext;

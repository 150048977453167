import query from '../query';
import TableMetric from './TableMetric';

const table = {
  name: 'Table',
  Component: TableMetric,
  columns: 6,
  rows: 3,
  query
};

export default table;

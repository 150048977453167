import styled from 'styled-components';

import { size } from '../styles';

const Fixed = styled.div`
  ${tw`fixed`}
  ${props => props.right && tw`pin-r`}
  ${props =>
    props.left && tw`pin-l`}
  ${props => props.top && tw`pin-t`}
  ${props =>
    props.bottom && tw`pin-b`}
  ${size}
`;

export default Fixed;

import { useMutation } from '@ubisend/pulse-hooks';

import { setupDemo } from '../api/index';

const useSetupDemo = params => {
  const mutation = useMutation(setupDemo, params);

  return mutation;
};

export default useSetupDemo;

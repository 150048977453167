import styled from 'styled-components';

const NodeFooter = styled.div`
  ${tw`flex justify-start w-full p-3 absolute pin-b`}
  & i {
    ${tw`flex`}
  }
`;

export default NodeFooter;

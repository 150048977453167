import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Label, Button, TextInput } from '@ubisend/pulse-components';

import { useCustomMetricReducer } from '../reducers/index';

const MetricDetails = ({ initialCustomMetric, loading, handleSubmit }) => {
  const reducer = useCustomMetricReducer(initialCustomMetric);

  const handleNameChange = event => {
    const name = event.target.value;

    reducer.dispatch({ type: reducer.TYPES.UPDATE_NAME, name });
  };

  const onSubmit = event => {
    event.preventDefault();

    if (reducer.valid && !loading) {
      handleSubmit(reducer.form);
    }
  };

  return (
    <Flex as="form" fat col onSubmit={onSubmit}>
      <Flex col mb fat>
        <Label htmlFor="name">Name</Label>
        <TextInput
          id="name"
          value={reducer.metric.name}
          onChange={handleNameChange}
          placeholder="My custom metric"
        />
      </Flex>
      <Flex right>
        <Button icon="save" type="submit" disabled={!reducer.valid || loading}>
          Save
        </Button>
      </Flex>
    </Flex>
  );
};

MetricDetails.propTypes = {
  initialCustomMetric: PropTypes.shape({
    name: PropTypes.string.isRequired
  }),
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default MetricDetails;

import styled from 'styled-components';

import Panel from './Panel';
import { spacing, flex } from '../styles';

const Card = styled(Panel)`
  ${spacing}
  ${flex}
`;

export default Card;

import React from 'react';

import { Button } from '@ubisend/pulse-components';

import { useTemplating } from '../../../../../hooks/index';

const SNIPPET = `{% if variable == 'value' %}
    pass
  {% else %}
    fail
  {% endif %}`;

const AddIfTag = () => {
  const templating = useTemplating();

  const handleAddIfTag = () => {
    templating.dispatch({
      type: templating.TYPES.ADD_SNIPPET,
      snippet: SNIPPET
    });
  };

  return (
    <Button variant="secondary" icon="code" onClick={handleAddIfTag}>
      If tag
    </Button>
  );
};

export default AddIfTag;

import React, { useState } from 'react';

import { useQuery } from '@ubisend/pulse-hooks';

import { IntegrationsContext } from '../Contexts/index';

const IntegrationsProvider = ({ children }) => {
  const [create, setCreate] = useState(false);
  const [edit, setEdit] = useState(null);
  const [viewEndpoints, setViewEndpoints] = useState(null);

  const { data, isSuccess } = useQuery('integrations');

  const getIntegration = id => {
    return data.data.find(integration => integration.id === id);
  };

  if (!isSuccess) {
    return null;
  }

  const context = {
    integrations: isSuccess ? data.data : null,
    create,
    setCreate,
    edit,
    setEdit,
    viewEndpoints: viewEndpoints ? getIntegration(viewEndpoints) : null,
    setViewEndpoints,
    hasEndpoints: isSuccess
      ? data.data.find(({ endpoints }) => endpoints.length > 0)
      : false,
    getIntegration
  };

  return (
    <IntegrationsContext.Provider value={context}>
      {children}
    </IntegrationsContext.Provider>
  );
};

export default IntegrationsProvider;

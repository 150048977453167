import React from 'react';
import PropTypes from 'prop-types';

import OrderableTableHeadCell from './OrderableTableHeadCell';
import TableRow from './TableRow';

const OrderableTableRow = ({ rows, ...props }) => {
  return (
    <TableRow>
      {rows.map((row, key) => {
        return <OrderableTableHeadCell row={row} key={key} {...props} />;
      })}
    </TableRow>
  );
};

OrderableTableRow.propTypes = {
  rows: PropTypes.arrayOf(OrderableTableHeadCell.propTypes.row)
};

export default OrderableTableRow;

import query from '../query';
import ValueMetric from '../Value/ValueMetric';

const duration = {
  name: 'Duration',
  Component: ValueMetric,
  columns: 2,
  rows: 1,
  query
};

export default duration;

import React from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import {
  LeftHalfLayout,
  StretchPanel,
  TableActions,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableHeadCell,
  Table,
  Button
} from '@ubisend/pulse-components';

import { useImports } from '../hooks/index';

const Versions = () => {
  const { section, item } = useImports();

  return (
    <LeftHalfLayout>
      <StretchPanel>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell>Name</TableHeadCell>
              <TableHeadCell>Created By</TableHeadCell>
              <TableHeadCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {section.other_content.map((content, key) => (
              <TableRow key={key}>
                <TableCell>{content.name}</TableCell>
                <TableCell>
                  {content.user.id
                    ? content.user.name
                    : 'Automatically Created'}{' '}
                  ({dayjs(content.created_at).from()})
                </TableCell>
                <TableActions>
                  <Button
                    variant="secondary"
                    as={Link}
                    to={`/knowledge-bases/${item.id}/sections/${section.id}/versions/${content.id}`}>
                    View
                  </Button>
                </TableActions>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StretchPanel>
    </LeftHalfLayout>
  );
};

export default Versions;

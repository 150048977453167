import { useReducer } from 'react';

const TYPES = {
  START_TYPING: 'START_TYPING',
  STOP_TYPING: 'STOP_TYPING'
};

const reducer = (state, { type, ...params }) => {
  switch (type) {
    case TYPES.START_TYPING:
      return { typing: true, message: params.message || null };
    case TYPES.STOP_TYPING:
      return { typing: false, message: null };
    default:
      throw new Error(`No event defined in useTypingReducer for ${type}`);
  }
};

const defaultTyping = {
  typing: false,
  message: null
};

const useTypingReducer = (initialTyping = defaultTyping) => {
  const [typing, dispatch] = useReducer(reducer, initialTyping);

  return { typing, dispatch, TYPES };
};

export default useTypingReducer;

import axios from 'axios';

const createConversation = conversation => {
  return axios.post(`conversations/builder`, conversation);
};

const deleteConversation = id => axios.delete(`conversations/builder/${id}`);

const toggleConversationLock = ({ id, locked }) => {
  return axios.put(`conversations/builder/${id}/toggleLocked`, {
    locked
  });
};

export { createConversation, deleteConversation, toggleConversationLock };

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Button } from '@ubisend/pulse-components';

const ConversationScope = ({ content }) => {
  return (
    <Button variant="inline" as={Link} to={`/builder/${content.id}`}>
      {content.title}
    </Button>
  );
};

ConversationScope.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired
};

export default ConversationScope;

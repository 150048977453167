import React from 'react';

import { Flex, Heading1, Divider } from '@ubisend/pulse-components';
import { useQuery } from '@ubisend/pulse-hooks';

import { useAuth } from '../hooks/index';
import methods from '../Components/LoginMethods/index';
import { AnimatedAuthContainer, LoggedInRedirect } from '../Components/index';

const Login = () => {
  const { authenticated } = useAuth();

  const query = useQuery('auth');

  if (authenticated) {
    return <LoggedInRedirect />;
  }

  return (
    <AnimatedAuthContainer>
      {query.isSuccess && (
        <>
          <Heading1 style={{ fontSize: '2rem' }} mb textCenter>
            Login
          </Heading1>
          <Flex col ySpace>
            {query.data.data.map((method, key) => {
              const { Login } = methods[method.name];

              return (
                <React.Fragment key={key}>
                  {key === 1 && <Divider text="Or" />}
                  <Login method={method} />
                </React.Fragment>
              );
            })}
          </Flex>
        </>
      )}
    </AnimatedAuthContainer>
  );
};

export default Login;

import query from '../query';
import BarChartMetric from './BarChartMetric';

const barChart = {
  name: 'Bar Chart',
  Component: BarChartMetric,
  columns: 4,
  rows: 2,
  query
};

export default barChart;

import axios from 'axios';

const login = params => {
  return axios.post('auth/email/login', params);
};

const requestNewPassword = email => {
  return axios.post('auth/email/password-reset', {
    email
  });
};

const resetPassword = params => {
  return axios.put('auth/email/password-reset', params);
};

export { login, requestNewPassword, resetPassword };

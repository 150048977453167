import React, { useState } from 'react';

import {
  Flex,
  Button,
  Placeholder,
  Span,
  Accordion,
  useNotification,
  Explainer,
  Divider
} from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';
import { useQuery, useMutation, useQueryClient } from '@ubisend/pulse-hooks';

import {
  createRender as createRenderApi,
  updateRender as updateRenderApi,
  deleteRender as deleteRenderApi
} from '../../api/index';
import ConditionalModal from './ConditionalModal';

const RenderSettings = () => {
  const [create, setCreate] = useState(false);
  const [edit, setEdit] = useState(false);

  const { showSuccess } = useNotification();

  const handleCreate = () => setCreate(true);

  const stopCreating = () => setCreate(false);

  const stopEditing = () => setEdit(false);

  const queryClient = useQueryClient();
  const { data, isLoading, isSuccess } = useQuery('converse/renders');
  const { mutate: createRender } = useMutation(createRenderApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('converse/renders');
      showSuccess('Successfully created a new display rule.');
      stopCreating();
    }
  });
  const { mutate: updateRender } = useMutation(updateRenderApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('converse/renders');
      showSuccess('Successfully updated your display rule.');
      stopEditing();
    }
  });
  const { mutate: deleteRender } = useMutation(deleteRenderApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('converse/renders');
      showSuccess('Successfully deleted your display rule.');
      stopEditing();
    }
  });

  const handleCreateSubmit = params => {
    createRender(params);
  };

  const handleUpdateSubmit = params => {
    updateRender({ id: edit.id, ...params });
  };

  const handleDeleteSubmit = ({ id }) => {
    deleteRender(id);
  };

  return (
    <Accordion inner header="Widget visibility rules">
      {isLoading && <Placeholder items={2} />}
      {isSuccess && (
        <Flex col ySpace>
          {create && (
            <ConditionalModal
              handleSubmit={handleCreateSubmit}
              handleCancel={stopCreating}
            />
          )}
          {edit && (
            <ConditionalModal
              handleSubmit={handleUpdateSubmit}
              handleCancel={stopEditing}
              initialName={edit.name}
              initialConditionals={{
                conditionals: edit.conditionals,
                match: edit.match
              }}
            />
          )}
          <Explainer>
            Control when to show your widget on a webpage. By default, your
            widget is visible to everyone, always.
          </Explainer>
          {data.data.length === 0 && (
            <Span light>No smart rules have been setup yet</Span>
          )}
          {data.data.map((render, key) => (
            <Flex between center key={key}>
              <Span>{render.name}</Span>
              <Flex xSpace>
                <PermissionFilter can="edit converse settings">
                  <Button onClick={() => setEdit(render)} variant="secondary">
                    Edit
                  </Button>
                </PermissionFilter>
                <PermissionFilter can="delete converse settings">
                  <Button
                    variant="secondary"
                    colour="danger"
                    icon="trash"
                    onClick={() => handleDeleteSubmit(render)}>
                    Delete
                  </Button>
                </PermissionFilter>
              </Flex>
            </Flex>
          ))}
          <PermissionFilter can="create converse settings">
            <Divider mtNone />
            <Flex right>
              <Button onClick={handleCreate} variant="secondary">
                + Add new
              </Button>
            </Flex>
          </PermissionFilter>
        </Flex>
      )}
    </Accordion>
  );
};

export default RenderSettings;

import React from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  ModalPortal,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalClose,
  ModalBody,
  useNotification,
  Button
} from '@ubisend/pulse-components';
import { useMutation } from '@ubisend/pulse-hooks';

import { createImport } from '../../api/index';
import { drivers } from '../Drivers/index';
import { useGandalfReducer, useImports } from '../../hooks/index';
import { WizardContext } from '../../Contexts/index';
import ChooseImportType from './ChooseImportType';
import WizardNextButton from './WizardNextButton';
import WizardBackButton from './WizardBackButton';

const firstStep = {
  title: 'Add New Knowledge Base',
  Component: ChooseImportType
};

const Wizard = ({ handleClose }) => {
  const wizard = useGandalfReducer({ firstStep, drivers });
  const { dispatch } = useImports();

  const { showSuccess } = useNotification();

  const mutation = useMutation(createImport, {
    onSuccess: ({ data }) => {
      dispatch({ type: 'ADD_IMPORT', newImport: data.data });
      showSuccess(drivers[wizard.form.type].wizard.success);
      handleClose();
    }
  });

  const handleSubmit = () => {
    mutation.mutate(wizard.form);
  };

  const WizardStep = wizard.WizardStep;

  return (
    <ModalPortal>
      <ModalContainer>
        <ModalContent>
          <WizardContext.Provider value={wizard}>
            <ModalHeader background="service-primary">
              {wizard.title}
              <ModalClose onClick={handleClose} />
            </ModalHeader>
            <ModalBody>
              <WizardStep />
            </ModalBody>
            <ModalFooter>
              <Flex xSpace>
                <WizardBackButton />
                {wizard.onLastStep ? (
                  <Button
                    onClick={handleSubmit}
                    icon="save"
                    loading={mutation.isLoading}
                    disabled={!wizard.valid}>
                    Save
                  </Button>
                ) : (
                  <WizardNextButton />
                )}
              </Flex>
            </ModalFooter>
          </WizardContext.Provider>
        </ModalContent>
      </ModalContainer>
    </ModalPortal>
  );
};

Wizard.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default Wizard;

import React from 'react';
import PropTypes from 'prop-types';

import MarkdownOrderedListItem from './MarkdownOrderedListItem';
import MarkdownUnorderedListItem from './MarkdownUnorderedListItem';

const ListItem = props => {
  if (props.ordered) {
    return <MarkdownOrderedListItem {...props} />;
  }

  return <MarkdownUnorderedListItem {...props} />;
};

ListItem.propTypes = {
  ordered: PropTypes.bool
};

export default ListItem;

import React from 'react';
import PropTypes from 'prop-types';

import { Select } from '@ubisend/pulse-components';

const statuses = [
  { value: 'submitted', label: 'Submitted' },
  { value: 'in_progress', label: 'In Progress' },
  { value: 'resolved', label: 'Resolved' }
];

const TicketStatusSelect = ({ value, ...props }) => (
  <Select
    isClearable
    isMulti
    options={statuses}
    value={
      value && value.map(value => statuses.find(type => type.value === value))
    }
    {...props}
  />
);

TicketStatusSelect.propTypes = {
  value: PropTypes.array
};

export default TicketStatusSelect;

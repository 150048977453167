import ExtractInput from './ExtractInput';

const extract = {
  name: 'Extract',
  Input: ExtractInput,
  content: {
    value: ''
  },
  format: (variable, content) => {
    return `${variable}|extract("${content.value}")`;
  },
  valid: content => content.value.length > 0
};

export default extract;

import React from 'react';
import PropTypes from 'prop-types';

import PermissionFilter from './PermissionFilter';

/**
 * Recursively build a tree of PermissionFilter components using an array
 * of permissions.
 */
const PermissionsFilter = ({ children, can: permissions, ...rest }) => {
  return (
    <PermissionFilter can={permissions[0]} {...rest}>
      {permissions.length > 1 ? (
        <PermissionsFilter can={permissions.slice(1)} {...rest}>
          {children}
        </PermissionsFilter>
      ) : (
        children
      )}
    </PermissionFilter>
  );
};

PermissionsFilter.propTypes = {
  can: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  fallback: PropTypes.string
};

export default PermissionsFilter;

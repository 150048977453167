import axios from 'axios';

const createBroadcast = params => {
  return axios.post('broadcasts', params);
};

const editBroadcast = ({ id, ...params }) => {
  return axios.put(`broadcasts/${id}`, params);
};

const deleteBroadcast = id => {
  return axios.delete(`broadcasts/${id}`);
};

export { createBroadcast, editBroadcast, deleteBroadcast };

import React from 'react';
import PropTypes from 'prop-types';

import { Select } from '@ubisend/pulse-components';

const options = [
  { label: 'Never', value: 'never' },
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Yearly', value: 'yearly' }
];

const PollingIntervalSelect = ({ value, ...props }) => {
  return (
    <Select
      options={options}
      value={value && options.find(option => option.value === value)}
      {...props}
    />
  );
};

PollingIntervalSelect.propTypes = {
  value: PropTypes.string
};

export default PollingIntervalSelect;

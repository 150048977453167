import { useContext } from 'react';

import { ButtonContext } from '../Contexts/index';

const useButtons = () => {
  const context = useContext(ButtonContext);

  return context;
};

export default useButtons;

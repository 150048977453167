import { useEffect, useCallback } from 'react';

import { useMutation } from '@ubisend/pulse-hooks';

import { checkSyntax as checkSyntaxApi } from '../../../api/index';
import { useTemplating } from '../../../hooks/index';

const DEBOUCE_TIME = 2000;
const MIN_DEBOUNCE_CHARACTER_COUNT = 2;

const SyntaxCheck = () => {
  const templating = useTemplating();

  const mutation = useMutation(checkSyntaxApi, {
    onSuccess: ({ data }) => {
      templating.dispatch({
        type: templating.TYPES.UPDATE_ERROR,
        error: data.error
      });
    }
  });

  const checkSyntax = useCallback(() => {
    mutation.mutate({ template: templating.value });
  }, [templating.value, mutation]);

  useEffect(() => {
    let timeout;

    // Don't check if already checking.
    if (mutation.isLoading) {
      return;
    }

    if (templating.showVariables) {
      return;
    }

    // Don't check small value.
    if (templating.value.length <= MIN_DEBOUNCE_CHARACTER_COUNT) {
      return;
    }

    // Don't check if you last checked that value.
    if (mutation.variables?.template === templating.value) {
      return;
    }

    // eslint-disable-next-line prefer-const
    timeout = setTimeout(checkSyntax, DEBOUCE_TIME);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [templating.value, templating.showVariables, mutation, checkSyntax]);

  return null;
};

export default SyntaxCheck;

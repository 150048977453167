import styled from 'styled-components';

import { motion } from '@ubisend/framer-motion';

const Button = styled(motion.button)`
  ${tw`flex items-center px-3 py-2 md:px-4 md:py-3 text-white uppercase tracking-wide font-semibold font-poppins rounded-full text-xs cursor-pointer`}
  background: rgba(255,255,255,0.25);
  border: 2px solid rgba(255, 255, 255, 0.25);
  & > svg {
    ${tw`w-4 h-4`}
  }
`;

export default Button;

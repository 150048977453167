import React, { useMemo } from 'react';

import composers from './composers';
import { useComposer } from '../hooks/index';

const ComposerContent = () => {
  const composer = useComposer();

  const Input = useMemo(() => {
    return composers.find(({ id }) => id === composer.type).Input;
  }, [composer.type]);

  return <Input />;
};

export default ComposerContent;

import axios from 'axios';

const createRender = params => {
  return axios.post(`converse/renders`, params);
};

const updateRender = ({ id, ...params }) => {
  return axios.put(`converse/renders/${id}`, params);
};

const deleteRender = id => axios.delete(`converse/renders/${id}`);

export { createRender, updateRender, deleteRender };

import React, { useCallback } from 'react';

import TemplatingCodeEditor, {
  tags as defaultTags
} from '@ubisend/pulse-templating';

import { useComposer } from '../../../hooks/index';
import customTags from './Tags/index';

const DynamicInput = () => {
  const { response, dispatch } = useComposer();

  const handleTemplateChange = useCallback(
    value => {
      dispatch({ type: 'UPDATE_CONTENT', newContent: { template: value } });
    },
    [dispatch]
  );

  return (
    <TemplatingCodeEditor
      aria-label="Dynamic template"
      tags={[customTags.response, defaultTags.if, defaultTags.for]}
      value={response.content.template}
      onChange={handleTemplateChange}
    />
  );
};

export default DynamicInput;

import React from 'react';
import PropTypes from 'prop-types';

import { Span } from '@ubisend/pulse-components';

const LanguageScope = ({ content }) => {
  return <Span>{content.name}</Span>;
};

LanguageScope.propTypes = {
  content: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired
};

export default LanguageScope;

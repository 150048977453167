import LookupSubject from './LookupSubject';

const subject = {
  spreadsheet_id: null,
  lookup_id: null,
  type: 'lookup'
};

const lookupSubject = {
  subject,
  name: 'Use spreadsheet values',
  permission: 'view spreadsheets',
  Subject: LookupSubject,
  toState: (subject = {}) => {
    return {
      type: 'lookup',
      lookup_id: subject.id || null,
      spreadsheet_id: subject.spreadsheet_id || null
    };
  },
  toApi: subject => {
    return {
      type: 'lookup',
      id: subject.lookup_id
    };
  }
};

export default lookupSubject;

import axios from 'axios';

const createLocation = params => axios.post(`locations`, params);

const updateLocation = ({ id, ...params }) => {
  return axios.put(`locations/${id}`, params);
};

const deleteLocation = id => axios.delete(`locations/${id}`);

export { createLocation, updateLocation, deleteLocation };

import axios from 'axios';

const createSheet = params => axios.post('spreadsheets', params);

const editSheet = ({ id, ...params }) => {
  return axios.put(`spreadsheets/${id}`, params);
};

const deleteSheet = id => axios.delete(`spreadsheets/${id}`);

export { createSheet, editSheet, deleteSheet };

import styled from 'styled-components';

import Panel from './Panel';
import { spacing } from '../styles';

/*
 * A Panel layout component to be used where the body should fill the width of the container
 */
const StretchPanel = styled(Panel)`
  ${tw`md:p-0 lg:p-0`};
  ${spacing}
  ${props => !props.clip && 'overflow-x: auto;'}
  & > div:first-child {
    ${props => props.header && props.actions && tw`p-4 mb-0`}
  }
  & > h2:first-child {
    ${props => props.header && !props.actions && tw`p-4 mb-0`}
  }
`;

export default StretchPanel;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledEventMessage = styled.div`
  ${tw`text-xs text-black text-center mb-2 italic whitespace-pre-wrap`}
  overflow-wrap: break-word;
`;

const Event = ({ content }) => (
  <StyledEventMessage>{content.text}</StyledEventMessage>
);

Event.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.string.isRequired
  })
};

export default Event;

import React from 'react';

/**
 * Context to handle showing and setting a notification
 */
const NotificationContext = React.createContext({
  notifications: [],
  showNotification: notification => notification,
  showSuccess: message => message,
  showError: message => message
});

export default NotificationContext;

import { useEffect, useState, useRef, useCallback } from 'react';

import useBot from './useBot';
import { replaceMessage } from '../utilities/index';

const MESSAGE_DELAY = 1000;

const defaultOptions = {
  messageDelay: MESSAGE_DELAY
};

/**
 * Consistently show typing indicators and render new messages
 * using a preset delay time.
 */
const useDelayedBot = ({ messageDelay = MESSAGE_DELAY } = defaultOptions) => {
  const [queuedMessages, setQueuedMessages] = useState([]);

  const interval = useRef();

  const { messages, setMessages, ...bot } = useBot();

  const moveQueuedMessages = useCallback(() => {
    // If no more messages to send, clear the interval until
    // the next message is sent.
    if (queuedMessages.length === 0) {
      return clearInterval(interval.current);
    }

    setMessages(messages => {
      return (
        messages
          // If one of the new messages is actually an updated version of a message
          // we already have, replace the content.
          .map(replaceMessage([queuedMessages[0]]))
          // Add any new messages.
          .concat(
            [queuedMessages[0]].filter(({ id }) => {
              if (!id) {
                return true;
              }

              return !messages.find(message => message.id === id);
            })
          )
      );
    });

    // Remove message from queue.
    setQueuedMessages(queuedMessages => queuedMessages.slice(1));
  }, [queuedMessages, setMessages]);

  /**
   * Reset the interval whenever a new message is sent, to maintain a
   * consistent time between new messages.
   */
  useEffect(() => {
    clearInterval(interval.current);

    const newInterval = setInterval(moveQueuedMessages, messageDelay);
    interval.current = newInterval;
  }, [queuedMessages, moveQueuedMessages, messageDelay]);

  return { queuedMessages, setQueuedMessages, messages, setMessages, ...bot };
};

export default useDelayedBot;
export { MESSAGE_DELAY, defaultOptions };

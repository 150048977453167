import React from 'react';

const Mattermost = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 35 32"
    {...props}>
    <path d="M16.109 0c-6.713-0.047-12.989 4.167-15.26 10.869-2.833 8.371 1.656 17.448 10.020 20.281 8.371 2.833 17.448-1.656 20.281-10.020 2.303-6.803-0.229-14.068-5.749-18.079l0.172 3.396c2.749 3.041 3.839 7.349 2.468 11.401-2.047 6.041-8.791 9.219-15.068 7.093-6.276-2.125-9.708-8.745-7.661-14.792 1.376-4.057 4.876-6.828 8.928-7.557l2.187-2.588c-0.104-0.005-0.213-0.005-0.323-0.005zM20.812 1.459c-0.057 0-0.115 0.011-0.167 0.031h-0.005c-0.052 0.027-0.104 0.057-0.145 0.099-0.192 0.188-0.875 1.105-0.875 1.105l-1.484 1.837-1.735 2.115-2.98 3.704c0 0-1.364 1.703-1.061 3.801 0.301 2.1 1.859 3.12 3.072 3.532 1.208 0.405 3.068 0.541 4.584-0.943 1.515-1.48 1.463-3.667 1.463-3.667l-0.115-4.745-0.093-2.735-0.063-2.364c0 0 0.011-1.141-0.025-1.412-0.011-0.047-0.027-0.099-0.047-0.14l-0.011-0.016-0.005-0.016c-0.068-0.109-0.183-0.181-0.308-0.187z" />
  </svg>
);

export default Mattermost;

import React from 'react';

import { Button } from '@ubisend/pulse-components';

import { useGandalf } from '../../hooks/index';

const WizardBackButton = props => {
  const { dispatch, onFirstStep } = useGandalf();

  const handleBack = () => {
    dispatch({ type: 'GO_BACK' });
  };

  return (
    <Button
      variant="secondary"
      disabled={onFirstStep}
      onClick={handleBack}
      {...props}>
      Back
    </Button>
  );
};

export default WizardBackButton;

import React from 'react';

import { Select } from '@ubisend/pulse-components';
import { useAuth } from '@ubisend/pulse-auth';

import { useComposer } from '../../hooks/index';

const SubjectSelect = props => {
  const { hasPermission } = useAuth();
  const {
    subject: selectedSubject,
    subjects: allSubjects,
    dispatch,
    TYPES
  } = useComposer();

  const format = subject => {
    return {
      label: subject.name,
      value: subject.subject.type,
      isDisabled: subject.permission
        ? !hasPermission(subject.permission)
        : false
    };
  };

  const handleSubjectChange = option => {
    if (!option) {
      dispatch({ type: TYPES.RESET_SUBJECT });
    } else {
      dispatch({ type: TYPES.UPDATE_SUBJECT_TYPE, subjectType: option.value });
    }
  };

  return (
    <Select
      {...props}
      isClearable
      value={
        selectedSubject
          ? format(
              allSubjects.find(
                ({ subject }) => selectedSubject.type === subject.type
              )
            )
          : null
      }
      onChange={handleSubjectChange}
      options={allSubjects.map(format)}
    />
  );
};

export default SubjectSelect;

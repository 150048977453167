import styled from 'styled-components';

import { inputStyles } from './TextInput';

const TextArea = styled.textarea`
  ${tw`w-full block resize-none`}
  ${inputStyles}
  box-sizing: border-box;
`;

TextArea.defaultProps = {
  rows: 10,
  cols: 50
};

export default TextArea;

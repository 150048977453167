import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import types from './Types/index';

const Block = props => {
  const { Block: Component, ...type } = useMemo(() => {
    return types[props.type];
  }, [props.type]);

  return <Component {...props} {...type} />;
};

Block.propTypes = {
  type: PropTypes.oneOf(Object.keys(types))
};

export default Block;

import React from 'react';
import PropTypes from 'prop-types';

import { Label, TextInput, Flex, Select } from '@ubisend/pulse-components';

const ApiKey = ({ credentials, handleCredentialsChange }) => {
  const handleKeyChange = event => {
    handleCredentialsChange({ ...credentials, key: event.target.value });
  };

  const handleValueChange = event => {
    handleCredentialsChange({ ...credentials, value: event.target.value });
  };

  const handleAddToHeaderChange = value => {
    handleCredentialsChange({ ...credentials, add_to_header: value.value });
  };

  const addToOptions = [
    { value: true, label: 'Header' },
    { value: false, label: 'Body' }
  ];

  return (
    <>
      <Flex col>
        <Label htmlFor="key">Key</Label>
        <TextInput
          id="key"
          value={credentials.key}
          onChange={handleKeyChange}
        />
      </Flex>
      <Flex col>
        <Label htmlFor="value">Value</Label>
        <TextInput
          id="value"
          placeholder="*************"
          value={credentials.value}
          onChange={handleValueChange}
        />
      </Flex>
      <Flex col>
        <Label htmlFor="addTo">Add to</Label>
        <Select
          aria-label="add-to-header"
          id="addTo"
          options={addToOptions}
          value={addToOptions.find(
            el => el.value === credentials.add_to_header
          )}
          onChange={handleAddToHeaderChange}
        />
      </Flex>
    </>
  );
};

ApiKey.propTypes = {
  credentials: PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    add_to_header: PropTypes.bool.isRequired
  }).isRequired,
  handleCredentialsChange: PropTypes.func.isRequired
};

export default ApiKey;

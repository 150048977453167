import axios from 'axios';

const startTyping = subscriberId => {
  return axios.post(`tickets/ubidesk/agents/me/typing`, {
    subscriber_id: subscriberId
  });
};

const stopTyping = subscriberId => {
  return axios.put(`tickets/ubidesk/agents/me/typing`, {
    subscriber_id: subscriberId
  });
};

export { startTyping, stopTyping };

import { useContext } from 'react';

import GlobalPayContext from './GlobalPayContext';

const useGlobalPay = () => {
  const context = useContext(GlobalPayContext);

  return context;
};

export default useGlobalPay;

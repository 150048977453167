import styled from 'styled-components';

import { spacing } from '../styles';

const SubHeader = styled.h2`
  ${tw`m-0`}
  ${spacing}
`;

export default SubHeader;

import PlatformContent from './PlatformContent';

const platform = {
  features: {
    // Filters
    search: true,
    // Fields
    groups: true,
    description: true,
    // Actions
    edit: true
  },
  Content: PlatformContent,
  setInitialContent: () => {
    return {};
  },
  isValidContent: () => true
};

export default platform;

import React from 'react';
import PropTypes from 'prop-types';

import { PermissionFilter } from '@ubisend/pulse-auth';

import NoPermissionConditional from './NoPermissionConditional';
import { useConditional } from '../hooks/index';

const Conditional = props => {
  const { subjects } = useConditional();

  const subject = subjects.find(
    ({ subject }) => subject.type === props.subject.type
  );

  if (!subject.permission) {
    return <subject.Conditional {...props} />;
  }

  return (
    <PermissionFilter
      can={subject.permission}
      fallback={
        <NoPermissionConditional permission={subject.permission} {...props} />
      }>
      <subject.Conditional {...props} />
    </PermissionFilter>
  );
};

Conditional.propTypes = {
  subject: PropTypes.shape({
    type: PropTypes.string.isRequired
  }).isRequired
};

export default Conditional;

import React from 'react';
import { PropTypes } from 'prop-types';

import { Flex } from '@ubisend/pulse-components';
import { Converse } from '@ubisend/pulse-volt';

const Wrapper = ({ children, isPublic, ...props }) => {
  if (isPublic) {
    return (
      <Flex tall center middle style={{ zIndex: 0 }} {...props}>
        {children}
      </Flex>
    );
  }

  return (
    <Flex middle style={{ zIndex: 0 }} {...props}>
      {children}
    </Flex>
  );
};

Wrapper.propTypes = {
  isPublic: PropTypes.bool
};

const ConverseDemo = ({ isPublic }) => (
  <Wrapper isPublic={isPublic} data-testid="converse-demo">
    <Converse start="/window/body/messages" />
  </Wrapper>
);

ConverseDemo.propTypes = {
  isPublic: PropTypes.bool
};

export default ConverseDemo;

import QueryParameterInput from './QueryParameterInput';

const queryParameter = {
  name: 'Query parameter',
  Input: QueryParameterInput,
  content: {
    param: ''
  },
  format: (variable, content) => {
    return `${variable}|query_parameter("${content.param}")`;
  },
  valid: content => content.param.length > 0
};

export default queryParameter;

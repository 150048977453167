import { OsConditional, OsPreview } from '../Components/Subjects/index';

const subject = { id: null, type: 'os' };

const conditional = {
  subject,
  type: null,
  condition: null,
  flipped: false,
  case_sensitive: false,
  target: ''
};

const osSubject = {
  subject,
  conditional,
  name: "User's Operating System",
  Conditional: OsConditional,
  Preview: OsPreview,
  toState: () => subject,
  toApi: () => subject
};

export default osSubject;

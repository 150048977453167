import { useLanguages } from '../hooks/index';

const LanguagesConsumer = ({ children }) => {
  const { languages } = useLanguages();

  if (languages.length <= 1) {
    return null;
  }

  return children;
};

export default LanguagesConsumer;

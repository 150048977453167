import { hasPermission } from '@ubisend/pulse-auth';

import { TeamsSettings } from './Pages/index';

const routes = [
  {
    name: 'Microsoft Teams',
    path: '/teams-link',
    component: TeamsSettings,
    canAccess: hasPermission('view microsoft teams settings'),
    actions: [
      {
        title: 'Manage your Teams channel',
        description:
          'Connect, disconnect, or edit your Microsoft Teams integration.',
        icon: 'lightningBolt'
      }
    ]
  }
];

export default routes;

import { SectionComposer } from '../../Components/Subjects/index';

const subject = {
  type: 'section',
  section_id: null,
  import_id: null
};

const sectionSubject = {
  subject,
  permission: 'view knowledge bases',
  name: 'Imported content',
  Composer: SectionComposer,
  toState: subject => {
    return {
      type: 'section',
      section_id: subject.id,
      import_id: subject.import_id
    };
  },
  toApi: subject => {
    return { id: subject.section_id, type: 'section' };
  }
};

export default sectionSubject;

import styled from 'styled-components';

const FormGroup = styled.div`
  ${tw`mb-4`}
  &:last-child {
    ${tw`mb-0`}
  }
`;

export default FormGroup;

import React from 'react';

import { Flex } from '@ubisend/pulse-components';
import { SheetSelect, LookupSelect } from '@ubisend/pulse-integrations';

import { useComposer } from '../../../hooks/index';

const LookupSubject = () => {
  const composer = useComposer();

  const handeSheetChange = option => {
    composer.dispatch({
      type: composer.TYPES.UPDATE_SUBJECT,
      subject: {
        spreadsheet_id: option ? option.value : null,
        lookup_id: null
      }
    });
  };

  const handleLookupChange = ({ value }) => {
    composer.dispatch({
      type: composer.TYPES.UPDATE_SUBJECT,
      subject: { lookup_id: value }
    });
  };

  return (
    <Flex xSpace fat>
      <Flex fat>
        <SheetSelect
          aria-label="Spreadsheets"
          value={composer.subject.spreadsheet_id}
          onChange={handeSheetChange}
        />
      </Flex>
      <Flex fat>
        <LookupSelect
          aria-label="Lookups"
          value={composer.subject.lookup_id}
          sheet={composer.subject.spreadsheet_id}
          onChange={handleLookupChange}
        />
      </Flex>
    </Flex>
  );
};

export default LookupSubject;

import React, { useState } from 'react';

import { useMutation } from '@ubisend/pulse-hooks';
import { Flex, Button, useNotification } from '@ubisend/pulse-components';
import { ConditionalProvider } from '@ubisend/pulse-conditionals';
import { PermissionFilter } from '@ubisend/pulse-auth';

import { conditionals } from '../../../subjects/index';
import { updateConversation } from '../api/index';
import { useBuilder } from '../hooks/index';
import ConversationDetails from '../../../Components/ConversationDetails';
import Modal from './Modal';

const EditConversation = () => {
  const [showEdit, setShowEdit] = useState(false);

  const { conversation, dispatch } = useBuilder();
  const { showSuccess } = useNotification();

  const mutation = useMutation(updateConversation, {
    onSuccess: ({ data }) => {
      dispatch({ type: 'UPDATE_CONVERSATION', conversationDetails: data.data });
      showSuccess('Successfully updated your conversation');
      setShowEdit(false);
    }
  });

  const handleSubmit = details => {
    mutation.mutate({ id: conversation.id, conversation: details });
  };

  const handleClose = () => {
    setShowEdit(false);
  };

  return (
    <>
      {showEdit && (
        <Modal header="Edit conversation" handleClose={handleClose}>
          <Flex pad col>
            <ConditionalProvider subjects={conditionals}>
              <ConversationDetails
                handleSubmit={handleSubmit}
                initialTitle={conversation.title}
                initialDescription={conversation.description}
                initialGroups={conversation.groups.map(group => group.id)}
                showConditional={false}
                loading={mutation.isLoading}
              />
            </ConditionalProvider>
          </Flex>
        </Modal>
      )}
      <PermissionFilter can="edit conversations">
        <Button
          aria-label="Edit conversation"
          icon="pencilAlt"
          onClick={() => setShowEdit(true)}
          variant="secondary"
        />
      </PermissionFilter>
    </>
  );
};

export default EditConversation;

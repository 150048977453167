import React from 'react';
import PropTypes from 'prop-types';

import { PreviewCondition } from '@ubisend/pulse-components';
import { ConditionPreview } from '@ubisend/pulse-conditionals';

const LookupPreview = ({ prefix = 'When', ...props }) => (
  <p>
    {prefix} looking up spreadsheet and {props.subject.target}
    <PreviewCondition>{props.type.name}</PreviewCondition>
    <ConditionPreview {...props} />
  </p>
);

LookupPreview.propTypes = {
  prefix: PropTypes.string,
  subject: PropTypes.shape({
    target: PropTypes.string
  }),
  flipped: PropTypes.number,
  type: PropTypes.shape({ type: PropTypes.string, name: PropTypes.string })
};

export default LookupPreview;

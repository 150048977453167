import { subjects as integrationSubjects } from '@ubisend/pulse-integrations';
import { subjects as defaultSubjects } from '@ubisend/pulse-conditionals';
import { subjects as importSubjects } from '@ubisend/pulse-import';

import message from './Message/index';
import payment from './Payment/index';

const conditionals = [
  message,
  ...defaultSubjects.subjects,
  integrationSubjects.conditionals.integration,
  integrationSubjects.conditionals.lookup,
  integrationSubjects.conditionals.variable,
  importSubjects.conditionals.section,
  payment
];

export default conditionals;

import React from 'react';
import { Redirect } from 'react-router-dom';

const ResetPasswordRedirect = () => {
  return (
    <Redirect
      to={{
        pathname: '/',
        state: {
          notification: {
            type: 'success',
            message:
              'You have successfully reset your password and can now login.'
          }
        }
      }}
    />
  );
};

export default ResetPasswordRedirect;

import axios from 'axios';

const getAllSmallTalk = () => axios.get('smalltalk/all');

const getSmallTalk = (page, name) => {
  return axios.get('smalltalk', {
    params: { page, name }
  });
};

const getPieceOfSmallTalk = smallTalkId => {
  return axios.get(`smalltalk/${smallTalkId}`);
};

const updateSmallTalkSettings = params => {
  return axios.put('smalltalk/settings', params);
};

export {
  getSmallTalk,
  getPieceOfSmallTalk,
  getAllSmallTalk,
  updateSmallTalkSettings
};

import React from 'react';
import PropTypes from 'prop-types';

import { Select } from '@ubisend/pulse-components';

const options = [
  {
    label: 'Message',
    value: 'success'
  },
  {
    label: 'Warning',
    value: 'warning'
  },
  {
    label: 'Error',
    value: 'error'
  }
];

const LogLevelSelect = ({ value, ...props }) => (
  <Select
    options={options}
    value={value.map(value => options.find(option => option.value === value))}
    isClearable
    isMulti
    {...props}
  />
);

LogLevelSelect.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default LogLevelSelect;

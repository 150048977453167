import { useContext } from 'react';

import { NotificationContext } from '../Contexts/index';

const useNotifications = () => {
  const notifications = useContext(NotificationContext);

  return notifications;
};

export default useNotifications;

import subjects from './subjects';
import url from './url';
import ip from './ip';
import browser from './browser';
import os from './os';
import event from './event';
import language from './language';

const conditionals = { subjects, url, ip, browser, os, event, language };

export default conditionals;

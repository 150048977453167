import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useMutation } from '@ubisend/pulse-hooks';
import {
  Label,
  Flex,
  Button,
  useNotification
} from '@ubisend/pulse-components';
import { useConditionalReducer } from '@ubisend/pulse-conditionals';

import Modal from '../../../Modal';
import { conditionals as conditionalSubjects } from '../../../../../../subjects/index';
import { useBuilder } from '../../../../hooks/index';
import {
  updateStepTransition as updateStepTransitionApi,
  updateStepTrigger as updateStepTriggerApi
} from '../../../../api/index';
import MetricSelect from '../../../MetricSelect';

const AddMetricBlock = ({ handleClose, node }) => {
  const [metric, setMetric] = useState(null);

  const { dispatch } = useBuilder();
  const { showSuccess } = useNotification();
  const conditionals = useConditionalReducer({
    subjects: conditionalSubjects,
    conditionals: {
      match: node.base.match,
      conditionals: node.base.conditionals
    }
  });

  const updateStepTransition = useMutation(updateStepTransitionApi, {
    onSuccess: ({ data }) => {
      showSuccess('Successfully added a metric');
      dispatch({
        type: 'UPDATE_TRANSITION',
        stepId: node.meta.stepId,
        transitionId: node.id,
        transitionDetails: data.data,
        transitionType: node.type
      });
      handleClose();
    }
  });
  const updateStepTrigger = useMutation(updateStepTriggerApi, {
    onSuccess: ({ data }) => {
      showSuccess('Successfully added a metric');
      dispatch({
        type: 'UPDATE_TRIGGER',
        stepId: node.meta.stepId,
        triggerId: node.id,
        triggerDetails: data.data
      });
      handleClose();
    }
  });

  const handleMetricChange = metric => {
    setMetric(metric ? metric.value : null);
  };

  const valid = useMemo(() => {
    return Boolean(metric);
  }, [metric]);

  const isLoading = useMemo(() => {
    return updateStepTrigger.isLoading || updateStepTransition.isLoading;
  }, [updateStepTrigger.isLoading, updateStepTransition.isLoading]);

  const handleTransitionSubmit = () => {
    const getDescription = () => {
      if (node.type === 'validation') {
        return `Validation for step #${node.base.destination.id}`;
      }

      return `Transition to step #${node.base.destination.id}`;
    };

    if (valid && !isLoading) {
      updateStepTransition.mutate({
        stepId: node.meta.stepId,
        transitionId: node.id,
        transition: {
          description: getDescription(),
          transition: {
            id: node.base.destination.id,
            ...conditionals.form,
            metrics: node.base.metrics.map(metric => metric.id).concat(metric),
            variables: node.base.variables.map(variable => variable.id)
          }
        }
      });
    }
  };

  const handleTriggerSubmit = () => {
    if (valid && !isLoading) {
      updateStepTrigger.mutate({
        stepId: node.meta.stepId,
        triggerId: node.id,
        trigger: {
          description: `Trigger step #${node.meta.stepId}`,
          ...conditionals.form,
          metrics: node.base.metrics.map(metric => metric.id).concat(metric),
          variables: node.base.variables.map(variable => variable.id)
        }
      });
    }
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (node.type === 'trigger') {
      handleTriggerSubmit();
    } else {
      handleTransitionSubmit();
    }
  };

  return (
    <Modal header="Add metric" handleClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <Flex pad col>
          <Flex col mb>
            <Label htmlFor="metric">Metric</Label>
            <MetricSelect
              id="metric"
              value={metric}
              onChange={handleMetricChange}
            />
          </Flex>
          <Flex right>
            <Button icon="save" type="submit" disabled={isLoading || !valid}>
              Save
            </Button>
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};

AddMetricBlock.propTypes = {
  handleClose: PropTypes.func.isRequired,
  node: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    meta: PropTypes.shape({
      stepId: PropTypes.number.isRequired
    }).isRequired,
    base: PropTypes.shape({
      match: PropTypes.string.isRequired,
      conditionals: PropTypes.array.isRequired,
      destination: PropTypes.shape({
        id: PropTypes.number.isRequired
      }).isRequired,
      metrics: PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired
      ).isRequired,
      variables: PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired
      ).isRequired
    }).isRequired
  }).isRequired
};

export default AddMetricBlock;

import React, { useCallback } from 'react';

import { FormGroup, Label, Flex, Divider } from '@ubisend/pulse-components';

import { useComposer } from '../../hooks/index';
import ComposerContent from './ComposerContent';
import TypeSelect from './TypeSelect';
import ComposerSelect from './ComposerSelect';

const Composer = () => {
  const {
    dispatch,
    response: { type, composer }
  } = useComposer();

  const handleTypeChange = ({ value }) => {
    dispatch({ type: 'CHANGE_TYPE', newType: value });
  };

  const handleComposerChange = useCallback(
    ({ value }) => {
      dispatch({ type: 'CHANGE_COMPOSER', newComposer: value });
    },
    [dispatch]
  );

  return (
    <>
      <Flex xSpaceSm fat>
        <Flex col fat>
          <Label htmlFor="response-type">Message Type</Label>
          <TypeSelect
            id="response-type"
            aria-label="response-type"
            value={type}
            onChange={handleTypeChange}
          />
        </Flex>
        <Flex col fat>
          <Label htmlFor="composer">Composer</Label>
          <ComposerSelect
            id="composer"
            aria-label="composer"
            value={composer}
            onChange={handleComposerChange}
          />
        </Flex>
      </Flex>
      <Divider />
      <FormGroup>
        <ComposerContent />
      </FormGroup>
    </>
  );
};

export default Composer;

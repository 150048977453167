import { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { ImportsContext } from '../Contexts/index';

const useImports = () => {
  const context = useContext(ImportsContext);

  const { importId, sectionId, versionId } = useParams();

  const item = useMemo(() => {
    if (!importId) {
      return null;
    }

    return context.imports.find(item => item.id === parseInt(importId));
  }, [importId, context]);

  const section = useMemo(() => {
    if (!sectionId || !item) {
      return null;
    }

    return item.sections.find(section => section.id === parseInt(sectionId));
  }, [sectionId, item]);

  const version = useMemo(() => {
    if (!versionId || !section) {
      return null;
    }

    return section.other_content.find(
      content => content.id === parseInt(versionId)
    );
  }, [versionId, section]);

  return {
    ...context,
    importId,
    sectionId,
    versionId,
    item,
    section,
    version
  };
};

export default useImports;

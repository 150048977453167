import React from 'react';

import {
  Divider,
  InnerPanel,
  FormGroup,
  Label
} from '@ubisend/pulse-components';
import TemplatingCodeEditor, {
  tags as defaultTags
} from '@ubisend/pulse-templating';

import ComposerSubject from './ComposerSubject';
import SubjectSelect from './SubjectSelect';
import customTags from './Tags/index';
import { useComposer } from '../../hooks/index';

const DynamicInput = () => {
  const composer = useComposer();

  const handleTemplateChange = value => {
    composer.dispatch({
      type: composer.TYPES.UPDATE_CONTENT,
      content: { template: value }
    });
  };

  return (
    <>
      <InnerPanel mb>
        <FormGroup>
          <Label htmlFor="variables">Variables</Label>
          <SubjectSelect id="variables" />
        </FormGroup>
        {composer.subject && <ComposerSubject />}
      </InnerPanel>
      <Divider fullWidth />
      <TemplatingCodeEditor
        aria-label="Dynamic template"
        tags={[customTags.composer, defaultTags.if, defaultTags.for]}
        value={composer.content.template}
        onChange={handleTemplateChange}
      />
    </>
  );
};

export default DynamicInput;

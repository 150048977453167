import React from 'react';

import ActionsPortal from './ActionsPortal';
import DeleteSection from './DeleteSection';
import { ExportSection } from './Exports/index';
import { useImports } from '../hooks/index';

const SectionActions = () => {
  const { item, section } = useImports();

  return (
    <ActionsPortal>
      <ExportSection item={item} section={section} />
      <DeleteSection item={item} section={section} />
    </ActionsPortal>
  );
};

export default SectionActions;

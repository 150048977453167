import { UrlConditional, UrlPreview } from '../Components/Subjects/index';

const subject = { id: null, type: 'url' };

const conditional = {
  subject,
  type: null,
  condition: null,
  flipped: false,
  case_sensitive: false,
  target: ''
};

const urlSubject = {
  subject,
  conditional,
  name: 'Page URL',
  Conditional: UrlConditional,
  Preview: UrlPreview,
  toState: () => subject,
  toApi: () => subject
};

export default urlSubject;

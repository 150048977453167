import React from 'react';
import PropTypes from 'prop-types';

import SplitText from '../SplitText';

const Image = ({ content }) => {
  return <SplitText>{content.text || ''}</SplitText>;
};

Image.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.string
  }).isRequired
};

export default Image;

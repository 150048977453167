import React from 'react';

import { ConverseComposerButton } from '@ubisend/pulse-volt';

const ToggleActionsButton = props => {
  return (
    <ConverseComposerButton {...props}>
      <svg
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg">
        <path
          className="secondary"
          d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"
        />
      </svg>
    </ConverseComposerButton>
  );
};

export default ToggleActionsButton;

import React from 'react';

import { Flex } from '@ubisend/pulse-components';
import { FullPage } from '@ubisend/pulse-volt';

const FullPageDemo = () => (
  <Flex middle relative style={{ zIndex: 0 }} grow data-testid="full-page-demo">
    <FullPage start="/window/body/messages" />
  </Flex>
);

export default FullPageDemo;

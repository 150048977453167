import React from 'react';

const EmailsContext = React.createContext({
  emails: [],
  setEmails: () => {},
  fetchEmails: () => {},
  refetchEmails: () => {}
});

export default EmailsContext;

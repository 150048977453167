import React from 'react';
import { Redirect } from 'react-router-dom';

const AcceptedInviteRedirect = () => {
  return (
    <Redirect
      to={{
        pathname: '/',
        state: {
          notification: {
            type: 'success',
            message:
              'You have successfully created an account and can now login!'
          }
        }
      }}
    />
  );
};

export default AcceptedInviteRedirect;

import React, { useMemo } from 'react';

import { useImports } from '../hooks/index';
import { drivers } from '../Components/Drivers/index';

const ContentPreview = props => {
  const { item } = useImports();

  const Component = useMemo(() => drivers[item.type].content.Preview, [item]);

  return <Component {...props} />;
};

export default ContentPreview;

import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { DateTimePicker } from '@ubisend/pulse-components';

const TimeCondition = ({ onChange, value, ...props }) => {
  const handleChange = ([date]) => {
    if (date) {
      onChange(dayjs(date).format('HH:mm:ss'));
    }
  };

  return (
    <DateTimePicker
      date={value}
      placeholder="12:00:00"
      options={{
        noCalendar: true,
        dateFormat: 'H:i:S'
      }}
      onChange={handleChange}
      onOpen={handleChange}
      onClose={handleChange}
      {...props}
    />
  );
};

TimeCondition.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default TimeCondition;

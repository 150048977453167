import { useContext } from 'react';

import { TemplatingContext } from '../Contexts/index';

const useTemplating = () => {
  const context = useContext(TemplatingContext);

  return context;
};

export default useTemplating;

import React from 'react';
import PropTypes from 'prop-types';

import { useQueryClient, useMutation } from '@ubisend/pulse-hooks';
import {
  Flex,
  ModalClose,
  ModalPortal,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalBody,
  useNotification
} from '@ubisend/pulse-components';

import { createSheet } from '../../api/index';
import SheetDetails from './SheetDetails';

const CreateSheet = ({ handleClose }) => {
  const { showSuccess } = useNotification();

  const queryClient = useQueryClient();
  const mutation = useMutation(createSheet, {
    onSuccess: () => {
      queryClient.invalidateQueries('spreadsheets');
      showSuccess('Successfully created spreadsheet integration');
      handleClose();
    }
  });

  const handleSubmit = form => {
    mutation.mutate(form);
  };

  return (
    <ModalPortal>
      <ModalContainer>
        <ModalContent>
          <ModalHeader>
            Create spreadsheet integration
            <Flex between>
              <ModalClose onClick={handleClose} />
            </Flex>
          </ModalHeader>
          <ModalBody>
            <SheetDetails
              handleSubmit={handleSubmit}
              loading={mutation.isLoading}
            />
          </ModalBody>
        </ModalContent>
      </ModalContainer>
    </ModalPortal>
  );
};

CreateSheet.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default CreateSheet;

import React from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  Button,
  ModalPortal,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Explainer
} from '@ubisend/pulse-components';

const SheetModal = ({ handleClose, handleSubmit, name }) => {
  return (
    <ModalPortal>
      <ModalContainer>
        <ModalContent>
          <ModalHeader>Confirm Deletion of {name}</ModalHeader>
          <ModalBody>
            <Explainer type="warning">
              Deleting this integration will remove all related endpoints that
              are currently attached to conversation steps
            </Explainer>
          </ModalBody>
          <ModalFooter>
            <Flex right>
              <Flex xSpace>
                <Button variant="secondary" onClick={handleClose}>
                  close
                </Button>
                <Button
                  variant="secondary"
                  colour="danger"
                  icon="trash"
                  onClick={handleSubmit}>
                  Delete
                </Button>
              </Flex>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalContainer>
    </ModalPortal>
  );
};

SheetModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  name: PropTypes.string
};

export default SheetModal;

import { useReducer, useMemo, useCallback } from 'react';
import QRCode from 'qrcode';

import { useTheme } from '@ubisend/pulse-components';

const TYPES = {
  CHANGE_DATA: 'CHANGE_DATA',
  CHANGE_COLOUR: 'CHANGE_COLOUR',
  CHANGE_SCALE: 'CHANGE_SCALE',
  CHANGE_MARGIN: 'CHANGE_MARGIN',
  CHANGE_FORMAT: 'CHANGE_FORMAT'
};

const reducer = (state, { type, ...params }) => {
  switch (type) {
    case TYPES.CHANGE_DATA:
      return { ...state, data: params.data };
    case TYPES.CHANGE_COLOUR:
      return { ...state, colour: { ...state.colour, ...params.colour } };
    case TYPES.CHANGE_SCALE:
      return { ...state, scale: params.scale };
    case TYPES.CHANGE_MARGIN:
      return { ...state, margin: params.margin };
    case TYPES.CHANGE_FORMAT:
      return { ...state, format: params.format };
    default:
      throw new Error(`No event defined in useQrCodeReducer for ${type}`);
  }
};

const defaultValue = {
  data: '',
  margin: 2,
  scale: 15,
  format: 'svg'
};

/**
 * https://gist.github.com/ibreathebsb/a104a9297d5df4c8ae944a4ed149bcf1
 */
const dataUrlToFile = (data, name) => {
  const arr = data.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n) {
    u8arr[n - 1] = bstr.charCodeAt(n - 1);
    n -= 1;
  }

  return new File([u8arr], name, { type: mime });
};

const saveQrCodeAsSvg = async (data, options) => {
  const svg = await QRCode.toString(data, {
    ...options,
    type: 'svg',
    rendererOpts: { quality: 1 }
  });

  const blob = new Blob([svg], { type: 'image/svg+xml' });

  return new File([blob], 'QR Code.svg');
};

const saveQrCodeAsImage = async (data, format, options) => {
  const dataUrl = await QRCode.toDataURL(data, {
    ...options,
    type: `image/${format}`,
    rendererOpts: { quality: 1 }
  });

  return dataUrlToFile(dataUrl, `QR Code.${format}`);
};

const saveQrCode = (data, format, options) => {
  if (format === 'svg') {
    return saveQrCodeAsSvg(data, options);
  }

  return saveQrCodeAsImage(data, format, options);
};

const useQrCodeReducer = (initialValue = defaultValue) => {
  const theme = useTheme();

  const [state, dispatch] = useReducer(reducer, {
    ...defaultValue,
    colour: { dark: theme.primary, light: theme.white },
    ...initialValue
  });

  const options = useMemo(() => {
    return {
      scale: state.scale,
      color: state.colour,
      margin: state.margin
    };
  }, [state.scale, state.colour, state.margin]);

  const valid = useMemo(() => {
    return state.data.length > 5;
  }, [state.data]);

  const exportQrCode = useCallback(() => {
    return saveQrCode(state.data, state.format, options);
  }, [state.data, state.format, options]);

  return {
    ...state,
    options,
    exportQrCode,
    valid,
    dispatch
  };
};

export default useQrCodeReducer;
export { saveQrCode };

import DefaultGroupInput from './DefaultGroupInput';

const defaultGroup = {
  key: null,
  content: {},
  format: ({ key, variable }) => `${key}.${variable}`,
  Input: DefaultGroupInput,
  valid: () => true
};

export default defaultGroup;

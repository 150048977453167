import React from 'react';
import { useHistory } from 'react-router-dom';

import { useQueryClient, useMutation } from '@ubisend/pulse-hooks';
import { PageWrapper, useNotification } from '@ubisend/pulse-components';

import { EmailDetails } from '../Components/index';
import { createEmail } from '../api/index';

const CreateEmail = () => {
  const history = useHistory();
  const { showSuccess } = useNotification();

  const queryClient = useQueryClient();
  const mutation = useMutation(createEmail, {
    onSuccess: () => {
      showSuccess('Successfully created new email');
      queryClient.invalidateQueries('/emails');
      history.push(`/emails`);
    }
  });

  const handleSubmit = form => {
    mutation.mutate(form);
  };

  return (
    <PageWrapper
      header="Create Email"
      subheader="Send emails as part of your conversations">
      <EmailDetails handleSubmit={handleSubmit} loading={mutation.isLoading} />
    </PageWrapper>
  );
};

export default CreateEmail;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import types from './Types/index';

const ActionButton = props => {
  const Component = useMemo(() => types[props.response.type], [
    props.response.type
  ]);

  return <Component colour="white" {...props} />;
};

ActionButton.propTypes = {
  response: PropTypes.shape({
    type: PropTypes.oneOf(Object.keys(types)).isRequired
  }).isRequired
};

export default ActionButton;

import { useCallback } from 'react';

import { useKeyboardShortcuts } from '@ubisend/pulse-component-hooks';

import { useCanvas } from '../hooks/index';

const KeyboardControls = () => {
  const { handleZoomInClick, handleZoomOutClick } = useCanvas();

  const handleZoomIn = useCallback(
    event => {
      event.preventDefault();
      handleZoomInClick();
    },
    [handleZoomInClick]
  );

  const handleZoomOut = useCallback(
    event => {
      event.preventDefault();
      handleZoomOutClick();
    },
    [handleZoomOutClick]
  );

  useKeyboardShortcuts([
    {
      keys: ['+', '='],
      handler: handleZoomIn
    },
    {
      keys: ['-', '_'],
      handler: handleZoomOut
    }
  ]);

  return null;
};

export default KeyboardControls;

const NumberFormatter = ({ children }) => {
  if (children === undefined) {
    return null;
  }

  if (isNaN(children)) {
    return children.toLocaleString();
  }

  return Number(children).toLocaleString();
};

export default NumberFormatter;

import roundRect from '../roundRect';
import textBox from '../textBox';
import { renderActionButtons } from './action';
import {
  RESPONSE_PREVIEW_PADDING,
  STEP_WIDTH,
  BUBBLE_BORDER_RADIUS,
  BUBBLE_PADDING_Y,
  PARAGRAPH_LINE_HEIGHT,
  BUBBLE_PADDING_X,
  PARAGRAPH_FONT_SIZE,
  BUBBLE_INNER_WIDTH,
  ACTION_BUTTON_HEIGHT,
  IMAGE_HEIGHT,
  ACTION_BUTTON_SPACING
} from '../constants';
import { getLines } from '../util/index';
import { renderImage } from './image';

/**
 * Function to draw a general message based on on a node object
 *
 * @param   {CanvasRenderingContext2D}  ctx current canvas rendering context
 * @param   {number}  x     x position of node
 * @param   {number}  y     y position of node
 * @param   {string}  fill  hex, rgba, or string preset colour value to fill the background
 * @param   {object}  response  response object
 *
 * @return  {number}        height of resulting draw object
 */
const general = (ctx, x, y, fill, response) => {
  const titleLines = getLines(ctx, response.content.title, BUBBLE_INNER_WIDTH);
  const subtitleLines = getLines(
    ctx,
    response.content.subtitle,
    BUBBLE_INNER_WIDTH
  );

  // Calculate height of buttons before so we can render the rectangle at the right height before the buttons
  const buttonsHeight =
    response.content.responses.length *
    (ACTION_BUTTON_HEIGHT + ACTION_BUTTON_SPACING);

  const containerHeight =
    (titleLines.length + subtitleLines.length) * PARAGRAPH_LINE_HEIGHT +
    IMAGE_HEIGHT +
    BUBBLE_PADDING_Y * 3 +
    buttonsHeight;

  roundRect(
    ctx,
    x + RESPONSE_PREVIEW_PADDING,
    y + RESPONSE_PREVIEW_PADDING,
    STEP_WIDTH - RESPONSE_PREVIEW_PADDING * 2,
    containerHeight,
    'transparent',
    fill,
    BUBBLE_BORDER_RADIUS
  );
  ctx.save();
  ctx.clip();

  renderImage(ctx, x, y, { content: { url: response.content.image } });

  textBox(
    ctx,
    x + RESPONSE_PREVIEW_PADDING + BUBBLE_PADDING_X,
    y +
      RESPONSE_PREVIEW_PADDING +
      PARAGRAPH_FONT_SIZE +
      BUBBLE_PADDING_Y +
      IMAGE_HEIGHT,
    titleLines
  );
  textBox(
    ctx,
    x + RESPONSE_PREVIEW_PADDING + BUBBLE_PADDING_X,
    y +
      RESPONSE_PREVIEW_PADDING +
      PARAGRAPH_FONT_SIZE +
      BUBBLE_PADDING_Y +
      IMAGE_HEIGHT +
      titleLines.length * PARAGRAPH_LINE_HEIGHT,
    subtitleLines
  );
  // Y Position to start rendering buttons from
  const yPosition =
    titleLines.length * PARAGRAPH_LINE_HEIGHT +
    subtitleLines.length * PARAGRAPH_LINE_HEIGHT +
    IMAGE_HEIGHT +
    RESPONSE_PREVIEW_PADDING * 2;
  renderActionButtons(ctx, x, y + yPosition, fill, response);
  ctx.restore();
  return containerHeight;
};

export default general;

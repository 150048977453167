import React from 'react';
import { Link } from 'react-router-dom';
import { components } from 'react-select';

import { Button, Flex, Divider } from '@ubisend/pulse-components';

const SourceSelectMenuList = props => {
  return (
    <components.MenuList {...props}>
      {props.children}
      <Flex col mtSm>
        <Divider text="actions" />
        <Flex fat col mtSm mbSm ySpaceSm>
          <Button
            icon="plus"
            fat
            variant="inline"
            as={Link}
            to="/files/storages/create">
            Add new file storage
          </Button>
          <Button
            icon="pencilAlt"
            fat
            variant="inline"
            as={Link}
            to="/files/storages">
            Manage file storage
          </Button>
        </Flex>
      </Flex>
    </components.MenuList>
  );
};

export default SourceSelectMenuList;

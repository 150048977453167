import axios from 'axios';

const getConverseSettings = () => axios.get('converse/settings');

const updateConverseSettings = (settings = {}) => {
  return axios.put('converse/settings', settings);
};

const updateConverseLogo = logo => {
  return axios.put('converse/settings/images', {
    logo_id: logo.id,
    source_id: logo.source.id
  });
};

export { getConverseSettings, updateConverseSettings, updateConverseLogo };

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getId } from './utils';

const YouTube = ({ url, ...props }) => {
  const { t } = useTranslation('bots');

  return (
    <iframe
      title={t('youtube_title')}
      src={`https://www.youtube.com/embed/${getId(url)}`}
      allowFullScreen
      frameBorder="0"
      {...props}
    />
  );
};

YouTube.propTypes = {
  url: PropTypes.string
};

export default YouTube;

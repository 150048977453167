import { useAuth } from '@ubisend/pulse-auth';

const useAllowedBlocks = (allBlocks, types) => {
  const { hasPermission } = useAuth();

  return allBlocks.filter(block => {
    const type = types[block.type];

    if (type.permission) {
      return hasPermission(`view ${type.permission}`);
    }

    return true;
  });
};

export default useAllowedBlocks;

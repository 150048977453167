import React from 'react';

import { motion } from '@ubisend/framer-motion';
import { Flex, TicketGraphic } from '@ubisend/pulse-components';

const NoTickets = ({ children }) => (
  <Flex col middle center grow ySpace>
    <TicketGraphic
      transition={{ ease: [0.16, 1, 0.3, 1], duration: 1 }}
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
    />
    <motion.div
      transition={{ delay: 0.2, ease: [0.16, 1, 0.3, 1], duration: 1 }}
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}>
      <Flex col center ySpace>
        {children}
      </Flex>
    </motion.div>
  </Flex>
);

export default NoTickets;

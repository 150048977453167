import styled from 'styled-components';

import { size, spacing, text, display, colours } from '../styles';

const Span = styled.span`
  ${size}
  ${spacing}
  ${text}
  ${display}
  ${colours}

  ${props => props.clickable && tw`cursor-pointer hover:underline`}
`;

export default Span;

import ExplodeToJsonArrayInput from './ExplodeToJsonArrayInput';

const date = {
  name: 'Explode to json array',
  Input: ExplodeToJsonArrayInput,
  content: {},
  format: variable => {
    return `${variable}|explode_to_json_array`;
  },
  valid: () => true
};

export default date;

import React from 'react';

/**
 * Context to handle programmatic breakpoints.
 */
const DeviceContext = React.createContext({
  //  Object: current device breakpoint {
  // 	breakpoint: String - The tailwind defined name of the breakpoint e.g. 'lg'
  //  dimentions: String - The tailwind defined dimentions of the breakpoint e.g. '700px'
  // }
  breakpoint: '', // {'lg', '700px'}
  /**
   * Handles returning mobile state
   * true : false
   */
  isMobile: false,
  isDesktop: true,
  onMobile: false,
  onDesktop: true
});

export default DeviceContext;

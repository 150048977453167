import React from 'react';

const Pulse = props => (
  <svg
    id="svg"
    xmlns="http://www.w3.org/2000/svg"
    width="400"
    height="400"
    viewBox="0, 0, 400,400"
    {...props}>
    <path
      id="path0"
      d="M37.771 14.910 C 21.032 17.737,6.728 30.501,1.637 47.153 C 0.032 52.405,-0.000 53.752,-0.000 116.887 L -0.000 181.265 36.896 181.062 L 73.792 180.859 104.279 141.207 C 139.570 95.307,141.199 93.791,152.030 96.772 C 160.474 99.096,157.292 94.519,208.604 178.125 C 232.734 217.441,252.737 249.882,253.054 250.214 C 253.372 250.547,264.642 236.558,278.098 219.126 C 291.554 201.695,303.764 186.443,305.232 185.233 C 309.988 181.313,310.801 181.250,356.927 181.250 L 400.000 181.250 399.991 116.602 C 399.983 62.594,399.795 51.252,398.847 47.692 C 394.930 32.973,383.360 21.059,367.969 15.895 C 363.251 14.312,46.900 13.369,37.771 14.910 M121.930 180.837 C 108.459 198.289,96.236 213.557,94.768 214.767 C 90.012 218.687,89.199 218.750,43.073 218.750 L 0.000 218.750 0.000 283.163 L 0.000 347.576 1.936 353.671 C 6.500 368.042,18.320 379.549,33.203 384.110 C 37.689 385.485,44.851 385.547,200.000 385.547 L 362.109 385.547 367.578 383.822 C 382.077 379.248,393.543 367.908,398.064 353.671 L 400.000 347.576 400.000 283.163 L 400.000 218.750 363.477 218.763 L 326.953 218.776 296.875 257.931 C 261.703 303.717,261.518 303.906,251.806 303.906 C 240.560 303.906,242.119 305.998,192.217 223.926 C 168.019 184.127,147.816 151.011,147.322 150.336 C 146.571 149.309,142.416 154.300,121.930 180.837 "
      stroke="none"
      fill="#000000"
      fillRule="evenodd"></path>
  </svg>
);

export default Pulse;

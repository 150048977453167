import { hasPermission } from '@ubisend/pulse-auth';

import { AllFeedback, Feedback } from './Pages/index';

const routes = [
  {
    path: '/feedback',
    component: AllFeedback,
    name: 'All Feedback',
    canAccess: hasPermission('view feedback')
  },
  {
    path: '/feedback/:id',
    component: Feedback,
    name: 'Feedback',
    canAccess: hasPermission('view feedback')
  }
];

export default routes;

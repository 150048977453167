import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { AnimateSharedLayout } from '@ubisend/framer-motion';
import {
  Flex,
  Tag,
  TabButton,
  Panel,
  Paragraph,
  Button
} from '@ubisend/pulse-components';
import { Comments, Activity } from '@ubisend/pulse-tickets';

const statuses = {
  in_progress: 'Claimed',
  submitted: 'Pending'
};

const Ticket = ({ ticket }) => {
  const [tab, setTab] = useState('COMMENTS');

  const getEvent = (status, previousStatus) => {
    if (!previousStatus) {
      return 'Ticket was created';
    }

    let changeText = `Ticket was changed from ${
      statuses[previousStatus.status]
    } to ${statuses[status.status]}`;

    if (status.agent) {
      changeText = `${changeText} by ${status.agent.name}`;
    }

    if (status.assignee) {
      changeText = `${changeText} (while being assigned to ${status.assignee.name})`;
    }

    return changeText;
  };

  return (
    <Flex ySpace col pad>
      <Panel
        header={ticket.name}
        actions={
          <Button
            as={Link}
            variant="secondary"
            to={`/tickets/${ticket.id}`}
            icon="pencilAlt">
            Edit
          </Button>
        }>
        <Flex col>
          {ticket.groups.length > 0 && (
            <Flex xSpaceSm mbSm>
              {ticket.groups.map(group => (
                <Tag key={group.id}>{group.name}</Tag>
              ))}
            </Flex>
          )}
          <Paragraph>{ticket.description}</Paragraph>
        </Flex>
      </Panel>
      <Flex center xSpace>
        <AnimateSharedLayout>
          <TabButton
            active={tab === 'COMMENTS'}
            onClick={() => setTab('COMMENTS')}>
            Comments
          </TabButton>
          <TabButton
            active={tab === 'ACTIVITY'}
            onClick={() => setTab('ACTIVITY')}>
            Activity
          </TabButton>
        </AnimateSharedLayout>
      </Flex>
      {tab === 'COMMENTS' && (
        <Comments query={`tickets/ubidesk/${ticket.id}/comments`} />
      )}
      {tab === 'ACTIVITY' && (
        <Activity
          query={`tickets/ubidesk/${ticket.id}/statuses`}
          getEvent={getEvent}
        />
      )}
    </Flex>
  );
};

Ticket.propTypes = {
  ticket: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    groups: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
      }).isRequired
    ).isRequired
  }).isRequired
};

export default Ticket;

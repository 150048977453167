import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { CreatableSelect } from '@ubisend/pulse-components';

const GroupCondition = ({ value = null, onChange, ...props }) => {
  const handleChange = value => {
    onChange(value ? value.map(({ value }) => value) : null);
  };

  const wrappedValue = useMemo(() => {
    return value ? value.map(value => ({ label: value, value })) : null;
  }, [value]);

  return (
    <CreatableSelect
      {...props}
      closeMenuOnSelect
      isMulti
      isClearable
      onChange={handleChange}
      value={wrappedValue}
      noOptionsMessage={() => 'Start typing to create your options...'}
    />
  );
};

GroupCondition.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired
};

export default GroupCondition;

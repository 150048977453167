import React from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import { Select } from '@ubisend/pulse-components';

const format = ({ id, subject }) => ({ label: subject, value: id });

const EmailSelect = ({ value, ...props }) => {
  const { data, isLoading, isSuccess } = useQuery('emails/all');

  return (
    <Select
      isLoading={isLoading}
      options={isSuccess ? data.data.map(format) : []}
      value={
        isSuccess &&
        value &&
        format(data.data.find(email => email.id === value))
      }
      {...props}
    />
  );
};

EmailSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default EmailSelect;

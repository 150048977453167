import { useContext } from 'react';

import { ConditionalTypeContext } from '../Contexts/index';

const useConditionalTypes = () => {
  const context = useContext(ConditionalTypeContext);

  return context;
};

export default useConditionalTypes;

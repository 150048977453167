import { SheetConditional } from '../../Components/Subjects/Conditionals/index';

const subject = { id: null, type: 'spreadsheet' };

const conditional = {
  subject,
  type: null,
  condition: null,
  flipped: false,
  case_sensitive: false,
  target: ''
};

const sheetSubject = {
  subject,
  conditional,
  permission: 'view spreadsheets',
  name: 'Lookup a spreadsheet',
  Conditional: SheetConditional,
  toState: ({ subject }) => {
    return { type: 'spreadsheet', id: subject.id };
  }
};

const buildSheetSubject = sheet => {
  return {
    ...sheetSubject,
    toApi: () => ({
      id: sheet?.id || null,
      type: 'spreadsheet'
    })
  };
};

export default buildSheetSubject;

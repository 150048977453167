import React from 'react';

import { Flex } from '@ubisend/pulse-components';

import { Sidebar, Doc } from '../Components/index';

const Docs = ({ match }) => {
  const params = match.params[0];

  return (
    <Flex xSpace yScreen>
      <Sidebar />
      <Flex fat>
        <Doc path={params ? params.slice(1) : null} />
      </Flex>
    </Flex>
  );
};

export default Docs;

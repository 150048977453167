import styled from 'styled-components';

import { flex, size, spacing, position, grid, border } from '../styles';

const Div = styled.div`
  ${flex}
  ${size}
  ${spacing}
  ${position}
  ${grid}
  ${border}

  box-sizing: border-box;
`;

export default Div;

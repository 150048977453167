import React from 'react';

import { ColourPicker } from '@ubisend/pulse-components';

import { useQrCode } from '../hooks/index';

const DarkColourInput = props => {
  const { colour, dispatch } = useQrCode();

  const handleColourChange = colour => {
    dispatch({ type: 'CHANGE_COLOUR', colour: { dark: colour.hex } });
  };

  return (
    <ColourPicker
      {...props}
      color={colour.dark}
      onChangeComplete={handleColourChange}
    />
  );
};

export default DarkColourInput;

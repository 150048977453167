import React from 'react';

import { PermissionFilter } from '@ubisend/pulse-auth';
import { Span } from '@ubisend/pulse-components';

import { useVariable } from '../../hooks/index';

const VariableSubject = () => {
  const { variable, subjects } = useVariable();

  if (!variable.subject) {
    return null;
  }

  const subject = subjects.find(
    ({ subject }) => subject.type === variable.subject.type
  );

  if (!subject.permission) {
    return <subject.Saver />;
  }

  return (
    <PermissionFilter
      can={subject.permission}
      fallback={
        <Span tinyText light>
          You do not have permission to {subject.permission}, so the remaining
          fields are hidden.
        </Span>
      }>
      <subject.Saver />
    </PermissionFilter>
  );
};

export default VariableSubject;

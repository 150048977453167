import { subjects as integrationSubjects } from '@ubisend/pulse-integrations';
import { subjects as importSubjects } from '@ubisend/pulse-import';

const composers = [
  integrationSubjects.composers.integration,
  integrationSubjects.composers.lookup,
  importSubjects.composers.section
];

export default composers;

import React from 'react';

import { AnimatePresence } from '@ubisend/framer-motion';
import {
  OneThirdLayout,
  Flex,
  TwoThirdsLayout,
  InfoSection,
  NoResults,
  Panel,
  PanelSlider,
  IconPanel,
  Button
} from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';
import { useDelete } from '@ubisend/pulse-hooks';

import { deleteSheet } from '../../api/index';
import { useSheets } from '../../hooks/index';
import CreateSheet from './CreateSheet';
import EditSheet from './EditSheet';
import Lookups from './Lookups';

const Sheets = () => {
  const {
    sheets,
    create,
    setCreate,
    edit,
    setEdit,
    viewLookups,
    setViewLookups
  } = useSheets();

  const { handleDelete } = useDelete(deleteSheet, {
    queries: ['spreadsheets']
  });

  return (
    <Flex>
      {create && <CreateSheet handleClose={() => setCreate(false)} />}
      {edit && <EditSheet sheet={edit} handleClose={() => setEdit(null)} />}
      <AnimatePresence>
        {viewLookups && (
          <PanelSlider
            header="Configure Lookups"
            handleHide={() => setViewLookups(null)}>
            <Lookups />
          </PanelSlider>
        )}
      </AnimatePresence>
      <OneThirdLayout>
        <InfoSection
          title="Spreadsheet integrations"
          info="Populate your chatbot with knowledge from an uploaded spreadsheet."
          actions={
            <PermissionFilter can="create spreadsheets">
              <Button onClick={() => setCreate(true)}>
                Add spreadsheet integration
              </Button>
            </PermissionFilter>
          }
        />
      </OneThirdLayout>
      <TwoThirdsLayout>
        <Flex col ySpace>
          {!sheets && (
            <>
              <Panel loading loadingItems={1} />
              <Panel loading loadingItems={1} />
            </>
          )}
          {sheets && sheets.length === 0 && (
            <NoResults
              text="No uploaded spreadsheets"
              subtitle={'Upload a spreadsheet to use its data in conversations'}
            />
          )}
          {sheets &&
            sheets.length > 0 &&
            sheets.map((sheet, key) => (
              <IconPanel
                key={key}
                icon={'documentReport'}
                title={sheet.name}
                status={'Active'}
                actions={
                  <Flex xSpace>
                    <PermissionFilter can="edit spreadsheets">
                      <Button
                        variant="secondary"
                        icon="pencilAlt"
                        onClick={() => setEdit(sheet)}>
                        Edit
                      </Button>
                    </PermissionFilter>
                    <Button onClick={() => setViewLookups(sheet.id)}>
                      Lookups
                    </Button>
                    {sheet.download_link && (
                      <Button
                        as="a"
                        download
                        icon="download"
                        href={sheet.download_link}>
                        Download
                      </Button>
                    )}
                    <PermissionFilter can="delete spreadsheets">
                      <Button
                        variant="secondary"
                        colour="danger"
                        icon="trash"
                        onClick={() => handleDelete(sheet.id)}>
                        Delete
                      </Button>
                    </PermissionFilter>
                  </Flex>
                }
              />
            ))}
        </Flex>
      </TwoThirdsLayout>
    </Flex>
  );
};

export default Sheets;

import React from 'react';
import PropTypes from 'prop-types';

import { PreviewCondition } from '@ubisend/pulse-components';

import ConditionPreview from '../../ConditionPreview';

const OsPreview = ({ prefix = 'When', ...props }) => (
  <p>
    {prefix}
    {props.flipped === 1 && ' not'} user&apos;s operating system
    <PreviewCondition>{props.type.name}</PreviewCondition>
    <ConditionPreview {...props} />
  </p>
);

OsPreview.propTypes = {
  prefix: PropTypes.string,
  flipped: PropTypes.number,
  type: PropTypes.shape({ type: PropTypes.string, name: PropTypes.string })
};

export default OsPreview;

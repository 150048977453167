import { useReducer } from 'react';

const TYPES = {
  // Imports
  SET_IMPORTS: 'SET_IMPORTS',
  ADD_IMPORT: 'ADD_IMPORT',
  UPDATE_IMPORT: 'UPDATE_IMPORT',
  REMOVE_IMPORT: 'REMOVE_IMPORT',
  REORDER_IMPORTS: 'REORDER_IMPORTS',

  // Sections
  ADD_SECTION: 'ADD_SECTION',
  REMOVE_SECTION: 'REMOVE_SECTION',
  REORDER_SECTION: 'REORDER_SECTION',

  // Section content
  REVISE_SECTION_CONTENT: 'REVISE_SECTION_CONTENT',
  APPROVE_SECTION_CONTENT: 'APPROVE_SECTION_CONTENT',

  // Resources
  ADD_RESOURCE: 'ADD_RESOURCE',
  REMOVE_RESOURCE: 'REMOVE_RESOURCE'
};

const reducer = (state, { type, ...params }) => {
  const updateSection = (imports, importId, sectionId, callback) => {
    return imports.map(item => {
      if (item.id === importId) {
        return {
          ...item,
          sections: item.sections.map(section => {
            if (section.id === sectionId) {
              return {
                ...section,
                ...callback(section)
              };
            }
            return section;
          })
        };
      }

      return item;
    });
  };

  switch (type) {
    // Imports
    case TYPES.SET_IMPORTS:
      return params.imports;
    case TYPES.ADD_IMPORT:
      return state.concat(params.newImport);
    case TYPES.UPDATE_IMPORT:
      return state.map(oldImport => {
        if (oldImport.id === params.newImport.id) {
          return params.newImport;
        }

        return oldImport;
      });
    case TYPES.REMOVE_IMPORT:
      return state
        .filter(item => item.id !== params.oldImport.id)
        .map(importItem => {
          if (importItem.index > params.oldImport.index) {
            return { ...importItem, index: importItem.index - 1 };
          }
          return importItem;
        });

    case TYPES.REORDER_IMPORTS:
      return state.map(importItem => {
        if (importItem.id === params.to.id) {
          return { ...importItem, index: params.from.index };
        }
        if (importItem.id === params.from.id) {
          return { ...importItem, index: params.to.index };
        }
        return importItem;
      });

    // Sections
    case TYPES.ADD_SECTION: {
      return state.map(item => {
        if (item.id === params.import.id) {
          return {
            ...item,
            sections: [...item.sections, { ...params.newSection }]
          };
        }
        return item;
      });
    }
    case TYPES.REMOVE_SECTION: {
      return state.map(item => {
        if (item.id === params.import.id) {
          return {
            ...item,
            sections: item.sections.filter(
              section => section.id !== params.section.id
            )
          };
        }
        return item;
      });
    }
    case TYPES.REORDER_SECTION: {
      const sections = updateSection(
        state,
        params.from.import_id,
        params.from.id,
        () => ({ index: params.to.index })
      );
      return updateSection(sections, params.to.import_id, params.to.id, () => ({
        index: params.from.index
      }));
    }

    // Section content
    case TYPES.REVISE_SECTION_CONTENT:
      return updateSection(
        state,
        params.import.id,
        params.section.id,
        section => ({
          other_content: section.other_content.concat(params.content)
        })
      );
    case TYPES.APPROVE_SECTION_CONTENT:
      return updateSection(
        state,
        params.import.id,
        params.section.id,
        section => ({
          content: params.content,
          other_content: section.other_content
            .concat(section.content)
            .filter(content => content.id !== params.content.id)
        })
      );

    // Resources
    case TYPES.ADD_RESOURCE:
      return updateSection(
        state,
        params.import.id,
        params.section.id,
        section => ({
          resources: section.resources.concat(params.resource)
        })
      );
    case TYPES.REMOVE_RESOURCE:
      return updateSection(
        state,
        params.import.id,
        params.section.id,
        section => ({
          resources: section.resources.filter(
            resource => resource.id !== params.resource.id
          )
        })
      );

    default:
      throw new Error(`No event defined in useImportReducer for ${type}`);
  }
};

const useImportReducer = (initialImports = null) => {
  const [imports, dispatch] = useReducer(reducer, initialImports);

  return { imports, dispatch };
};

export default useImportReducer;

import { useContext } from 'react';

import { ChannelContext } from '../Contexts/index';

const useChannels = () => {
  const context = useContext(ChannelContext);

  return context;
};

export default useChannels;

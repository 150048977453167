import React from 'react';
import { useQuery } from 'react-query';

import { ConditionalTypeContext } from '../Contexts/index';

const ConditionalTypeProvider = ({ children }) => {
  const { data } = useQuery('conversations/builder/conditionals');

  return (
    <ConditionalTypeContext.Provider value={data ? data.data : []}>
      {children}
    </ConditionalTypeContext.Provider>
  );
};

export default ConditionalTypeProvider;

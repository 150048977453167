import { useState, useCallback } from 'react';

import useAuth from './useAuth';

const useResubscribe = () => {
  const [resubscribed, setResubscribed] = useState(false);

  const { query, setToken } = useAuth();

  const handleResubscribe = useCallback(() => {
    setResubscribed(true);
    setToken(null);
  }, [setResubscribed, setToken]);

  const isSuccess = resubscribed && !query.isFetching;

  const isLoading = resubscribed && query.isFetching;

  return { resubscribe: handleResubscribe, isSuccess, isLoading };
};

export default useResubscribe;

import useMutation from './useMutation';

const sendFileToPulse = (axios, params) => {
  return axios.post('files', params);
};

const useSendFiles = options => {
  const mutation = useMutation(sendFileToPulse, options);

  return mutation;
};

export default useSendFiles;

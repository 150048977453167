const languages = [
  'en', // English
  'de', // German
  'nl', // Dutch
  'fr', // French
  'vi', // Vietnamese
  'pt' // Portuguese
];

export default languages;

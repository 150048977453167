import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useMutation } from '@ubisend/pulse-hooks';
import { AnimatePresence } from '@ubisend/framer-motion';
import {
  Divider,
  Flex,
  useModal,
  useNotification,
  ActionMenu
} from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';

import {
  useBuilder,
  useAllowedBlocks,
  useCanvas
} from '../../../../hooks/index';
import { deleteStepTransition } from '../../../../api/index';
import { Block, types } from '../../../Blocks/index';
import EditTransitionNode from './EditTransitionNode';
import NodeButton from '../../NodeButton';

const Wrapper = styled.div`
  ${tw`p-3 overflow-y-auto h-full flex items-end`}

  & > p {
    ${tw`m-0 mb-1 text-sm mr-4`}
    &:last-of-type {
      ${tw`mb-0`}
    }
  }
`;

const TransitionNode = ({ node, handleEditBlock }) => {
  const [edit, setEdit] = useState(false);

  const { dispatch } = useBuilder();
  const blocks = useAllowedBlocks(node.blocks, types);
  const { showModal, hideModal } = useModal();
  const { showSuccess } = useNotification();
  const { setPanningEnabled, handleCanvasRender } = useCanvas();

  const { mutateAsync } = useMutation(deleteStepTransition, {
    onSuccess: () => {
      dispatch({
        type: 'DELETE_TRANSITION',
        stepId: node.meta.stepId,
        transitionId: node.id
      });
      showSuccess('Successfully deleted transition');
    }
  });

  const handleDelete = () => {
    showModal({
      header: 'Delete transition',
      body: `Are you sure you want to delete this transition?`,
      handleConfirm: async () => {
        try {
          await mutateAsync({
            stepId: node.meta.stepId,
            transitionId: node.id
          });
          handleCanvasRender();
        } finally {
          hideModal();
        }
      }
    });
  };

  return (
    <Wrapper
      onMouseLeave={() => setPanningEnabled(true)}
      onScroll={() => setPanningEnabled(false)}>
      <AnimatePresence>
        {edit && (
          <EditTransitionNode handleClose={() => setEdit(false)} node={node} />
        )}
      </AnimatePresence>
      {blocks.length > 0 && (
        <Flex mtSm xScroll xSpaceSm>
          {blocks.map((block, key) => (
            <Block
              key={key}
              onClick={() => handleEditBlock(block)}
              {...block}
            />
          ))}
        </Flex>
      )}
      <Flex absolute style={{ top: '0.5rem', right: '0.5rem' }}>
        <ActionMenu
          buttonProps={{
            'aria-label': 'Toggle transition menu',
            onMouseDown: event => event.stopPropagation(),
            onMouseUp: event => event.stopPropagation()
          }}>
          <PermissionFilter can="edit conversations">
            <>
              <NodeButton
                variant="inline"
                icon="pencilAlt"
                onClick={() => {
                  setEdit(true);
                }}>
                Edit Transition
              </NodeButton>
              <Divider mtNone mbNone />
            </>
          </PermissionFilter>
          <PermissionFilter can="delete conversations">
            <NodeButton
              variant="inline"
              colour="danger"
              icon="trash"
              onClick={handleDelete}>
              Delete Transition
            </NodeButton>
          </PermissionFilter>
        </ActionMenu>
      </Flex>
    </Wrapper>
  );
};

TransitionNode.propTypes = {
  node: PropTypes.shape({
    id: PropTypes.number.isRequired,
    blocks: PropTypes.arrayOf(PropTypes.object).isRequired,
    meta: PropTypes.shape({
      stepId: PropTypes.number.isRequired
    }).isRequired,
    base: PropTypes.object.isRequired
  }),
  handleEditBlock: PropTypes.func.isRequired
};

export { Wrapper };
export default TransitionNode;

import LiveValueMetric from './LiveValueMetric';

const liveValue = {
  name: 'Live value',
  Component: LiveValueMetric,
  columns: 2,
  rows: 1,
  query: {
    refetchIntervalInBackground: 60000,
    refetchInterval: 60000
  }
};

export default liveValue;

import React from 'react';

import {
  ColourPicker,
  Panel,
  Label,
  FormGroup,
  Flex,
  OneQuarterLayout,
  InfoSection
} from '@ubisend/pulse-components';

import { useBotSettings } from '../../hooks/index';
import ColourContrast from '../ColourContrast';

const FullPageColourSettings = () => {
  const { settings, focusBanner, handleColourChangeNested } = useBotSettings();

  return (
    <Flex>
      <OneQuarterLayout>
        <InfoSection
          title="Colours"
          info="Edit the colour scheme of your bot."
        />
      </OneQuarterLayout>
      <Panel>
        <FormGroup>
          <Flex mbSm between middle>
            <Label mb={false}>Gradient start colour</Label>
            <ColourContrast
              backgrounds={[settings.styles.gradient.from]}
              foreground={'white'}
            />
          </Flex>
          <ColourPicker
            onChange={focusBanner}
            color={settings.styles.gradient.from}
            onChangeComplete={handleColourChangeNested('gradient', 'from')}
          />
        </FormGroup>
        <FormGroup>
          <Flex mbSm between middle>
            <Label mb={false}>Gradient end colour</Label>
            <ColourContrast
              backgrounds={[settings.styles.gradient.to]}
              foreground={'white'}
            />
          </Flex>
          <ColourPicker
            onChange={focusBanner}
            color={settings.styles.gradient.to}
            onChangeComplete={handleColourChangeNested('gradient', 'to')}
          />
        </FormGroup>
      </Panel>
    </Flex>
  );
};

export default FullPageColourSettings;

import DateInput from './DateInput';

const date = {
  name: 'Date',
  Input: DateInput,
  content: {
    format: ''
  },
  format: (variable, content) => {
    return `${variable}|date("${content.format}")`;
  },
  valid: content => content.format.length > 0
};

export default date;

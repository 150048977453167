import styled from 'styled-components';

import { motion } from '@ubisend/framer-motion';
import { hexToRgba } from '@ubisend/pulse-component-hooks';

const UserIcon = styled(motion.div)`
  ${tw`font-poppins w-12 h-12 rounded-full text-xl flex font-bold items-center justify-center flex-no-shrink uppercase`}
  color: ${({ theme, colour = 'primary' }) => theme[colour]};
  background-color: ${({ theme, background = 'primary' }) => {
    return hexToRgba(theme[background], 0.25);
  }};
`;

export default UserIcon;

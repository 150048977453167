import axios from 'axios';

const createLink = params => axios.post('links', params);

const updateLink = ({ id, ...params }) => axios.put(`links/${id}`, params);

const deleteLink = id => axios.delete(`links/${id}`);

const enablement = ({ id, ...params }) => {
  return axios.put(`links/${id}/enable`, params);
};

export { createLink, updateLink, deleteLink, enablement };

import React from 'react';

import { useNotification, useModal, Button } from '@ubisend/pulse-components';
import { useAuth } from '@ubisend/pulse-auth';

import { exportUser } from '../api/index';

const ExportUser = () => {
  const { showModal, hideModal } = useModal();
  const { showSuccess } = useNotification();
  const { user: loggedInUser } = useAuth();

  const handleDataExport = () => {
    showModal({
      header: 'Export Your Data',
      body: `By clicking confirm, you will receive an email at "${loggedInUser.email}" with a link to download all of the user data associated with this account that we have stored.`,
      handleConfirm: async () => {
        try {
          await exportUser();
          showSuccess(
            'You will receive an email shortly with details on accessing your data'
          );
        } finally {
          hideModal();
        }
      }
    });
  };

  return (
    <Button onClick={handleDataExport} icon="download">
      Export Your Data
    </Button>
  );
};

export default ExportUser;

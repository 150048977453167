const STEP_HEADER_HEIGHT = 54;
const BLOCK_CONTAINER_HEIGHT = 65;
const STEP_WIDTH = 288;
const STEP_HEADER_PADDING_Y = 8;
const STEP_HEADER_PADDING_X = 12;
const STEP_HEADER_FONT_SIZE = 12;
const BORDER_RADIUS = 4;
const RESPONSE_PREVIEW_PADDING = 16;
const BUBBLE_BORDER_RADIUS = 12;
const BUBBLE_PADDING_Y = 10;
const BUBBLE_PADDING_X = 12;
const PARAGRAPH_FONT_SIZE = 14;
const PARAGRAPH_LINE_HEIGHT = PARAGRAPH_FONT_SIZE * 1.5;
const SIDEBAR_WIDTH = 12 * 16;
const BUBBLE_INNER_WIDTH =
  STEP_WIDTH - RESPONSE_PREVIEW_PADDING * 2 - BUBBLE_PADDING_X * 2;
const ACTION_BUTTON_WIDTH =
  STEP_WIDTH - RESPONSE_PREVIEW_PADDING * 2 - BUBBLE_PADDING_X * 2;
const ACTION_BUTTON_LINE_HEIGHT = 14;
const ACTION_BUTTON_HEIGHT = ACTION_BUTTON_LINE_HEIGHT + BUBBLE_PADDING_Y * 2;
const ACTION_BUTTON_SPACING = RESPONSE_PREVIEW_PADDING / 2;
const MAX_BUTTON_TEXT_WIDTH = BUBBLE_INNER_WIDTH - BUBBLE_PADDING_X * 2;
const IMAGE_ASPECT_RATIO = 9 / 16;
const IMAGE_WIDTH = STEP_WIDTH - RESPONSE_PREVIEW_PADDING * 2;
const IMAGE_HEIGHT = IMAGE_WIDTH * IMAGE_ASPECT_RATIO;

export {
  STEP_HEADER_HEIGHT,
  BLOCK_CONTAINER_HEIGHT,
  STEP_WIDTH,
  STEP_HEADER_PADDING_Y,
  STEP_HEADER_PADDING_X,
  STEP_HEADER_FONT_SIZE,
  BORDER_RADIUS,
  RESPONSE_PREVIEW_PADDING,
  BUBBLE_BORDER_RADIUS,
  BUBBLE_PADDING_X,
  BUBBLE_PADDING_Y,
  PARAGRAPH_FONT_SIZE,
  PARAGRAPH_LINE_HEIGHT,
  SIDEBAR_WIDTH,
  BUBBLE_INNER_WIDTH,
  ACTION_BUTTON_HEIGHT,
  ACTION_BUTTON_WIDTH,
  ACTION_BUTTON_SPACING,
  MAX_BUTTON_TEXT_WIDTH,
  ACTION_BUTTON_LINE_HEIGHT,
  IMAGE_WIDTH,
  IMAGE_HEIGHT,
  IMAGE_ASPECT_RATIO
};

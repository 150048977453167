import { useRef } from 'react';

const useOveridableRef = existingRef => {
  const newRef = useRef();

  if (existingRef) {
    return existingRef;
  }

  return newRef;
};

export default useOveridableRef;

import React from 'react';
import PropTypes from 'prop-types';

import { Converse, BotContext } from '@ubisend/pulse-volt';

import { useComposer } from '../../hooks/index';

const defaultProps = {
  showClose: false,
  open: true,
  name: 'New message',
  showName: true
};

const ComposerWidget = ({ as, ...props }) => {
  const { response } = useComposer();

  const Widget = as || Converse;

  return (
    <BotContext.Provider
      value={{
        ...defaultProps,
        messages: [{ direction: 'toClient', ...response }],
        ...props
      }}>
      <Widget start="/window/body/messages" />
    </BotContext.Provider>
  );
};

ComposerWidget.propTypes = {
  as: PropTypes.elementType
};

export default ComposerWidget;
export { defaultProps };

import ArrayDefaultValueInput from './ArrayDefaultValueInput';
import ArrayPreview from './ArrayPreview';

const arrayType = {
  key: 'Array',
  name: 'Array',
  default_value: [],
  DefaultValueInput: ArrayDefaultValueInput,
  Preview: ArrayPreview
};

export default arrayType;

import React from 'react';

import { Flex } from '@ubisend/pulse-components';

import CreateLookup from './CreateLookup';
import EditLookup from './EditLookup';
import { useSheets } from '../../hooks/index';

const Lookups = () => {
  const { viewLookups: sheet } = useSheets();

  if (!sheet) {
    return null;
  }

  return (
    <Flex ySpace col>
      {sheet.lookups.map((lookup, key) => (
        <EditLookup {...lookup} key={key} />
      ))}
      <CreateLookup />
    </Flex>
  );
};

export default Lookups;

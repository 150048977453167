import React from 'react';

import { Button } from '@ubisend/pulse-components';
import { usePulse } from '@ubisend/pulse-hooks';

const LicenseLink = ({ children, ...props }) => {
  const { platform } = usePulse();

  return (
    <Button
      as={'a'}
      variant="inline"
      href={`mailto:${platform.account_manager_email}`}
      {...props}>
      {children}
    </Button>
  );
};

export default LicenseLink;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import { Select } from '@ubisend/pulse-components';

// E.g. "High (80% to 100%)"
const readableThreshold = (name, [start, end]) => {
  return `${name} (${(start * 100).toFixed() + '%'} to ${
    (end * 100).toFixed() + '%'
  })`;
};

const format = ([name, threshold]) => ({
  value: name,
  label: readableThreshold(name, threshold)
});

const ThresholdSelect = ({ value, ...props }) => {
  const { data, isLoading, isSuccess } = useQuery('thresholds');

  const options = useMemo(() => {
    if (!isSuccess) {
      return null;
    }

    return Object.entries(data).map(format);
  }, [data, isSuccess]);

  return (
    <Select
      aria-label="thresholds"
      isLoading={isLoading}
      value={options && value && options.find(option => option.value === value)}
      options={options || []}
      {...props}
    />
  );
};

ThresholdSelect.propTypes = {
  value: PropTypes.string
};

export default ThresholdSelect;

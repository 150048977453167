import { hasPermission } from '@ubisend/pulse-auth';

import { ChannelsPage } from './Pages/index';

const routes = [
  {
    path: '/channels',
    component: ChannelsPage,
    name: 'Channels',
    canAccess: hasPermission('view channels')
  }
];

export default routes;

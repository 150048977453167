import React from 'react';

import { Label } from '@ubisend/pulse-components';
import { TemplatingTextArea } from '@ubisend/pulse-templating';

import { useComposer } from '../../../../hooks/index';

const EventInput = () => {
  const {
    response: { content },
    dispatch
  } = useComposer();

  const handleResponseChange = value => {
    dispatch({ type: 'UPDATE_CONTENT', newContent: { text: value } });
  };

  return (
    <div>
      <Label htmlFor="event">Message</Label>
      <TemplatingTextArea
        id="event"
        name="event"
        value={content.text}
        onChange={handleResponseChange}
      />
    </div>
  );
};

export default EventInput;

import styled from 'styled-components';

const MarkdownTableRow = styled.tr`
  ${tw`py-2`}
  &:not(:last-child) {
    ${tw`border-0 border-b border-grey-medium border-solid`}
  }
`;

export default MarkdownTableRow;

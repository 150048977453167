import { useReducer, useMemo } from 'react';

import { isValidCsv } from '../utils/index';

const TYPES = {
  UPDATE_SUBJECT: 'UPDATE_SUBJECT',
  UPDATE_BODY: 'UPDATE_BODY',
  UPDATE_FROM_NAME: 'UPDATE_FROM_NAME',
  UPDATE_FROM_SUFFIX: 'UPDATE_FROM_SUFFIX',
  UPDATE_FROM_PREFIX: 'UPDATE_FROM_PREFIX',
  UPDATE_TO: 'UPDATE_TO',
  UPDATE_CC: 'UPDATE_CC',
  UPDATE_BCC: 'UPDATE_BCC'
};

const defaultEmail = {
  subject: '',
  body: '',
  fromName: '',
  fromSuffix: '',
  fromPrefix: '',
  to: '',
  cc: '',
  bcc: ''
};

const reducer = (state, { type, ...params }) => {
  switch (type) {
    case TYPES.UPDATE_SUBJECT:
      return { ...state, subject: params.subject };
    case TYPES.UPDATE_BODY:
      return { ...state, body: params.body };
    case TYPES.UPDATE_FROM_NAME:
      return { ...state, fromName: params.fromName };
    case TYPES.UPDATE_FROM_SUFFIX:
      return { ...state, fromSuffix: params.fromSuffix };
    case TYPES.UPDATE_FROM_PREFIX:
      return { ...state, fromPrefix: params.fromPrefix };
    case TYPES.UPDATE_TO:
      return { ...state, to: params.to };
    case TYPES.UPDATE_CC:
      return { ...state, cc: params.cc };
    case TYPES.UPDATE_BCC:
      return { ...state, bcc: params.bcc };
    default:
      throw new Error(`No event defined in useEmailReducer for ${type}`);
  }
};

const useEmailReducer = (initialEmail = defaultEmail) => {
  const [email, dispatch] = useReducer(reducer, {
    ...defaultEmail,
    ...initialEmail
  });

  const form = {
    subject: email.subject,
    body: email.body,
    from_name: email.fromName,
    from_suffix: email.fromSuffix,
    from_prefix: email.fromPrefix,
    to: email.to,
    cc: email.cc,
    bcc: email.bcc
  };

  const valid = useMemo(() => {
    if (email.cc && !isValidCsv(email.cc)) {
      return false;
    }

    if (email.bcc && !isValidCsv(email.bcc)) {
      return false;
    }

    return (
      email.subject &&
      email.body &&
      email.fromName &&
      email.fromPrefix &&
      email.fromSuffix &&
      email.to
    );
  }, [email]);

  return { ...email, email, form, dispatch, valid, TYPES };
};

export default useEmailReducer;

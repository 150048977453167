// Handle all permutations of the message direction.
// The funny thing is we also have 'recievedMessage' and 'sentMessage',
// and recievedMessage is spelled wrong :)
// Please god someone grab this:
// https://ubisend.atlassian.net/secure/RapidBoard.jspa?rapidView=44&view=planning&selectedIssue=PUL-643
const flips = {
  outgoing: 'toServer',
  incoming: 'toClient',
  toServer: 'toClient',
  toClient: 'toServer'
};

const flipDirection = ({ direction, ...rest }) => {
  return { ...rest, direction: flips[direction] };
};

export { flipDirection };

import React from 'react';
import dayjs from 'dayjs';

import { Flex, Heading2 } from '@ubisend/pulse-components';
import { useAuth } from '@ubisend/pulse-auth';

const getTimeNoun = () => {
  const time = dayjs().hour();

  if (time < 4) {
    return 'evening';
  }

  if (time < 12) {
    return 'morning';
  }

  if (time < 17) {
    return 'afternoon';
  }

  return 'evening';
};

const WelcomeMessage = () => {
  const { user } = useAuth();

  return (
    <Flex>
      <Heading2>
        Welcome back {user.first_name}, hope you&apos;re having a great{' '}
        {getTimeNoun()}.
      </Heading2>
    </Flex>
  );
};

export default WelcomeMessage;

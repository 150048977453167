import React from 'react';
import PropTypes from 'prop-types';

import SplitText from '../SplitText';

const Progress = ({ content }) => {
  return <SplitText>{content.text}</SplitText>;
};

Progress.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.string.isRequired
  }).isRequired
};

export default Progress;

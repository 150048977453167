import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const useTheme = () => {
  const { bot, ...rest } = useContext(ThemeContext);

  return { ...bot, ...rest };
};

export default useTheme;

import React from 'react';

import { HiddenInput } from '@ubisend/pulse-components';

// TODO: Do we want to make this configurable per composer?
const fileTypesToAccept = ['*'];

const UploadFileInput = props => {
  return (
    <HiddenInput
      type="file"
      multiple
      accept={fileTypesToAccept.join(',')}
      {...props}
    />
  );
};

export default UploadFileInput;

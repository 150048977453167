import React from 'react';
import styled from 'styled-components';

import Footer, { animations } from './Footer';

const StickyContainer = styled(Footer)`
  ${tw`sticky w-full pin-b z-20`}
`;

const StickyFooter = ({ children }) => (
  <StickyContainer {...animations}>{children}</StickyContainer>
);

export default StickyFooter;

import React from 'react';
import PropTypes from 'prop-types';

import { IntentSelect } from '@ubisend/pulse-training';

const IntentCondition = ({ value, onChange }) => {
  const handleChange = option => {
    onChange(option ? option.value : null);
  };

  return <IntentSelect value={value} onChange={handleChange} />;
};

IntentCondition.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default IntentCondition;

import query from '../query';
import PieChartMetric from './PieChartMetric';

const pieChart = {
  name: 'Pie chart',
  Component: PieChartMetric,
  columns: 2,
  rows: 1,
  query
};

export default pieChart;

import React from 'react';

const AuthContext = React.createContext({
  token: null,
  setToken: () => null,
  query: {
    refetch: () => null,
    isFetching: false,
    isLoading: false
  }
});

export default AuthContext;

import { useQuery } from 'react-query';

const useLocations = () => {
  const { data, isSuccess } = useQuery('locations');

  return {
    locations: isSuccess ? data.data : null,
    hasLocations: isSuccess ? data.data.length > 0 : false
  };
};

export default useLocations;

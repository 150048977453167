import { useContext } from 'react';

import { EndpointsContext } from '../Contexts/index';

const useEndpoints = () => {
  const context = useContext(EndpointsContext);

  return context;
};

export default useEndpoints;

import React from 'react';
import PropTypes from 'prop-types';

import { TextInput, Label } from '@ubisend/pulse-components';

const RefexInput = ({ content, onChange }) => {
  const handleChange = event => {
    const regex = event.target.value;

    onChange({ regex });
  };

  return (
    <div>
      <Label htmlFor="regex">Regex to use</Label>
      <TextInput
        id="regex"
        placeholder="/email\\((.*?)\\)/"
        value={content.regex}
        onChange={handleChange}
      />
    </div>
  );
};

RefexInput.propTypes = {
  content: PropTypes.shape({
    regex: PropTypes.string.isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired
};

export default RefexInput;

import { useReducer, useMemo } from 'react';

const TYPES = {
  UPDATE_NAME: 'UPDATE_NAME'
};

const reducer = (state, { type, ...params }) => {
  switch (type) {
    case TYPES.UPDATE_NAME:
      return { ...state, name: params.name };

    // Errors
    default:
      throw new Error(`No event defined in useCustomMetricReducer for ${type}`);
  }
};

const defaultCustomMetric = {
  name: ''
};

const useCustomMetricReducer = (inititalCustomMetric = defaultCustomMetric) => {
  const [metric, dispatch] = useReducer(reducer, {
    ...defaultCustomMetric,
    ...inititalCustomMetric
  });

  const valid = useMemo(() => {
    return Boolean(metric.name);
  }, [metric]);

  const form = { name: metric.name };

  return { metric, dispatch, valid, form, TYPES };
};

export default useCustomMetricReducer;

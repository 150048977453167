import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import types from './Types/index';

const CreateFile = ({ type, children, ...props }) => {
  const { Create } = useMemo(() => types[type], [type]);

  return <Create {...props} />;
};

CreateFile.propTypes = {
  type: PropTypes.string
};

export default CreateFile;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Heading2,
  ModalPortal,
  ModalContainer,
  ModalContent,
  Flex,
  ModalClose,
  Divider,
  Button
} from '@ubisend/pulse-components';
import {
  CreateQrCode as DumbCreateQrCode,
  useQrCodeReducer,
  QrCodeContext
} from '@ubisend/pulse-qr-codes';

import { useUploadFile, useFile, useFileSource } from '../../../hooks/index';
import { FileProvider } from '../../../Providers/index';
import FileDetails from '../../FileDetails';

const CreateQrCodeModal = ({ handleCancel, handleConfirm }) => {
  const [step, setStep] = useState('QR');

  const qr = useQrCodeReducer();
  const { dispatch } = useFile();

  const handleQrConfirm = file => {
    dispatch({ type: 'UPLOAD_FILE', file });
    setStep('FILE');
  };

  return (
    <ModalPortal>
      <ModalContainer>
        <ModalContent style={{ width: '100%', maxWidth: '57rem' }}>
          <Flex between fat pad center middle>
            <Heading2>Create a QR code</Heading2>
            <ModalClose onClick={handleCancel} />
          </Flex>
          <Divider mtNone mbNone />
          <Flex fat>
            {step === 'QR' && (
              <QrCodeContext.Provider value={qr}>
                <DumbCreateQrCode
                  handleSave={handleQrConfirm}
                  submitText={'Next'}
                />
              </QrCodeContext.Provider>
            )}
            {step === 'FILE' && (
              <Flex col fat>
                <FileDetails showUpload={false} onSubmit={handleConfirm} />
              </Flex>
            )}
          </Flex>
        </ModalContent>
      </ModalContainer>
    </ModalPortal>
  );
};

CreateQrCodeModal.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired
};

const CreateQrCode = ({ queryKey }) => {
  const [upload, setUpload] = useState(false);

  const source = useFileSource();

  const { mutateAsync, isLoading } = useUploadFile(queryKey);

  const handleSubmit = async form => {
    await mutateAsync({
      endpoint: queryKey,
      source_id: source.source.id,
      type: 'qr-code',
      ...form
    });
    setUpload(false);
  };

  return (
    <>
      {upload && (
        <FileProvider>
          <CreateQrCodeModal
            handleConfirm={handleSubmit}
            handleCancel={() => setUpload(false)}
          />
        </FileProvider>
      )}
      <Button
        disabled={!source}
        loading={isLoading}
        onClick={() => setUpload(true)}>
        + File
      </Button>
    </>
  );
};

CreateQrCode.propTypes = {
  queryKey: PropTypes.string.isRequired
};

export default CreateQrCode;

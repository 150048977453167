import React, { useState, useEffect } from 'react';

import { LoadingContainer } from '@ubisend/pulse-components';

import { FacebookContext } from '../Contexts/index';
import { src, version, appId } from '../facebook';

const addFacebookToWindow = () => {
  return new Promise(resolve => {
    window.fbAsyncInit = () => {
      window.FB.init({ appId, version });
      resolve(window.FB);
    };
  });
};

const FacebookProvider = ({ children }) => {
  const [data, setData] = useState();

  const setupFacebook = async script => {
    const promise = addFacebookToWindow();

    script.src = src;
    script.async = true;
    script.defer = true;
    script.crossOrigin = 'anonymous';
    document.body.appendChild(script);

    const FB = await promise;

    setData(FB);
  };

  useEffect(() => {
    const script = document.createElement('script');

    setupFacebook(script);

    return () => {
      document.body.removeChild(script);

      if (window.fbAsyncInit) {
        delete window.fbAsyncInit;
      }

      if (window.FB) {
        delete window.FB;
      }
    };
  }, []);

  if (!data) {
    return <LoadingContainer />;
  }

  return (
    <FacebookContext.Provider value={data}>{children}</FacebookContext.Provider>
  );
};

export default FacebookProvider;

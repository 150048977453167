import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@ubisend/pulse-components';

const LinkScope = ({ content }) => {
  return (
    <Button variant="inline" as="a" to={content.url}>
      {content.url}
    </Button>
  );
};

LinkScope.propTypes = {
  content: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired
};

export default LinkScope;

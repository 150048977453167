import React from 'react';

import { Select, Label, FormGroup } from '@ubisend/pulse-components';
import { TemplatingTextInput } from '@ubisend/pulse-templating';

import { useComposer } from '../../../../hooks/index';

const processors = [{ value: 'global-pay', label: 'Global Pay' }];

const PaymentInput = () => {
  const {
    response: { content },
    dispatch
  } = useComposer();

  const handleProviderChange = ({ value }) => {
    dispatch({ type: 'UPDATE_CONTENT', newContent: { processor: value } });
  };

  const handleDescriptionChange = value => {
    dispatch({
      type: 'UPDATE_CONTENT',
      newContent: { ...content, content_description: value }
    });
  };

  return (
    <>
      <FormGroup>
        <Label htmlFor="content_description">Content Description</Label>
        <TemplatingTextInput
          id="content_description"
          placeholder="Pay with Global Pay"
          value={content.content_description}
          onChange={handleDescriptionChange}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="providers">Providers</Label>
        <Select
          id="providers"
          value={
            content.processor &&
            processors.find(processor => processor.value === content.processor)
          }
          options={processors}
          onChange={handleProviderChange}
        />
      </FormGroup>
    </>
  );
};

export default PaymentInput;

import React from 'react';
import PropTypes from 'prop-types';

import { Label, FormGroup, Select, TextInput } from '@ubisend/pulse-components';
import {
  LocationsConsumer,
  useLocations,
  useLanguages
} from '@ubisend/pulse-hooks';

import { useTriggerStep } from '../../hooks/index';

const LocationsSelect = ({ value, ...props }) => {
  const { locations } = useLocations();

  const format = value => ({
    value: value.id,
    label: value.name
  });

  return (
    <Select
      value={
        locations &&
        value &&
        format(locations.find(location => location.id === value))
      }
      options={locations ? locations.map(format) : []}
      isLoading={!locations}
      {...props}
    />
  );
};

LocationsSelect.propTypes = {
  value: PropTypes.number
};

const LanguagesSelect = ({ value, ...props }) => {
  const { languages } = useLanguages();

  const format = value => ({
    value: value.id,
    label: value.name
  });

  return (
    <Select
      value={
        languages &&
        value &&
        format(languages.find(language => language.id === value))
      }
      options={languages ? languages.map(format) : []}
      isLoading={!languages}
      {...props}
    />
  );
};

LanguagesSelect.propTypes = {
  value: PropTypes.number
};

const AdvancedDetails = () => {
  const { trigger, dispatch } = useTriggerStep();

  const handleMessageChange = event => {
    const message = event.target.value;

    dispatch({ type: 'UPDATE_MESSAGE', message });
  };

  const handlePostbackChange = event => {
    const postback = event.target.value;

    dispatch({ type: 'UPDATE_POSTBACK', postback });
  };

  const handleLanguageChange = location => {
    dispatch({
      type: 'UPDATE_LANGUAGE_ID',
      location: location ? location.value : null
    });
  };

  const handleLocationChange = location => {
    dispatch({
      type: 'UPDATE_LOCATION_ID',
      location: location ? location.value : null
    });
  };

  return (
    <>
      <FormGroup>
        <Label htmlFor="message">Message</Label>
        <TextInput
          id="message"
          placeholder="Message"
          value={trigger.message}
          onChange={handleMessageChange}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="postback">Postback</Label>
        <TextInput
          id="postback"
          placeholder="Postback"
          value={trigger.postback}
          onChange={handlePostbackChange}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="language">Language</Label>
        <LanguagesSelect
          id="language"
          value={trigger.language_id}
          onChange={handleLanguageChange}
        />
      </FormGroup>
      <LocationsConsumer>
        <FormGroup>
          <Label htmlFor="location">Location</Label>
          <LocationsSelect
            id="location"
            value={trigger.location_id}
            onChange={handleLocationChange}
          />
        </FormGroup>
      </LocationsConsumer>
    </>
  );
};

export default AdvancedDetails;

import { IntegrationComposer } from '../../Components/Subjects/Composers/index';

const subject = {
  type: 'endpoint',
  endpoint_id: null,
  integration_id: null
};

const integrationSubject = {
  subject,
  permission: 'view integrations',
  name: 'Use API request response',
  Composer: IntegrationComposer,
  toState: subject => {
    return {
      type: 'endpoint',
      endpoint_id: subject.id,
      integration_id: subject.integration_id
    };
  },
  toApi: subject => {
    return { id: subject.endpoint_id, type: 'endpoint' };
  }
};

export default integrationSubject;

import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  TextInput,
  Label,
  Flex,
  TabButton,
  Divider
} from '@ubisend/pulse-components';
import { AnimateSharedLayout } from '@ubisend/framer-motion';
import { LanguagesConsumer, useLanguages } from '@ubisend/pulse-hooks';

import { useNotification } from '../hooks/index';

const NotificationDetails = ({ loading, onSubmit }) => {
  const { state, body, link, form, valid, dispatch } = useNotification();
  const { languages } = useLanguages();

  const handleLanguageIdChange = language => {
    dispatch({ type: 'UPDATE_LANGUAGE_ID', languageId: language.id });
  };

  const handleBodyChange = event => {
    const body = event.target.value;

    dispatch({ type: 'UPDATE_BODY', body });
  };

  const handleLinkChange = event => {
    const link = event.target.value;

    dispatch({ type: 'UPDATE_LINK', link });
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (!valid || loading) {
      return;
    }

    onSubmit(form);
  };

  return (
    <form onSubmit={handleSubmit}>
      <LanguagesConsumer>
        <Flex>
          <AnimateSharedLayout>
            {languages.map(language => (
              <TabButton
                key={language.id}
                onClick={() => handleLanguageIdChange(language)}
                active={language.id === state.language_id}>
                {language.name}
              </TabButton>
            ))}
          </AnimateSharedLayout>
        </Flex>
        <Divider fullWidth />
      </LanguagesConsumer>
      <Flex ySpace col>
        <Flex col>
          <Label htmlFor="body">Notification</Label>
          <TextInput
            id="body"
            placeholder="Notification"
            value={body}
            onChange={handleBodyChange}
          />
        </Flex>
        <Flex col>
          <Label htmlFor="link">Link</Label>
          <TextInput
            id="link"
            placeholder="https://www.example.com"
            value={link}
            onChange={handleLinkChange}
          />
        </Flex>
        <Flex center>
          <Button
            type="submit"
            disabled={!valid}
            loading={loading}
            colour="primary">
            Save
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};

NotificationDetails.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default NotificationDetails;

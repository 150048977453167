import React from 'react';
import styled from 'styled-components';

import { motion } from '@ubisend/framer-motion';

const StyledWindow = styled(motion.div)`
  ${tw`w-screen bot:w-96 shadow-lg flex flex-col justify-between bg-grey-light rounded`};
  height: 640px;
  max-height: 100%;
  min-height: 440px;
  @media only screen and (max-width: 420px) {
    ${tw`h-full w-full`}
  }
`;

const initial = { y: 100, opacity: 0 };
const animate = { y: 0, opacity: 1 };
const exit = { y: 100, opacity: 0 };
const transition = {
  type: 'spring',
  stiffness: 400,
  damping: 60
};

const Window = ({ children, ...props }) => (
  <StyledWindow
    data-testid="converse-window"
    initial={initial}
    transition={transition}
    animate={animate}
    exit={exit}
    {...props}>
    {children}
  </StyledWindow>
);

export default Window;

import account from './Account/index';
import subscriber from './Subscriber/index';

const allScopes = [subscriber, account];

const scopes = {
  account,
  subscriber,
  default: allScopes,
  all: allScopes
};

export default scopes;

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Switch, Redirect, useRouteMatch } from 'react-router-dom';

import { Route } from '@ubisend/pulse-auth';
import { PageWrapper, Flex } from '@ubisend/pulse-components';

import { useImports } from '../hooks/index';
import { SectionNav, Actions } from '../Components/index';
import {
  Content,
  CreateSection,
  Faqs,
  Resources,
  Versions,
  Version,
  Metadata,
  Preview
} from '../SubPages/index';

const routes = [
  {
    path: '/knowledge-bases/:importId/sections/new',
    component: CreateSection
  },
  {
    path: '/knowledge-bases/:importId/sections/:sectionId/content',
    component: Content
  },
  {
    path: '/knowledge-bases/:importId/sections/:sectionId/preview',
    component: Preview
  },
  {
    path: '/knowledge-bases/:importId/sections/:sectionId/resources',
    component: Resources
  },
  {
    path: '/knowledge-bases/:importId/sections/:sectionId/metadata',
    component: Metadata
  },
  {
    path: '/knowledge-bases/:importId/sections/:sectionId/faqs',
    component: Faqs
  },
  {
    path: '/knowledge-bases/:importId/sections/:sectionId/versions',
    component: Versions
  },
  {
    path: '/knowledge-bases/:importId/sections/:sectionId/versions/:versionId',
    component: Version
  }
];

const Container = styled.div`
  ${tw`max-h-screen overflow-x-hidden w-full`}
`;

const SectionRouter = () => {
  const { item, section, setOpen } = useImports();

  const isOnCreateSectionRoute = useRouteMatch(
    '/knowledge-bases/:importId/sections/new'
  );

  useEffect(() => {
    if (item) {
      setOpen(item.id);
    }
  }, [item, setOpen]);

  const getKey = ({ name, path }) => {
    // Force a complete rerender on every content page.
    // This makes our MarkdownEditor refresh the content.
    if (path === '/knowledge-bases/:importId/sections/:sectionId/content') {
      return Math.random();
    }

    return routes.findIndex(route => route.name === name);
  };

  if (!section && !isOnCreateSectionRoute) {
    return <Redirect to="/knowledge-bases" />;
  }

  return (
    <Container>
      <PageWrapper
        header={
          isOnCreateSectionRoute
            ? 'Create a new section'
            : `Editing "${section?.content?.name}"`
        }
        actions={<Actions />}>
        <Flex col>
          {!isOnCreateSectionRoute && <SectionNav />}
          <Switch>
            {routes.map(route => (
              <Route
                exact
                public={false}
                key={getKey(route)}
                path={route.path}
                canAccess={() => true}
                render={props => <route.component {...props} />}
              />
            ))}
          </Switch>
        </Flex>
      </PageWrapper>
    </Container>
  );
};

export default SectionRouter;

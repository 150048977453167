import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useBot from './useBot';

const useMessage = () => {
  const { id } = useParams();
  const { messages } = useBot();

  const message = useMemo(() => {
    return messages.find(message => message.id === parseInt(id));
  }, [id, messages]);

  return message;
};

export default useMessage;

import React from 'react';
import PropTypes from 'prop-types';

import { Flex } from '@ubisend/pulse-components';

import Bubble from './Bubble';
import ActionButton from './ActionButton/index';

const Action = ({ content, handleButtonClick, direction, ...props }) => (
  <Flex fat right={direction === 'recievedMessage'}>
    <Bubble direction={direction} {...props}>
      <span>{content.text}</span>
      <Flex col mt ySpaceSm fat>
        {content.responses.map((response, key) => (
          <ActionButton
            key={key}
            direction={direction}
            response={response}
            handleButtonClick={handleButtonClick}
          />
        ))}
      </Flex>
    </Bubble>
  </Flex>
);

Action.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.string.isRequired,
    responses: PropTypes.array.isRequired
  }).isRequried,
  direction: Bubble.propTypes.direction,
  handleButtonClick: PropTypes.func.isRequired
};

export default Action;

import React from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  Label,
  InnerPanel,
  Button,
  Select,
  TextInput
} from '@ubisend/pulse-components';

const Rows = ({
  rows,
  handleNewRow,
  handleEditKey,
  handleEditValue,
  handleDeleteRow,
  KeyInput = Select,
  ValueInput = TextInput
}) => {
  return (
    <InnerPanel>
      {rows.map(({ key, value }, index) => (
        <Flex key={index} mb xSpace>
          <Flex col fat>
            {index === 0 && <Label>Key</Label>}
            <KeyInput
              aria-label={`row-key-${index}`}
              value={key}
              onChange={handleEditKey(index)}
            />
          </Flex>
          <Flex col fat>
            {index === 0 && <Label>Value</Label>}
            <ValueInput
              aria-label={`row-value-${index}`}
              placeholder="application/json"
              value={value}
              onChange={handleEditValue(index)}
            />
          </Flex>
          <Flex bottom>
            <Button
              variant="secondary"
              colour="danger"
              icon="trash"
              onClick={handleDeleteRow(index)}>
              Delete
            </Button>
          </Flex>
        </Flex>
      ))}
      <Button onClick={handleNewRow} disabled={rows.length > 20}>
        Add Row
      </Button>
    </InnerPanel>
  );
};

Rows.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.string, value: PropTypes.string })
  ),
  handleNewRow: PropTypes.func.isRequired,
  handleEditKey: PropTypes.func.isRequired,
  handleEditValue: PropTypes.func.isRequired,
  handleDeleteRow: PropTypes.func.isRequired,
  KeyInput: PropTypes.elementType,
  ValueInput: PropTypes.elementType
};

export default Rows;

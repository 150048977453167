import { STEP_WIDTH, STEP_HEADER_HEIGHT } from './constants';

const nodeHeaderBorder = (ctx, x, y) => {
  ctx.save();
  ctx.strokeStyle = '#dddbdf';
  ctx.beginPath();
  ctx.moveTo(x, y + STEP_HEADER_HEIGHT);
  ctx.lineTo(x + STEP_WIDTH, y + STEP_HEADER_HEIGHT);
  ctx.stroke();
  ctx.restore();
};

export default nodeHeaderBorder;

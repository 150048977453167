import React from 'react';

import { useQuery } from '@ubisend/pulse-hooks';
import {
  PageWrapper,
  OneHalfLayout,
  Flex,
  Placeholder,
  Panel
} from '@ubisend/pulse-components';

import { UpdateWorkplaceLink, CreateWorkplaceLink } from '../Components/index';

const LinkFacebookWorkplace = () => {
  const { isLoading, isSuccess, data } = useQuery('workplace/settings');

  const hasSettings = Boolean(data?.data.auth_token);

  return (
    <PageWrapper center header="Facebook Workplace">
      <Flex col center>
        <OneHalfLayout>
          {isLoading && (
            <Panel>
              <Placeholder items={1} subitems={3} center />
            </Panel>
          )}
          {isSuccess && (
            <Flex>
              {!hasSettings && <CreateWorkplaceLink />}
              {hasSettings && <UpdateWorkplaceLink />}
            </Flex>
          )}
        </OneHalfLayout>
      </Flex>
    </PageWrapper>
  );
};

export default LinkFacebookWorkplace;

const isValidMessage = (message, { min = 2, max = 1000 } = {}) => {
  const cleanMessage = message.trim().trim();

  if (cleanMessage.length < min) {
    return false;
  }

  if (cleanMessage.length >= max) {
    return false;
  }

  return cleanMessage;
};

export { isValidMessage };

import ArrayDefaultValueInput from './StringDefaultValueInput';
import StringPreview from './StringPreview';

const stringType = {
  key: 'String',
  name: 'Text',
  default_value: '',
  DefaultValueInput: ArrayDefaultValueInput,
  Preview: StringPreview
};

export default stringType;

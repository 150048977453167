import React from 'react';
import { Route as RouterRoute } from 'react-router-dom';
import PropTypes from 'prop-types';

import PrivateRoute from './PrivateRoute';

const Route = ({ public: isPublic, ...props }) => {
  if (isPublic) {
    return <RouterRoute {...props} />;
  }

  return <PrivateRoute {...props} />;
};

Route.propTypes = {
  public: PropTypes.bool
};

export default Route;

import React from 'react';
import PropTypes from 'prop-types';

import { MetricContext } from '../Contexts/index';

const MetricProvider = ({ metric, children }) => {
  return (
    <MetricContext.Provider
      value={{
        id: null,
        metric,
        start: null,
        end: null,
        page: null,
        setPage: () => {},
        isLoading: false,
        editing: false
      }}>
      {children}
    </MetricContext.Provider>
  );
};

MetricProvider.propTypes = {
  metric: PropTypes.object
};

export default MetricProvider;

const WORKPLACE_APP_ID =
  process.env.NODE_ENV === 'test'
    ? 'test_id'
    : process.env.REACT_APP_WORKPLACE_APP_ID;

const WORKPLACE_URL = `https://work.workplace.com/v3.3/dialog/work/app_install/`;

const WORKPLACE_REDIRECT_URL =
  process.env.NODE_ENV === 'test'
    ? 'https://test.com'
    : process.env.REACT_APP_WORKPLACE_REDIRECT_URL;

export { WORKPLACE_APP_ID, WORKPLACE_URL, WORKPLACE_REDIRECT_URL };

import React from 'react';

const NumberedList = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <line
      x1="8"
      y1="6"
      x2="22"
      y2="6"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="8"
      y1="12"
      x2="22"
      y2="12"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="8"
      y1="18"
      x2="22"
      y2="18"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M1.48234 6.5V4.836H4.19434V10.5H2.33034V6.5H1.48234Z"
      fill="black"
    />
    <path
      d="M0.958375 18.084C1.65704 17.556 2.20904 17.0867 2.61438 16.676C3.02504 16.2653 3.23038 15.8973 3.23038 15.572C3.23038 15.3053 3.13704 15.172 2.95038 15.172C2.84371 15.172 2.76638 15.22 2.71838 15.316C2.67571 15.4067 2.65438 15.58 2.65438 15.836H0.934375C0.950375 15.34 1.05704 14.932 1.25438 14.612C1.45704 14.292 1.71838 14.0573 2.03838 13.908C2.35838 13.7587 2.71038 13.684 3.09438 13.684C3.76638 13.684 4.25704 13.8493 4.56638 14.18C4.88104 14.5107 5.03838 14.916 5.03838 15.396C5.03838 15.9027 4.87038 16.38 4.53438 16.828C4.19838 17.276 3.77438 17.6627 3.26238 17.988H5.09438V19.42H0.958375V18.084Z"
      fill="black"
    />
  </svg>
);

export default NumberedList;

const getId = url => {
  if (url.includes('?v=')) {
    return url.split('?v=')[1];
  }

  const urlParts = url.split('/');
  return urlParts[urlParts.length - 1];
};

export { getId };

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Label from '../Forms/Label';

const Radio = props => (
  <div className={props.className}>
    <input
      type={'radio'}
      onChange={props.handleClick}
      checked={props.checked}
    />
    <Label>
      <span>{props.label}</span>
    </Label>
  </div>
);

Radio.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired
};

const StyledRadio = styled(Radio)`
  ${tw`relative`}
  & > input {
    ${tw`absolute opacity-0 pin-t pin-l m-0`}
    height: 24px;
    width: 24px;
    & + label {
      height: 24px;
      ${tw`inline-flex items-center relative cursor-pointer p-0 w-full`}
      & > span {
        ${tw`inline-block pl-3`}
        margin-top: -3px;
        height: 24px;
      }
    }

    /* Box */
    & + label:before {
      ${tw`mr-2 inline-block align-middle rounded-full border-solid border-granite border`}
      content: '';
      width: 20px;
      height: 20px;
    }

    &:checked + label:after {
      ${tw`absolute`}
      content: '⬤';
      left: 4px;
      top: 2px;
      font-size: 12px;
    }
  }
`;

export default StyledRadio;

import React from 'react';
import styled from 'styled-components';

import { motion } from '@ubisend/framer-motion';
import {
  Flex,
  Label,
  useTheme,
  NumberFormatter,
  Heading2,
  Tag,
  Span
} from '@ubisend/pulse-components';
import Icon from '@ubisend/pulse-icons';

import { useMetric } from '../../../hooks/index';

const Pulse = styled(motion.div)`
  ${tw`w-2 h-2 rounded-full`}
  background: ${props => props.theme.primary};
`;

Pulse.defaultProps = {
  transition: {
    duration: 1,
    repeat: Infinity,
    repeatType: 'loop',
    repeatDelay: 1,
    delay: 0.5
  },
  initial: { opacity: 0 },
  animate: { scale: [1, 2.5], opacity: [0.75, 0] }
};

const Dot = styled(motion.div)`
  ${tw`w-2 h-2 absolute rounded-full`}
  background: ${props => props.theme.primary};
`;

const IconContainer = styled.div`
  ${tw`absolute opacity-25`}
  right: 2.5rem;
  bottom: 1rem;
`;

const LiveValueMetric = () => {
  const { metric } = useMetric();
  const { primary } = useTheme();

  return (
    <Flex fat center relative tall pl pr>
      <Flex absolute style={{ right: '0.5rem', top: '0.5rem' }}>
        <Tag>
          <Flex xSpaceSm middle center>
            <Flex relative middle center>
              <Pulse />
              <Dot />
            </Flex>
            <Span>Live</Span>
          </Flex>
        </Tag>
      </Flex>
      <IconContainer>
        <Icon
          data-testid="metric-icon"
          type={metric.data.data.icon}
          colour={primary}
          stroke={primary}
          width="5rem"
          height="5rem"
        />
      </IconContainer>
      <Flex col ml>
        <Heading2 style={{ fontSize: '1.875rem' }}>
          <NumberFormatter>{metric.data.data.value}</NumberFormatter>
        </Heading2>
        <Label mb={false}>{metric.data.label}</Label>
      </Flex>
    </Flex>
  );
};

export default LiveValueMetric;

import { useContext } from 'react';

import { FileSourceContext } from '../Contexts/index';

const useFileSource = () => {
  const context = useContext(FileSourceContext);

  return context;
};

export default useFileSource;

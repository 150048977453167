import React from 'react';
import PropTypes from 'prop-types';

import {
  PanelSlider,
  Table,
  TableRow,
  TableHead,
  TableHeadCell,
  TableBody,
  TableCell,
  Flex,
  Explainer,
  Divider
} from '@ubisend/pulse-components';

import IntentSearcher from './IntentSearcher';

const MultiExplore = ({
  handleHide,
  multiRemap,
  handleMultpleIntentChange,
  languageId
}) => {
  return (
    <PanelSlider
      width={'50vw'}
      header="Manually Train Multiple Messages"
      handleHide={handleHide}>
      <Flex col>
        <Explainer mb>
          Here you can map all of the selected messages to a single FAQ.
        </Explainer>
        <Flex mb border>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeadCell>Message</TableHeadCell>
                <TableHeadCell>Understood As</TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {multiRemap.map((value, index) => (
                <TableRow key={index}>
                  <TableCell>{value.message.text}</TableCell>
                  <TableCell>{value.trained_intent.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Flex>
        <Divider />
        <Flex mt col>
          <IntentSearcher
            aria-label="multi-intent-searcher"
            submitText="Update All"
            languageId={languageId}
            handleSubmit={({ value, label }) => {
              handleMultpleIntentChange(
                multiRemap.map(value => value.id),
                {
                  identifier: value,
                  name: label
                }
              );
            }}
          />
        </Flex>
      </Flex>
    </PanelSlider>
  );
};

MultiExplore.propTypes = {
  handleHide: PropTypes.func.isRequired,
  multiRemap: PropTypes.object.isRequired,
  handleMultpleIntentChange: PropTypes.func.isRequired,
  languageId: PropTypes.number.isRequired
};

export default MultiExplore;

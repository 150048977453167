import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const colours = {
  warning: '255, 194, 93',
  danger: '230, 40, 81',
  note: '0,0,0'
};

const ExplainerPanel = styled.div`
  ${tw`border border-grey-medium border-solid p-4 w-full rounded-sm`}
  box-sizing: border-box;
  & > * {
    margin: 0;
    color: rgb(${props => colours[props.type]});
  }
  background-color: rgba(
    ${props => colours[props.type]},
    ${props => (props.type !== 'note' ? 0.1 : 0.033)}
  );
  border: 1px solid
    rgba(
      ${props => colours[props.type]},
      ${props => (props.type !== 'note' ? 0.5 : 0.25)}
    );
`;

const Explainer = ({ children, type = 'note', ...props }) => (
  <ExplainerPanel type={type} {...props}>
    {children}
  </ExplainerPanel>
);

Explainer.propTypes = {
  type: PropTypes.oneOf(['note', 'danger', 'warning'])
};

export default Explainer;

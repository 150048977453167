import React from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  Label,
  TextInput,
  Checkbox,
  InnerPanel
} from '@ubisend/pulse-components';

const LookupInput = ({ content, onChange }) => {
  const handleOnChange = event => {
    const value = event.target.value;

    onChange({ target: value });
  };

  const handleUseEntireSpreadsheetChange = () => {
    const newValue = !content.use_entire_spreadsheet;

    onChange({ use_entire_spreadsheet: newValue });
  };

  return (
    <InnerPanel>
      <Flex mb>
        <Checkbox
          checked={content.use_entire_spreadsheet}
          onChange={handleUseEntireSpreadsheetChange}
          label="Use entire spreadsheet"
        />
      </Flex>
      <Flex col>
        <Label htmlFor="target">Target</Label>
        <TextInput
          id="target"
          disabled={content.use_entire_spreadsheet}
          value={content.target}
          onChange={handleOnChange}
          placeholder="0.column_name"
        />
      </Flex>
    </InnerPanel>
  );
};

LookupInput.propTypes = {
  content: PropTypes.shape({
    use_entire_spreadsheet: PropTypes.bool.isRequired,
    target: PropTypes.string.isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired
};

export default LookupInput;

import { useContext } from 'react';

import { LanguagesContext } from '../Contexts/index';

const useLanguages = () => {
  const context = useContext(LanguagesContext);

  return context;
};

export default useLanguages;

import { useReducer, useMemo } from 'react';

import { useLanguages } from '@ubisend/pulse-hooks';

const TYPES = {
  // Languages
  UPDATE_LANGUAGE_ID: 'UPDATE_LANGUAGE_ID',

  // Notification
  UPDATE_BODY: 'UPDATE_BODY',
  UPDATE_LINK: 'UPDATE_LINK'
};

const reducer = (state, { type, ...params }) => {
  switch (type) {
    // Language
    case TYPES.UPDATE_LANGUAGE_ID:
      return {
        ...state,
        language_id: params.languageId
      };

    // Notification
    case TYPES.UPDATE_BODY:
      return {
        ...state,
        bodies: state.bodies.map(body => {
          if (body.language_id === state.language_id) {
            return {
              language_id: body.language_id,
              content: { body: params.body }
            };
          }

          return body;
        })
      };
    case TYPES.UPDATE_LINK:
      return {
        ...state,
        links: state.links.map(link => {
          if (link.language_id === state.language_id) {
            return {
              language_id: link.language_id,
              content: { link: params.link }
            };
          }

          return link;
        })
      };

    default:
      throw new Error(`No event defined in useNotificationReducer for ${type}`);
  }
};

const setInitialNotification = languages => {
  return {
    bodies: languages.map(language => {
      return {
        language_id: language.id,
        content: { body: '' }
      };
    }),
    links: languages.map(language => {
      return {
        language_id: language.id,
        content: { link: '' }
      };
    })
  };
};

const useNotificationReducer = initialNotification => {
  const { defaultLanguage, languages } = useLanguages();

  const [state, dispatch] = useReducer(reducer, {
    language_id: defaultLanguage.id,
    ...(initialNotification
      ? initialNotification
      : setInitialNotification(languages))
  });

  const valid = useMemo(() => {
    return state.bodies.every(body => body.content.body);
  }, [state]);

  const form = { bodies: state.bodies, links: state.links };

  const body = state.bodies.find(
    body => body.language_id === state.language_id
  );

  const link = state.links.find(link => link.language_id === state.language_id);

  return {
    state,
    form,
    body: body.content.body,
    link: link.content.link,
    dispatch,
    valid
  };
};

export default useNotificationReducer;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from '@ubisend/pulse-components';

import ForwardTicketModal from './ForwardTicketModal';

const ForwardTicketButton = ({ children, id, onSuccess }) => {
  const [forward, setForward] = useState(false);

  const handleStartForwarding = () => setForward(true);

  const handleStopForwarding = () => setForward(false);

  return (
    <>
      {forward && (
        <ForwardTicketModal
          handleClose={handleStopForwarding}
          id={id}
          onSuccess={onSuccess}
        />
      )}
      <Button
        variant="secondary"
        icon="airplane"
        onClick={handleStartForwarding}>
        {children}
      </Button>
    </>
  );
};

ForwardTicketButton.propTypes = {
  id: PropTypes.string.isRequired,
  onSuccess: PropTypes.func
};

export default ForwardTicketButton;

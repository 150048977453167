import Postback from './Postback';
import Url from './Url';
import MailTo from './MailTo';
import CallTo from './CallTo';
import File from './File';

const types = {
  postback: Postback,
  url: Url,
  mailto: MailTo,
  callto: CallTo,
  file: File
};

export default types;

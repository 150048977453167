import React from 'react';
import PropTypes from 'prop-types';

import { ButtonSelect as Select } from '@ubisend/pulse-components';

import composers from './composers';

const customComposers = composers.filter(composer => Boolean(composer.Input));

const ComposerTypeSelect = ({ value, ...props }) => {
  const format = composer => {
    return {
      label: composer.name,
      value: composer.id
    };
  };

  return (
    <Select
      options={customComposers.map(format)}
      value={
        value
          ? format(customComposers.find(composer => composer.id === value))
          : null
      }
      {...props}
    />
  );
};

ComposerTypeSelect.propTypes = {
  value: PropTypes.oneOf(composers.map(({ id }) => id))
};

export default ComposerTypeSelect;

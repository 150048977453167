import React from 'react';

import { Select } from '@ubisend/pulse-components';

import { useQrCode } from '../hooks/index';

const formats = [
  { label: 'SVG', value: 'svg' },
  { label: 'PNG', value: 'png' },
  { label: 'JPG', value: 'jpeg' },
  { label: 'WebP', value: 'webp' }
];

const FormatsSelect = props => {
  const { format, dispatch } = useQrCode();

  const handleFormatChange = option => {
    dispatch({ type: 'CHANGE_FORMAT', format: option.value });
  };

  return (
    <Select
      {...props}
      value={formats.find(option => option.value === format)}
      options={formats}
      onChange={handleFormatChange}
    />
  );
};

export default FormatsSelect;

import React from 'react';
import PropTypes from 'prop-types';

import { Select } from '@ubisend/pulse-components';

const options = [
  { label: 'Status Code', value: 'status' },
  { label: 'Response Body', value: 'body' }
];

const IntegrationSubjectSelect = ({ value, ...props }) => (
  <Select
    value={value && options.find(option => option.value === value)}
    options={options}
    {...props}
  />
);

IntegrationSubjectSelect.propTypes = {
  value: PropTypes.string
};

export default IntegrationSubjectSelect;

import React from 'react';

const Linkedin = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32pt"
    height="32pt"
    viewBox="0 0 35 32"
    version="1.1"
    {...props}>
    <g id="surface1">
      <path d="M 10.84375 31.792969 C 10.84375 31.644531 10.84375 31.5 10.84375 31.355469 C 10.84375 24.625 10.84375 17.898438 10.84375 11.167969 C 10.84375 10.980469 10.832031 10.832031 11.105469 10.832031 C 13.09375 10.84375 15.085938 10.835938 17.074219 10.835938 C 17.125 10.835938 17.175781 10.851562 17.257812 10.863281 C 17.257812 11.738281 17.257812 12.601562 17.257812 13.46875 C 17.28125 13.476562 17.300781 13.488281 17.324219 13.492188 C 17.574219 13.167969 17.8125 12.832031 18.074219 12.511719 C 18.835938 11.574219 19.792969 10.886719 20.917969 10.476562 C 21.53125 10.25 22.199219 10.113281 22.84375 10.082031 C 23.730469 10.042969 24.636719 10.054688 25.511719 10.207031 C 26.949219 10.449219 28.21875 11.085938 29.269531 12.132812 C 30.261719 13.113281 30.917969 14.292969 31.273438 15.625 C 31.460938 16.332031 31.582031 17.054688 31.695312 17.773438 C 31.769531 18.273438 31.804688 18.789062 31.804688 19.289062 C 31.8125 23.351562 31.8125 27.414062 31.8125 31.476562 C 31.8125 31.574219 31.804688 31.675781 31.800781 31.804688 C 31.679688 31.804688 31.582031 31.804688 31.480469 31.804688 C 29.5625 31.804688 27.648438 31.800781 25.730469 31.8125 C 25.414062 31.8125 25.355469 31.707031 25.355469 31.417969 C 25.363281 27.679688 25.375 23.9375 25.351562 20.199219 C 25.34375 19.542969 25.28125 18.863281 25.113281 18.230469 C 24.804688 17.125 24.105469 16.324219 22.992188 15.917969 C 22.054688 15.574219 21.105469 15.511719 20.144531 15.757812 C 19.082031 16.023438 18.351562 16.730469 17.800781 17.664062 C 17.375 18.382812 17.292969 19.175781 17.289062 19.96875 C 17.261719 23.761719 17.273438 27.550781 17.273438 31.34375 C 17.273438 31.488281 17.273438 31.632812 17.273438 31.789062 C 15.117188 31.792969 13 31.792969 10.84375 31.792969 Z M 10.84375 31.792969 " />
      <path d="M 6.820312 21.414062 C 6.820312 24.742188 6.8125 28.074219 6.824219 31.40625 C 6.824219 31.707031 6.761719 31.820312 6.429688 31.8125 C 4.523438 31.792969 2.617188 31.792969 0.710938 31.8125 C 0.398438 31.8125 0.332031 31.707031 0.332031 31.417969 C 0.335938 24.726562 0.335938 18.023438 0.332031 11.332031 C 0.332031 11.0625 0.40625 10.96875 0.679688 10.96875 C 2.605469 10.980469 4.53125 10.980469 6.460938 10.96875 C 6.761719 10.96875 6.820312 11.082031 6.820312 11.351562 C 6.820312 14.707031 6.820312 18.0625 6.820312 21.414062 Z M 6.820312 21.414062 " />
      <path d="M 3.507812 0.300781 C 5.5625 0.25 6.792969 1.742188 6.800781 3.574219 C 6.804688 5.449219 5.492188 6.851562 3.429688 6.84375 C 1.511719 6.84375 0.15625 5.46875 0.164062 3.523438 C 0.167969 1.707031 1.488281 0.238281 3.507812 0.300781 Z M 3.507812 0.300781 " />
    </g>
  </svg>
);

export default Linkedin;

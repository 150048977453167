import React from 'react';

const Kik = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32pt"
    height="31pt"
    viewBox="0 0 35 31"
    version="1.1"
    {...props}>
    <g id="surface1">
      <path d="M 2.667969 27.824219 L 2.667969 3.175781 C 2.667969 1.421875 3.953125 0 5.546875 0 C 7.140625 0 8.4375 1.421875 8.4375 3.175781 L 8.4375 17.046875 L 15.640625 9.53125 C 16.480469 8.644531 17.160156 8.109375 18.183594 8.109375 C 19.746094 8.109375 20.722656 9.484375 20.722656 10.945312 C 20.722656 12.035156 20.191406 12.867188 19.402344 13.710938 L 14.847656 18.007812 L 20.28125 26.066406 C 20.777344 26.820312 21 27.402344 21 28.121094 C 21 29.824219 19.75 31 18.308594 31 C 17.164062 31 16.484375 30.582031 15.796875 29.542969 L 10.753906 21.890625 L 8.4375 24.191406 L 8.4375 27.824219 C 8.4375 29.578125 7.140625 31 5.546875 31 C 3.953125 31 2.667969 29.578125 2.667969 27.824219 Z M 25.867188 24.070312 C 27.777344 24.070312 29.332031 22.558594 29.332031 20.703125 C 29.332031 18.832031 27.777344 17.320312 25.867188 17.320312 C 23.945312 17.320312 22.402344 18.832031 22.402344 20.703125 C 22.402344 22.558594 23.945312 24.070312 25.867188 24.070312 Z M 25.867188 24.070312 " />
    </g>
  </svg>
);

export default Kik;

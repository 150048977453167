import React from 'react';

import { PermissionFilter } from '@ubisend/pulse-auth';
import { Span } from '@ubisend/pulse-components';

import { useComposer } from '../../hooks/index';

const Subject = props => {
  const {
    subjects,
    response: { subject: currentSubject }
  } = useComposer();

  if (!currentSubject) {
    return null;
  }

  const subject = subjects.find(
    ({ subject }) => subject.type === currentSubject.type
  );

  return (
    <PermissionFilter
      can={subject.permission}
      fallback={
        <Span tinyText light>
          You do not have permission to {subject.permission}, so the remaining
          fields are hidden.
        </Span>
      }>
      <subject.Composer {...props} />
    </PermissionFilter>
  );
};

export default Subject;

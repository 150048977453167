import React from 'react';

const Proprietary = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32pt"
    height="32pt"
    viewBox="0 0 35 32"
    version="1.1"
    {...props}>
    <g id="surface1">
      <path
        style={{
          fillRule: 'nonzero',
          fill: 'rgb(100%,100%,100%)',
          fillOpacity: 1,
          strokeWidth: 2,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          stroke: 'rgb(0%,0%,0%)',
          strokeOpacity: 1,
          strokeMiterlimit: 4
        }}
        d="M 11.999307 19.000051 L 21.001263 21.001418 L 12.000085 2.998657 L 2.998584 21.001256 Z M 11.999307 19.000051 L 11.999671 11.000819 "
        transform="matrix(0.023012,1.333135,-1.333135,0.023012,31.721468,-0.273765)"
      />
    </g>
  </svg>
);

export default Proprietary;

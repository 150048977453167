import VariableBlock from './VariableBlock';
import AddVariableBlock from './AddVariableBlock';
import EditVariableBlock from './EditVariableBlock';

const variable = {
  id: 'variable',
  name: 'Variable',
  icon: 'variable',
  colour: '#57B75F',
  permission: 'variables',
  Block: VariableBlock,
  AddBlock: AddVariableBlock,
  EditBlock: EditVariableBlock
};

export default variable;

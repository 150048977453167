import {
  IntegrationConditional,
  IntegrationPreview
} from '../../Components/Subjects/Conditionals/index';

const subject = {
  type: 'endpoint',
  endpoint_id: null,
  integration_id: null,
  integration_subject: 'status'
};

const conditional = {
  subject,
  type: null,
  condition: null,
  flipped: false,
  case_sensitive: false,
  target: ''
};

const integrationSubject = {
  subject,
  conditional,
  permission: 'view integrations',
  name: 'Making an API request',
  Conditional: IntegrationConditional,
  Preview: IntegrationPreview,
  toState: ({ subject }) => {
    return {
      type: 'endpoint',
      endpoint_id: subject.id,
      integration_id: subject.integration_id,
      integration_subject: subject.target ? 'body' : 'status'
    };
  },
  toApi: ({ subject }) => {
    return { id: subject.endpoint_id, type: 'endpoint' };
  }
};

export default integrationSubject;

import React from 'react';

import { Auth as OriginalAuth } from '@ubisend/pulse-components';
import { usePulse } from '@ubisend/pulse-hooks';

const Auth = ({ children, ...props }) => {
  const { owner, versions } = usePulse();

  return (
    <OriginalAuth {...props} owner={owner} versions={versions}>
      {children}
    </OriginalAuth>
  );
};

export default Auth;

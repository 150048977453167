import React from 'react';

/**
 * Handle storing whether to show a tooltip, as well as where to render it.
 */
const TooltipContext = React.createContext({
  // Boolean: Whether to show the tooltip message.
  visible: false,
  // String [top, bottom, left, right]: Where to display
  // the message container when showing tooltip.
  position: 'bottom',
  // Object: positional styles such as top: left: etc.
  styles: {}
});

export default TooltipContext;

import React from 'react';

import DeliveryProvider from './DeliveryProvider';

const DemoDeliveryProvider = ({ children, ...rest }) => (
  <DeliveryProvider demo={true} channelDriverName={'Converse'} {...rest}>
    {children}
  </DeliveryProvider>
);

export default DemoDeliveryProvider;

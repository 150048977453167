import React, { useEffect } from 'react';

import { defaultStepTrigger, formatStepTrigger } from '@ubisend/pulse-demo';

import { BankContext } from '../Contexts/index';
import { useBankReducer } from '../reducers/index';
import { useBuilder } from '../hooks/index';

const BankProvider = ({ children }) => {
  const { conversation } = useBuilder();

  const bank = useBankReducer({
    show: localStorage.getItem('show_bank')
      ? JSON.parse(localStorage.getItem('show_bank'))
      : false,
    demo_trigger: formatStepTrigger({
      ...defaultStepTrigger,
      step_id: conversation.entry_step.id
    })
  });

  useEffect(() => {
    localStorage.setItem('show_bank', JSON.stringify(bank.bank.show));
  }, [bank.bank]);

  return <BankContext.Provider value={bank}>{children}</BankContext.Provider>;
};

export default BankProvider;

import { useContext } from 'react';

import { QrCodeContext } from '../Contexts/index';

const useQrCode = () => {
  const context = useContext(QrCodeContext);

  return context;
};

export default useQrCode;

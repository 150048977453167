import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import io from 'socket.io-client';

import { SubscriptionContext } from '../Contexts/index';

const SubscriptionProvider = ({ children, url }) => {
  const [socket, setSocket] = useState(null);
  const [connected, setConnected] = useState(false);

  /**
   * Connect to the socket server and listen for events.
   */
  useEffect(() => {
    const socket = io.connect(url, {
      // Don't try and reconnect in development.
      reconnection: process.env.NODE_ENV === 'production',
      withCredentials: false
    });

    socket.on('connect', () => {
      setConnected(true);
    });

    socket.on('disconnect', () => {
      setConnected(false);
    });

    socket.io.on('reconnection_attempt', () => {
      //
    });

    socket.io.on('reconnect', () => {
      //
    });

    setSocket(socket);
  }, [url]);

  useEffect(() => {
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [socket]);

  if (!socket) {
    return null;
  }

  const context = { socket, connected, url };

  return (
    <SubscriptionContext.Provider value={context}>
      {children}
    </SubscriptionContext.Provider>
  );
};

SubscriptionProvider.propTypes = {
  url: PropTypes.string.isRequired
};

export default SubscriptionProvider;

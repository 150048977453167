import React from 'react';
import PropTypes from 'prop-types';

import { Select } from '@ubisend/pulse-components';

import { useComposer } from '../../hooks/index';

const SubjectSelect = ({ value, ...props }) => {
  const { formattedSubjects } = useComposer();

  return (
    <Select
      options={formattedSubjects}
      value={formattedSubjects.find(subject => subject.value === value) || null}
      {...props}
    />
  );
};

SubjectSelect.propTypes = {
  value: PropTypes.string
};

export default SubjectSelect;

import { hasPermission } from '@ubisend/pulse-auth';

import { Files, FilesRedirect, FileSources } from './Pages/index';

const routes = [
  {
    path: '/files/storages',
    component: FileSources,
    name: 'File Storages',
    canAccess: hasPermission('view files'),
    exact: false,
    actions: []
  },
  {
    path: '/files',
    component: FilesRedirect,
    name: 'Files',
    exact: true,
    canAccess: hasPermission('view files'),
    actions: [
      {
        title: 'Files',
        description: 'Manage your uploaded files.',
        icon: 'link'
      }
    ]
  },
  {
    path: '/files/:sourceId/view',
    component: Files,
    name: 'Files',
    exact: false,
    canAccess: hasPermission('view files'),
    actions: [],
    disableBreadCrumbs: true
  }
];

export default routes;

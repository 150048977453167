import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { motion, AnimatePresence } from '@ubisend/framer-motion';
import {
  Flex,
  ModalContainer,
  ModalContent,
  Heading2,
  ModalPortal,
  WizardProgress,
  Paragraph,
  Button,
  Divider
} from '@ubisend/pulse-components';

import { stepOne, stepTwo, stepThree, stepFour } from './images/index';

const FooterSection = styled.div`
  ${tw`w-full flex items-center justify-center p-4`}
  &:not(:last-child) {
    ${tw` border-0 border-r border-solid border-grey`}
  }
  &:first-child {
    margin-left: -1rem;
  }
  &:last-child {
    margin-right: -1rem;
  }
`;

const steps = [
  {
    image: stepOne,
    title: 'Welcome to the new builder!',
    description: 'Create conversations faster with the new drag & drop builder.'
  },
  {
    image: stepTwo,
    title: 'Drag & drop',
    description:
      'Build and organise your conversation by clicking, dragging, and dropping any element.'
  },
  {
    image: stepThree,
    title: 'Bank',
    description:
      'Use the bank on your right to add blocks in your conversation such as new steps, variables, and metrics.'
  },
  {
    image: stepFour,
    title: 'Test',
    description:
      'Test your conversation without leaving the page. Use the demo in the top right of your screen.'
  }
];

const WalkThrough = ({ handleClose }) => {
  const [step, setStep] = useState(0);

  const handleNext = () => {
    setStep(step => step + 1);
  };

  const onLastStep = step === steps.length - 1;

  return (
    <ModalPortal>
      <ModalContainer>
        <ModalContent style={{ maxWidth: '30rem' }}>
          <Flex
            relative
            style={{
              width: '100%',
              paddingBottom: '56.25%'
            }}
            xHidden>
            <AnimatePresence exitBeforeEnter>
              <motion.img
                key={step}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  x: {
                    type: 'spring',
                    stiffness: 300,
                    damping: 30
                  }
                }}
                style={{ position: 'absolute', width: '100%', height: '100%' }}
                src={steps[step].image}
              />
            </AnimatePresence>
          </Flex>
          <Flex fat col>
            <Flex pad col center xHidden>
              <Heading2 mb>{steps[step].title}</Heading2>
              <AnimatePresence exitBeforeEnter>
                <motion.div
                  key={step}
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 50 }}
                  transition={{
                    x: {
                      type: 'spring',
                      stiffness: 300,
                      damping: 30
                    }
                  }}>
                  <Paragraph secondary mb style={{ textAlign: 'center' }}>
                    {steps[step].description}
                  </Paragraph>
                </motion.div>
              </AnimatePresence>
              <Flex pad>
                <WizardProgress steps={steps.length} current={step} />
              </Flex>
            </Flex>
            <Divider mNone />
            <Flex>
              <FooterSection>
                <Button variant="inline" colour="danger" onClick={handleClose}>
                  Close
                </Button>
              </FooterSection>
              <FooterSection>
                <Button
                  variant="inline"
                  onClick={onLastStep ? handleClose : handleNext}>
                  {onLastStep ? 'Finish' : 'Next'}
                </Button>
              </FooterSection>
            </Flex>
          </Flex>
        </ModalContent>
      </ModalContainer>
    </ModalPortal>
  );
};

WalkThrough.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default WalkThrough;

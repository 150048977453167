import { useReducer, useMemo } from 'react';

const TYPES = {
  // General Details
  UPDATE_CONVERSATION_ID: 'UPDATE_CONVERSATION_ID',
  UPDATE_STEP_ID: 'UPDATE_STEP_ID',
  UPDATE_MESSAGE: 'UPDATE_MESSAGE',
  UPDATE_POSTBACK: 'UPDATE_POSTBACK',
  UPDATE_LOCATION_ID: 'UPDATE_LOCATION_ID',
  UPDATE_LANGUAGE_ID: 'UPDATE_LANGUAGE_ID',
  // Variables
  ADD_VARIABLE: 'ADD_VARIABLE',
  UPDATE_VARIABLE: 'UPDATE_VARIABLE',
  DELETE_VARIABLE: 'DELETE_VARIABLE'
};

const defaultStepTrigger = {
  conversation_id: null,
  step_id: null,
  message: null,
  postback: null,
  location_id: null,
  language_id: null,
  variables: []
};

const formatStepTrigger = trigger => ({
  step_id: trigger.step_id,
  message: trigger.message,
  postback: trigger.postback,
  location_id: trigger.location_id,
  language_id: trigger.language_id,
  variables: trigger.variables
});

const reducer = (state, { type, ...params }) => {
  switch (type) {
    // General Details
    case TYPES.UPDATE_CONVERSATION_ID:
      return {
        ...state,
        conversation_id: params.conversationId,
        step_id: null
      };
    case TYPES.UPDATE_STEP_ID:
      return { ...state, step_id: params.stepId };
    case TYPES.UPDATE_MESSAGE:
      return { ...state, message: params.message };
    case TYPES.UPDATE_POSTBACK:
      return { ...state, postback: params.postback };
    case TYPES.UPDATE_LOCATION_ID:
      return { ...state, location_id: params.locationId };
    case TYPES.UPDATE_LANGUAGE_ID:
      return { ...state, language_id: params.languageId };

    // Variables
    case TYPES.ADD_VARIABLE:
      return {
        ...state,
        variables: state.variables.concat({
          id: null,
          value: ''
        })
      };
    case TYPES.UPDATE_VARIABLE:
      return {
        ...state,
        variables: state.variables.map((variable, key) => {
          if (params.index === key) {
            return params.variable;
          }
          return variable;
        })
      };
    case TYPES.DELETE_VARIABLE:
      return {
        ...state,
        variables: state.variables.filter((_, key) => key !== params.index)
      };
    default:
      throw new Error(`No event defined in useTriggerStepReducer for ${type}`);
  }
};

const useTriggerStepReducer = (initialParams = defaultStepTrigger) => {
  const [trigger, dispatch] = useReducer(reducer, {
    ...defaultStepTrigger,
    ...initialParams
  });

  const form = formatStepTrigger(trigger);

  const valid = useMemo(() => {
    return (
      trigger.step_id &&
      trigger.variables.every(variable =>
        Boolean(variable.id && variable.value)
      )
    );
  }, [trigger.step_id, trigger.variables]);

  return { ...trigger, trigger, form, dispatch, valid };
};

export { defaultStepTrigger, formatStepTrigger };
export default useTriggerStepReducer;

import { useContext } from 'react';

import { DeviceContext } from '../Contexts/index';

const useDevice = () => {
  const context = useContext(DeviceContext);

  return context;
};

export default useDevice;

import React from 'react';

import FileSelect from './FileSelect';
import types from './Types/index';

const ImageSelect = props => (
  <FileSelect types={[types.image, types['qr-code']]} {...props} />
);

export default ImageSelect;

import React from 'react';
import { Switch } from 'react-router-dom';

import { useQuery } from '@ubisend/pulse-hooks';
import { Flex, Placeholder } from '@ubisend/pulse-components';
import { Route } from '@ubisend/pulse-auth';

import { LiveChat, LiveChatFeedback, NoLiveChat } from './Pages/index';
import { Sidebar } from './Components/index';
import { AgentDeliveryProvider } from './Providers/index';

const routes = [
  {
    path: '/live-chat/:id',
    component: LiveChat
  },
  {
    path: '/live-chat/:id/feedback',
    component: LiveChatFeedback
  },
  {
    path: null,
    component: NoLiveChat
  }
];

const LiveChats = () => {
  const query = useQuery('tickets/ubidesk/agents/me');

  return (
    <Flex>
      {query.isLoading && (
        <Flex pad>
          <Placeholder />
        </Flex>
      )}
      {query.isSuccess && (
        <AgentDeliveryProvider>
          <Sidebar agent={query.data.data} />
          <Flex fat>
            <Switch>
              {routes.map((route, key) => (
                <Route
                  exact={route.exact ?? true}
                  public={false}
                  key={key}
                  path={route.path}
                  canAccess={() => true}
                  render={props => (
                    <route.component {...props} agent={query.data.data} />
                  )}
                />
              ))}
            </Switch>
          </Flex>
        </AgentDeliveryProvider>
      )}
    </Flex>
  );
};

export default LiveChats;

import React from 'react';
import PropTypes from 'prop-types';

import {
  Condition,
  showCondition,
  isDateTimeType
} from '@ubisend/pulse-conditionals';

import IntentCondition from './IntentCondition';
import FaqCondition from './FaqCondition';

const MessageCondition = props => {
  if (!showCondition(props.name) && !isDateTimeType(props.name)) {
    return null;
  }
  if (props.name === 'message-intent-check') {
    return <IntentCondition {...props} />;
  }

  if (props.name === 'message-faq-check') {
    return <FaqCondition {...props} />;
  }

  return <Condition {...props} />;
};

MessageCondition.propTypes = {
  name: PropTypes.string
};

export default MessageCondition;

import { hasPermission } from '@ubisend/pulse-auth';

import { InstagramLink } from './Pages/index';

const routes = [
  {
    name: 'Instagram',
    path: '/instagram-link',
    component: InstagramLink,
    canAccess: hasPermission('view instagram settings'),
    actions: [
      {
        title: 'Manage your Instagram channel',
        description: 'Connect, disconnect, or edit your Instagram integration.',
        icon: 'lightningBolt'
      }
    ]
  }
];

export default routes;

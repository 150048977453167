import axios from 'axios';

const createInitialOpen = params => {
  return axios.post(`converse/initial-open`, params);
};

const updateInitialOpen = ({ id, ...params }) => {
  return axios.put(`converse/initial-open/${id}`, params);
};

const deleteInitialOpen = id => axios.delete(`converse/initial-open/${id}`);

export { createInitialOpen, updateInitialOpen, deleteInitialOpen };

import { useContext } from 'react';

import { TypingContext } from '../Contexts/index';

const useTyping = () => {
  const context = useContext(TypingContext);

  return context;
};

export default useTyping;

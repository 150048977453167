import React from 'react';

const Telegram = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32pt"
    height="32pt"
    viewBox="0 0 29 29"
    version="1.1"
    {...props}>
    <g id="surface1">
      <path d="M 13.039062 24.867188 L 13.414062 19.226562 L 23.652344 10 C 24.105469 9.585938 23.558594 9.386719 22.960938 9.746094 L 10.320312 17.734375 L 4.851562 16 C 3.679688 15.667969 3.667969 14.851562 5.121094 14.265625 L 26.414062 6.054688 C 27.386719 5.613281 28.320312 6.292969 27.945312 7.785156 L 24.320312 24.867188 C 24.066406 26.078125 23.332031 26.375 22.320312 25.8125 L 16.800781 21.734375 L 14.148438 24.308594 C 13.839844 24.613281 13.585938 24.867188 13.039062 24.867188 Z M 13.039062 24.867188 " />
    </g>
  </svg>
);

export default Telegram;

const GENERIC_MESSAGE = 'generic_error_message';

const TOO_MANY_ATTEMPTS_MESSAGE = 'too_many_attempts_error_message';

const getErrorMessage = error => {
  if (!error?.response) {
    return GENERIC_MESSAGE;
  }

  if (error.response.status === 429) {
    return TOO_MANY_ATTEMPTS_MESSAGE;
  }

  return GENERIC_MESSAGE;
};

export default getErrorMessage;
export { GENERIC_MESSAGE, TOO_MANY_ATTEMPTS_MESSAGE };

import styled from 'styled-components';

import { motion } from '@ubisend/framer-motion';

const ConnectionLine = styled(motion.path)`
  stroke: ${props => props.theme.primary};
  stroke-width: 2px;
`;

export default ConnectionLine;

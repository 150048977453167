import React from 'react';
import PropTypes from 'prop-types';

import { PaymentContext } from '../Contexts/index';
import { useQuery } from '../hooks/index';

const PaymentProvider = ({ children, processor }) => {
  const { data, isSuccess } = useQuery(['payments', { processor }]);

  if (!isSuccess) {
    return null;
  }

  return (
    <PaymentContext.Provider value={data.data}>
      {children}
    </PaymentContext.Provider>
  );
};

PaymentProvider.propTypes = {
  processor: PropTypes.oneOf(['global-pay'])
};

export default PaymentProvider;

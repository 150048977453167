import React from 'react';

/**
 * Context to handle showing an alert modal.
 */
const ModalContext = React.createContext({
  //  Object: Alert to show to the user. {
  // 	  header: String - Title of the alert modal.
  // 	  body: String - Body message of the alert modal.
  // 	  handleConfirm: Function - Function to execute when pressing 'Confirm'.
  // }
  modal: null,
  /**
   * Handle showing an alert to the user.
   * @param Object  alert  Alert to show, takes the format shown above.
   * @return Promise
   */
  showModal: alert => alert,
  /**
   * Hide the displayed alert.
   * @return Void
   */
  hideModal: () => {}
});

export default ModalContext;

import React from 'react';

import { Paragraph, Heading1, Flex, Button } from '@ubisend/pulse-components';
import Icon from '@ubisend/pulse-icons';

import { AnimatedAuthContainer, AuthLink } from '../Components/index';

const AuthError = () => (
  <AnimatedAuthContainer>
    <Flex center middle col mb>
      <Heading1 style={{ fontSize: '2rem' }} textCenter mbSm>
        Error
      </Heading1>
      <Icon
        colour="red"
        type="exclamationCircle"
        size="3rem"
        width="3rem"
        height="3rem"
      />
    </Flex>
    <Paragraph secondary textCenter>
      Something went wrong, please contact an administrator for assistance.
    </Paragraph>
    <Flex middle>
      <Button variant="inline" as={AuthLink} to="/">
        Go to login
      </Button>
    </Flex>
  </AnimatedAuthContainer>
);

export default AuthError;

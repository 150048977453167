import useMessage from './useMessage';

const useContent = () => {
  const message = useMessage();

  return {
    title: message.content.title || 'Content',
    content: message.content.markdown
  };
};

export default useContent;

import { hasPermission } from '@ubisend/pulse-auth';

import { Imports } from './Pages/index';

const routes = [
  {
    exact: false,
    path: '/knowledge-bases',
    component: Imports,
    name: 'Knowledge Bases',
    canAccess: hasPermission('view knowledge bases'),
    actions: [
      {
        title: 'Manage your knowledge bases',
        description: 'View and update your knowledge bases.',
        icon: 'pencil'
      }
    ]
  }
];

export default routes;

import React from 'react';

import HeaderButton from './HeaderButton';
import HeaderSvg from './HeaderSvg';

const BackArrowButton = props => (
  <HeaderButton type="button" style={{ marginRight: '0.25rem' }} {...props}>
    <HeaderSvg
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M10 19l-7-7m0 0l7-7m-7 7h18"
      />
    </HeaderSvg>
  </HeaderButton>
);

export default BackArrowButton;

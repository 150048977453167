import React from 'react';
import PropTypes from 'prop-types';

import { Select } from '@ubisend/pulse-components';

const providers = [
  { value: 'youtube', label: 'YouTube' },
  { value: 'vimeo', label: 'Vimeo' }
];

const VideoProviders = ({ value, ...props }) => (
  <Select
    options={providers}
    value={value && providers.find(providers => providers.value === value)}
    {...props}
  />
);

VideoProviders.propTypes = {
  value: PropTypes.oneOf(providers.map(({ value }) => value))
};

export default VideoProviders;
export { providers };

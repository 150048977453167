import { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

const ActionsPortal = ({ children }) => {
  const [element, setElement] = useState();

  useEffect(() => {
    const newElement = document.getElementById('section-actions');

    if (!element && newElement) {
      setElement(newElement);
    }
  }, [element]);

  if (process.env.NODE_ENV === 'test') {
    return children;
  }

  if (!element) {
    return null;
  }

  return ReactDOM.createPortal(children, element);
};

export default ActionsPortal;

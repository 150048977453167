import ActionBlock from './ActionBlock';
import AddActionBlock from './AddActionBlock';
import EditActionBlock from './EditActionBlock';

const action = {
  id: 'action',
  name: 'Action',
  icon: 'adjustments',
  colour: '#F78C6B',
  Block: ActionBlock,
  AddBlock: AddActionBlock,
  EditBlock: EditActionBlock
};

export default action;

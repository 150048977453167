import React, { useRef, useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { useDrop } from 'react-dnd';

import { AnimatePresence, motion } from '@ubisend/framer-motion';

import { Node, NodeConnector } from './Nodes/index';
import { Overlay } from './Nodes/Node';
import nodeTypes from './Nodes/Types/index';
import AddStepNode from './Nodes/Types/Step/AddStepNode';
import AddTransitionNode from './Nodes/Types/Transition/AddTransitionNode';
import { useCanvas } from '../hooks/index';
import AutoZoom from './AutoZoom';

const Container = styled(motion.svg)`
  ${tw`h-screen w-full h-full absolute`}
  pointer-events: none;
`;

const CanvasContainer = styled.div`
  ${tw`relative overflow-hidden flex flex-col items-start h-screen`}
  z-index: 0;
  cursor: ${props =>
    props.linking ? `default` : props.panning ? `grabbing` : `grab`};
`;

/**
 * The DOM layer contains invisible boxes that match the size and position of
 * the nodes rendered on the canvas, so that we can still make use of our dnd
 * library and drag and drop nodes using `onMouseUp` and `onMouseMove`
 * It also renders node blocks and the action / tooltip menu for editing nodes.
 *
 * - DOM elements need to be repainted and composited individually every time
 *   their position or size has changed. This quickly becomes an issue as
 *   conversations get larger. To combat this, we now render as little as few
 *   HTML elements as possible, and hide (debounce) these elements as soon as
 *   the canvas view is panned or zoomed. This is represented in state using the
 *   `showPreview` field.
 */
const Nodes = () => {
  const ref = useRef(null);
  const [hoverStyle, setHoverStyle] = useState(null);
  const [node, setNode] = useState(null);

  const {
    handleMouseDown,
    handleMouseUp,
    handleMouseMove,
    panX,
    panY,
    panning,
    scale,
    newLinkOffset,
    newLinkDestinationOffset,
    compareNodes,
    linking,
    setLinking,
    snapPoint,
    setSnapPoint,
    nodes,
    nodeSizes,
    handleCanvasRender
  } = useCanvas();

  const [, drop] = useDrop({
    accept: 'BLOCK',
    drop: item => {
      if (['step', 'transition'].includes(item.id)) {
        setNode(item);
      }
    },
    canDrop: item => ['step', 'transition'].includes(item.id),
    collect: monitor => {
      const hovering = monitor.isOver({ shallow: true });
      if (!hovering) {
        return setHoverStyle(null);
      }
      setHoverStyle(monitor.canDrop() ? 'primary' : 'danger');
    }
  });

  const { AddNode } = useMemo(() => {
    if (!node) {
      return {};
    }

    return nodeTypes[node.id];
  }, [node]);

  const handleStartLink = node => () => {
    setLinking({
      from: compareNodes(linking.from, node) ? null : node,
      to: null
    });
  };

  const [wizardActive, setWizardActive] = useState(false);

  const handleCanvasClick = () => {
    if (!linking.from) {
      return;
    }
    setNode({
      id: 'step',
      AddNode: AddStepNode
    });
    setWizardActive(true);
  };

  const handleEndLink = node => event => {
    event.stopPropagation();
    if (linking.from && linking.to) {
      return;
    }

    if (!linking.from || compareNodes(node, linking.from)) {
      return;
    }

    setLinking(link => ({
      from: link.from,
      to: node
    }));
  };

  const handleClose = () => {
    setNode(null);
    handleCanvasRender();
  };

  const onSave = node => {
    if (wizardActive) {
      setLinking(link => ({
        from: link.from,
        to: {
          base: node,
          id: node.id
        }
      }));
    }
    handleClose();
  };

  const modalOpen = AddNode || (linking.from && linking.to);

  // Cancel the link if the modal is closed
  useEffect(() => {
    if (!modalOpen) {
      setLinking({ from: null, to: null });
    }
  }, [modalOpen, setLinking]);

  drop(ref);

  return (
    <>
      <AnimatePresence>
        {AddNode && <AddNode handleClose={handleClose} onSave={onSave} />}
        {linking.from && linking.to && (
          <AddTransitionNode
            handleClose={() => {
              handleCanvasRender();
              setLinking({ from: null, to: null });
            }}
            link={linking}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {hoverStyle && (
          <Overlay
            style={{ zIndex: 0 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            colour={hoverStyle}
          />
        )}
      </AnimatePresence>
      <CanvasContainer
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseUp}
        onClick={handleCanvasClick}
        linking={linking.from}
        panning={panning}
        ref={ref}>
        <Container>
          <motion.g
            style={{
              scale,
              x: panX,
              y: panY
            }}>
            {nodes && linking.from && !linking.to && (
              <NodeConnector snapPoint={snapPoint} />
            )}
          </motion.g>
        </Container>
        <AutoZoom />
        <motion.div
          style={{
            scale,
            originX: 'top',
            originY: 'left',
            x: panX,
            y: panY,
            position: 'absolute'
          }}>
          {nodes.map((node, key) => (
            <Node key={key} node={node} x={node.x} y={node.y} />
          ))}
          {nodes
            .filter(node => node.type === 'step')
            .map((node, key) => {
              const nodeSize = nodeSizes?.find(
                info => info.id === node.id && info.type === node.type
              );

              if (!nodeSize) {
                return;
              }

              return (
                <React.Fragment key={key}>
                  {nodeSize && (
                    <>
                      <motion.button
                        style={{
                          position: 'absolute',
                          opacity: 0,
                          cursor: 'pointer',
                          height: 50,
                          width: 50,
                          x: node.x + newLinkOffset(node) - 25,
                          y: node.y + nodeSize.height / 2 - 1 - 25
                        }}
                        onClick={handleStartLink(node)}
                      />
                      <motion.button
                        onClick={handleEndLink(node)}
                        onMouseEnter={() =>
                          setSnapPoint({
                            x: node.x + newLinkDestinationOffset(node),
                            y: node.y - nodeSize.height / 2 + 1
                          })
                        }
                        onMouseLeave={() => setSnapPoint(null)}
                        style={{
                          position: 'absolute',
                          height: 50,
                          width: 50,
                          opacity: 0,
                          cursor: 'pointer',
                          x: node.x + newLinkDestinationOffset(node) - 25,
                          y: node.y - nodeSize.height / 2 + 1 - 25
                        }}
                      />
                    </>
                  )}
                </React.Fragment>
              );
            })}
        </motion.div>
      </CanvasContainer>
    </>
  );
};

export default Nodes;

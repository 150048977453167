import React, { useState } from 'react';

import { ModalContext } from '../Contexts/index';

const ModalProvider = ({ children }) => {
  const [modal, setModal] = useState();

  const showModal = modal => setModal(modal);

  const hideModal = () => setModal(null);

  const context = {
    modal,
    showModal,
    hideModal
  };

  return (
    <ModalContext.Provider value={context}>{children}</ModalContext.Provider>
  );
};

export default ModalProvider;

import axios from 'axios';

const createTicket = params => axios.post('tickets/ubidesk', params);

const updateTicket = ({ id, ...params }) => {
  return axios.put(`tickets/ubidesk/${id}`, params);
};

const archiveTicket = id => axios.delete(`tickets/ubidesk/${id}`);

const restoreTicket = id => axios.put(`tickets/ubidesk/archived/${id}`);

const forwardTicket = ({ id, ...params }) => {
  return axios.post(`tickets/ubidesk/${id}/forward`, params);
};

const deleteTicket = id => axios.delete(`tickets/ubidesk/archived/${id}`);

export {
  createTicket,
  updateTicket,
  archiveTicket,
  restoreTicket,
  forwardTicket,
  deleteTicket
};

import React, { useState } from 'react';

import { Button } from '@ubisend/pulse-components';

import CreateTicketModal from './CreateTicketModal';

const CreateTicketButton = ({ children }) => {
  const [create, setCreate] = useState(false);

  const handleStartCreating = () => setCreate(true);

  const handleStopCreating = () => setCreate(false);

  return (
    <>
      {create && <CreateTicketModal handleClose={handleStopCreating} />}
      <Button onClick={handleStartCreating} icon="plus">
        {children}
      </Button>
    </>
  );
};

export default CreateTicketButton;

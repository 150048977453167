import ValidationNode from './ValidationNode';
import AddValidationNode from './AddValidationNode';

const blocks = ['metric', 'variable'];

const validation = {
  id: 'validation',
  name: 'Validation',
  icon: 'exclamation',
  colour: 'grey',
  permission: 'conversations',
  Node: ValidationNode,
  AddBlock: AddValidationNode,
  blocks,
  canDrop: block => {
    return blocks.includes(block.id);
  }
};

export default validation;

import React from 'react';
import PropTypes from 'prop-types';

import { Label, TextInput, Flex } from '@ubisend/pulse-components';

const BearerToken = ({ credentials, handleCredentialsChange }) => {
  const onChange = event => {
    const token = event.target.value;

    handleCredentialsChange({ token });
  };

  return (
    <Flex col>
      <Label htmlFor="token">Bearer Token</Label>
      <TextInput
        id="token"
        type="password"
        placeholder="*************"
        value={credentials.token}
        onChange={onChange}
      />
    </Flex>
  );
};

BearerToken.propTypes = {
  credentials: PropTypes.shape({
    token: PropTypes.string.isRequired
  }).isRequired,
  handleCredentialsChange: PropTypes.func.isRequired
};

export default BearerToken;

import styled from 'styled-components';

import { Panel } from '@ubisend/pulse-components';

const DetailsPanel = styled(Panel)`
  ${tw`hidden lg:block h-screen w-full max-w-sm z-10 fixed pin-r overflow-y-auto`}
  margin-top: -2.5rem;
`;

export default DetailsPanel;

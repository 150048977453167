import { hasPermission } from '@ubisend/pulse-auth';

import { DemoPage, PublicDemo } from './Pages/index';

const routes = [
  {
    name: 'Demo',
    path: '/demo',
    component: DemoPage,
    canAccess: hasPermission('view demo'),
    actions: [
      {
        title: 'Test your conversations',
        description: 'Preview and test your chatbot in a demo environment.',
        icon: 'eye'
      }
    ]
  },
  {
    name: 'Demo',
    path: '/demo/public',
    component: PublicDemo,
    public: true,
    disableBreadCrumbs: true
  }
];

export default routes;

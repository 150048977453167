import { useEffect } from 'react';

import { useTemplating } from '../../hooks/index';

const ExternalStateSync = ({ externalValue, setExternalValue }) => {
  const {
    dispatch,
    TYPES,
    value: internalValue,
    wasInternalUpdate
  } = useTemplating();

  // Update external state when internal state changes.
  useEffect(() => {
    if (wasInternalUpdate.current) {
      wasInternalUpdate.current = false;
      setExternalValue(internalValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalValue]);

  // Update internal state when external state changes.
  useEffect(() => {
    dispatch({
      type: TYPES.UPDATE_VALUE,
      value: externalValue
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalValue, dispatch]);

  return null;
};

export default ExternalStateSync;

import React from 'react';

const MenuAlt4 = props => (
  <svg
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4 8h16M4 16h16"></path>
  </svg>
);

export default MenuAlt4;

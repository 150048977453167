import React from 'react';
import { Switch } from 'react-router-dom';

import { Route, hasPermission } from '@ubisend/pulse-auth';

import { CreateFileSource, EditFileSource } from '../Pages/index';

const routes = [
  {
    name: 'Create file storage',
    path: '/files/storages/create',
    component: CreateFileSource,
    canAccess: hasPermission('create file sources')
  },
  {
    name: 'Edit file storage',
    path: '/files/storages/:id/edit',
    component: EditFileSource,
    canAccess: hasPermission('edit file sources')
  }
];

const FileSourcesRouter = () => {
  return (
    <Switch>
      {routes.map((route, key) => (
        <Route
          exact={route.exact ?? true}
          public={false}
          key={key}
          path={route.path}
          canAccess={route.canAccess}
          render={props => <route.component {...props} />}
        />
      ))}
    </Switch>
  );
};

export default FileSourcesRouter;

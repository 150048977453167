import React from 'react';
import PropTypes from 'prop-types';

import { Flex } from '@ubisend/pulse-components';

import { useConditional } from '../hooks/index';
import Conditional from './Conditional';

const Conditionals = ({ labelPrefix = 'conditionals' }) => {
  const { conditionals } = useConditional();

  return (
    <Flex col ySpace mb>
      {conditionals.map((conditional, key) => (
        <Conditional
          key={key}
          index={key}
          labelPrefix={labelPrefix}
          {...conditional}
        />
      ))}
    </Flex>
  );
};

Conditionals.propTypes = {
  labelPrefix: PropTypes.string
};

export default Conditionals;

import React from 'react';
import PropTypes from 'prop-types';

import {
  PageWrapper,
  Panel,
  Flex,
  Label,
  StretchPanel,
  Table,
  TableRow,
  TableHead,
  TableHeadCell,
  TableBody,
  TableCell,
  Sidebar,
  Button,
  Explainer,
  Tag,
  Pagination,
  Toggle,
  NoResults,
  Heading2,
  Notification,
  TextInput,
  TextArea,
  Select,
  ToggleButton,
  Divider,
  Span,
  SidebarItem as PulseSidebarItem
} from '@ubisend/pulse-components';
import Icon from '@ubisend/pulse-icons';

const SidebarItem = ({ icon, children }) => (
  <PulseSidebarItem type="client">
    <button>
      <Flex between fat>
        <Flex xSpaceSm>
          <Icon
            type={icon}
            outline
            height="1.25rem"
            width="1.25rem"
            size="1.25rem"
          />
          <Span>{children}</Span>
        </Flex>
      </Flex>
    </button>
  </PulseSidebarItem>
);

SidebarItem.propTypes = {
  icon: PropTypes.string.isRequired
};

const WhiteLabelPreview = () => {
  const noop = () => true;

  return (
    <Flex sticky border style={{ height: 'calc(100vh - 200px)', top: 0 }}>
      <Flex fat tall mb>
        <Sidebar type="client">
          <Flex middle mb>
            <img
              alt={'whitelabel placeholder logo'}
              src="https://via.placeholder.com/500"
              style={{ width: '75%', height: '100%' }}
            />
          </Flex>
          <SidebarItem icon={'users'}>Users</SidebarItem>
          <SidebarItem icon={'lightningBolt'}>Training</SidebarItem>
          <SidebarItem icon={'chat'}>Conversations</SidebarItem>
          <SidebarItem icon={'cog'}>Settings</SidebarItem>
        </Sidebar>
        <Flex tall yScroll fat>
          <PageWrapper
            header="Example Page"
            subheader="Example page description">
            <Flex col>
              <Flex mb>
                <Heading2>Buttons</Heading2>
              </Flex>
              <Panel>
                <Flex xSpace>
                  <Button icon="save">Save</Button>
                  <Button variant="secondary" icon="pencilAlt">
                    Edit
                  </Button>
                  <Button variant="secondary" colour="danger" icon="trash">
                    Delete
                  </Button>
                </Flex>
              </Panel>
            </Flex>
            <Divider tall />
            <Flex col>
              <Flex mb>
                <Heading2>Toggles</Heading2>
              </Flex>
              <Panel>
                <Flex xSpace center>
                  <Toggle on handleToggleChange={noop} />
                  <Toggle on={false} handleToggleChange={noop} />
                  <ToggleButton on onChange={noop}>
                    Toggled On
                  </ToggleButton>
                  <ToggleButton on={false} onChange={noop}>
                    Toggled Off
                  </ToggleButton>
                </Flex>
              </Panel>
            </Flex>
            <Divider tall />
            <Flex col>
              <Flex mb>
                <Heading2>Tags</Heading2>
              </Flex>
              <Panel>
                <Flex xSpace>
                  <Tag colour="positive" background="positive">
                    Positive
                  </Tag>
                  <Tag colour="warning" background="warning">
                    Warning
                  </Tag>
                  <Tag colour="danger" background="danger">
                    Danger
                  </Tag>
                </Flex>
              </Panel>
            </Flex>
            <Divider tall />
            <Flex col>
              <Flex mb>
                <Heading2>Inputs</Heading2>
              </Flex>
              <Flex>
                <Panel>
                  <Flex ySpace col>
                    <Flex col>
                      <Label>Text Input</Label>
                      <TextInput placeholder="Type here..." />
                    </Flex>
                    <Flex col>
                      <Label>Text Area</Label>
                      <TextArea placeholder="Type here..." />
                    </Flex>
                    <Flex col>
                      <Label>Select</Label>
                      <Select
                        options={[
                          { label: 'Option 1', value: '1' },
                          { label: 'Option 2', value: '2' },
                          { label: 'Option 3', value: '3' }
                        ]}
                      />
                    </Flex>
                  </Flex>
                </Panel>
              </Flex>
              <Divider tall />
              <Flex mb>
                <Heading2>Explainers</Heading2>
              </Flex>
              <Flex col ySpace>
                <Explainer>This is information</Explainer>
                <Explainer type="warning">This is warning</Explainer>
                <Explainer type="danger">This is danger</Explainer>
              </Flex>
              <Divider tall />
              <Flex mb>
                <Heading2>Notifications</Heading2>
              </Flex>
              <Flex>
                <Flex col ySpace>
                  <Notification
                    type="information"
                    title="Information"
                    subtitle="Something neutral has happened"
                    handleClose={noop}
                  />
                  <Notification
                    type="success"
                    title="Success"
                    subtitle="Something good has happened"
                    handleClose={noop}
                  />
                  <Notification
                    type="error"
                    title="Error"
                    subtitle="Something bad has happened"
                    handleClose={noop}
                  />
                </Flex>
              </Flex>
              <Divider tall />
              <Flex mb>
                <Heading2>Tables</Heading2>
              </Flex>
              <Flex>
                <StretchPanel>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableHeadCell>One</TableHeadCell>
                        <TableHeadCell>Two</TableHeadCell>
                        <TableHeadCell>Three</TableHeadCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>One</TableCell>
                        <TableCell>Two</TableCell>
                        <TableCell>Three</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>One</TableCell>
                        <TableCell>Two</TableCell>
                        <TableCell>Three</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>One</TableCell>
                        <TableCell>Two</TableCell>
                        <TableCell>Three</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <Pagination
                    pagination={{
                      current_page: 1,
                      last_page: 20,
                      from: 1,
                      to: 15
                    }}
                    perPageOptions={[15, 30, 50, 75, 100]}
                    handlePageChange={noop}
                    handlePerPageChange={noop}
                  />
                </StretchPanel>
              </Flex>
              <Divider tall />
              <Flex col>
                <Flex mb>
                  <Heading2>No Results</Heading2>
                </Flex>
                <StretchPanel>
                  <NoResults />
                </StretchPanel>
              </Flex>
            </Flex>
          </PageWrapper>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default WhiteLabelPreview;

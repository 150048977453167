import styled from 'styled-components';

import { hexToRgba } from '@ubisend/pulse-component-hooks';

const Tag = styled.span`
  ${tw`px-2 py-1 rounded text-xs leading-tight uppercase font-poppins whitespace-no-wrap`}
  color: ${({ theme, colour = 'primary' }) => theme[colour]};
  background-color: ${({ theme, background = 'primary' }) => {
    return hexToRgba(theme[background], 0.15);
  }};
`;

export default Tag;

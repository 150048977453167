import React from 'react';

import { AutoTextArea } from '@ubisend/pulse-components';

import { useQrCode } from '../hooks/index';

const DataInput = props => {
  const { data, dispatch } = useQrCode();

  const handleDataChange = event => {
    const data = event.target.value;

    dispatch({ type: 'CHANGE_DATA', data });
  };

  return (
    <AutoTextArea
      {...props}
      value={data}
      onChange={handleDataChange}
      placeholder="https://my-test.com"
    />
  );
};

export default DataInput;

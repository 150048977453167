import { EventPreview, EventConditional } from '../Components/index';

const subject = { id: null, type: 'message' };

const conditional = {
  subject,
  type: null,
  condition: null,
  flipped: false,
  case_sensitive: false,
  target: ''
};

const eventSubject = {
  subject,
  conditional,
  name: null,
  Conditional: EventConditional,
  Preview: EventPreview,
  toState: () => subject,
  toApi: () => subject
};

export default eventSubject;

import { useContext } from 'react';

import { ModalContext } from '../Contexts/index';

const useModal = () => {
  const context = useContext(ModalContext);

  return context;
};

export default useModal;

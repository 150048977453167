import axios from 'axios';

const createStepTransition = ({ stepId, transition, response = {} }) => {
  return axios.post(`conversations/builder/steps/${stepId}/transitions`, {
    ...transition,
    ...response
  });
};

const updateStepTransition = ({ stepId, transitionId, transition }) => {
  return axios.put(
    `conversations/builder/steps/${stepId}/transitions/${transitionId}`,
    transition
  );
};

const updateStepTransitionPosition = ({ stepId, transitionId, position }) => {
  return axios.put(
    `conversations/builder/steps/${stepId}/transitions/${transitionId}/position`,
    position
  );
};

const deleteStepTransition = ({ stepId, transitionId }) => {
  return axios.delete(
    `conversations/builder/steps/${stepId}/transitions/${transitionId}`
  );
};

const updateTransitionResponses = ({ stepId, transitionId, responses }) => {
  return axios.put(
    `conversations/builder/steps/${stepId}/transitions/${transitionId}/responses`,
    responses
  );
};

export {
  createStepTransition,
  updateStepTransition,
  updateStepTransitionPosition,
  deleteStepTransition,
  updateTransitionResponses
};

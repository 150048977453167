import React from 'react';

import { Flex, Span } from '@ubisend/pulse-components';

import MatchSelect from './MatchSelect';

const Match = () => (
  <Flex center xSpace>
    <Span style={{ minWidth: '4rem', textAlign: 'right' }}>When</Span>
    <MatchSelect />
    <Span>of the following conditions are met:</Span>
  </Flex>
);

export default Match;

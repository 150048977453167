import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Flex, Span } from '@ubisend/pulse-components';

import TypingIndicator from './TypingIndicator';
import { useTyping } from '../../../hooks/index';

const Typing = ({ scrollToBottom }) => {
  const { typing: typingObject } = useTyping();

  const { typing, message } = typingObject;

  useEffect(() => {
    if (typing) {
      scrollToBottom();
    }
  }, [typing, scrollToBottom]);

  if (!typing) {
    return null;
  }

  return (
    <Flex col ySpaceSm>
      <TypingIndicator />
      {message && <Span tinyText>{message}</Span>}
    </Flex>
  );
};

Typing.propTypes = {
  scrollToBottom: PropTypes.func.isRequired
};

export default Typing;

import React from 'react';

import { Converse, DeliveryContext } from '@ubisend/pulse-volt';

const ErrorWidget = () => {
  const sendMessage = () => {
    //
  };

  return (
    <DeliveryContext.Provider value={{ sendMessage }}>
      <Converse start="/window/body/messages" />
    </DeliveryContext.Provider>
  );
};

export default ErrorWidget;

import React from 'react';

import {
  Converse,
  BotContext,
  DeactivatedQueryProvider
} from '@ubisend/pulse-volt';

const AppWidget = props => (
  <DeactivatedQueryProvider>
    <BotContext.Provider
      value={{
        showClose: false,
        open: true,
        showName: true,
        showLogo: false,
        ...props
      }}>
      <Converse start="/window/body/messages" />
    </BotContext.Provider>
  </DeactivatedQueryProvider>
);

export default AppWidget;

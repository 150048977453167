import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  Panel,
  FormGroup,
  TextInput,
  Label,
  WysiwgEditor
} from '@ubisend/pulse-components';

import EditorActions from '../../EditorActions';

const WebsiteImportEditor = props => {
  const [name, setName] = useState(props.content ? props.content.name : '');
  const [content, setContent] = useState(
    props.content ? props.content.content : ''
  );

  const handleNameChange = event => {
    setName(event.target.value);
  };

  const handleContentChange = useCallback(content => {
    setContent(content);
  }, []);

  return (
    <Panel>
      <FormGroup>
        <Label htmlFor="name">Section name</Label>
        <TextInput id="name" value={name} onChange={handleNameChange} />
      </FormGroup>
      <FormGroup>
        <WysiwgEditor
          value={content}
          onChange={handleContentChange}
          aria-label="Section content"
        />
      </FormGroup>
      <EditorActions
        content={{ name, content }}
        valid={Boolean(name && content)}
      />
    </Panel>
  );
};

WebsiteImportEditor.propTypes = {
  content: PropTypes.shape({
    name: PropTypes.string,
    content: PropTypes.string
  }).isRequired
};
export default WebsiteImportEditor;

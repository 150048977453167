import { STEP_WIDTH, BLOCK_CONTAINER_HEIGHT } from './constants';

const nodeFooterBorder = (ctx, x, y, height) => {
  ctx.save();
  ctx.beginPath();
  ctx.strokeStyle = '#dddbdf';
  ctx.moveTo(x, y + height - BLOCK_CONTAINER_HEIGHT);
  ctx.lineTo(x + STEP_WIDTH, y + height - BLOCK_CONTAINER_HEIGHT);
  ctx.stroke();
  ctx.restore();
};

export default nodeFooterBorder;

import React from 'react';

import { useMutation, useQuery, useQueryClient } from '@ubisend/pulse-hooks';
import {
  OneHalfLayout,
  PageWrapper,
  Panel,
  Placeholder,
  useNotification
} from '@ubisend/pulse-components';

import { updateLink } from '../api/index';
import { LinkDetails } from '../Components/index';

const EditLink = ({
  match: {
    params: { linkId }
  }
}) => {
  const { showSuccess } = useNotification();

  const queryClient = useQueryClient();
  const query = useQuery(`links/${linkId}`);
  const mutation = useMutation(updateLink, {
    onSuccess: () => {
      showSuccess(`Successfully updated tracked link.`);
      queryClient.invalidateQueries('links');
    }
  });

  const handleSubmit = form => {
    mutation.mutate({
      id: linkId,
      qr_code_id: query.data.data.qr_code ? query.data.data.qr_code.id : null,
      ...form
    });
  };

  return (
    <PageWrapper header="Links" subheader="Edit a trackable link">
      <OneHalfLayout>
        <Panel>
          {query.isLoading && <Placeholder items={1} subitems={3} />}
          {query.isSuccess && (
            <LinkDetails
              handleSubmit={handleSubmit}
              loading={mutation.isLoading}
              initialName={query.data.data.name || ''}
              initialUrl={query.data.data.url || ''}
              initialExpiry={
                query.data.data.expires_at
                  ? new Date(query.data.data.expires_at)
                  : null
              }
              initialGroups={query.data.data.groups.map(group => group.id)}
              initialNotes={query.data.data.notes}
              initialExpiredLink={query.data.data.expiry_link}
            />
          )}
        </Panel>
      </OneHalfLayout>
    </PageWrapper>
  );
};

export default EditLink;

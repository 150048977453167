import axios from 'axios';

const createNotification = params => {
  return axios.post('template-notifications', params);
};

const updateNotification = ({ id, ...params }) => {
  return axios.put(`template-notifications/${id}`, params);
};

const deleteNotification = id => axios.delete(`template-notifications/${id}`);

export { createNotification, deleteNotification, updateNotification };

import action from './Action/index';
import email from './Email/index';
import integration from './Integration/index';
import metric from './Metric/index';
import variable from './Variable/index';

const types = {
  action,
  email,
  integration,
  metric,
  variable
};

export default types;

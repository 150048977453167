import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import solidIcons from './icons/solid/index';
import outlineIcons from './icons/outline/index';

const DynamicIcon = ({ type, outline = false, ...rest }) => {
  const Component = outline ? outlineIcons[type] : solidIcons[type];

  if (typeof Component === 'undefined') {
    return null;
  }

  return <Component {...rest} />;
};

const Element = styled.i`
  width: ${props => props.size};
  height: ${props => props.size};
  -webkit-font-smoothing: antialiased;
  transform: translate3d(0, 0, 0);
  transform: rotate(${props => props.rotation || 0}deg) !important;
  & svg,
  & > svg {
    -webkit-font-smoothing: antialiased;
    transform: translate3d(0, 0, 0);
    ${tw`h-26 w-8`}
    height: ${props => props.height};
    width: ${props => props.width};
    & > path {
      ${tw`h-6 w-8`}
      fill: ${props => props.colour};
      stroke: ${props => props.stroke};
    }
  }
`;

const Icon = ({ size, colour, height, width, stroke, rotation, ...props }) => (
  <Element
    size={size}
    colour={colour}
    height={height}
    width={width}
    stroke={stroke}
    rotation={rotation}
    data-testid={`icon-${props.type}`}>
    <DynamicIcon {...props} />
  </Element>
);

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  outline: PropTypes.bool,
  size: PropTypes.string,
  colour: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  stroke: PropTypes.string,
  rotation: PropTypes.number
};

DynamicIcon.propTypes = {
  type: PropTypes.string.isRequired,
  outline: PropTypes.bool
};

export default Icon;

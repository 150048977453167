import React from 'react';
import PropTypes from 'prop-types';

import { Flex, InputButton } from '@ubisend/pulse-components';
import Icon from '@ubisend/pulse-icons';

import { useTemplatingReducer } from '../../../reducers/index';
import { TemplatingContext } from '../../../Contexts/index';
import TextArea from './TextArea';
import VariableModal, {
  filters as allFilters
} from '../../VariableModal/index';
import ExternalStateSync from '../ExternalStateSync';
import InputFocus from '../InputFocus';

const TemplatingTextArea = ({
  value: externalValue,
  onChange: setExternalValue,
  filters = allFilters.default,
  rows = 5,
  ...props
}) => {
  const templating = useTemplatingReducer({
    value: externalValue
  });

  const handleShowVariables = () => {
    templating.dispatch({ type: templating.TYPES.SHOW_VARIABLES });
  };

  return (
    <TemplatingContext.Provider value={templating}>
      <ExternalStateSync
        externalValue={externalValue}
        setExternalValue={setExternalValue}
      />
      <InputFocus />
      {templating.showVariables && <VariableModal filters={filters} />}
      <Flex relative fat>
        <TextArea rows={rows} {...props} />
        <InputButton
          style={{
            bottom: '0',
            height: 'auto',
            paddingBottom: 'calc(0.75rem - 1px)',
            paddingTop: 'calc(0.75rem - 1px)'
          }}
          aria-label="Add variable"
          title="Add variable"
          onClick={handleShowVariables}>
          <Icon
            size="1.25rem"
            width="1.25rem"
            height="1.25rem"
            type="variable"
          />
        </InputButton>
      </Flex>
    </TemplatingContext.Provider>
  );
};

TemplatingTextArea.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.number
};

export default TemplatingTextArea;

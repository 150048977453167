import React, { useState, useEffect } from 'react';

import {
  PageWrapper,
  LoadingContainer,
  useNotification
} from '@ubisend/pulse-components';
import { Drivers } from '@ubisend/pulse-hooks';
import { useAuth } from '@ubisend/pulse-auth';

import { getAllDrivers, getDriver, updateDriver } from '../api/index';

const LiveChatDrivers = () => {
  const [drivers, setDrivers] = useState();
  const [driver, setDriver] = useState();

  const { hasPermission } = useAuth();
  const { showSuccess } = useNotification();

  const fetchDriver = async () => {
    const { data } = await getDriver();
    setDriver(data.data.id);
  };

  const fetchDrivers = async () => {
    const { data } = await getAllDrivers();
    setDrivers(
      data.data.map(driver => ({
        ...driver,
        logo: 'ticket'
      }))
    );
  };

  useEffect(() => {
    fetchDriver();
  }, []);

  useEffect(() => {
    fetchDrivers();
  }, []);

  const handleDriver = async driver => {
    await updateDriver(driver.id);

    showSuccess(`Successfully made ${driver.name} your Live Chat Provider.`);
    setDriver(driver.id);
  };

  const loading = !driver || !drivers;

  return (
    <PageWrapper
      header="Live Chat"
      subheader="Configure your chosen Live Chat Provider">
      {loading && <LoadingContainer />}
      {!loading && (
        <Drivers
          name="Live Chat"
          type="single"
          required
          value={driver}
          drivers={drivers}
          handleClick={handleDriver}
          canEditDriver={() => false}
          canEnableDriver={() => hasPermission('edit live chat providers')}
          canDisableDriver={() => hasPermission('edit live chat providers')}
        />
      )}
    </PageWrapper>
  );
};

export default LiveChatDrivers;

import React from 'react';
import PropTypes from 'prop-types';

import { Select } from '@ubisend/pulse-components';

const options = ['get', 'put', 'post', 'delete'].map(option => ({
  value: option,
  label: option.toUpperCase()
}));

const MethodSelect = ({ value, ...props }) => (
  <Select
    aria-label="method-select"
    closeMenuOnSelect
    value={options.find(option => option.value === value)}
    options={options}
    {...props}
  />
);

MethodSelect.propTypes = {
  value: PropTypes.string
};

export default MethodSelect;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { useQuery } from '@ubisend/pulse-hooks';
import { AnimatePresence } from '@ubisend/framer-motion';
import {
  RightHalfLayout,
  LeftHalfLayout,
  PanelSlider,
  Table,
  TableRow,
  TableHead,
  TableHeadCell,
  TableBody,
  TableCell,
  Flex,
  Divider,
  FormHeading,
  Span,
  TableActions,
  Paragraph,
  Button
} from '@ubisend/pulse-components';
import { AppWidget } from '@ubisend/pulse-bot';

const SemanticExplore = ({ handleHide, message }) => {
  const { data, isSuccess } = useQuery(`messages/${message.id}/context`);

  return (
    <AnimatePresence>
      <PanelSlider header="Explore semantic search" handleHide={handleHide}>
        <Flex>
          <RightHalfLayout>
            <FormHeading>Matched section</FormHeading>
            <Flex border>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeadCell>Section</TableHeadCell>
                    <TableHeadCell>Confidence</TableHeadCell>
                    <TableHeadCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Flex col>{message.trainedSection.content.name}</Flex>
                    </TableCell>
                    <TableCell>
                      {Math.round(message.trainedSection.confidence * 100)}%
                    </TableCell>
                    <TableActions>
                      <Button
                        icon="eye"
                        as={Link}
                        to={`/knowledge-bases/${message.trainedSection.import_id}/sections/${message.trainedSection.id}/content`}>
                        View
                      </Button>
                    </TableActions>
                  </TableRow>
                </TableBody>
              </Table>
            </Flex>
            <Divider />
            <FormHeading>Other returned sections</FormHeading>
            {message.otherSections.length > 0 ? (
              <Flex border>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableHeadCell>Section</TableHeadCell>
                      <TableHeadCell>Confidence</TableHeadCell>
                      <TableHeadCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {message.otherSections
                      .sort((a, b) => b.confidence - a.confidence)
                      .map(section => (
                        <TableRow key={section.id}>
                          <TableCell>
                            <Flex col>
                              <Paragraph pbSm>{section.content.name}</Paragraph>
                              <Paragraph secondary>
                                {section.content.content}
                              </Paragraph>
                            </Flex>
                          </TableCell>
                          <TableCell>
                            {Math.round(section.confidence * 100)}%
                          </TableCell>
                          <TableActions>
                            <Button
                              icon="eye"
                              as={Link}
                              to={`/knowledge-bases/${section.import_id}/sections/${section.id}/content`}>
                              View
                            </Button>
                          </TableActions>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Flex>
            ) : (
              <Span light>No additional sections were returned</Span>
            )}
          </RightHalfLayout>
          <LeftHalfLayout>
            {isSuccess && (
              <Flex middle>
                <AppWidget
                  name={'Training'}
                  messages={data.data.map(
                    ({ id, message: content, direction, type }) => ({
                      composer: { type: 'hidden' },
                      content,
                      direction:
                        direction === 'incoming' ? 'toServer' : 'toClient',
                      type,
                      highlight: id === message.id
                    })
                  )}></AppWidget>
              </Flex>
            )}
          </LeftHalfLayout>
        </Flex>
      </PanelSlider>
    </AnimatePresence>
  );
};

SemanticExplore.propTypes = {
  handleHide: PropTypes.func.isRequired,
  message: PropTypes.object.isRequired
};

export default SemanticExplore;

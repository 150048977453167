import React from 'react';

import {
  Button,
  ModalPortal,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Explainer,
  Flex,
  Label,
  FormGroup
} from '@ubisend/pulse-components';

import SubjectSelect from './SubjectSelect';
import Subject from './Subject';
import { useComposer } from '../../hooks/index';

const ComposerSettings = () => {
  const {
    response: { subject },
    subjects,
    dispatch,
    getSubject,
    showSettings
  } = useComposer();

  if (subjects?.length === 0) {
    return null;
  }

  const handleSettingsOpen = () => dispatch({ type: 'OPEN_SETTINGS' });

  const handleSettingsSave = () => dispatch({ type: 'SAVE_SETTINGS' });

  const handleSettingsClose = () => dispatch({ type: 'CLOSE_SETTINGS' });

  const handleSubjectChange = ({ value }) => {
    const subject = getSubject(value);

    dispatch({ type: 'CHANGE_SUBJECT', newSubject: subject.subject });
  };

  const handleSubjectClear = () => {
    dispatch({ type: 'CHANGE_SUBJECT', newSubject: null });
  };

  return (
    <>
      <Button variant="secondary" onClick={handleSettingsOpen}>
        Advanced settings
      </Button>
      {showSettings && (
        <ModalPortal>
          <ModalContainer>
            <ModalContent>
              <ModalHeader>Advanced Settings</ModalHeader>
              <ModalBody>
                <Explainer mb>
                  Values from external sources can be used in the content of
                  your responses. Choose the data type and then reference the
                  data type by typing {'{{'}.
                </Explainer>
                <FormGroup>
                  <Label htmlFor="subjects">Data Type</Label>
                  <SubjectSelect
                    id="subjects"
                    value={subject ? subject.type : null}
                    onChange={handleSubjectChange}
                  />
                </FormGroup>
                <Subject />
              </ModalBody>
              <ModalFooter>
                <Flex xSpace>
                  <Button variant="secondary" onClick={handleSubjectClear}>
                    Clear settings
                  </Button>
                  <Button variant="secondary" onClick={handleSettingsClose}>
                    Close
                  </Button>
                  <Button onClick={handleSettingsSave} icon="save">
                    Save
                  </Button>
                </Flex>
              </ModalFooter>
            </ModalContent>
          </ModalContainer>
        </ModalPortal>
      )}
    </>
  );
};

export default ComposerSettings;

import { hasPermission } from '@ubisend/pulse-auth';

import { Fallback } from './Pages/index';

const routes = [
  {
    name: 'Human Fallback',
    path: '/fallback',
    component: Fallback,
    canAccess: hasPermission('view human fallback'),
    actions: [
      {
        title: 'Enable / disable human fallback',
        description:
          'Teach your chatbot to step aside and let humans take over.',
        icon: 'lightningBolt'
      }
    ]
  }
];

export default routes;

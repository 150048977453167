import { hasPermission } from '@ubisend/pulse-auth';

import { Compliance } from './Pages/index';

const routes = [
  {
    name: 'Compliance',
    path: '/compliance',
    component: Compliance,
    canAccess: hasPermission('view compliance'),
    actions: [
      {
        title: 'Review data compliance',
        description: "Configure your chatbot's data retention period.",
        icon: 'badgeCheck'
      }
    ]
  }
];

export default routes;

import React from 'react';

import {
  PageWrapper,
  Panel,
  Toggle,
  Placeholder,
  useNotification,
  Flex,
  LeftHalfLayout,
  RightHalfLayout,
  Explainer
} from '@ubisend/pulse-components';
import { AppWidget } from '@ubisend/pulse-bot';
import { useQuery, useMutation, useQueryClient } from '@ubisend/pulse-hooks';

import { updateSettings } from '../api/index';

const messages = [
  {
    content: {
      text: 'When are you open?'
    },
    direction: 'toServer',
    type: 'standard'
  },
  {
    content: {
      text: 'Our opening hours are 9pm to 5pm on weekdays.'
    },
    direction: 'toClient',
    type: 'standard'
  },
  {
    content: {
      text: 'Was this helpful?',
      responses: [
        {
          label: 'Yes',
          postback: 'Yes'
        },
        {
          label: 'No',
          postback: 'No'
        }
      ]
    },
    direction: 'toClient',
    type: 'quick-reply'
  },
  {
    content: {
      text: 'Yes'
    },
    direction: 'toServer',
    type: 'standard'
  },
  {
    content: {
      text:
        'Great, glad I could help. Feel free to ask me any additional questions.'
    },
    direction: 'toClient',
    type: 'standard',
    composer: { type: 'hidden' }
  }
];

const FollowUp = () => {
  const { showSuccess } = useNotification();

  const queryClient = useQueryClient();
  const { data } = useQuery('faqs/settings');
  const { mutate: handleToggleChange } = useMutation(updateSettings, {
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries('faqs/settings');
      showSuccess(
        `FAQ follow up successfully ${
          data.data.has_follow_ups_enabled ? 'enabled' : 'disabled'
        }.`
      );
    }
  });

  return (
    <PageWrapper
      header="Faq Follow Up"
      subheader="Ask users to rate the helpfulness of your chatbot">
      {!data && <Placeholder ySpace />}
      {data && (
        <Flex>
          <LeftHalfLayout>
            <Panel
              header="Follow Up"
              actions={
                <Toggle
                  on={data.data.has_follow_ups_enabled}
                  handleToggleChange={() => {
                    handleToggleChange(!data.data.has_follow_ups_enabled);
                  }}
                />
              }>
              <Explainer mt mb>
                When FAQ follow up is enabled, subscribers are asked to rate the
                helpfulness of the FAQ answer they receive.
              </Explainer>
            </Panel>
          </LeftHalfLayout>
          <RightHalfLayout>
            <Flex middle>
              <AppWidget name={'Follow Up Example'} messages={messages} />
            </Flex>
          </RightHalfLayout>
        </Flex>
      )}
    </PageWrapper>
  );
};

export default FollowUp;

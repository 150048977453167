import React from 'react';
import PropTypes from 'prop-types';

import { Flex } from '@ubisend/pulse-components';

import Bubble from './Bubble';

const Standard = ({ content, direction, ...props }) => (
  <Flex fat right={direction === 'recievedMessage'}>
    <Bubble direction={direction} {...props}>
      {content.text}
    </Bubble>
  </Flex>
);

Standard.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.string.isRequired
  }),
  direction: Bubble.propTypes.direction
};

export default Standard;

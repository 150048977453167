import { useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

import { useBot, useAuth } from '../../../hooks/index';

const ResetOnClose = () => {
  const location = useLocation();
  const { isExact: isOnLauncherRoute } = useRouteMatch('/');

  const { resetOnClose } = useBot();
  const { query } = useAuth();

  const hasClosedWidget = Boolean(location.state?.closed);

  const refetch = query.refetch;

  useEffect(() => {
    if (!resetOnClose) {
      return;
    }

    if (!isOnLauncherRoute) {
      return;
    }

    if (!hasClosedWidget) {
      return;
    }

    refetch();
  }, [resetOnClose, isOnLauncherRoute, hasClosedWidget, refetch]);

  return null;
};

export default ResetOnClose;

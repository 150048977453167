const isUnlinked = node => {
  if (node.type !== 'step') {
    return false;
  }

  return node.numberOfParents === 0 && node.numberOfAddons === 0;
};

const isSensitive = node => {
  if (node.type !== 'step') {
    return false;
  }

  return node.base.sensitive;
};

export { isUnlinked, isSensitive };

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ButtonSelect } from '@ubisend/pulse-components';

import { useMutation, useQuery, useLanguage, useAuth } from '../../hooks/index';
import { changeLanguage } from '../../api/index';

const LanguageSelect = ({ value, isLoading = false, ...props }) => {
  const query = useQuery('languages');

  const formatLanguage = language => {
    return {
      value: language.id,
      label: language.name,
      language
    };
  };

  return (
    <ButtonSelect
      {...props}
      isLoading={query.isLoading || isLoading}
      value={
        query.isSuccess && value
          ? formatLanguage(
              query.data.data.find(language => language.id === value)
            )
          : null
      }
      options={query.isSuccess ? query.data.data.map(formatLanguage) : []}
    />
  );
};

LanguageSelect.propTypes = {
  value: PropTypes.number,
  isLoading: PropTypes.bool
};

const ChangeLanguage = () => {
  const { language, setLanguage } = useLanguage();

  const { t } = useTranslation('bots');

  const mutation = useMutation(changeLanguage);
  const { query } = useAuth();

  const handleLanguageSelect = async option => {
    await mutation.mutateAsync(option.language.id);
    await query.refetch();
    setLanguage(option.language);
  };

  return (
    <LanguageSelect
      aria-label={t('change_language_label')}
      isDisabled={mutation.isDisabled}
      isLoading={mutation.isLoading || query.isFetching}
      value={language ? language.id : null}
      onChange={handleLanguageSelect}
    />
  );
};

export default ChangeLanguage;

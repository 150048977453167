import general from './general';

/**
 * Function to draw a carousel message based on on a node object (first item only)
 *
 * @param   {CanvasRenderingContext2D}  ctx current canvas rendering context
 * @param   {number}  x     x position of node
 * @param   {number}  y     y position of node
 * @param   {string}  fill  hex, rgba, or string preset colour value to fill the background
 * @param   {object}  response  response object
 *
 * @return  {number}        height of resulting draw object
 */
const carousel = (ctx, x, y, fill, response) => {
  return general(ctx, x, y, fill, { content: response.content.items[0] });
};

export default carousel;

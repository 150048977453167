import React from 'react';

import { Label, FormGroup } from '@ubisend/pulse-components';
import {
  TemplatingTextInput,
  TemplatingTextArea
} from '@ubisend/pulse-templating';

import { useComposer } from '../../../../hooks/index';
import VideoProviders from './VideoProviders';

const VideoInput = () => {
  const {
    response: { content },
    dispatch
  } = useComposer();

  const handleResponseChange = value => {
    dispatch({
      type: 'UPDATE_CONTENT',
      newContent: { ...content, text: value }
    });
  };

  const handleUrlChange = value => {
    dispatch({
      type: 'UPDATE_CONTENT',
      newContent: { ...content, url: value }
    });
  };

  const handleProviderChange = value => {
    dispatch({
      type: 'UPDATE_CONTENT',
      newContent: { ...content, provider: value.value }
    });
  };

  const handleDescriptionChange = value => {
    dispatch({
      type: 'UPDATE_CONTENT',
      newContent: { ...content, content_description: value }
    });
  };

  return (
    <>
      <FormGroup>
        <Label htmlFor="provider">Video Provider</Label>
        <VideoProviders
          id="provider"
          value={content.provider}
          onChange={handleProviderChange}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="url">Video URL</Label>
        <TemplatingTextInput
          id="url"
          name="url"
          value={content.url}
          onChange={handleUrlChange}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="content_description">Content Description</Label>
        <TemplatingTextInput
          id="content_description"
          name="content_description"
          value={content.content_description}
          onChange={handleDescriptionChange}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="message">Message</Label>
        <TemplatingTextArea
          id="message"
          name="message"
          value={content.text}
          onChange={handleResponseChange}
        />
      </FormGroup>
    </>
  );
};

export default VideoInput;

import React from 'react';
import { Line } from 'react-chartjs-2';

import { Heading2, Flex } from '@ubisend/pulse-components';

import { useChartColours, useMetric } from '../../../hooks/index';

const LineGraphMetric = () => {
  const { metric } = useMetric();
  const colours = useChartColours();

  return (
    <Flex pad col tall>
      <Heading2>{metric.data.label}</Heading2>
      <Flex tall>
        <Line
          options={{ animations: false, maintainAspectRatio: false }}
          data={{
            labels: metric.data.data.labels,
            datasets: metric.data.data.datasets.map((set, key) => ({
              ...set,
              borderColor: colours(
                metric.data.data.length,
                metric.data.data.datasets.length
              )[key]
            }))
          }}
        />
      </Flex>
    </Flex>
  );
};

export default LineGraphMetric;

import React from 'react';
import PropTypes from 'prop-types';

import { TemplatingTextInput } from '@ubisend/pulse-templating';

const TextCondition = ({ onChange, ...props }) => {
  const handleChange = value => {
    onChange(value);
  };

  return (
    <TemplatingTextInput
      onChange={handleChange}
      placeholder="Tell me about chatbots"
      {...props}
    />
  );
};

TextCondition.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default TextCondition;

const hasGlobalRole = roleName => auth => {
  if (!auth?.user || !auth?.user?.global_roles) {
    return false;
  }

  return Boolean(auth.user.global_roles.find(role => role.name === roleName));
};

const hasAllGlobalRoles = (...roles) => auth => {
  return roles.every(role => {
    return hasGlobalRole(role)(auth);
  });
};

const hasSomeGlobalRoles = (...roles) => auth => {
  return roles.some(role => {
    return hasGlobalRole(role)(auth);
  });
};

export { hasGlobalRole, hasAllGlobalRoles, hasSomeGlobalRoles };

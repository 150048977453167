import RealexHpp from './RealexHpp';
import RealexRemote from './RealexRemote';

const load = () => {
  window.RealexHpp = RealexHpp();
  window.RealexRemote = RealexRemote();

  return {
    ...window.RealexHpp,
    ...window.RealexRemote
  };
};

export default load;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  Flex,
  InnerPanel,
  Placeholder,
  PanelSlider,
  Button,
  SubHeader,
  Heading3
} from '@ubisend/pulse-components';
import { DevelopmentFlag, useQuery } from '@ubisend/pulse-hooks';

import AssignmentSelect from './AssignmentSelect';

const TicketSettings = ({ closeSettings, ...props }) => {
  const { data: currentAssigner, isLoading } = useQuery(
    'tickets/ubidesk/settings'
  );

  const { data: driver, isLoading: driverLoading } = useQuery(
    'tickets/drivers'
  );

  const loading = isLoading || driverLoading;

  return (
    <PanelSlider
      {...props}
      header="Ticket settings"
      handleHide={closeSettings}
      width={'30rem'}>
      {loading && <Placeholder />}
      {!loading && (
        <Flex ySpace col mt>
          <AssignmentSelect currentAssigner={currentAssigner?.data} />
          <DevelopmentFlag>
            <Flex>
              <Heading3>Ticket driver</Heading3>
            </Flex>
            <InnerPanel>
              <Flex center col>
                <Flex mb>
                  <SubHeader>{driver.data.name}</SubHeader>
                </Flex>
                <Button as={Link} to="/tickets/drivers">
                  Update Driver
                </Button>
              </Flex>
            </InnerPanel>
          </DevelopmentFlag>
        </Flex>
      )}
    </PanelSlider>
  );
};

TicketSettings.propTypes = {
  closeSettings: PropTypes.func.isRequired
};

export default TicketSettings;

import React, { useState } from 'react';

import {
  TextInput,
  Button,
  Panel,
  Flex,
  Checkbox,
  OneQuarterLayout,
  InfoSection,
  TabButton,
  Divider
} from '@ubisend/pulse-components';
import { AnimateSharedLayout } from '@ubisend/framer-motion';
import { ImageSelect } from '@ubisend/pulse-files';
import { useLanguages, LanguagesConsumer } from '@ubisend/pulse-hooks';

import { useBotSettings } from '../../hooks/index';

const EmbedGeneralSettings = () => {
  const [selectImage, setSelectImage] = useState();

  const {
    settings,
    setSettings,
    language,
    setLanguage,
    name,
    focusBot,
    handleNewImage
  } = useBotSettings();
  const { languages } = useLanguages();

  const showSelectImage = () => setSelectImage(true);

  const hideSelectImage = () => setSelectImage(false);

  const handleShowNameToggle = () => {
    setSettings(settings => ({
      ...settings,
      name_enabled: !settings.name_enabled
    }));
  };

  const handleNameChange = event => {
    const newName = event.target.value;

    setSettings(settings => ({
      ...settings,
      names: settings.names.map(name => {
        if (name.language_id === language.id) {
          return {
            language_id: language.id,
            content: { name: newName }
          };
        }

        return name;
      })
    }));
  };

  const handleShowLogoToggle = () => {
    setSettings(settings => ({
      ...settings,
      logo_enabled: !settings.logo_enabled
    }));
  };

  const handleImageChange = file => {
    handleNewImage(file);
    hideSelectImage();
  };

  return (
    <Flex>
      <OneQuarterLayout>
        <InfoSection
          title="Main Settings"
          info="Adjust the name of your chatbot, and add a logo."
        />
      </OneQuarterLayout>
      <Panel>
        {selectImage && (
          <ImageSelect
            handleCancel={hideSelectImage}
            handleFileSelect={handleImageChange}
          />
        )}
        <LanguagesConsumer>
          <Flex>
            <AnimateSharedLayout>
              {languages.map(languageTab => (
                <TabButton
                  onClick={() => setLanguage(languageTab)}
                  active={languageTab.id === language.id}
                  key={languageTab.id}>
                  {languageTab.name}
                </TabButton>
              ))}
            </AnimateSharedLayout>
          </Flex>
          <Divider fullWidth />
        </LanguagesConsumer>
        <Flex col ySpace>
          <Flex col ySpaceSm grow>
            <Checkbox
              checked={settings.name_enabled}
              onChange={handleShowNameToggle}
              label="Chatbot name"
            />
            <TextInput
              disabled={!settings.name_enabled}
              aria-label="bot-name"
              value={name}
              onChange={handleNameChange}
              onClick={focusBot}
              maxLength="255"
            />
          </Flex>
          <Flex col ySpaceSm>
            <Checkbox
              checked={settings.logo_enabled}
              onChange={handleShowLogoToggle}
              label="Chatbot logo"
            />
            <Button
              selfTop
              disabled={!settings.logo_enabled}
              variant="secondary"
              onClick={showSelectImage}>
              Change Logo
            </Button>
          </Flex>
        </Flex>
      </Panel>
    </Flex>
  );
};

export default EmbedGeneralSettings;

import EscapeInput from './EscapeInput';

const escape = {
  name: 'Escape',
  Input: EscapeInput,
  content: {},
  format: variable => {
    return `${variable}|escape`;
  },
  valid: () => true
};

export default escape;

import PaymentConditional from './PaymentConditional';

const subject = { id: null, type: 'payment' };

const conditional = {
  subject,
  type: 'A payment',
  condition: null,
  flipped: false,
  case_sensitive: false,
  target: ''
};

const payment = {
  subject,
  conditional,
  name: 'Payment',
  Conditional: PaymentConditional,
  toState: () => subject,
  toApi: () => subject
};

export default payment;

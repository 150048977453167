import React from 'react';

import { Flex, Heading2 } from '@ubisend/pulse-components';
import { NoTickets } from '@ubisend/pulse-tickets';

const NoLiveChat = () => {
  return (
    <NoTickets>
      <Flex col center ySpace>
        <Heading2>Choose a live chat ticket to get started.</Heading2>
      </Flex>
    </NoTickets>
  );
};

export default NoLiveChat;

import React from 'react';

import { PageWrapper, Flex, Grid } from '@ubisend/pulse-components';

import { WelcomeMessage, User, Client, Actions } from '../Components/index';

const Home = () => (
  <PageWrapper header="Home" subheader="Your personal overview">
    <Flex col ySpace>
      <WelcomeMessage />
      <Grid columns={3}>
        <User />
        <Client />
      </Grid>
      <Actions />
    </Flex>
  </PageWrapper>
);

export default Home;

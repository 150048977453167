import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { TwitterPicker } from 'react-color';

const StyledPicker = styled(TwitterPicker)`
  /* stylelint-disable */
  border: none !important;
  box-shadow: none !important;
  margin: -15px -9px -9px -15px;
  background: none !important;
  /* stylelint-enable */
`;

const ColourPicker = props => {
  const theme = useContext(ThemeContext);

  const colours = [
    theme.primary,
    theme.secondary,
    theme.positive,
    theme.warning,
    theme.danger
  ];

  return (
    <StyledPicker width="100%" triangle="hide" colors={colours} {...props} />
  );
};

export default ColourPicker;

import React from 'react';

import { Button } from '@ubisend/pulse-components';

import { useConditional } from '../hooks/index';

const AddConditionalButton = props => {
  const { handleNewConditional } = useConditional();

  return (
    <Button variant="secondary" onClick={handleNewConditional} {...props}>
      + Add new
    </Button>
  );
};

export default AddConditionalButton;

import React, { useState } from 'react';

import { useQuery } from '@ubisend/pulse-hooks';

import { useImportReducer } from '../reducers/index';
import { ImportsContext } from '../Contexts/index';

const ImportsProvider = ({ children }) => {
  const [open, setOpen] = useState();

  const reducer = useImportReducer();

  useQuery('imports', {
    onSuccess: ({ data }) => {
      reducer.dispatch({ type: 'SET_IMPORTS', imports: data });
    }
  });

  const context = {
    ...reducer,
    open,
    setOpen,
    loading: Boolean(!reducer.imports)
  };

  return (
    <ImportsContext.Provider value={context}>
      {children}
    </ImportsContext.Provider>
  );
};

export default ImportsProvider;

import React from 'react';
import { useHistory } from 'react-router-dom';

import { useQueryClient, useQuery, useMutation } from '@ubisend/pulse-hooks';
import {
  PageWrapper,
  Placeholder,
  useNotification
} from '@ubisend/pulse-components';

import { EmailDetails } from '../Components/index';
import { updateEmail } from '../api/index';

const EditEmail = ({ match }) => {
  const history = useHistory();
  const { showSuccess } = useNotification();

  const queryClient = useQueryClient();
  const query = useQuery(`emails/${match.params.id}`);
  const mutation = useMutation(updateEmail, {
    onSuccess: () => {
      showSuccess('Successfully updated email');
      queryClient.invalidateQueries(`emails/${match.params.id}`);
      queryClient.invalidateQueries('emails');
      history.push(`/emails`);
    }
  });

  const handleSubmit = form => {
    mutation.mutate({ id: match.params.id, ...form });
  };

  return (
    <PageWrapper
      header="Edit Email"
      subheader="Send emails as part of your conversations">
      {query.isLoading && <Placeholder />}
      {query.isSuccess && (
        <EmailDetails
          handleSubmit={handleSubmit}
          loading={mutation.isLoading}
          initialEmail={{
            subject: query.data.data.subject || '',
            body: query.data.data.body || '',
            fromName: query.data.data.from_name || '',
            fromSuffix: query.data.data.from_suffix || '',
            fromPrefix: query.data.data.from_prefix || '',
            to: query.data.data.to || '',
            cc: query.data.data.cc || '',
            bcc: query.data.data.bcc || ''
          }}
        />
      )}
    </PageWrapper>
  );
};

export default EditEmail;

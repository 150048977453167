import { useContext } from 'react';

import { SubscriptionContext } from '../Contexts/index';

const useSubscriptionContext = () => {
  const context = useContext(SubscriptionContext);

  return context;
};

export default useSubscriptionContext;

// eslint-disable-next-line
const isEnrolledInBeta = feature => auth => {
  if (['development', 'testing'].includes(process.env.NODE_ENV)) {
    return true;
  }

  return Boolean(process.env.REACT_APP_SHOW_BETA_FEATURES);
};

export default isEnrolledInBeta;

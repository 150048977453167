import { hasPermission } from '@ubisend/pulse-auth';

import { Conversations, Builder } from './Pages/index';

const routes = [
  {
    name: 'Conversations',
    path: '/builder',
    component: Conversations,
    disableBreadCrumbs: true,
    canAccess: hasPermission('view conversations')
  },
  {
    name: 'Conversation Builder',
    path: '/builder/:id',
    component: Builder,
    disableBreadCrumbs: true,
    canAccess: hasPermission('view conversations')
  }
];

export default routes;

import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  FormGroup,
  Label,
  Button,
  CreatableSelect
} from '@ubisend/pulse-components';

import PermissionFilter from '../../PermissionFilter';
import Roles from '../../Roles';
import Permissions from '../../Permissions';
import { useRolesAndPermissions } from '../../../hooks/index';

const EmailPasswordInvite = ({ handleSubmit, loading }) => {
  const [emails, setEmails] = useState([]);
  const {
    roles,
    setRoles,
    permissions,
    setPermissions,
    formattedPermissions
  } = useRolesAndPermissions();

  const handleRolesChange = roles => {
    setRoles(roles);
  };

  const handlePermissionsChange = permissions => {
    setPermissions(permissions);
  };

  const onCreateOption = input => {
    setEmails(emails.concat({ label: input, value: input }));
  };

  const handleEmailChange = options => {
    setEmails(options || []);
  };

  const onSubmit = event => {
    event.preventDefault();

    handleSubmit({
      emails: emails.map(email => email.value),
      roles: roles.map(role => role.id),
      permissions: permissions.map(permission => permission.id)
    });
  };

  const valid = useMemo(() => {
    return emails.length > 0;
  }, [emails]);

  return (
    <form onSubmit={onSubmit}>
      <FormGroup>
        <Label htmlFor="emails">Emails</Label>
        <CreatableSelect
          closeMenuOnSelect
          isClearable
          isMulti
          id="emails"
          value={emails}
          onChange={handleEmailChange}
          onCreateOption={onCreateOption}
          placeholder="john@smith.com"
        />
      </FormGroup>
      <PermissionFilter can="view roles">
        <FormGroup>
          <Label htmlFor="roles">Roles</Label>
          <Roles onChange={handleRolesChange} value={roles} />
        </FormGroup>
      </PermissionFilter>
      <PermissionFilter can="view permissions">
        <FormGroup>
          <Label htmlFor="roles">Extra Permissions</Label>
          <Permissions
            onChange={handlePermissionsChange}
            value={formattedPermissions}
          />
        </FormGroup>
      </PermissionFilter>
      <Flex right>
        <Button icon="save" type="submit" loading={loading} disabled={!valid}>
          Invite
        </Button>
      </Flex>
    </form>
  );
};

EmailPasswordInvite.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default EmailPasswordInvite;

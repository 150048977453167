import axios from 'axios';

const getCallToActions = () => axios.get('converse/call-to-actions');

const getCallToAction = id => axios.get(`converse/call-to-actions/${id}`);

const createCallToAction = params =>
  axios.post(`converse/call-to-actions`, params);

const updateCallToAction = (id, params) => {
  return axios.put(`converse/call-to-actions/${id}`, params);
};

const deleteCallToAction = id => axios.delete(`converse/call-to-actions/${id}`);

export {
  getCallToActions,
  getCallToAction,
  createCallToAction,
  updateCallToAction,
  deleteCallToAction
};

import { useState, useEffect, useCallback } from 'react';

import { breakpoints } from '@ubisend/pulse-components';

const useBelowScreenSize = (size = breakpoints.md) => {
  const [currentSize, setCurrentSize] = useState(screen.width);

  const handleResize = useCallback(() => {
    setCurrentSize(screen.width);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return currentSize < size;
};

export default useBelowScreenSize;

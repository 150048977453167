import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Indicator as BaseIndicator } from '@ubisend/pulse-components';

const FatIndicator = styled(BaseIndicator)`
  ${tw`w-3 h-3 mr-2`}
`;

const Error = () => <FatIndicator colour="danger" />;

const Message = () => <FatIndicator colour="positive" />;

const Warning = () => <FatIndicator colour="warning" />;

const levels = {
  warning: Warning,
  success: Message,
  error: Error
};

const LogLevel = ({ level }) => {
  const Indicator = levels[level];

  return <Indicator />;
};

LogLevel.propTypes = {
  level: PropTypes.string.isRequired
};

export default LogLevel;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Button } from '@ubisend/pulse-components';

const ConversationStepScope = ({ content }) => {
  return (
    <Button
      variant="inline"
      as={Link}
      to={`/builder/${content.conversation_id}`}>
      {content.title}
    </Button>
  );
};

ConversationStepScope.propTypes = {
  content: PropTypes.shape({
    conversation_id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired
};

export default ConversationStepScope;

import {
  VariableConditional,
  VariablePreview
} from '../../Components/Subjects/Conditionals/index';

const subject = {
  type: 'variable'
};

const conditional = {
  subject,
  type: null,
  condition: null,
  flipped: false,
  case_sensitive: false,
  target: ''
};

const variableSubject = {
  subject,
  conditional,
  permission: 'view variables',
  name: 'Variable',
  Conditional: VariableConditional,
  Preview: VariablePreview,
  toState: () => {
    return {
      id: null,
      type: 'variable'
    };
  },
  toApi: () => {
    return {
      id: null,
      type: 'variable'
    };
  }
};

export default variableSubject;

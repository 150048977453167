import React from 'react';
import { Redirect } from 'react-router-dom';

import { useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import { PageWrapper, OneHalfLayout, Panel } from '@ubisend/pulse-components';

import { createNotification } from '../api/index';
import { NotificationProvider } from '../Providers/index';
import { NotificationDetails } from '../Components/index';

const CreateNotification = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(createNotification, {
    onSuccess: () => {
      queryClient.invalidateQueries('template-notifications');
    }
  });

  const handleSubmit = form => {
    mutation.mutate(form);
  };

  return (
    <PageWrapper header="Notifications" subheader="Create a notification">
      {mutation.isSuccess && (
        <Redirect
          to={{
            pathname: '/notifications',
            state: {
              notification: {
                type: 'success',
                message: 'Successfully created notification.'
              }
            }
          }}
        />
      )}
      <OneHalfLayout>
        <Panel>
          <NotificationProvider>
            <NotificationDetails
              onSubmit={handleSubmit}
              loading={mutation.isLoading}
            />
          </NotificationProvider>
        </Panel>
      </OneHalfLayout>
    </PageWrapper>
  );
};

export default CreateNotification;

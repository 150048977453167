import React from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import { Select } from '@ubisend/pulse-components';

const formatSelectOption = subscriber => {
  return { value: subscriber.id, label: `Subscriber #${subscriber.id}` };
};

const SubscriberSelect = ({ value, onChange, ...props }) => {
  const { isLoading, isSuccess, data } = useQuery('subscribers/all');

  return (
    <Select
      isClearable
      isLoading={isLoading}
      options={isSuccess ? data.data.map(formatSelectOption) : []}
      onChange={onChange}
      value={
        isSuccess &&
        value &&
        formatSelectOption(
          data.data.find(subscriber => subscriber.id === value)
        )
      }
      {...props}
    />
  );
};

SubscriberSelect.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

export default SubscriberSelect;

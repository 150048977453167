import TransitionNode from './TransitionNode';

const blocks = ['metric', 'variable'];

const transition = {
  id: 'transition',
  name: 'Transition',
  icon: 'arrowNarrowRight',
  colour: 'grey',
  permission: 'conversations',
  Node: TransitionNode,
  blocks,
  canDrop: block => {
    return blocks.includes(block.id);
  }
};

export default transition;

import React from 'react';

import { FormGroup, Label } from '@ubisend/pulse-components';
import { useComposer } from '@ubisend/pulse-composer';

import { LookupSelect, SheetSelect } from '../../../Sheets/index';

const LookupComposer = () => {
  const {
    response: { subject },
    dispatch
  } = useComposer();

  const handleSheetChange = option => {
    dispatch({
      type: 'CHANGE_SUBJECT',
      newSubject: {
        ...subject,
        spreadsheet_id: option ? option.value : null,
        lookup_id: null
      }
    });
  };

  const handleLookupChange = ({ value }) => {
    dispatch({
      type: 'CHANGE_SUBJECT',
      newSubject: { ...subject, lookup_id: value }
    });
  };

  return (
    <div>
      <FormGroup>
        <Label>Spreadsheet</Label>
        <SheetSelect
          aria-label={'spreadsheet'}
          value={subject.spreadsheet_id}
          onChange={handleSheetChange}
        />
      </FormGroup>
      <FormGroup>
        <Label>Lookup</Label>
        <LookupSelect
          aria-label="lookup"
          sheet={subject.spreadsheet_id}
          value={subject.lookup_id}
          onChange={handleLookupChange}
        />
      </FormGroup>
    </div>
  );
};

export default LookupComposer;

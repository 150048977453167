import React from 'react';

import { TextInput } from '@ubisend/pulse-components';

import { useQrCode } from '../hooks/index';

const ScaleInput = props => {
  const { scale, dispatch } = useQrCode();

  const handleScaleChange = event => {
    const scale = event.target.value;

    dispatch({ type: 'CHANGE_SCALE', scale });
  };

  return (
    <TextInput
      {...props}
      min={0}
      type="number"
      value={scale}
      onChange={handleScaleChange}
    />
  );
};

export default ScaleInput;

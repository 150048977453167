import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactSVG from 'react-svg';

const Svg = ({ className, src, ...rest }) => (
  <ReactSVG
    className={className}
    svgClassName={className}
    src={src}
    {...rest}
  />
);

const StyledSvg = styled(Svg)`
  ${tw`h-6 w-8`}
  height: ${props => props.height};
  width: ${props => props.width};
  & > path {
    fill: ${props => props.colour};
    stroke: ${props => props.stroke};
  }
`;

Svg.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired
};

const Element = styled.i`
  width: ${props => props.size};
  -webkit-font-smoothing: antialiased;
  transform: translate3d(0, 0, 0);
  & svg {
    -webkit-font-smoothing: antialiased;
    transform: translate3d(0, 0, 0);
  }
`;

const Icon = props => (
  <Element size={props.size}>
    <StyledSvg
      {...props}
      colour={props.colour}
      height={props.height}
      width={props.width}
      stroke={props.stroke}
    />
  </Element>
);

Icon.propTypes = {
  size: PropTypes.string,
  src: PropTypes.string.isRequired,
  colour: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  stroke: PropTypes.string
};

export default Icon;

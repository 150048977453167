import React, { useState, useEffect } from 'react';

import {
  PageWrapper,
  LoadingContainer,
  useNotification
} from '@ubisend/pulse-components';
import { Drivers } from '@ubisend/pulse-hooks';
import { useAuth } from '@ubisend/pulse-auth';

import { getAllDrivers, getDriver, updateDriver } from '../api/index';

const NlpDrivers = () => {
  const [drivers, setDrivers] = useState();
  const [driver, setDriver] = useState();

  const { showSuccess } = useNotification();
  const { hasPermission } = useAuth();

  const fetchDrivers = async () => {
    const { data } = await getAllDrivers();
    setDrivers(data.data);
  };

  const fetchDriver = async () => {
    const { data } = await getDriver();
    setDriver(data.data.id);
  };

  useEffect(() => {
    fetchDrivers();
  }, []);

  useEffect(() => {
    fetchDriver();
  }, []);

  const handleDriver = async driver => {
    await updateDriver(driver.id);

    showSuccess(`Successfully made ${driver.name} your NLP Provider.`);
    setDriver(driver.id);
  };

  const loading = !drivers || !driver;

  return (
    <PageWrapper header="NLP" subheader="Configure your chosen NLP Provider">
      {loading && <LoadingContainer />}
      {!loading && (
        <Drivers
          type="single"
          name="NLP"
          required
          value={driver}
          drivers={drivers}
          handleClick={handleDriver}
          canEditDriver={() => false}
          canEnableDriver={() => hasPermission('edit nlp providers')}
          canDisableDriver={() => hasPermission('edit nlp providers')}
        />
      )}
    </PageWrapper>
  );
};

export default NlpDrivers;

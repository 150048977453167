import React from 'react';
import PropTypes from 'prop-types';

import { useAutoTextArea } from '@ubisend/pulse-component-hooks';

import TextArea from './TextArea';

const AutoTextArea = props => {
  const ref = useAutoTextArea(props.value);

  return <TextArea ref={ref} {...props} />;
};

AutoTextArea.propTypes = {
  value: PropTypes.string
};

export default AutoTextArea;

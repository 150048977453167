import styled from 'styled-components';

const MessageGroupContainer = styled.div`
  ${tw`inline-flex mb-4 w-full`}
  & > div {
    ${tw`flex-col w-full`}
  }
`;

export default MessageGroupContainer;

import { useCallback, useEffect } from 'react';

import useOveridableRef from './useOveridableRef';

const useClickOutside = (handleHide, options = {}) => {
  const containerRef = useOveridableRef(options.ref);

  const handleClickOutside = useCallback(
    event => {
      if (!containerRef.current) {
        return;
      }
      if (!containerRef.current.contains(event.target)) {
        handleHide();
      }
    },
    [handleHide, containerRef]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  return containerRef;
};

export default useClickOutside;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { pseudoStyles } from '@ubisend/pulse-components';

import styles from './styles';

const StyledButton = styled.a`
  ${styles}
  ${pseudoStyles}
`;

const File = ({ response, handleButtonClick, ...props }) => {
  const handleClick = () => {
    if (response.linkback) {
      handleButtonClick(response.label, response.linkback);
    }

    return true;
  };

  return (
    <StyledButton
      {...props}
      colour="white"
      onClick={handleClick}
      href={response.action}
      target="_blank"
      rel="noopener noreferrer"
      download>
      {response.label}
    </StyledButton>
  );
};

File.propTypes = {
  response: PropTypes.shape({
    label: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    linkback: PropTypes.string
  }).isRequired,
  handleButtonClick: PropTypes.func.isRequired
};

export default File;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ConverseMessage } from '@ubisend/pulse-volt';

const Wrapper = styled.div`
  & > div > * {
    max-width: 100%;
  }
`;

const ResponsePreview = ({ type = 'dynamic', content }) => {
  const message = { id: null, type, content, direction: 'toClient' };

  return (
    <Wrapper>
      <ConverseMessage
        message={message}
        groups={[[message]]}
        groupIndex={0}
        handleButtonClick={() => {}}
      />
    </Wrapper>
  );
};

ResponsePreview.propTypes = {
  type: PropTypes.string.isRequired,
  content: PropTypes.object
};

export default ResponsePreview;

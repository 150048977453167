import styled from 'styled-components';
import chroma from 'chroma-js';

import { position } from '../styles';

const background = ({ theme, type = 'client' }) => {
  if (type === 'owner') {
    return 'white';
  }

  return chroma
    .blend(theme.black, theme.primary, 'overlay')
    .brighten(0.5)
    .css();
};

const Sidebar = styled.div`
  ${tw`flex flex-col h-full pt-5 m-0 z-40 w-48 flex-no-shrink overflow-y-auto h-screen`};
  background-color: ${background};
  ${position}
`;

export default Sidebar;

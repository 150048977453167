import React, { useState, useEffect } from 'react';

import { ChannelContext } from '../Contexts/index';
import { getChannels, getAllChannels } from '../api/index';

const ChannelProvider = ({ children }) => {
  const [channels, setChannels] = useState();
  const [allChannels, setAllChannels] = useState();

  const fetchChannels = async () => {
    const {
      data: { data }
    } = await getChannels();
    setChannels(data.map(({ id }) => id));
  };

  const fetchAllChannels = async () => {
    const {
      data: { data }
    } = await getAllChannels();
    setAllChannels(data);
  };

  useEffect(() => {
    fetchChannels();
  }, []);

  useEffect(() => {
    fetchAllChannels();
  }, []);

  if (!channels || !allChannels) {
    return null;
  }

  const context = {
    channels,
    allChannels,
    setChannels
  };

  return (
    <ChannelContext.Provider value={context}>
      {children}
    </ChannelContext.Provider>
  );
};

export default ChannelProvider;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { Flex, Heading3, Button } from '@ubisend/pulse-components';
import { GlobalRoleFilter } from '@ubisend/pulse-auth';

const NoLogoHeader = styled.h4`
  ${tw`m-0 flex items-center justify-center `}
  width: 4rem;
  height: 4rem;
`;

const ClientLogo = styled.img`
  width: 4rem;
  height: 4rem;
  object-fit: contain;
`;

const ActiveStatus = ({ id }) => {
  return (
    <Flex left>
      <Button
        style={{ paddingLeft: '0' }}
        variant="inline"
        colour="positive"
        as={Link}
        to={`/owner/licenses/${id}`}>
        Active license
      </Button>
    </Flex>
  );
};

ActiveStatus.propTypes = {
  id: PropTypes.number.isRequired
};

const ExpiringStatus = ({ id, period }) => {
  return (
    <Flex left>
      <Button
        style={{ paddingLeft: '0' }}
        variant="inline"
        colour="warning"
        as={Link}
        to={`/owner/licenses/${id}`}>
        License Expiring in {dayjs(period.end).fromNow(true)}
      </Button>
    </Flex>
  );
};

ExpiringStatus.propTypes = {
  id: PropTypes.number.isRequired,
  period: PropTypes.shape({
    end: PropTypes.string.isRequired
  }).isRequired
};

const ExpiredStatus = ({ id, period }) => {
  return (
    <Flex col left>
      <Button
        style={{ paddingLeft: '0' }}
        variant="inline"
        colour="danger"
        as={Link}
        to={`/owner/licenses/${id}`}>
        License Expired {dayjs(period.end).from(dayjs())}
      </Button>
    </Flex>
  );
};

ExpiredStatus.propTypes = {
  id: PropTypes.number.isRequired,
  period: PropTypes.shape({
    end: PropTypes.string.isRequired
  }).isRequired
};

const NoLicense = () => {
  return (
    <Flex left>
      <Button
        style={{ paddingLeft: '0' }}
        variant="inline"
        colour="danger"
        as={Link}
        to={`/owner/licenses`}>
        No Attached License
      </Button>
    </Flex>
  );
};

const statuses = {
  active: ActiveStatus,
  expired: ExpiredStatus,
  expiring: ExpiringStatus
};

const License = ({ license }) => {
  if (!license) {
    return <NoLicense />;
  }

  const Component = statuses[license.status];

  return <Component {...license} />;
};

License.propTypes = {
  license: PropTypes.shape({
    status: PropTypes.oneOf(Object.keys(statuses)).isRequired
  })
};

const ClientCard = ({ client }) => {
  return (
    <Flex inline xSpace center tall>
      <div>
        {!client.logo && <NoLogoHeader>No Logo</NoLogoHeader>}
        {client.logo && (
          <ClientLogo src={client.logo} alt={`${client.name} logo`} />
        )}
      </div>
      <Flex col ySpace ml>
        <Heading3>{client.name}</Heading3>
        <GlobalRoleFilter for="owner">
          <License license={client.license} />
        </GlobalRoleFilter>
      </Flex>
    </Flex>
  );
};

ClientCard.propTypes = {
  client: PropTypes.shape({
    name: PropTypes.string,
    logo: PropTypes.string,
    license: PropTypes.object
  }).isRequired
};

export default ClientCard;

import { PARAGRAPH_FONT_SIZE, PARAGRAPH_LINE_HEIGHT } from './constants';

/**
 * Renders lines of text
 *
 * @param   {CanvasRenderingContext2D}  ctx current canvas rendering context
 * @param   {number}  x     x position of text
 * @param   {number}  y     y position of text
 * @param   {array<string>}  lines array of strings to render
 *
 */
const textBox = (ctx, x, y, lines, colour = 'white') => {
  ctx.save();
  ctx.fillStyle = colour;
  ctx.font = `${PARAGRAPH_FONT_SIZE}px Open Sans`;
  lines.forEach((line, i) => {
    ctx.fillText(line, x, y + PARAGRAPH_LINE_HEIGHT * i);
  });
  ctx.restore();
  return PARAGRAPH_LINE_HEIGHT * lines.length;
};

export default textBox;

import styled from 'styled-components';

const NodeHeader = styled.div`
  ${tw`flex justify-end px-3 py-2 w-full`}
  height: 54px;
  & i {
    ${tw`flex`}
  }
`;

export default NodeHeader;

import { useState, useEffect } from 'react';

const useFullscreen = ref => {
  const [isFullscreen, setFullscreen] = useState(false);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    if (isFullscreen && !document.fullscreenElement) {
      ref.current.webkitRequestFullscreen();
    } else if (!isFullscreen && document.fullscreenElement) {
      document.exitFullscreen();
    }
  }, [isFullscreen, ref]);

  return [isFullscreen, setFullscreen];
};

export default useFullscreen;

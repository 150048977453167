import {
  STEP_HEADER_PADDING_X,
  STEP_HEADER_FONT_SIZE,
  STEP_HEADER_PADDING_Y
} from './constants';

const nodeHeading = (ctx, x, y, node) => {
  ctx.save();
  ctx.fillStyle = 'black';
  ctx.font = '600 12px Poppins';
  ctx.fillText(
    'CONVERSATION STEP',
    x + STEP_HEADER_PADDING_X,
    y + STEP_HEADER_FONT_SIZE + STEP_HEADER_PADDING_Y
  );
  ctx.fillStyle = '#908c97';
  ctx.font = '400 14px Open Sans';
  ctx.fillText(
    node.base.title.length < 25
      ? node.base.title
      : `${node.base.title.slice(0, 25)}...`,
    x + STEP_HEADER_PADDING_X,
    y + (STEP_HEADER_FONT_SIZE + STEP_HEADER_PADDING_Y) * 2
  );
  ctx.restore();
};

export default nodeHeading;

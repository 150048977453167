import React from 'react';

const Discord = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32pt"
    height="32pt"
    viewBox="0 0 35 32"
    version="1.1"
    {...props}>
    <g id="surface1">
      <path d="M 29.332031 32 L 22.332031 25.332031 L 23.171875 28 L 6 28 C 4.160156 28 2.667969 26.507812 2.667969 24.667969 L 2.667969 4.667969 C 2.667969 2.824219 4.160156 1.332031 6 1.332031 L 26 1.332031 C 27.839844 1.332031 29.332031 2.824219 29.332031 4.667969 L 29.332031 32 M 16 9.066406 C 12.425781 9.066406 9.921875 10.601562 9.921875 10.601562 C 11.292969 9.375 13.691406 8.667969 13.691406 8.667969 L 13.464844 8.441406 C 11.214844 8.480469 9.171875 10.039062 9.171875 10.039062 C 6.878906 14.828125 7.027344 18.960938 7.027344 18.960938 C 8.894531 21.375 11.667969 21.199219 11.667969 21.199219 L 12.613281 20 C 10.945312 19.640625 9.894531 18.160156 9.894531 18.160156 C 9.894531 18.160156 12.398438 19.867188 16 19.867188 C 19.601562 19.867188 22.105469 18.160156 22.105469 18.160156 C 22.105469 18.160156 21.054688 19.640625 19.386719 20 L 20.332031 21.199219 C 20.332031 21.199219 23.105469 21.375 24.972656 18.960938 C 24.972656 18.960938 25.121094 14.828125 22.828125 10.039062 C 22.828125 10.039062 20.785156 8.480469 18.535156 8.441406 L 18.308594 8.667969 C 18.308594 8.667969 20.707031 9.375 22.078125 10.601562 C 22.078125 10.601562 19.574219 9.066406 16 9.066406 M 13.238281 14.121094 C 14.105469 14.121094 14.8125 14.878906 14.800781 15.8125 C 14.800781 16.734375 14.105469 17.507812 13.238281 17.507812 C 12.386719 17.507812 11.691406 16.734375 11.691406 15.8125 C 11.691406 14.878906 12.375 14.121094 13.238281 14.121094 M 18.800781 14.121094 C 19.667969 14.121094 20.359375 14.878906 20.359375 15.8125 C 20.359375 16.734375 19.667969 17.507812 18.800781 17.507812 C 17.945312 17.507812 17.253906 16.734375 17.253906 15.8125 C 17.253906 14.878906 17.933594 14.121094 18.800781 14.121094 Z M 18.800781 14.121094 " />
    </g>
  </svg>
);

export default Discord;

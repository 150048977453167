import React, { useState, useEffect } from 'react';

import { DeviceContext } from '../Contexts/index';

const screens = {
  bot: '384px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px'
};

const orderedScreenSizes = Object.keys(screens).sort(
  (a, b) => screens[a].split('px')[0] - screens[b].split('px')[0]
);

const getBreakpoint = () => {
  const name =
    orderedScreenSizes.find(
      size => screens[size].split('px')[0] > window.innerWidth
    ) || 'xl';

  return { name, width: parseInt(screens[name].replace('px', ''), 10) };
};

const DeviceProvider = ({ children }) => {
  const [breakpoint, setBreakpoint] = useState(getBreakpoint());

  const handleResize = () => setBreakpoint(getBreakpoint());

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isMobile = ['xs', 'sm', 'md', 'bot'].includes(breakpoint.name);
  const isDesktop = !isMobile;

  const context = {
    breakpoint,
    isMobile,
    isDesktop,
    onMobile: isMobile,
    onDesktop: isDesktop
  };

  return (
    <DeviceContext.Provider value={context}>{children}</DeviceContext.Provider>
  );
};

export default DeviceProvider;
export { screens, orderedScreenSizes, getBreakpoint };

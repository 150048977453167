import React from 'react';

import FileSelect from './FileSelect';
import types from './Types/index';

const SheetSelect = props => (
  <FileSelect types={[types.spreadsheet]} {...props} />
);

export default SheetSelect;

import React from 'react';
import { useHistory } from 'react-router-dom';

import { useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import {
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalClose,
  Label,
  ModalPortal,
  Flex,
  useNotification
} from '@ubisend/pulse-components';

import { CustomMetricDetails } from '../Components/index';
import { createCustomMetric } from '../api/index';

const CreateCustomMetric = () => {
  const { showSuccess } = useNotification();

  const history = useHistory();

  const queryClient = useQueryClient();
  const mutation = useMutation(createCustomMetric, {
    onSuccess: () => {
      queryClient.invalidateQueries('custom-metrics');
      queryClient.invalidateQueries('custom-metrics/all');
      showSuccess('Successfully created custom metric');
      history.push('/custom-metrics');
    }
  });

  const handleClose = () => {
    history.push('/custom-metrics');
  };

  const handleCreateCustomMetric = form => {
    mutation.mutate(form);
  };

  return (
    <ModalPortal>
      <ModalContainer
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.1 }}>
        <ModalContent>
          <ModalHeader>
            <Label mb={false}>Create custom metric</Label>
            <Flex xSpace>
              <ModalClose onClick={handleClose} />
            </Flex>
          </ModalHeader>
          <Flex pad fat>
            <CustomMetricDetails
              loading={mutation.isLoading}
              handleSubmit={handleCreateCustomMetric}
            />
          </Flex>
        </ModalContent>
      </ModalContainer>
    </ModalPortal>
  );
};

export default CreateCustomMetric;

import React from 'react';
import PropTypes from 'prop-types';

import { Select } from '@ubisend/pulse-components';

import { useVariable } from '../../hooks/index';

const VariableSubjectSelect = ({ value, ...props }) => {
  const { formattedSubjects } = useVariable();

  return (
    <Select
      options={formattedSubjects}
      value={formattedSubjects.find(subject => subject.value === value) || null}
      {...props}
    />
  );
};

VariableSubjectSelect.propTypes = {
  value: PropTypes.string
};

export default VariableSubjectSelect;

import websiteDriver from './WebsiteImport/driver';
import pdfDriver from './PdfImport/driver';
import manualDriver from './ManualImport/driver';

const drivers = {
  'Website Import': websiteDriver,
  'PDF Import': pdfDriver,
  'Manual Import': manualDriver
};

export default drivers;

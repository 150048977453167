import { useMutation, useQueryClient } from 'react-query';

import { useModal, useNotification } from '@ubisend/pulse-components';

const defaultOptions = {
  header: `Confirm deletion`,
  description: 'Are you sure you want to delete this item?',
  notification: 'Deletion successful',
  queries: [],
  mutationOptions: {}
};

const useDelete = (mutationFn, passedOptions = defaultOptions) => {
  const { showModal, hideModal } = useModal();
  const { showSuccess } = useNotification();

  const options = { ...defaultOptions, ...passedOptions };

  const queryClient = useQueryClient();
  const mutation = useMutation(mutationFn, {
    ...options.mutationOptions,
    onSuccess: async (...result) => {
      if (options.mutationOptions.onSuccess) {
        await options.mutationOptions.onSuccess(...result);
      }

      if (options.notification) {
        showSuccess(options.notification);
      }

      for (const query of options.queries) {
        await queryClient.invalidateQueries(query);
      }
    }
  });

  const handleDelete = params => {
    showModal({
      header: options.header,
      body: options.description,
      handleConfirm: async () => {
        try {
          await mutation.mutateAsync(params);
        } finally {
          hideModal();
        }
      }
    });
  };

  return { handleDelete, ...mutation };
};

export default useDelete;

import { SaveVariable } from '../../Components/Subjects/Variables/index';

const subject = {
  type: 'variable',
  target: ''
};

const variableSubject = {
  subject,
  name: 'Copy existing variable',
  permission: 'view variables',
  Saver: SaveVariable,
  toState: subject => {
    return {
      type: 'variable',
      target: subject.target
    };
  },
  toApi: subject => {
    return {
      id: null,
      type: 'variable',
      target: subject.target
    };
  }
};

export default variableSubject;

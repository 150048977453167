import React, { useState, useEffect } from 'react';

import {
  PageWrapper,
  OneHalfLayout,
  Flex,
  Placeholder,
  Panel,
  Button,
  useNotification
} from '@ubisend/pulse-components';

import { getSettings, updateSettings } from '../api';
import { GlobalPayDetails } from '../Components/index';

const requiredKeys = [
  'merchant_id',
  'account_id',
  'currency',
  'total',
  'email',
  'phone',
  'billing_address_1',
  'billing_city',
  'billing_postcode',
  'billing_country'
];

const formatSettings = settings => {
  return {
    merchant_id: settings.merchant_id || '',
    account_id: settings.account_id || '',
    shared_secret: settings.shared_secret || '',
    currency: settings.currency || '',
    total: settings.total || '',
    email: settings.email || '',
    phone: settings.phone || '',
    billing_address_1: settings.billing_address_1 || '',
    billing_address_2: settings.billing_address_2 || '',
    billing_city: settings.billing_city || '',
    billing_region: settings.billing_region || '',
    billing_postcode: settings.billing_postcode || '',
    billing_country: settings.billing_country || ''
  };
};

const GlobalPaySettings = () => {
  const [settings, setSettings] = useState();
  const [submitted, setSubmitted] = useState(false);

  const { showSuccess } = useNotification();

  const fetchSettings = async () => {
    const { data } = await getSettings();
    const {
      data: { billing_address: billingAddress, ...initialSettings }
    } = data;

    if (!billingAddress) {
      return;
    }

    for (const property in billingAddress) {
      initialSettings[`billing_${property}`] = billingAddress[property];
    }

    setSettings(formatSettings(initialSettings));
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const handleInputChange = field => event => {
    const { value } = event.target;
    handleInputChange(field);
    setSettings(settings => ({
      ...settings,
      [field]: value
    }));
  };

  const handleVariableInputChange = field => option => {
    setSettings(settings => ({
      ...settings,
      [field]: option
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setSubmitted(true);

    try {
      await updateSettings(settings);
    } catch (error) {
      return;
    } finally {
      showSuccess(`Successfully updated Global Pay settings`);
      setSubmitted(false);
    }
  };

  const valid = settings && requiredKeys.every(key => Boolean(settings[key]));

  const loading = !settings;

  return (
    <PageWrapper header="Global Pay Settings">
      <Flex col center>
        <OneHalfLayout>
          {loading ? (
            <Panel>
              <Placeholder items={1} subitems={3} center />
            </Panel>
          ) : (
            <Panel>
              <form onSubmit={handleSubmit}>
                <GlobalPayDetails
                  settings={settings}
                  onInputChange={handleInputChange}
                  onVariableInputChange={handleVariableInputChange}
                />
                <Flex mt right>
                  <Button
                    icon="save"
                    loading={submitted}
                    type="submit"
                    disabled={!valid}>
                    Save
                  </Button>
                </Flex>
              </form>
            </Panel>
          )}
        </OneHalfLayout>
      </Flex>
    </PageWrapper>
  );
};

export default GlobalPaySettings;

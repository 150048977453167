import React from 'react';

import { usePulse, useQuery } from '@ubisend/pulse-hooks';
import {
  Version,
  SecondarySidebarHeader,
  SecondarySidebarContainer,
  SecondarySidebarContent,
  Placeholder
} from '@ubisend/pulse-components';

import SidebarItems from './SidebarItems';

const Sidebar = () => {
  const { data, isLoading, isSuccess } = useQuery('docs');

  const versions = usePulse();

  return (
    <SecondarySidebarContainer>
      <SecondarySidebarHeader>
        <h1>Documentation</h1>
        <Version {...versions} />
      </SecondarySidebarHeader>
      <SecondarySidebarContent>
        {isLoading && <Placeholder ySpace />}
        {isSuccess && <SidebarItems items={data} />}
      </SecondarySidebarContent>
    </SecondarySidebarContainer>
  );
};

export default Sidebar;

import CreatedInput from './CreatedInput';

const created = {
  key: 'created',
  content: {},
  format: ({ variable }) => variable,
  Input: CreatedInput,
  valid: () => true
};

export default created;

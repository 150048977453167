import React from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  Panel,
  Explainer,
  WizardProgress
} from '@ubisend/pulse-components';

import LoginButton from './LoginButton';

const CreateWorkplaceLink = ({ loading = false }) => (
  <Panel
    divider
    header="Link your Facebook account for Workplace"
    actions={<WizardProgress steps={1} current={0} />}>
    <Flex col ySpace mt>
      <Explainer>
        Clicking the button below will open your Facebook Workplace integrations
        page.
      </Explainer>
      <LoginButton loading={loading} />
    </Flex>
  </Panel>
);

CreateWorkplaceLink.propTypes = {
  loading: PropTypes.bool
};

export default CreateWorkplaceLink;

import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useNotifySubscription } from '@ubisend/pulse-subscribe';

import { NotificationContext } from '../Contexts/index';
import { useExternallyChangableState } from '../hooks/index';

const NotificationProvider = ({ children, initialNotifications = [] }) => {
  const [notifications, setNotifications] = useExternallyChangableState(
    initialNotifications
  );

  const handleNewNotifications = useCallback(
    ({ notifications: newNotifications }) => {
      setNotifications(notifications => notifications.concat(newNotifications));
    },
    [setNotifications]
  );

  useNotifySubscription('NOTIFICATION', handleNewNotifications);

  const unreadCount = useMemo(() => {
    return notifications.filter(({ read_at }) => !read_at).length;
  }, [notifications]);

  const context = {
    notifications,
    unreadCount
  };

  return (
    <NotificationContext.Provider value={context}>
      {children}
    </NotificationContext.Provider>
  );
};

NotificationProvider.propTypes = {
  initialNotifications: PropTypes.arrayOf(
    PropTypes.shape({
      body: PropTypes.string.isRequired,
      link: PropTypes.string,
      read_at: PropTypes.string,
      created_at: PropTypes.string.isRequired
    })
  ).isRequired
};

export default NotificationProvider;

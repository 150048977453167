import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AnimatePresence } from '@ubisend/framer-motion';
import {
  Button,
  Accordion,
  Explainer,
  Span,
  InlineNotification,
  Checkbox
} from '@ubisend/pulse-components';

import FormGroup from './FormGroup';
import SettingContainer from './SettingContainer';
import { useResubscribe, useDelivery, useTheme } from '../../hooks/index';

const ResetBot = () => {
  const [confirmed, setConfirmed] = useState(false);

  const { resubscribe, isLoading, isSuccess } = useResubscribe();
  const { hasChannel } = useDelivery();
  const theme = useTheme();

  const { t } = useTranslation('bots');

  const valid = Boolean(hasChannel && !isLoading && !isSuccess);

  return (
    <SettingContainer>
      <Accordion divider header={t('reset_setting_header')}>
        <FormGroup>
          <Explainer type="warning" hideLabel>
            <Span tinyText colour="black">
              {t('reset_setting_description')}
            </Span>
          </Explainer>
          <AnimatePresence>
            {isSuccess && (
              <InlineNotification
                description={t('reset_setting_success_notification')}
                type="success"
              />
            )}
          </AnimatePresence>
          {valid && (
            <Checkbox
              checked={confirmed}
              onChange={() => setConfirmed(!confirmed)}
              label={t('reset_setting_confirmation_message')}
              colour={theme.sentMessage?.background || theme.gradient.from}
            />
          )}
          <Button
            disabled={!valid || !confirmed}
            onClick={resubscribe}
            customColour={theme.sentMessage?.background || theme.gradient.from}
            customTextColour={theme.sentMessage?.text || 'white'}>
            {t('reset_setting_submit_button')}
          </Button>
        </FormGroup>
      </Accordion>
    </SettingContainer>
  );
};

export default ResetBot;

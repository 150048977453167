import React from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import { Label, FormGroup, Select } from '@ubisend/pulse-components';

import { useTriggerStep } from '../../hooks/index';

const ConversationsSelect = ({ value, ...props }) => {
  const query = useQuery('conversations/builder');

  const format = value => ({
    value: value.id,
    label: value.title
  });

  return (
    <Select
      value={
        query.isSuccess &&
        value &&
        format(query.data.data.find(conversation => conversation.id === value))
      }
      options={query.isSuccess ? query.data.data.map(format) : []}
      isLoading={query.isLoading}
      {...props}
    />
  );
};

ConversationsSelect.propTypes = {
  value: PropTypes.number
};

const StepsSelect = ({ value, conversation, ...props }) => {
  const query = useQuery(`conversations/builder/${conversation}/steps`, {
    enabled: Boolean(conversation)
  });

  const format = value => ({
    value: value.id,
    label: value.title
  });

  return (
    <Select
      isDisabled={!conversation}
      value={
        query.isSuccess &&
        value &&
        format(query.data.data.find(step => step.id === value))
      }
      options={query.isSuccess ? query.data.data.map(format) : []}
      isLoading={query.isLoading}
      {...props}
    />
  );
};

StepsSelect.propTypes = {
  value: PropTypes.number,
  conversation: PropTypes.number
};

const StepDetails = () => {
  const { conversation_id, step_id, dispatch } = useTriggerStep();

  const handleConversationChange = conversation => {
    dispatch({
      type: 'UPDATE_CONVERSATION_ID',
      conversationId: conversation ? conversation.value : null
    });
  };

  const handleStepChange = step => {
    dispatch({
      type: 'UPDATE_STEP_ID',
      stepId: step ? step.value : null
    });
  };

  return (
    <>
      <FormGroup>
        <Label htmlFor="conversations">Conversation</Label>
        <ConversationsSelect
          id="conversations"
          value={conversation_id}
          onChange={handleConversationChange}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="steps">Step</Label>
        <StepsSelect
          id="steps"
          conversation={conversation_id}
          value={step_id}
          onChange={handleStepChange}
        />
      </FormGroup>
    </>
  );
};

export default StepDetails;

import { useState, useMemo } from 'react';

const filterDuplicatePermissions = (permission, index, self) => {
  return index === self.findIndex(({ id }) => id === permission.id);
};

const getPermissionsFromRole = (permissions, role) => {
  return permissions
    .filter(permission => {
      return role.permissions.find(
        rolePermission => rolePermission.id !== permission.id
      );
    })
    .concat(
      role.permissions.map(permission => ({
        ...permission,
        disabled: true
      }))
    );
};

const defaultParams = {
  initialRoles: [],
  initialPermissions: []
};

const useRolesAndPermissions = ({
  initialRoles = [],
  initialPermissions = []
} = defaultParams) => {
  const [roles, setRoles] = useState(initialRoles);
  const [permissions, setPermissions] = useState(initialPermissions);

  const formattedPermissions = useMemo(() => {
    return permissions
      .filter(permission => !permission.disabled)
      .map(permission => ({ ...permission, disabled: false }))
      .concat(roles.reduce(getPermissionsFromRole, []))
      .filter(filterDuplicatePermissions);
  }, [roles, permissions]);

  return {
    roles,
    setRoles,
    permissions,
    setPermissions,
    formattedPermissions
  };
};

export default useRolesAndPermissions;

import React from 'react';

import { Span } from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';

import { useComposer } from '../../hooks/index';

const ComposerSubject = () => {
  const { subject: selectedSubject, subjects: allSubjects } = useComposer();

  const subject = allSubjects.find(
    ({ subject }) => subject.type === selectedSubject.type
  );

  if (!subject.permission) {
    return <subject.Subject />;
  }

  return (
    <PermissionFilter
      can={subject.permission}
      fallback={
        <Span tinyText light>
          You do not have permission to {subject.permission}, so the remaining
          fields are hidden.
        </Span>
      }>
      <subject.Subject />
    </PermissionFilter>
  );
};

export default ComposerSubject;

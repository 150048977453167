import React from 'react';

import { AnimatePresence } from '@ubisend/framer-motion';
import { Flex } from '@ubisend/pulse-components';

import {
  Composer,
  Body,
  PoweredBy,
  BurgerMenu,
  Messages as MessagesComponent
} from '../Components/index';
import { RecordSubscriberTyping } from '../../../Components/index';
import { useDelivery, useBot, useJumpToBottom } from '../../../hooks/index';
import { ComposerProvider } from '../../../Providers/index';

const Messages = () => {
  const { sendMessage } = useDelivery();
  const { showPoweredByUbisend, showBurgerMenu } = useBot();
  const { showJump, handleScroll } = useJumpToBottom();

  return (
    <>
      <Body onScroll={handleScroll}>
        <MessagesComponent showJump={showJump} />
      </Body>
      <AnimatePresence>
        {showBurgerMenu && <BurgerMenu handleButtonClick={sendMessage} />}
      </AnimatePresence>
      <ComposerProvider>
        <RecordSubscriberTyping />
        <Composer channel="converse" />
      </ComposerProvider>
      <Flex between background="white" ptSm pbSm pl pr>
        <div id="chatbot-footer" />
        {showPoweredByUbisend && <PoweredBy />}
      </Flex>
    </>
  );
};

export default Messages;

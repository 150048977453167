import React from 'react';
import PropTypes from 'prop-types';

const NotificationWrapper = ({ link, children }) => {
  if (link) {
    return (
      <a rel="noreferrer" href={link} target="_blank">
        {children}
      </a>
    );
  }

  return <div>{children}</div>;
};

NotificationWrapper.propTypes = {
  link: PropTypes.string
};

export default NotificationWrapper;

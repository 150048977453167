import React from 'react';
import styled from 'styled-components';
import chroma from 'chroma-js';
import PropTypes from 'prop-types';

const ProgressContainer = styled.svg`
  ${tw`w-5 h-5`}
`;

const ProgressCircle = styled.circle`
  r: calc(50% - 3px);
  cx: 50%;
  cy: 50%;
  fill: none;
  stroke: ${props => props.theme.grey};
  stroke-width: 3px;
  opacity: 25%;
`;

const Progress = styled.circle`
  r: calc(50% - 3px);
  cx: 50%;
  cy: 50%;
  fill: none;
  stroke: ${({ theme, progress }) =>
    chroma.scale([theme.positive, theme.warning, theme.danger])(progress)};
  stroke-width: 3px;
  stroke-dasharray: 50;
  stroke-dashoffset: ${props => 50 - props.progress * 50};
  stroke-linecap: round;
`;

const ProgressArc = ({ progress }) => {
  return (
    <ProgressContainer>
      <ProgressCircle />
      <Progress progress={progress} />
    </ProgressContainer>
  );
};

ProgressArc.propTypes = {
  progress: PropTypes.number
};

export default ProgressArc;

import styled from 'styled-components';

import { motion } from '@ubisend/framer-motion';

const animations = {
  initial: { opacity: 0, x: 0, y: 100 },
  animate: { opacity: 1, x: 0, y: 0 },
  transition: { delay: 0.1 },
  exit: { opacity: 0, x: 0, y: 100 }
};

const Footer = styled(motion.div)`
  box-sizing: border-box;
  ${tw`bg-white shadow p-6`}
`;

export { animations };
export default Footer;

import React from 'react';
import styled from 'styled-components';

import { motion } from '@ubisend/framer-motion';

import GalleryImage from './GalleryImage';
import { useBot } from '../../../../hooks/index';

const Gallery = styled(motion.div)`
  ${tw`absolute flex items-center justify-center w-screen h-screen`}
  z-index: 1000;
  margin-top: -100%;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  margin-left: calc(384px - 100vw);
`;

const variants = {
  hide: {
    opacity: 0,
    transition: {
      duration: 0.6,
      delay: 0.4
    }
  },
  show: {
    opacity: 1,
    transition: {
      duration: 0.6
    }
  }
};

const LightBox = () => {
  const { lightBoxImage, setLightBoxImage, onLightboxClose } = useBot();

  const handleClose = () => {
    setLightBoxImage('');
    onLightboxClose();
  };

  return (
    <Gallery
      onClick={handleClose}
      initial="hide"
      animate="show"
      exit="hide"
      variants={variants}>
      <GalleryImage image={lightBoxImage} />
    </Gallery>
  );
};

export default LightBox;

import { useEffect, useState, useRef, useCallback } from 'react';

const arrowSize = 16;
const margin = 8;

const POSITIONS = {
  RIGHT: 'RIGHT',
  LEFT: 'LEFT',
  TOP: 'TOP',
  BOTTOM: 'BOTTOM'
};

const getStyles = (position, height, width, innerHeight, innerWidth) => {
  const widthDiff = width - innerWidth;

  switch (position) {
    case POSITIONS.RIGHT:
      return {
        left: innerWidth + arrowSize + margin,
        top: 0
      };
    case POSITIONS.LEFT:
      return {
        right: innerWidth + arrowSize + margin,
        top: 0
      };
    case POSITIONS.TOP:
      return {
        left: (arrowSize - widthDiff) / 2,
        bottom: innerHeight + arrowSize + margin
      };
    case POSITIONS.BOTTOM:
      return {
        left: (arrowSize - widthDiff) / 2,
        top: innerHeight + arrowSize + margin
      };
    default:
      throw new Error();
  }
};

const useTooltip = ({ visible, position, tooltip }) => {
  const [styles, setStyles] = useState({});
  const ref = useRef();

  const opacity = visible && Object.keys(styles).length > 0 ? 1 : 0;

  const setTooltipPostion = useCallback(() => {
    const [
      { offsetHeight: targetHeight, offsetWidth: targetWidth },
      { offsetHeight: tooltipHeight, offsetWidth: tooltipWidth }
    ] = ref.current.childNodes;

    const calculatedPosition = getStyles(
      position,
      tooltipHeight,
      tooltipWidth,
      targetHeight,
      targetWidth
    );
    setStyles({ opacity, ...calculatedPosition });
  }, [position, opacity]);

  useEffect(() => {
    if (!ref.current | !visible) {
      return;
    }

    setTooltipPostion();
  }, [visible, setTooltipPostion]);

  const props = {
    visible,
    position,
    tooltip,
    styles
  };

  return { ref, styles, props };
};

export default useTooltip;
export { getStyles, POSITIONS };

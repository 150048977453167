import languages from './languages';

const format = namespaces => {
  return languages.reduce((languages, language) => {
    languages[language] = {};

    for (const [namespaceKey, namespaceValues] of Object.entries(namespaces)) {
      languages[language][namespaceKey] = namespaceValues.reduce(
        (translations, translation) => {
          translations[translation.key] = translation.values.find(
            value => value.language === language
          ).value;
          return translations;
        },
        {}
      );
    }

    return languages;
  }, {});
};

export default format;

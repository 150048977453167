import React, { useState } from 'react';

import {
  Flex,
  Button,
  InfoSection,
  Panel,
  Checkbox,
  TextInput,
  Label,
  OneQuarterLayout
} from '@ubisend/pulse-components';
import { ImageSelect } from '@ubisend/pulse-files';

import { useBotSettings } from '../../hooks/index';

const FullPageGeneralSettings = () => {
  const [selectImage, setSelectImage] = useState();

  const { settings, setSettings, focusBot, handleNewImage } = useBotSettings();

  const showSelectImage = () => setSelectImage(true);

  const hideSelectImage = () => setSelectImage(false);

  const handleShowLogoToggle = () => {
    focusBot();
    setSettings(settings => ({
      ...settings,
      logo_enabled: !settings.logo_enabled
    }));
  };

  const handleImageChange = file => {
    handleNewImage(file);
    hideSelectImage();
  };

  const handleLogoLinkChange = event => {
    const value = event.target.value;

    setSettings(settings => ({ ...settings, logo_link: value }));
  };

  return (
    <Flex>
      <OneQuarterLayout>
        <InfoSection title="Logo" info="Add a logo to your bot." />
      </OneQuarterLayout>
      <Panel>
        {selectImage && (
          <ImageSelect
            handleCancel={hideSelectImage}
            handleFileSelect={handleImageChange}
          />
        )}
        <Flex col ySpace>
          <Checkbox
            checked={settings.logo_enabled}
            onChange={handleShowLogoToggle}
            label="Show chatbot logo"
          />
          <Button
            selfTop
            disabled={!settings.logo_enabled}
            variant="secondary"
            onClick={showSelectImage}>
            Change Logo
          </Button>
          <Label htmlFor="logo_link">Logo link</Label>
          <TextInput
            disabled={!settings.logo_enabled}
            id="logo_link"
            aria-label="logo link input"
            placeholder="https://website.com"
            value={settings.logo_link}
            onChange={handleLogoLinkChange}
          />
        </Flex>
      </Panel>
    </Flex>
  );
};

export default FullPageGeneralSettings;

import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Button } from '@ubisend/pulse-components';

import { useTemplatingReducer } from '../../../reducers/index';
import { TemplatingContext } from '../../../Contexts/index';
import SyntaxCheck from './SyntaxCheck';
import LineNumbers from './LineNumbers';
import CodeEditor from './CodeEditor';
import AddTag from './AddTag';
import VariableModal, {
  filters as allFilters
} from '../../VariableModal/index';
import ExternalStateSync from '../ExternalStateSync';
import InputFocus from '../InputFocus';

const TemplatingCodeEditor = ({
  value: externalValue,
  onChange: setExternalValue,
  tags = [],
  filters = allFilters.default,
  showLineNumbers = true,
  ...props
}) => {
  const templating = useTemplatingReducer({
    tags,
    value: externalValue
  });

  const handleShowVariables = () => {
    templating.dispatch({ type: templating.TYPES.SHOW_VARIABLES });
  };

  return (
    <TemplatingContext.Provider value={templating}>
      <ExternalStateSync
        externalValue={externalValue}
        setExternalValue={setExternalValue}
      />
      <InputFocus />
      {templating.showVariables && <VariableModal filters={filters} />}
      <Flex mbSm xSpaceSm>
        <Button
          variant="secondary"
          icon="variable"
          onClick={handleShowVariables}>
          Variable
        </Button>
        {tags.length > 0 && tags.map(tag => <AddTag key={tag.id} tag={tag} />)}
      </Flex>
      {showLineNumbers && <SyntaxCheck />}
      <Flex>
        {showLineNumbers && <LineNumbers />}
        <CodeEditor {...props} />
      </Flex>
    </TemplatingContext.Provider>
  );
};

TemplatingCodeEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  ),
  filters: PropTypes.arrayOf(PropTypes.object),
  showLineNumbers: PropTypes.bool.isRequired
};

export default TemplatingCodeEditor;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from '@ubisend/pulse-icons';

import { Flex } from '../Layout/index';

const Span = styled.span`
  ${tw`text-sm`}
`;

const BuilderTitle = ({ title, icon = 'chat' }) => (
  <Flex center xSpaceSm>
    <Icon type={icon} colour={'black'} size={'1.5rem'} height={'1.5rem'} />
    <Span>{title}</Span>
  </Flex>
);

BuilderTitle.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string
};

export default BuilderTitle;

import React from 'react';

import { Select } from '@ubisend/pulse-components';

import { useConditional } from '../hooks/index';

const matches = [
  { value: 'any', label: 'Any' },
  { value: 'all', label: 'All' }
];

const MatchSelect = props => {
  const { match, handleMatchChange } = useConditional();

  return (
    <Select
      aria-label="match-select"
      onChange={handleMatchChange}
      value={matches.find(({ value }) => value === match)}
      options={matches}
      {...props}
    />
  );
};

export default MatchSelect;

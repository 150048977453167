const getConfig = () => {
  const client = 'ubisend-bots';

  const identifier = 'zcoILccnkO';

  const socketServer = 'https://ubisend-bots-bot-server.ubisendaws.com';

  const channelKey = `${identifier}-channel`;

  const seenBannerKey = `${identifier}-seen-banner`;

  const baseUrl = 'https://bots-api.ubisend.io';

  return {
    client,
    identifier,
    socketServer,
    channelKey,
    seenBannerKey,
    baseUrl
  };
};

export default getConfig;

import React from 'react';
import styled from 'styled-components';

import { AnimatePresence } from '@ubisend/framer-motion';

const StyledBody = styled.div`
  ${tw`p-4 overflow-y-auto h-full relative`};
`;

const Body = ({ children, ...rest }) => (
  <AnimatePresence initial={false}>
    <StyledBody {...rest}>{children}</StyledBody>
  </AnimatePresence>
);

export default Body;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { motion } from '@ubisend/framer-motion';

import { useTickets } from '../../../hooks/index';

const TicketContainer = styled(motion.div)`
  ${tw`overflow-y-auto overflow-x-hidden h-full bg-white`};
`;

const Ticket = styled(motion.div)`
  ${tw`p-4`}
`;

const TicketNameContainer = styled.div`
  ${tw`mb-2 flex justify-between items-center`}
`;

const TicketName = styled.h3`
  ${tw`m-0 mr-4`}
`;

const TicketStatusContainer = styled.div`
  ${tw`flex items-center rounded-full rounded-full px-2 py-1`}
  ${props => {
    const statuses = {
      submitted: 'rgba(230, 40, 81, 0.5)',
      in_progress: 'rgba(255, 194, 93, 0.5)',
      resolved: 'rgba(59, 182, 161, 0.5)'
    };

    return `background-color: ${statuses[props.status]};`;
  }}
`;

const TicketStatus = styled.span`
  ${tw`uppercase text-xs font-semibold text-black`}
`;

const Indicator = styled.span`
  ${tw`w-2 h-2 block rounded-full mr-2 bg-black`}
`;

const TicketDescriptionParagraph = styled.p`
  ${tw`text-sm my-1 break-words`}
`;

const TicketDescriptionContainer = styled.div``;

const TicketDivider = styled.hr`
  ${tw`m-0 border-grey-medium border-0 border-t w-full border-solid`}
`;

const ButtonContainer = styled.div`
  ${tw`flex justify-center`}
`;

const Button = styled.button`
  ${tw`border-0 bg-white cursor-pointer w-6 h-6`}
  & > svg {
    ${tw`w-6 h-6`}
    pointer-events: none;
  }
`;

const CheveronButton = ({ direction, ...props }) => {
  return (
    <Button {...props}>
      {direction === 'up' ? (
        <svg
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
          {...props}>
          <path
            fillRule="evenodd"
            d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
            clipRule="evenodd"
          />
        </svg>
      ) : (
        <svg
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
          {...props}>
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      )}
    </Button>
  );
};

CheveronButton.propTypes = {
  direction: PropTypes.oneOf(['up', 'down'])
};

const TicketDescription = ({ children }) => {
  const [showMore, setShowMore] = useState(false);
  const { t } = useTranslation('bots');

  const MAX_DESCRIPTION_LENGTH = 200;

  const descriptionIsBig = children.length > MAX_DESCRIPTION_LENGTH;

  const description =
    descriptionIsBig && !showMore
      ? `${children.slice(0, MAX_DESCRIPTION_LENGTH)}...`
      : children;

  const handleShowMore = () => {
    setShowMore(showMore => !showMore);
  };

  return (
    <TicketDescriptionContainer>
      <TicketDescriptionParagraph>{description}</TicketDescriptionParagraph>
      {descriptionIsBig && (
        <ButtonContainer>
          <CheveronButton
            aria-label={t('expand_ticket_description_button_label')}
            onClick={handleShowMore}
            direction={showMore ? 'up' : 'down'}
          />
        </ButtonContainer>
      )}
    </TicketDescriptionContainer>
  );
};

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05
    }
  }
};

const item = {
  hidden: { opacity: 0, x: -100 },
  show: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.4,
      ease: [0.25, 1, 0.5, 1]
    }
  }
};

const Tickets = () => {
  const { tickets } = useTickets();

  const { t } = useTranslation('bots');

  const statuses = {
    submitted: t('reported_ticket_status'),
    in_progress: t('in_progress_ticket_status'),
    resolved: t('resolved_ticket_status')
  };

  return (
    <>
      <TicketContainer
        data-testid="tickets"
        initial="hidden"
        animate="show"
        variants={container}>
        {tickets.map((ticket, key) => (
          <React.Fragment key={key}>
            <Ticket variants={item} data-testid="ticket">
              <TicketNameContainer>
                <TicketName>{ticket.name}</TicketName>
                <TicketStatusContainer status={ticket.status}>
                  <Indicator status={ticket.status} />
                  <TicketStatus status={ticket.status}>
                    {statuses[ticket.status]}
                  </TicketStatus>
                </TicketStatusContainer>
              </TicketNameContainer>
              <TicketDescription>{ticket.description}</TicketDescription>
            </Ticket>
            <TicketDivider />
          </React.Fragment>
        ))}
      </TicketContainer>
    </>
  );
};

export default Tickets;

import React, {
  useRef,
  useEffect,
  useMemo,
  useCallback,
  useLayoutEffect
} from 'react';
import PropTypes from 'prop-types';

import { AnimatePresence } from '@ubisend/framer-motion';

import { useDelivery, useBot } from '../../../hooks/index';
import { scroll, groupMessages } from '../../../utilities/index';
import {
  DefaultMessageWrapper,
  DefaultGroupWrapper
} from '../../../Providers/BotProvider';
import Message from './Message';
import MessageGroup from './MessageGroup';
import MessageContainer from './MessageContainer';
import JumpToBottom from './JumpToBottom';
import Typing from './Typing';
import TypingIndicator from './TypingIndicator';

const Messages = ({ showJump }) => {
  const bottomRef = useRef(null);

  const {
    messages,
    MessageWrapper: PassedMessageWrapper,
    GroupWrapper: PassedGroupWrapper
  } = useBot();
  const { sendMessage } = useDelivery();

  const MessageWrapper = PassedMessageWrapper || DefaultMessageWrapper;
  const GroupWrapper = PassedGroupWrapper || DefaultGroupWrapper;

  const groups = useMemo(() => {
    return messages.length > 0 && messages.reduce(groupMessages, []);
  }, [messages]);

  const scrollToBottom = useCallback(() => scroll(bottomRef.current), []);

  const snapToBottom = useCallback(() => scroll(bottomRef.current), []);

  /**
   * Scroll to bottom on every message send and receive.
   */
  useEffect(() => {
    if (messages && bottomRef.current) {
      scrollToBottom();
    }
  }, [messages, scrollToBottom]);

  /**
   * Snap to bottom on render.
   */
  useLayoutEffect(() => {
    if (bottomRef.current) {
      snapToBottom();
    }
  }, [snapToBottom]);

  return (
    <>
      <AnimatePresence>
        {showJump && <JumpToBottom onClick={scrollToBottom} />}
      </AnimatePresence>
      {!groups && (
        <MessageContainer>
          <TypingIndicator />
        </MessageContainer>
      )}
      {groups &&
        groups.map((messages, groupIndex) => (
          <GroupWrapper key={groupIndex} messages={messages} index={groupIndex}>
            <MessageGroup
              group={messages}
              previousGroup={groups[groupIndex - 1]}>
              {messages.map((message, messageKey) => {
                return (
                  <MessageWrapper
                    index={messageKey}
                    message={message}
                    label={`widget-message-${groupIndex}-${messageKey}`}
                    aria-label={`widget-message-${groupIndex}-${messageKey}`}
                    key={`${groupIndex}-${messageKey}`}>
                    <Message
                      label={`${
                        message.direction === 'toServer'
                          ? 'Message from user: '
                          : 'Message from chatbot: '
                      } ${message.content.text}`}
                      handleButtonClick={sendMessage}
                      groupIndex={groupIndex}
                      group={messages}
                      groups={groups}
                      message={message}
                    />
                  </MessageWrapper>
                );
              })}
            </MessageGroup>
          </GroupWrapper>
        ))}
      <Typing scrollToBottom={scrollToBottom} />
      <div ref={bottomRef} />
    </>
  );
};

Messages.propTypes = {
  showJump: PropTypes.bool
};

export default Messages;

import React from 'react';
import styled, { keyframes } from 'styled-components';
import chroma from 'chroma-js';
import PropTypes from 'prop-types';

import { Placeholder, Flex } from '@ubisend/pulse-components';

import Header from './Header';

const slideDown = keyframes`
0%{
  transform: translateY(-100%);
}
100%{
  transform: translateY(0);
}
`;

const flash = keyframes`
0%{
  opacity: 0.5;
}
50%{
  opacity: 0.75;
}
100%{
  opacity: 0.5;
}
`;

const fadeIn = keyframes`
0%{
  opacity: 0;
}
100%{
  opacity: 1;
}
`;

const LoadingContainer = styled.div`
  ${tw`flex justify-between flex-col`}
  height: calc(100% - 1rem);
  animation-fill-mode: forwards;
  & > *:first-child {
    animation: ${slideDown} cubic-bezier(0.16, 1, 0.3, 1) 0.4s;
  }
  & > *:last-child {
    animation: ${fadeIn} cubic-bezier(0.16, 1, 0.3, 1) 1s;
  }
`;

const DatePlaceholderContainer = styled.div`
  ${tw`w-full flex justify-center`}
  & > * {
    max-width: 150px;
  }
`;

const PlaceholderContainer = styled.div`
  max-width: 16rem;
  width: 100%;
`;

const PlaceholderLogo = styled.div`
  ${tw`mr-8`}
  width: 40px;
  height: 40px;
  border-radius: 0.25rem;
  background: ${props => chroma(props.colour).alpha(0.5)};
  animation: ${flash} infinite cubic-bezier(0.76, 0, 0.24, 1) 1s;
`;

const Loading = ({ styles }) => {
  return (
    <LoadingContainer>
      <Header>
        <Flex center>
          <PlaceholderLogo colour={styles.textColour} />
          <PlaceholderContainer>
            <Placeholder colour={styles.textColour} items={1} subitems={1} />
          </PlaceholderContainer>
        </Flex>
      </Header>
      <Flex pad grow>
        <DatePlaceholderContainer>
          <Placeholder items={1} subitems={1} />
        </DatePlaceholderContainer>
      </Flex>
    </LoadingContainer>
  );
};

Loading.propTypes = {
  styles: PropTypes.shape({
    textColour: PropTypes.string
  })
};

export default Loading;

import React from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import { Flex } from '@ubisend/pulse-components';
import { AppWidget } from '@ubisend/pulse-bot';

const Conversation = ({ subscriber }) => {
  const { data, isSuccess } = useQuery(`subscribers/${subscriber.id}/messages`);

  if (!isSuccess) {
    return null;
  }

  return (
    <Flex fat middle>
      <AppWidget
        name={`Conversation #${subscriber.id}`}
        messages={data.data.map(({ message, direction, type }) => ({
          ...message,
          composer: { type: 'hidden' },
          content: message,
          direction: direction === 'incoming' ? 'toServer' : 'toClient',
          type
        }))}
      />
    </Flex>
  );
};

Conversation.propTypes = {
  subscriber: PropTypes.object
};

export default Conversation;

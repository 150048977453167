import React from 'react';

import { Label, FormGroup } from '@ubisend/pulse-components';
import { TemplatingTextArea } from '@ubisend/pulse-templating';

import { useComposer } from '../../../../hooks/index';
import { ButtonComposer } from '../../../Buttons/index';

const QuickReplyInput = () => {
  const {
    response: { content },
    dispatch
  } = useComposer();

  const handleResponseChange = value => {
    dispatch({
      type: 'UPDATE_CONTENT',
      newContent: { text: value, responses: content.responses }
    });
  };

  const handleResponsesChange = responses => {
    dispatch({
      type: 'UPDATE_CONTENT',
      newContent: {
        text: content.text,
        responses: responses.map(response => ({
          label: response.label,
          postback: response.action
        }))
      }
    });
  };

  return (
    <div>
      <FormGroup>
        <Label htmlFor="message">Message</Label>
        <TemplatingTextArea
          id="message"
          name="message"
          value={content.text}
          onChange={handleResponseChange}
        />
      </FormGroup>
      <ButtonComposer
        only={['postback']}
        value={content.responses.map(response => ({
          label: response.label,
          action: response.postback
        }))}
        onChange={handleResponsesChange}
      />
    </div>
  );
};

export default QuickReplyInput;

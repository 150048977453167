import { useState, useEffect } from 'react';
import { diffSentences } from 'diff';

const useDiff = (before, after) => {
  const [diff, setDiff] = useState(null);

  useEffect(() => {
    diffSentences(before, after, function (err, result) {
      setDiff(result);
    });
  }, [before, after]);

  return { diff, loading: !diff };
};

export default useDiff;

import React from 'react';
import PropTypes from 'prop-types';

import { TextInput, Label } from '@ubisend/pulse-components';

const ExtractInput = ({ content, onChange }) => {
  const handleChange = event => {
    const value = event.target.value;

    onChange({ value });
  };

  return (
    <div>
      <Label htmlFor="format">Value to extract</Label>
      <TextInput
        id="format"
        placeholder="name"
        value={content.value}
        onChange={handleChange}
      />
    </div>
  );
};

ExtractInput.propTypes = {
  content: PropTypes.shape({
    value: PropTypes.string.isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired
};

export default ExtractInput;

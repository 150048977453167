import axios from 'axios';

const createComposer = params => axios.post('composers', params);

const updateComposer = ([id, params]) => {
  return axios.put(`composers/${id}`, params);
};

const deleteComposer = id => axios.delete(`composers/${id}`);

const getComposerTypes = () => axios.get('composers/types');

export { createComposer, updateComposer, deleteComposer, getComposerTypes };

import React from 'react';

import TableCell from './TableCell';
import Flex from '../Layout/Flex';

const TableActions = ({ children, ...rest }) => (
  <TableCell {...rest}>
    <Flex xSpace right middle center>
      {children}
    </Flex>
  </TableCell>
);

export default TableActions;

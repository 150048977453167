import { useContext } from 'react';

import { PulseContext } from '../Contexts/index';

const usePulse = () => {
  const context = useContext(PulseContext);

  return context;
};

export default usePulse;

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Animation = styled.svg`
  animation: rotate-1 ease 4s infinite;
  overflow: hidden;
  width: ${props => (props.size ? props.size : '72px')};
  height: ${props => (props.size ? props.size : '72px')};

  & circle:first-child {
    fill: ${({ theme, firstColour }) => firstColour || theme.primary};
    animation: dot-1-move 2s cubic-bezier(0.175, 0.885, 0.2, 1.175) infinite;
  }
  & circle:last-child {
    fill: ${({ theme, secondColour }) => secondColour || theme.secondary};
    animation: dot-2-move 2s cubic-bezier(0.175, 0.885, 0.32, 1.175) infinite;
  }
  @keyframes dot-2-move {
    0%,
    66%,
    100% {
      transform: translateX(0);
    }
    33% {
      transform: translateX(-30px);
    }
  }
  @keyframes dot-1-move {
    0%,
    66%,
    100% {
      transform: translateX(0%);
    }
    33% {
      transform: translateX(30px);
    }
  }
  @keyframes rotate-1 {
    5% {
      transform: rotate(0deg);
    }
    22.5%,
    40% {
      transform: rotate(90deg);
    }
    55% {
      transform: rotate(180deg);
    }
    72.5%,
    90%,
    100% {
      transform: rotate(450deg);
    }
  }
`;

const Spinner = ({ size, firstColour, secondColour }) => (
  <Animation
    size={size}
    firstColour={firstColour}
    secondColour={secondColour}
    viewBox="0 0 100 100">
    <filter id="gooey" colorInterpolationFilters="sRGB">
      <feGaussianBlur stdDeviation="7.5" />
      <feComponentTransfer>
        <feFuncA type="linear" slope="100" intercept="-50" />
      </feComponentTransfer>
    </filter>
    <g filter="url(#gooey)">
      <circle cx="50%" cy="50%" r="15" />
      <circle cx="50%" cy="50%" r="15" />
    </g>
  </Animation>
);

Spinner.propTypes = {
  /** Size of the spinner */
  size: PropTypes.string,
  /** Colour of the first circle */
  firstColour: PropTypes.string,
  /** Colour of the second circle */
  secondColour: PropTypes.string
};

Spinner.defaultProps = {
  size: '72px'
};

export default Spinner;

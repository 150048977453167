import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import chroma from 'chroma-js';

import { motion } from '@ubisend/framer-motion';
import { pseudoStyles } from '@ubisend/pulse-components';

const getGradient = (colour, darken = 0.2) => {
  if (!colour) {
    return;
  }
  return `linear-gradient(to right bottom, 
    ${chroma(colour).brighten(darken)},${chroma(colour).darken(darken)})`;
};

const StyledBubble = styled(motion.div)`
  ${tw`flex flex-col text-sm whitespace-pre-wrap`};
  border-radius: 0.675rem;
  box-shadow: ${props => {
      return chroma(
        props.theme?.bot[props.direction].background || props.theme.primary
      )
        .darken()
        .desaturate()
        .alpha(0.25);
    }}
    0px 8px 12px -4px;
  padding: 0.625rem 0.75rem;
  color: ${props => props.theme.bot[props.direction].text};
  background-color: ${props => {
    return props.theme?.bot[props.direction].background || props.theme.primary;
  }};
  background-image: ${props => {
    return getGradient(props.theme.bot[props.direction].background);
  }};
  width: fit-content;
  min-width: 0;
  max-width: 75%;
  overflow-wrap: break-word;
  overflow: hidden;
  min-height: 1rem;
  ${pseudoStyles};
`;

const Bubble = ({ children, direction, ...props }) => (
  <StyledBubble
    data-testid="bubble"
    direction={direction}
    style={{ originX: direction === 'sentMessage' ? 0 : 1, originY: 1 }}
    initial={{ scale: 0 }}
    animate={{ scale: 1 }}
    transition={{ type: 'spring', stiffness: 400, damping: 50 }}
    {...props}>
    {children}
  </StyledBubble>
);

Bubble.propTypes = {
  direction: PropTypes.oneOf(['recievedMessage', 'sentMessage'])
};
Bubble.defaultProps = {
  tabIndex: 0
};

export default Bubble;

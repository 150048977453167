import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, Flex } from '@ubisend/pulse-components';
import Icon from '@ubisend/pulse-icons';

import { useTheme } from '../../../../../hooks/index';

const BannerComposer = () => {
  const history = useHistory();
  const theme = useTheme();

  const { t } = useTranslation('bots');

  const handleReplyClick = () => {
    history.push('/window/body/messages');
  };

  return (
    <Flex background="white" pad>
      <Button
        variant="secondary"
        customColour={theme.sentMessage.background}
        autoHeight
        fat
        middle
        rounded
        smallText
        onClick={handleReplyClick}>
        <Flex xSpace padSm>
          <Flex center>{t('banner_composer_submit_button')}</Flex>
          <Icon
            type="airplane"
            outline
            rotation={90}
            height="1rem"
            width="1rem"
            size="1rem"
          />
        </Flex>
      </Button>
    </Flex>
  );
};

export default BannerComposer;

import React, { useState } from 'react';

import { ComposerContext } from '../Contexts/index';

const ComposerProvider = ({ children }) => {
  const [reply, setReply] = useState('');

  const context = {
    reply,
    setReply
  };

  return (
    <ComposerContext.Provider value={context}>
      {children}
    </ComposerContext.Provider>
  );
};

export default ComposerProvider;

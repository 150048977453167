import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import types from './Types/index';

const FilePreview = ({ type, children, ...props }) => {
  const { Preview } = useMemo(() => types[type], [type]);

  return <Preview {...props} />;
};

FilePreview.propTypes = {
  type: PropTypes.string
};

export default FilePreview;

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import RootSidebarItem, { hasActiveItem } from './RootSidebarItem';

const SidebarItems = ({ items }) => {
  const { pathname } = useLocation();

  const [open, setOpen] = useState(
    items.findIndex(item => hasActiveItem(pathname, item.children))
  );

  const handleOpen = key => () => {
    if (open === key) {
      setOpen(null);
    } else {
      setOpen(key);
    }
  };

  return items.map((item, key) => (
    <RootSidebarItem
      key={key}
      items={item.children}
      label={item.label}
      to={item.url}
      open={open === key}
      handleOpen={handleOpen(key)}
    />
  ));
};

export default SidebarItems;

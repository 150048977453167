import { useState, useRef, useEffect, useCallback } from 'react';

const useExternallyChangableState = (initialState, passedCompare) => {
  const initialStateRef = useRef(initialState);

  const [state, setState] = useState(initialState);

  const defaultCompare = useCallback((ref, initialState) => {
    return ref !== initialState;
  }, []);

  useEffect(() => {
    const compare = passedCompare || defaultCompare;

    if (compare(initialStateRef.current, initialState)) {
      initialStateRef.current = initialState;
      setState(initialState);
    }
  }, [initialState, passedCompare, defaultCompare]);

  return [state, setState];
};

export default useExternallyChangableState;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Paragraph } from '../Text/index';
import { NoResultsGraphic } from '../Graphics/index';

const NoResultsContainer = styled.div`
  ${tw`py-8 w-full`}

  svg {
    ${tw`w-48 p-4 mx-auto block`};
  }
  h2 {
    ${tw`text-center w-full mt-2 font-semibold`};
  }
  p {
    ${tw`text-center`};
  }
`;

const NoResults = ({ text = 'Nothing to see here...', subtitle }) => {
  return (
    <NoResultsContainer>
      <NoResultsGraphic />
      {text && <h2>{text}</h2>}
      {subtitle && <Paragraph secondary>{subtitle}</Paragraph>}
    </NoResultsContainer>
  );
};

NoResults.propTypes = {
  text: PropTypes.string,
  subtitle: PropTypes.string
};

export default NoResults;

import { useLocations } from '../hooks/index';

const LocationsConsumer = ({ children }) => {
  const { hasLocations } = useLocations();

  if (!hasLocations) {
    return null;
  }

  return children;
};

export default LocationsConsumer;

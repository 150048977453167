import { useState } from 'react';

const defaultRequest = { endpoint: '', method: 'get' };

const useRequest = ({
  endpoint: defaultEndpoint,
  method: defaultMethod
} = defaultRequest) => {
  const [method, setMethod] = useState(defaultMethod);
  const [endpoint, setEndpoint] = useState(defaultEndpoint);

  const resetRequest = () => {
    setMethod(defaultMethod);
    setEndpoint(defaultEndpoint);
  };

  const valid = Boolean(method && endpoint && endpoint.length > 0);

  const form = {
    method,
    endpoint
  };

  return {
    method,
    setMethod,
    endpoint,
    setEndpoint,
    form,
    valid,
    resetRequest
  };
};

export default useRequest;

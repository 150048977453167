import { hasPermission } from '@ubisend/pulse-auth';

import { Logs, Log } from './Pages/index';

const routes = [
  {
    path: '/logs',
    component: Logs,
    name: 'Logs',
    canAccess: hasPermission('view logs'),
    actions: [
      {
        title: 'View conversation logs',
        description:
          'Troubleshoot your chatbot with success, warnings, and error logs.',
        icon: 'informationCircle'
      }
    ]
  },
  {
    path: '/logs/:id',
    component: Log,
    name: 'Log Details',
    canAccess: hasPermission('view logs')
  }
];

export default routes;

import styled from 'styled-components';

import Panel from './Panel';
import { active, spacing } from '../styles';

const InnerPanel = styled(Panel)`
  box-sizing: border-box;
  box-shadow: none;
  ${active}
  ${spacing}
`;

export default InnerPanel;

import React, { useState } from 'react';

import { useDelivery } from '../../../../../../hooks/index';
import EmailComposer from './EmailComposer';
import PasswordComposer from './PasswordComposer';

const AuthComposer = () => {
  const [step, setStep] = useState('EMAIL');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { sendMessage } = useDelivery();

  const handleEmailChange = event => setEmail(event.target.value);

  const handleEmailSubmit = () => {
    setStep('PASSWORD');
  };

  const handlePasswordChange = event => setPassword(event.target.value);

  const handlePasswordSubmit = () => {
    sendMessage(
      `Authentication details`,
      `user(${email}) password(${password})`
    );
    setEmail('');
    setPassword('');
    setStep('EMAIL');
  };

  if (step === 'EMAIL') {
    return (
      <EmailComposer
        value={email}
        onChange={handleEmailChange}
        handleSubmit={handleEmailSubmit}
      />
    );
  }

  return (
    <PasswordComposer
      value={password}
      onChange={handlePasswordChange}
      handleSubmit={handlePasswordSubmit}
    />
  );
};

export default AuthComposer;

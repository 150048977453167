import {
  BrowserConditional,
  BrowserPreview
} from '../Components/Subjects/index';

const subject = { id: null, type: 'browser' };

const conditional = {
  subject,
  type: null,
  condition: null,
  flipped: false,
  case_sensitive: false,
  target: ''
};

const browserSubject = {
  subject,
  conditional,
  name: "User's Browser",
  Conditional: BrowserConditional,
  Preview: BrowserPreview,
  toState: () => subject,
  toApi: () => subject
};

export default browserSubject;

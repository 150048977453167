import React from 'react';
import PropTypes from 'prop-types';

import { Panel, InnerPanel } from '@ubisend/pulse-components';
import { Markdown } from '@ubisend/pulse-markdown';

const ManualImportPreview = ({ value }) => (
  <Panel header="Content">
    <InnerPanel style={{ maxHeight: '60vh', overflowY: 'auto' }}>
      <Markdown>{value}</Markdown>
    </InnerPanel>
  </Panel>
);

ManualImportPreview.propTypes = {
  value: PropTypes.string.isRequired
};

export default ManualImportPreview;

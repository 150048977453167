import { useContext } from 'react';

import { WizardContext } from '../Contexts/index';

const useGandalf = () => {
  const context = useContext(WizardContext);

  return context;
};

export default useGandalf;

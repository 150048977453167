import React, { useState, useEffect } from 'react';

import {
  PageWrapper,
  OneHalfLayout,
  Flex,
  useNotification,
  Placeholder,
  Panel
} from '@ubisend/pulse-components';

import { FacebookSettings, SetupFacebookComponent } from '../Components/index';
import { FacebookProvider } from '../Providers';
import { updateSettings, getSettings } from '../api/settings';

const FacebookLink = () => {
  const [loading, setLoading] = useState(true);
  const [pageSettings, setPageSettings] = useState();
  const { showSuccess, showError } = useNotification();

  const fetchSettings = async () => {
    const {
      data: { data }
    } = await getSettings();

    setPageSettings(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const handlePageSelect = async page => {
    const {
      value: { id, access_token },
      label
    } = page;

    const response = await updateSettings({
      page_id: id,
      auth_token: access_token,
      page_name: label
    });

    if (response?.status === 201) {
      showSuccess(`Linked Facebook page ${label}`);
      fetchSettings();
    } else {
      showError(`Something went wrong whilst selecting a Facebook page`);
    }
  };

  const handleUnlinkPage = () => setPageSettings(null);

  const hasSettings = Boolean(pageSettings?.auth_token);

  return (
    <FacebookProvider>
      <PageWrapper center header="Facebook Messenger">
        <Flex col center>
          <OneHalfLayout>
            {loading ? (
              <Panel>
                <Placeholder items={1} subitems={3} center />
              </Panel>
            ) : (
              <Flex mr>
                {!hasSettings && (
                  <SetupFacebookComponent handlePageSelect={handlePageSelect} />
                )}
                {hasSettings && (
                  <FacebookSettings
                    settings={pageSettings}
                    handleUnlinkPage={handleUnlinkPage}
                  />
                )}
              </Flex>
            )}
          </OneHalfLayout>
        </Flex>
      </PageWrapper>
    </FacebookProvider>
  );
};

export default FacebookLink;

import { formatClientMessage, formatServerMessage } from './messages';

const appendMessage = newMessage => messages => {
  return messages.concat(
    formatClientMessage({ content: { text: newMessage } })
  );
};

/**
 * Map over an array of responses, sorting them into
 * client and server messages.
 * @param  Array responses
 */
const createInitialMessages = responses => {
  return responses.map(response => {
    return response.direction === 'outgoing'
      ? formatServerMessage(response)
      : formatClientMessage(response);
  });
};

const formatMessages = createInitialMessages;

/**
 * If a message already exists in state, replace the values.
 */
const replaceMessage = newMessages => existingMessage => {
  const updatedMessage = newMessages
    .filter(({ id }) => id)
    .find(({ id }) => id === existingMessage.id);

  if (updatedMessage) {
    return updatedMessage;
  }

  return existingMessage;
};

/**
 * Append a message from the server to state.
 * @param  Array newMessages
 */
const appendServerMessages = newMessages => messages => {
  return messages.concat(newMessages.responses.map(formatServerMessage));
};

export {
  appendMessage,
  createInitialMessages,
  formatMessages,
  appendServerMessages,
  replaceMessage
};

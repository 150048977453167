import React, { useState } from 'react';

import { useAuth } from '@ubisend/pulse-auth';
import {
  PageWrapper,
  Button,
  Flex,
  ButtonSelect,
  useNotification
} from '@ubisend/pulse-components';
import { useCopyToClipboard } from '@ubisend/pulse-component-hooks';

import { Demo, TriggerStepModal } from '../Components/index';
import { TriggerStepProvider } from '../Providers/index';
import { useSetupDemo } from '../hooks/index';

const bots = [
  {
    label: 'Converse',
    value: 'converse'
  },
  {
    label: 'Embedded',
    value: 'embed'
  },
  {
    label: 'Full Page',
    value: 'full-page'
  }
];

const getDefaultBot = () => {
  const bot = localStorage.getItem('demo_type');

  if (!bot) {
    return bots[0];
  }

  return bots.find(({ value }) => bot === value);
};

const DemoPage = () => {
  const [bot, setBot] = useState(getDefaultBot());
  const [triggerStep, setTriggerStep] = useState(false);

  const { copy, copied } = useCopyToClipboard();
  const { showSuccess } = useNotification();
  const { client } = useAuth();

  const mutation = useSetupDemo({
    onSuccess: () => {
      showSuccess('Successfully triggered step');
      setTriggerStep(false);
    }
  });

  const publicDemoUrl = `${window.location.host}/demo/public?hash=${process.env.REACT_APP_DEMO_HASH}&client=${client.identifier}&channel=${bot.value}`;

  const handleTypeSelect = ({ value, label }) => {
    setBot({ value, label });
    localStorage.setItem('demo_type', value);
  };

  const handleTriggerStepOpen = () => {
    setTriggerStep(true);
  };

  const handleTriggerStepClose = () => {
    setTriggerStep(false);
  };

  const handleTriggerStepSubmit = form => {
    mutation.mutate(form);
  };

  return (
    <PageWrapper
      header="Demo"
      subheader="Test your chatbot"
      actions={
        <Flex xSpace>
          <ButtonSelect
            aria-label="bots"
            options={bots}
            value={bot}
            onChange={handleTypeSelect}
          />
          <Button variant="secondary" onClick={handleTriggerStepOpen}>
            Trigger step
          </Button>
          <Button
            variant="secondary"
            icon="clipboardCopy"
            onClick={() => copy(publicDemoUrl)}>
            {copied ? 'Copied' : 'Public demo'}
          </Button>
        </Flex>
      }>
      {triggerStep && (
        <TriggerStepProvider>
          <TriggerStepModal
            handleClose={handleTriggerStepClose}
            handleSubmit={handleTriggerStepSubmit}
            loading={mutation.isLoading}
          />
        </TriggerStepProvider>
      )}
      {!mutation.isLoading && (
        <Demo
          bot={bot.value}
          client={client.identifier}
          isPublic={false}
          token={mutation.data?.data.token}
        />
      )}
    </PageWrapper>
  );
};

export default DemoPage;

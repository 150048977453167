import React from 'react';

import { Flex } from '@ubisend/pulse-components';
import { IntegrationSelect, EndpointSelect } from '@ubisend/pulse-integrations';

import { useComposer } from '../../../hooks/index';

const IntegrationSubject = () => {
  const composer = useComposer();

  const handleIntegrationChange = option => {
    composer.dispatch({
      type: composer.TYPES.UPDATE_SUBJECT,
      subject: {
        integration_id: option ? option.value : null,
        endpoint_id: null
      }
    });
  };

  const handleEndpointChange = ({ value }) => {
    composer.dispatch({
      type: composer.TYPES.UPDATE_SUBJECT,
      subject: { endpoint_id: value }
    });
  };

  return (
    <Flex xSpace fat>
      <Flex fat>
        <IntegrationSelect
          aria-label="Integrations"
          value={composer.subject.integration_id}
          onChange={handleIntegrationChange}
        />
      </Flex>
      <Flex fat>
        <EndpointSelect
          aria-label="Endpoints"
          value={composer.subject.endpoint_id}
          integration={composer.subject.integration_id}
          onChange={handleEndpointChange}
        />
      </Flex>
    </Flex>
  );
};

export default IntegrationSubject;

import axios from 'axios';

const sendMessage = ({ subscriberId, ...params }) => {
  return axios.post(`/subscribers/${subscriberId}/messages`, params);
};

const sendFile = ({ subscriberId, ...params }) => {
  return axios.post(`/subscribers/${subscriberId}/files`, params);
};

export { sendMessage, sendFile };

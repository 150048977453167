import React from 'react';
import PropTypes from 'prop-types';

import {
  Panel,
  Flex,
  Button,
  InfoSection,
  Divider,
  OneQuarterLayout
} from '@ubisend/pulse-components';
import { MarkdownCode } from '@ubisend/pulse-markdown';
import { useCopyToClipboard } from '@ubisend/pulse-component-hooks';

const DeploymentCode = ({ script }) => {
  const { copy, copied } = useCopyToClipboard();

  return (
    <Flex>
      <OneQuarterLayout>
        <InfoSection
          title="Deploy"
          info="Paste the code onto your web page, and see your bot appear."
        />
      </OneQuarterLayout>
      <Panel>
        <Flex col ySpace>
          <MarkdownCode aria-label="deployment-code-preview">
            {script}
          </MarkdownCode>
          <Divider mtNone />
          <Flex start>
            <Button
              variant="secondary"
              icon="clipboardCopy"
              onClick={() => copy(script)}>
              {copied ? 'Copied' : 'Copy code'}
            </Button>
          </Flex>
        </Flex>
      </Panel>
    </Flex>
  );
};

DeploymentCode.propTypes = {
  script: PropTypes.string.isRequired
};

export default DeploymentCode;

import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@ubisend/pulse-components';

const LoginButton = ({ href, ...rest }) => (
  <Button variant="secondary" as={'a'} href={href} {...rest}>
    View on Teams
  </Button>
);

LoginButton.propTypes = {
  href: PropTypes.string
};

export default LoginButton;

import { BORDER_RADIUS } from './constants';

// https://stackoverflow.com/questions/1255512/how-to-draw-a-rounded-rectangle-using-html-canvas
const roundRect = (
  ctx,
  x,
  y,
  width,
  height,
  stroke = '#dddbdf',
  fill = 'white',
  radius = BORDER_RADIUS
) => {
  const borderRadius = {
    tl: radius,
    tr: radius,
    br: radius,
    bl: radius
  };
  ctx.save();
  ctx.strokeStyle = stroke;
  ctx.fillStyle = fill;
  ctx.beginPath();

  ctx.moveTo(x + borderRadius.tl, y);
  ctx.lineTo(x + width - borderRadius.tr, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + borderRadius.tr);
  ctx.lineTo(x + width, y + height - borderRadius.br);
  ctx.quadraticCurveTo(
    x + width,
    y + height,
    x + width - borderRadius.br,
    y + height
  );
  ctx.lineTo(x + borderRadius.bl, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - borderRadius.bl);
  ctx.lineTo(x, y + borderRadius.tl);
  ctx.quadraticCurveTo(x, y, x + borderRadius.tl, y);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
};

export default roundRect;

import axios from 'axios';

const createCustomMetric = params => axios.post('custom-metrics', params);

const editCustomMetric = ({ id, ...params }) => {
  return axios.put(`custom-metrics/${id}`, params);
};

const deleteCustomMetric = id => axios.delete(`custom-metrics/${id}`);

export { createCustomMetric, editCustomMetric, deleteCustomMetric };

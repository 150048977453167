const shouldShow = () => {
  if (['development', 'testing'].includes(process.env.NODE_ENV)) {
    return true;
  }

  return Boolean(process.env.REACT_APP_SHOW_BETA_FEATURES);
};

const BetaFlag = ({ feature, children }) => {
  if (!shouldShow(feature)) {
    return null;
  }

  return children;
};

export default BetaFlag;

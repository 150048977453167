import React from 'react';
import PropTypes from 'prop-types';

import { useTriggerStepReducer } from '../hooks/index';
import { TriggerStepContext } from '../Contexts/index';

const TriggerStepProvider = ({ children, initialTrigger }) => {
  const trigger = useTriggerStepReducer(initialTrigger);

  return (
    <TriggerStepContext.Provider value={trigger}>
      {children}
    </TriggerStepContext.Provider>
  );
};

TriggerStepProvider.propTypes = {
  initialTrigger: PropTypes.object
};

export default TriggerStepProvider;

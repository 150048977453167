import styled from 'styled-components';

import { motion } from '@ubisend/framer-motion';

const Graphic = styled(motion.svg)`
  ${tw`w-full max-w-lg`}
  max-height: 60vh;
`;

export default Graphic;

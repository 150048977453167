import React from 'react';
import PropTypes from 'prop-types';

import { TicketsContext } from '../Contexts/index';

const TicketsProvider = ({ children, tickets = [] }) => {
  const context = { tickets, hasTickets: tickets.length > 0 };

  return (
    <TicketsContext.Provider value={context}>
      {children}
    </TicketsContext.Provider>
  );
};

TicketsProvider.propTypes = {
  tickets: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired
    })
  ).isRequired
};

export default TicketsProvider;

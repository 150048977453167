import React from 'react';
import PropTypes from 'prop-types';

import { TableCell } from '@ubisend/pulse-components';

const TicketNameTableCell = ({ children, maxWidth = '15vw' }) => (
  <TableCell
    style={{
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth,
      overflow: 'hidden'
    }}>
    {children}
  </TableCell>
);

TicketNameTableCell.propTypes = {
  maxWidth: PropTypes.string
};

export default TicketNameTableCell;

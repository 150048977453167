import React from 'react';

import { PageWrapper, Divider } from '@ubisend/pulse-components';
import { ConditionalTypeProvider } from '@ubisend/pulse-conditionals';
import { PermissionFilter } from '@ubisend/pulse-auth';

import { Integrations, Sheets, Webhooks } from '../Components/index';
import { IntegrationsProvider, SheetsProvider } from '../Providers/index';

const IntegrationsPage = () => {
  return (
    <PageWrapper
      header="Integrations"
      subheader="Manage your chatbot's integrations">
      <PermissionFilter can="view integrations">
        <IntegrationsProvider>
          <Integrations />
        </IntegrationsProvider>
        <Divider tall />
      </PermissionFilter>
      <PermissionFilter can="view spreadsheets">
        <ConditionalTypeProvider>
          <SheetsProvider>
            <Sheets />
          </SheetsProvider>
        </ConditionalTypeProvider>
      </PermissionFilter>
      <PermissionFilter can="view webhooks">
        <Divider tall />
        <Webhooks />
      </PermissionFilter>
    </PageWrapper>
  );
};

export default IntegrationsPage;

import styled from 'styled-components';

const StickyWidget = styled.div`
  ${tw`ml-4 flex items-center justify-center`}
  ${props => {
    return !props.pinned
      ? `position: fixed;
      bottom: 1rem;
      right: 1rem;
      min-width: 10rem;`
      : `position: sticky;
      top: calc(50% - 20rem);`;
  }}
`;

export default StickyWidget;

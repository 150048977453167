import React, { useCallback } from 'react';
import * as Sentry from '@sentry/react';

import { ReportingContext } from '@ubisend/pulse-errors';

const scope = new Sentry.Scope();

const ReportingProvider = ({ children }) => {
  const report = useCallback(error => {
    Sentry.captureException(error, scope);
  }, []);

  const setContext = useCallback((key, value) => {
    scope.setContext(key, value);
  }, []);

  const setTag = useCallback((key, value) => {
    scope.setTag(key, value);
  }, []);

  const setUser = useCallback(user => {
    scope.setUser(user);
  }, []);

  const clearScope = useCallback(() => {
    scope.clear();
  }, []);

  const context = {
    report,
    setContext,
    setTag,
    setUser,
    clearScope
  };

  return (
    <ReportingContext.Provider value={context}>
      {children}
    </ReportingContext.Provider>
  );
};

export default ReportingProvider;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  ModalPortal,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Flex,
  Button,
  Label,
  TextInput
} from '@ubisend/pulse-components';

const EditUtterance = ({
  utterance: initialUtterance,
  handleCancel,
  handleSubmit
}) => {
  const [utterance, setUtterance] = useState(initialUtterance);

  return (
    <ModalPortal>
      <ModalContainer>
        <ModalContent>
          <ModalHeader>Edit Question</ModalHeader>
          <ModalBody>
            <Flex col>
              <Label htmlFor="name">Question text</Label>
              <TextInput
                name="question"
                value={utterance.text}
                onChange={event =>
                  setUtterance({ ...utterance, text: event.target.value })
                }
                id="name"
              />
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Flex xSpace>
              <Button variant="secondary" onClick={handleCancel}>
                Cancel
              </Button>
              <Button onClick={() => handleSubmit(utterance)} icon="save">
                Save
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalContainer>
    </ModalPortal>
  );
};

EditUtterance.propTypes = {
  utterance: PropTypes.shape({ text: PropTypes.string.isRequired }).isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default EditUtterance;

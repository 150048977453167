import React from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  FormHeading,
  Label,
  TextInput,
  Divider,
  Panel,
  Span,
  Button
} from '@ubisend/pulse-components';
import {
  TemplatingTextInput,
  TemplatingTextArea
} from '@ubisend/pulse-templating';

import SenderSelect from './SenderSelect';
import { useEmailReducer } from '../../reducers/index';

const EmailDetails = ({ handleSubmit, loading, initialEmail }) => {
  const { email, valid, form, dispatch, TYPES } = useEmailReducer(initialEmail);

  const onSubmit = event => {
    event.preventDefault();

    if (valid && !loading) {
      handleSubmit(form);
    }
  };

  const handleSubjectChange = value => {
    dispatch({ type: TYPES.UPDATE_SUBJECT, subject: value });
  };

  const handleBodyChange = value => {
    dispatch({ type: TYPES.UPDATE_BODY, body: value });
  };

  const handleFromNameChange = event => {
    const value = event.target.value;

    dispatch({ type: TYPES.UPDATE_FROM_NAME, fromName: value });
  };

  const handleFromSuffixChange = option => {
    const value = option.value;

    dispatch({ type: TYPES.UPDATE_FROM_SUFFIX, fromSuffix: value });
  };

  const handleFromPrefixChange = event => {
    const value = event.target.value;

    dispatch({ type: TYPES.UPDATE_FROM_PREFIX, fromPrefix: value });
  };

  const handleToChange = value => {
    dispatch({ type: TYPES.UPDATE_TO, to: value });
  };

  const handleCcChange = value => {
    dispatch({ type: TYPES.UPDATE_CC, cc: value });
  };

  const handleBccChange = value => {
    dispatch({ type: TYPES.UPDATE_BCC, bcc: value });
  };

  return (
    <form id="email form" onSubmit={onSubmit}>
      <Panel>
        <Flex col>
          <FormHeading>Sender</FormHeading>
          <Flex xSpace>
            <Flex col fat>
              <Label htmlFor="from_prefix">From email</Label>
              <Flex xSpace center>
                <Flex fat>
                  <TextInput
                    id="from_prefix"
                    placeholder="support"
                    value={email.fromPrefix}
                    onChange={handleFromPrefixChange}
                  />
                </Flex>
                <Span>@</Span>
                <Flex fat>
                  <SenderSelect
                    value={email.fromSuffix}
                    onChange={handleFromSuffixChange}
                  />
                </Flex>
              </Flex>
            </Flex>
            <Flex col fat>
              <Label htmlFor="from_name">From name</Label>
              <TextInput
                id="from_name"
                placeholder="Dave Smith"
                value={email.fromName}
                onChange={handleFromNameChange}
              />
            </Flex>
          </Flex>
          <Divider fullWidth />
          <FormHeading>Recipient</FormHeading>
          <Flex xSpace>
            <Flex col fat mb>
              <Label htmlFor="to_email">To email</Label>
              <TemplatingTextInput
                id="to_email"
                value={email.to}
                onChange={handleToChange}
                placeholder="dave@smith.com"
              />
            </Flex>
            <Flex col fat mb>
              <Label htmlFor="subject">Subject</Label>
              <TemplatingTextInput
                id="subject"
                value={email.subject}
                onChange={handleSubjectChange}
                placeholder="My Subject"
              />
            </Flex>
          </Flex>
          <Flex xSpace>
            <Flex col fat>
              <Label htmlFor="cc">Cc</Label>
              <TemplatingTextInput
                id="cc"
                value={email.cc}
                onChange={handleCcChange}
                placeholder="dave@example.com,john@doe.com,jane@example.com"
              />
            </Flex>
            <Flex col fat>
              <Label htmlFor="bcc">Bcc</Label>
              <TemplatingTextInput
                id="bcc"
                value={email.bcc}
                onChange={handleBccChange}
                placeholder="dave@example.com,john@doe.com,jane@example.com"
              />
            </Flex>
          </Flex>
          <Divider fullWidth />
          <Flex col fat mb>
            <Label htmlFor="message">Message</Label>
            <TemplatingTextArea
              id="message"
              value={email.body}
              onChange={handleBodyChange}
              placeholder="My Email Copy"
            />
          </Flex>
        </Flex>
        <Flex right>
          <Button
            form="email form"
            type="submit"
            disabled={!valid}
            loading={loading}
            icon="save">
            Save
          </Button>
        </Flex>
      </Panel>
    </form>
  );
};

EmailDetails.propTypes = {
  initialEmail: PropTypes.shape({
    subject: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    from_name: PropTypes.string.isRequired,
    from_address: PropTypes.string.isRequired,
    from_prefix: PropTypes.string.isRequired,
    from_suffix: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    cc: PropTypes.string,
    bcc: PropTypes.string
  }),
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default EmailDetails;

import YouTube from './YouTube';
import Vimeo from './Vimeo';
import EmptyProvider from './EmptyProvider';

const providers = {
  youtube: YouTube,
  vimeo: Vimeo,
  default: EmptyProvider
};

export default providers;

import { hasPermission, hasSomePermissions } from '@ubisend/pulse-auth';

import {
  IntegrationsPage,
  Emails,
  CreateEmail,
  EditEmail,
  Variables
} from './Pages/index';

const routes = [
  {
    path: '/integrations',
    component: IntegrationsPage,
    name: 'Integrations',
    canAccess: hasSomePermissions('view integrations', 'view spreadsheets'),
    actions: [
      {
        title: 'Manage your integrations',
        description:
          'Manage the connections between your platform and external software.',
        icon: 'link'
      }
    ]
  },
  {
    path: '/emails',
    component: Emails,
    name: 'Emails',
    canAccess: hasPermission('view emails'),
    actions: [
      {
        title: 'Manage your emails',
        description: "View and update your chatbot's email notifications.",
        icon: 'inboxIn'
      }
    ]
  },
  {
    path: '/emails/create',
    component: CreateEmail,
    name: 'Create Email',
    canAccess: hasPermission('view emails')
  },
  {
    path: '/emails/:id',
    component: EditEmail,
    name: 'Edit Email',
    canAccess: hasPermission('view emails')
  },
  {
    path: '/variables',
    component: Variables,
    name: 'Variables',
    canAccess: hasPermission('view variables'),
    actions: [
      {
        title: 'Manage your variables',
        description: 'View and update your variables.',
        icon: 'x'
      }
    ]
  }
];

export default routes;

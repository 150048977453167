import { useContext } from 'react';

import { ReportingContext } from '../Contexts/index';

const useReporting = () => {
  const context = useContext(ReportingContext);

  return context;
};

export default useReporting;

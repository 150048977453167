import MessageConditional from './MessageConditional';

const subject = { id: null, type: 'message' };

const conditional = {
  subject,
  type: 'A message is sent',
  condition: null,
  flipped: false,
  case_sensitive: false,
  target: ''
};

const message = {
  subject,
  conditional,
  name: 'Message',
  Conditional: MessageConditional,
  toState: () => subject,
  toApi: () => subject
};

export default message;

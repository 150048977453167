import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { PreviewValue } from '@ubisend/pulse-components';

import {
  hasDateTimeCondition,
  hasTimeCondition,
  hasGroupCondition
} from '../utils/index';

const ConditionPreview = ({ type, condition }) => {
  if (!condition) {
    return null;
  }

  if (hasDateTimeCondition(type.type)) {
    return (
      <PreviewValue>
        {dayjs(condition).format('DD-MM-YYYY HH:mm:ss')}
      </PreviewValue>
    );
  }

  if (hasTimeCondition(type.type)) {
    return <PreviewValue>{dayjs(condition).format('HH:mm:ss')}</PreviewValue>;
  }

  if (hasGroupCondition(type.type)) {
    return <PreviewValue>{condition.join(', ')}</PreviewValue>;
  }

  return <PreviewValue>{condition}</PreviewValue>;
};

ConditionPreview.propTypes = {
  type: PropTypes.shape({ type: PropTypes.string }),
  condition: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

export default ConditionPreview;

import React, { useState } from 'react';

import {
  TextInput,
  useNotification,
  FormGroup,
  Label,
  Flex,
  Heading1,
  Paragraph,
  Button
} from '@ubisend/pulse-components';
import { useMutation } from '@ubisend/pulse-hooks';

import { requestNewPassword } from '../../../api/index';
import { useAuth } from '../../../hooks/index';
import {
  AuthLink,
  AnimatedAuthContainer,
  AuthenticatedRedirect
} from '../../../Components/index';

const EmailPasswordRequestNewPassword = () => {
  const [email, setEmail] = useState('');
  const { authenticated } = useAuth();

  const { showSuccess } = useNotification();

  const mutation = useMutation(requestNewPassword, {
    onSuccess: () => {
      setEmail('');
      showSuccess(
        'Password reset request successfully sent. You will receive an email shortly'
      );
    }
  });

  const handleEmailChange = event => setEmail(event.target.value);

  const handleSubmit = event => {
    event.preventDefault();

    if (email && !mutation.isLoading) {
      mutation.mutate(email);
    }
  };

  if (authenticated) {
    return <AuthenticatedRedirect />;
  }

  return (
    <AnimatedAuthContainer>
      <Heading1 style={{ fontSize: '2rem' }} mb textCenter>
        Forgot Password
      </Heading1>
      <Paragraph mb secondary textCenter>
        Enter your email below and we will send you a link to reset your
        password.
      </Paragraph>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Label htmlFor="email">Email</Label>
          <TextInput
            id="email"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
          />
        </FormGroup>
        <Flex col ySpace center>
          <Button
            mt
            fat
            middle
            variant="primary"
            disabled={!email}
            loading={mutation.isLoading}
            type="submit">
            {mutation.isLoading ? 'Submitting' : 'Submit'}
          </Button>
          <Button variant="inline" as={AuthLink} to="/">
            Go to login
          </Button>
        </Flex>
      </form>
    </AnimatedAuthContainer>
  );
};

export default EmailPasswordRequestNewPassword;

import React from 'react';

import { Heading1, Paragraph } from '@ubisend/pulse-components';
import { useQuerySearch, useQuery } from '@ubisend/pulse-hooks';

import {
  AnimatedAuthContainer,
  LoggedInRedirect,
  AuthErrorRedirect
} from '../../../Components/index';
import { useAuth } from '../../../hooks/index';

const OAuthCallback = ({ location }) => {
  const code = useQuerySearch(location, 'code');

  const { authenticated, loginUser } = useAuth();

  const query = useQuery(`auth/oauth2/callback${location.search}`, {
    enabled: Boolean(code && !authenticated),
    onSuccess: ({ data }) => {
      const { token, client, ...user } = data;
      loginUser({ user, token, client });
    }
  });

  if (authenticated) {
    return <LoggedInRedirect />;
  }

  if (!code || query.isError) {
    return <AuthErrorRedirect />;
  }

  return (
    <AnimatedAuthContainer>
      <Heading1 style={{ fontSize: '2rem' }} mb textCenter>
        Logging you in
      </Heading1>
      <Paragraph mb secondary textCenter>
        Please wait and you will be automatically logged in
      </Paragraph>
    </AnimatedAuthContainer>
  );
};

export default OAuthCallback;

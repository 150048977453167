import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Flex, Span } from '@ubisend/pulse-components';

import Bubble from './Bubble';
import BaseTypingIndicator from '../TypingIndicator';

const TypingIndicator = styled(BaseTypingIndicator)`
  ${tw`mr-1 flex-no-shrink`}
`;

const Progress = ({ direction, content, isLoading = true, ...props }) => {
  return (
    <Flex fat right={direction === 'recievedMessage'}>
      <Bubble direction={direction} {...props}>
        <Flex center>
          {isLoading && <TypingIndicator />}
          <Span fat style={{ minWidth: 0 }}>
            {content.text}
          </Span>
        </Flex>
      </Bubble>
    </Flex>
  );
};

Progress.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.string.isRequired
  }),
  direction: Bubble.propTypes.direction,
  isLoading: PropTypes.bool
};

export default Progress;

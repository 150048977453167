import { PARAGRAPH_FONT_SIZE } from '../constants';

const previousValues = {};

/**
 * Get the width of the text within the Canvas context.
 * Store any results in previousValues so we don't have re run the expensive
 * ctx.measureText() call.
 */
const getWidth = (ctx, text) => {
  if (previousValues[text]) {
    return previousValues[text];
  }

  const value = ctx.measureText(text).width;

  previousValues[text] = value;

  return value;
};

const checkIfTextIsTooLong = (ctx, text, maxWidth) => {
  return getWidth(ctx, text) > maxWidth;
};

const trimLongWord = (ctx, text, maxWidth) => {
  while (checkIfTextIsTooLong(ctx, `${text}...`, maxWidth)) {
    text = text.slice(0, text.length - 1);
  }

  return `${text}...`;
};

/**
 * Seperates a string into lines based on a maximum width that string can occupy
 *
 * @param   {CanvasRenderingContext2D}  ctx current canvas rendering context
 * @param   {string}  text      string to seperate into lines
 * @param   {number}  maxWidth  maximum width the text can occupy
 *
 * @return  {array<string>}           returns an array of strings that make up each line
 */
const getLines = (ctx, text, maxWidth) => {
  ctx.save();

  const newLines = text.split('\n');

  ctx.font = `${PARAGRAPH_FONT_SIZE}px Open Sans`;

  const allLines = newLines.flatMap(line => {
    const words = line.trim().split(' ');
    const lines = [];
    let currentLine = words[0];

    for (let i = 1; i < words.length; i++) {
      const word = words[i];

      if (!checkIfTextIsTooLong(ctx, currentLine + ' ' + word, maxWidth)) {
        currentLine += ' ' + word;
      } else if (!checkIfTextIsTooLong(ctx, word, maxWidth)) {
        lines.push(currentLine);
        currentLine = word;
      } else {
        lines.push(currentLine);
        currentLine = trimLongWord(ctx, word, maxWidth);
      }
    }

    lines.push(currentLine);

    return lines;
  });

  ctx.restore();

  return allLines;
};

export default getLines;

import React from 'react';
import PropTypes from 'prop-types';

import { Converse, BotContext } from '@ubisend/pulse-volt';

import { useComposer } from '../../hooks/index';
import MultiComposerMessageWrapper from './MultiComposerMessageWrapper';
import MultiComposerGroupWrapper from './MultiComposerGroupWrapper';
import { defaultProps } from './ComposerWidget';

const MultiComposerWidget = ({ as, ...props }) => {
  const { responses } = useComposer();

  const Widget = as || Converse;

  return (
    <BotContext.Provider
      value={{
        ...defaultProps,
        messages: responses.map(response => ({
          ...response,
          direction: 'toClient'
        })),
        ...props,
        MessageWrapper: MultiComposerMessageWrapper,
        GroupWrapper: MultiComposerGroupWrapper
      }}>
      <Widget start="/window/body/messages" />
    </BotContext.Provider>
  );
};

MultiComposerWidget.propTypes = {
  as: PropTypes.elementType
};

export default MultiComposerWidget;

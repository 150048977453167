import React from 'react';

import { useTheme } from '../../hooks/index';
import Graphic from './Graphic';

const TicketGraphic = props => {
  const { primary } = useTheme();

  return (
    <Graphic
      viewBox="0 0 345 293"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M293.957 100.429C297.648 107.69 300.557 115.178 308.699 115.178C312.609 115.178 316.359 113.624 319.123 110.858C321.888 108.092 323.441 104.341 323.441 100.429C323.441 96.5177 321.888 92.7663 319.123 90.0004C316.359 87.2345 312.609 85.6806 308.699 85.6806C300.425 85.5769 287.087 86.9156 293.957 100.429Z"
        fill="#2F2E41"
      />
      <path
        d="M305.544 277.803L299.244 279.207L290.831 255.564L300.129 253.491L305.544 277.803Z"
        fill="#A0616A"
      />
      <path
        d="M308.512 283.555L288.198 288.084L288.14 287.827C287.909 286.788 287.884 285.714 288.068 284.665C288.251 283.617 288.64 282.615 289.21 281.717C289.781 280.818 290.523 280.041 291.393 279.43C292.264 278.818 293.247 278.384 294.285 278.153L294.286 278.153L306.693 275.387L308.512 283.555Z"
        fill="#2F2E41"
      />
      <path
        d="M324.26 286.254H317.805L314.734 261.345L324.261 261.345L324.26 286.254Z"
        fill="#A0616A"
      />
      <path
        d="M325.906 292.514L305.093 292.513V292.249C305.093 291.185 305.303 290.131 305.71 289.148C306.117 288.165 306.714 287.271 307.466 286.519C308.218 285.766 309.111 285.169 310.094 284.762C311.077 284.354 312.13 284.145 313.194 284.145H313.195L325.907 284.145L325.906 292.514Z"
        fill="#2F2E41"
      />
      <path
        d="M324.303 273.201H316.065C315.493 273.199 314.94 272.99 314.509 272.614C314.078 272.238 313.797 271.718 313.717 271.151L306.25 213.359C306.225 213.178 306.138 213.012 306.005 212.887C305.871 212.763 305.699 212.689 305.517 212.678C305.335 212.666 305.155 212.718 305.007 212.825C304.859 212.931 304.752 213.085 304.705 213.262L300.279 229.764L304.268 267.348C304.336 267.927 304.187 268.511 303.852 268.988C303.516 269.464 303.016 269.8 302.447 269.931L295.913 271.439C295.332 271.571 294.723 271.48 294.206 271.184C293.69 270.888 293.302 270.41 293.12 269.843L282.597 233.305C281.749 230.627 281.458 227.804 281.74 225.01L285.033 192.071L285.283 192.083L329.241 194.202L326.671 270.913C326.652 271.528 326.393 272.111 325.951 272.538C325.509 272.965 324.918 273.203 324.303 273.201Z"
        fill="#2F2E41"
      />
      <path
        d="M307.974 115.465C315.115 115.465 320.905 109.673 320.905 102.528C320.905 95.3829 315.115 89.5907 307.974 89.5907C300.832 89.5907 295.042 95.3829 295.042 102.528C295.042 109.673 300.832 115.465 307.974 115.465Z"
        fill="#A0616A"
      />
      <path
        d="M328.257 197.75C328.214 197.75 328.172 197.749 328.129 197.746L284.3 195.389C283.937 195.37 283.585 195.267 283.268 195.09C282.952 194.913 282.681 194.665 282.475 194.366C282.269 194.067 282.135 193.725 282.082 193.366C282.03 193.007 282.06 192.641 282.172 192.296L285.641 181.519C289.354 169.965 289.989 157.641 287.481 145.766C286.824 142.659 286.838 139.447 287.521 136.345C288.205 133.244 289.542 130.324 291.443 127.781C293.314 125.273 295.698 123.193 298.437 121.681C301.175 120.169 304.204 119.259 307.323 119.012H307.323C307.537 118.995 307.753 118.98 307.969 118.967C311.268 118.772 314.568 119.316 317.63 120.56C320.692 121.803 323.438 123.715 325.668 126.155C327.897 128.596 329.554 131.503 330.518 134.666C331.481 137.828 331.727 141.166 331.237 144.435L328.181 164.964L330.617 195.189C330.644 195.516 330.602 195.844 330.496 196.153C330.389 196.462 330.219 196.746 329.997 196.987C329.776 197.227 329.507 197.419 329.207 197.551C328.907 197.682 328.584 197.75 328.257 197.75V197.75Z"
        fill="#CCCCCC"
      />
      <path
        d="M274.324 200.478C274.965 200.044 275.503 199.475 275.9 198.81C276.297 198.146 276.544 197.402 276.623 196.631C276.702 195.861 276.611 195.083 276.356 194.352C276.102 193.62 275.69 192.954 275.151 192.399L283.59 175.575L273.881 176.725L267.355 192.525C266.483 193.494 265.997 194.749 265.988 196.053C265.98 197.356 266.45 198.618 267.309 199.598C268.168 200.579 269.356 201.21 270.649 201.372C271.942 201.534 273.25 201.216 274.324 200.478H274.324Z"
        fill="#A0616A"
      />
      <path
        d="M332.258 203.22C331.698 202.685 331.263 202.033 330.982 201.312C330.702 200.59 330.583 199.816 330.634 199.043C330.685 198.27 330.904 197.518 331.277 196.84C331.65 196.161 332.167 195.573 332.792 195.116L327.282 177.118L336.663 179.875L340.457 196.543C341.155 197.644 341.425 198.963 341.215 200.25C341.006 201.537 340.332 202.702 339.321 203.525C338.311 204.348 337.033 204.772 335.732 204.715C334.43 204.659 333.194 204.127 332.258 203.22V203.22Z"
        fill="#A0616A"
      />
      <path
        d="M331.346 193.792C330.771 193.794 330.215 193.585 329.783 193.206C329.351 192.827 329.073 192.303 329 191.733L324.615 158.641L317.62 132.782C317.325 131.689 317.281 130.543 317.493 129.43C317.705 128.318 318.167 127.269 318.844 126.361C319.521 125.453 320.395 124.712 321.4 124.192C322.406 123.672 323.516 123.387 324.647 123.359V123.359C326.44 123.321 328.187 123.927 329.572 125.067C330.956 126.207 331.886 127.805 332.194 129.572L342.616 190.645C342.672 190.977 342.658 191.317 342.573 191.643C342.488 191.968 342.334 192.272 342.123 192.534C341.911 192.796 341.647 193.009 341.346 193.161C341.046 193.312 340.716 193.398 340.38 193.412L331.449 193.79C331.414 193.791 331.38 193.792 331.346 193.792Z"
        fill="#CCCCCC"
      />
      <path
        d="M279.022 190.075C278.853 190.075 278.684 190.057 278.518 190.022L269.775 188.157C269.446 188.086 269.136 187.947 268.865 187.747C268.594 187.548 268.368 187.293 268.204 186.999C268.039 186.706 267.938 186.381 267.909 186.045C267.88 185.71 267.922 185.372 268.033 185.055L288.51 126.583C289.11 124.894 290.295 123.475 291.85 122.582C293.405 121.69 295.227 121.384 296.988 121.719V121.719C298.099 121.935 299.146 122.402 300.051 123.083C300.955 123.764 301.693 124.641 302.209 125.649C302.724 126.657 303.004 127.769 303.028 128.901C303.051 130.033 302.817 131.156 302.343 132.184L291.114 156.542L281.276 188.403C281.129 188.887 280.831 189.311 280.425 189.612C280.019 189.913 279.528 190.075 279.022 190.075V190.075Z"
        fill="#CCCCCC"
      />
      <path
        d="M294.251 95.2165C297.206 97.2542 300.714 98.3386 304.303 98.3242C303.022 99.2123 301.565 99.8121 300.031 100.082C304.802 101.106 309.734 101.134 314.517 100.164C315.58 99.9934 316.606 99.6409 317.549 99.1219C318.02 98.8592 318.429 98.5004 318.752 98.0689C319.074 97.6374 319.302 97.1429 319.421 96.6174C319.739 94.8009 318.324 93.1506 316.854 92.0384C314.603 90.3615 312.007 89.2061 309.255 88.6559C306.503 88.1057 303.663 88.1744 300.941 88.857C299.163 89.3167 297.382 90.0934 296.228 91.5216C295.073 92.9498 294.731 95.1517 295.831 96.6224L294.251 95.2165Z"
        fill="#2F2E41"
      />
      <path
        d="M226.774 1.03962e-09H4.59119C3.37387 0.00104609 2.20671 0.485304 1.34592 1.34646C0.48514 2.20762 0.00107632 3.37531 0 4.59319V221.253C0.00108328 222.47 0.485141 223.638 1.34592 224.499C2.20671 225.36 3.37387 225.845 4.59119 225.846H24.0717V224.534H4.59119C3.72156 224.533 2.88784 224.187 2.27292 223.572C1.658 222.957 1.3121 222.123 1.31109 221.253V4.59319C1.3121 3.72316 1.658 2.88906 2.27292 2.27386C2.88784 1.65865 3.72156 1.3126 4.59119 1.31159H226.774C227.644 1.31152 228.479 1.65706 229.095 2.27234C229.711 2.88762 230.058 3.72234 230.059 4.59319V221.253C230.06 221.893 229.871 222.52 229.517 223.054C229.202 223.524 228.775 223.908 228.274 224.171C227.811 224.41 227.296 224.535 226.774 224.534H207.293V225.846H226.774C227.438 225.846 228.093 225.702 228.696 225.423C229.299 225.145 229.834 224.739 230.264 224.234C230.301 224.194 230.334 224.152 230.364 224.107C230.639 223.772 230.86 223.398 231.023 222.996C231.254 222.444 231.372 221.851 231.37 221.253V4.59319C231.369 3.37451 230.884 2.20621 230.022 1.34497C229.16 0.483725 227.992 -2.58949e-05 226.774 1.03962e-09Z"
        fill="#3F3D56"
      />
      <path
        d="M230.711 18.4112H0.654053V19.7243H230.711V18.4112Z"
        fill="#3F3D56"
      />
      <path
        d="M11.81 13.7892C13.9846 13.7892 15.7474 12.0256 15.7474 9.85003C15.7474 7.67447 13.9846 5.91083 11.81 5.91083C9.63544 5.91083 7.8726 7.67447 7.8726 9.85003C7.8726 12.0256 9.63544 13.7892 11.81 13.7892Z"
        fill="#3F3D56"
      />
      <path
        d="M23.13 13.7892C25.3046 13.7892 27.0674 12.0256 27.0674 9.85003C27.0674 7.67447 25.3046 5.91083 23.13 5.91083C20.9554 5.91083 19.1926 7.67447 19.1926 9.85003C19.1926 12.0256 20.9554 13.7892 23.13 13.7892Z"
        fill="#3F3D56"
      />
      <path
        d="M34.45 13.7892C36.6246 13.7892 38.3874 12.0256 38.3874 9.85003C38.3874 7.67447 36.6246 5.91083 34.45 5.91083C32.2754 5.91083 30.5126 7.67447 30.5126 9.85003C30.5126 12.0256 32.2754 13.7892 34.45 13.7892Z"
        fill="#3F3D56"
      />
      <path
        d="M171.23 48.8057H60.1391C58.3238 48.8057 56.5829 48.0842 55.2993 46.8C54.0157 45.5158 53.2946 43.7741 53.2946 41.958C53.2946 40.1419 54.0157 38.4002 55.2993 37.116C56.5829 35.8318 58.3238 35.1104 60.1391 35.1104H171.23C173.046 35.1104 174.787 35.8318 176.07 37.116C177.354 38.4002 178.075 40.1419 178.075 41.958C178.075 43.7741 177.354 45.5158 176.07 46.8C174.787 48.0842 173.046 48.8057 171.23 48.8057V48.8057ZM60.1391 36.1639C58.6031 36.1639 57.13 36.7743 56.0439 37.8609C54.9578 38.9475 54.3476 40.4213 54.3476 41.958C54.3476 43.4947 54.9578 44.9685 56.0439 46.0551C57.13 47.1417 58.6031 47.7522 60.1391 47.7522H171.23C172.766 47.7522 174.239 47.1417 175.326 46.0551C176.412 44.9685 177.022 43.4947 177.022 41.958C177.022 40.4213 176.412 38.9475 175.326 37.8609C174.239 36.7743 172.766 36.1639 171.23 36.1639H60.1391Z"
        fill="#3F3D56"
      />
      <path
        d="M199.398 144.459H31.9713C29.5983 144.456 27.3233 143.512 25.6453 141.833C23.9674 140.154 23.0235 137.878 23.0208 135.504V81.7766C23.0235 79.4025 23.9674 77.1265 25.6453 75.4477C27.3233 73.769 29.5983 72.8247 31.9713 72.822H199.398C201.771 72.8247 204.046 73.769 205.724 75.4477C207.402 77.1265 208.346 79.4025 208.349 81.7766V135.504C208.346 137.878 207.402 140.154 205.724 141.833C204.046 143.512 201.771 144.456 199.398 144.459V144.459ZM31.9713 73.8755C29.8775 73.8779 27.8701 74.7111 26.3896 76.1923C24.909 77.6735 24.0762 79.6818 24.0738 81.7766V135.504C24.0762 137.599 24.909 139.607 26.3896 141.089C27.8701 142.57 29.8775 143.403 31.9713 143.405H199.398C201.492 143.403 203.499 142.57 204.98 141.089C206.46 139.607 207.293 137.599 207.296 135.504V81.7766C207.293 79.6818 206.46 77.6735 204.98 76.1923C203.499 74.7111 201.492 73.8779 199.398 73.8755H31.9713Z"
        fill="#3F3D56"
      />
      <path
        d="M137.64 105.743H79.1988C78.0817 105.743 77.0104 105.299 76.2205 104.509C75.4306 103.719 74.9868 102.647 74.9868 101.529C74.9868 100.412 75.4306 99.34 76.2205 98.5497C77.0104 97.7595 78.0817 97.3155 79.1988 97.3155H137.64C138.757 97.3155 139.829 97.7595 140.619 98.5497C141.408 99.34 141.852 100.412 141.852 101.529C141.852 102.647 141.408 103.719 140.619 104.509C139.829 105.299 138.757 105.743 137.64 105.743Z"
        fill={primary}
      />
      <path
        d="M191.87 119.965H79.1988C78.0817 119.965 77.0104 119.521 76.2205 118.731C75.4306 117.941 74.9868 116.869 74.9868 115.751C74.9868 114.634 75.4306 113.562 76.2205 112.772C77.0104 111.981 78.0817 111.538 79.1988 111.538H191.87C192.987 111.538 194.058 111.981 194.848 112.772C195.638 113.562 196.082 114.634 196.082 115.751C196.082 116.869 195.638 117.941 194.848 118.731C194.058 119.521 192.987 119.965 191.87 119.965Z"
        fill={primary}
      />
      <path
        d="M51.0829 124.443C59.8062 124.443 66.8778 117.368 66.8778 108.64C66.8778 99.9131 59.8062 92.8382 51.0829 92.8382C42.3595 92.8382 35.2879 99.9131 35.2879 108.64C35.2879 117.368 42.3595 124.443 51.0829 124.443Z"
        fill={primary}
      />
      <path
        d="M191.863 137.084H177.121C176.004 137.084 174.933 136.641 174.143 135.85C173.353 135.06 172.909 133.988 172.909 132.871C172.909 131.753 173.353 130.681 174.143 129.891C174.933 129.101 176.004 128.657 177.121 128.657H191.863C192.98 128.657 194.052 129.101 194.842 129.891C195.632 130.681 196.075 131.753 196.075 132.871C196.075 133.988 195.632 135.06 194.842 135.85C194.052 136.641 192.98 137.084 191.863 137.084Z"
        fill="white"
      />
      <path
        d="M191.863 137.084H177.121C176.004 137.084 174.933 136.641 174.143 135.85C173.353 135.06 172.909 133.988 172.909 132.871C172.909 131.753 173.353 130.681 174.143 129.891C174.933 129.101 176.004 128.657 177.121 128.657H191.863C192.98 128.657 194.052 129.101 194.842 129.891C195.632 130.681 196.075 131.753 196.075 132.871C196.075 133.988 195.632 135.06 194.842 135.85C194.052 136.641 192.98 137.084 191.863 137.084Z"
        fill="#CCCCCC"
      />
      <path
        d="M199.396 169.742H31.9691C29.5964 169.746 27.3217 170.69 25.6439 172.369C23.9661 174.048 23.022 176.323 23.0187 178.697V232.425C23.022 234.799 23.9661 237.074 25.6439 238.753C27.3217 240.431 29.5964 241.376 31.9691 241.379H199.396C201.769 241.377 204.044 240.433 205.722 238.754C207.4 237.075 208.344 234.799 208.346 232.425V178.697C208.344 176.323 207.4 174.047 205.722 172.368C204.044 170.689 201.769 169.745 199.396 169.742ZM207.293 232.425C207.292 234.52 206.459 236.528 204.978 238.01C203.498 239.491 201.49 240.324 199.396 240.326H31.9691C29.8754 240.323 27.8681 239.49 26.3876 238.009C24.9071 236.528 24.0742 234.519 24.0717 232.425V178.697C24.0742 176.602 24.9071 174.594 26.3876 173.113C27.8681 171.632 29.8754 170.799 31.9691 170.796H199.396C201.49 170.798 203.498 171.631 204.978 173.112C206.459 174.593 207.292 176.602 207.293 178.697V232.425Z"
        fill="#3F3D56"
      />
      <path
        d="M137.64 202.664H79.1988C78.0817 202.664 77.0104 202.22 76.2205 201.43C75.4306 200.639 74.9868 199.567 74.9868 198.45C74.9868 197.332 75.4306 196.26 76.2205 195.47C77.0104 194.68 78.0817 194.236 79.1988 194.236H137.64C138.757 194.236 139.829 194.68 140.619 195.47C141.408 196.26 141.852 197.332 141.852 198.45C141.852 199.567 141.408 200.639 140.619 201.43C139.829 202.22 138.757 202.664 137.64 202.664Z"
        fill={primary}
      />
      <path
        d="M191.87 216.886H79.1988C78.0817 216.886 77.0104 216.442 76.2205 215.652C75.4306 214.861 74.9868 213.79 74.9868 212.672C74.9868 211.554 75.4306 210.482 76.2205 209.692C77.0104 208.902 78.0817 208.458 79.1988 208.458H191.87C192.987 208.458 194.058 208.902 194.848 209.692C195.638 210.482 196.082 211.554 196.082 212.672C196.082 213.79 195.638 214.861 194.848 215.652C194.058 216.442 192.987 216.886 191.87 216.886Z"
        fill={primary}
      />
      <path
        d="M51.0829 221.363C59.8062 221.363 66.8778 214.288 66.8778 205.561C66.8778 196.834 59.8062 189.759 51.0829 189.759C42.3595 189.759 35.2879 196.834 35.2879 205.561C35.2879 214.288 42.3595 221.363 51.0829 221.363Z"
        fill={primary}
      />
      <path
        d="M191.863 234.005H177.121C176.004 234.005 174.933 233.561 174.143 232.771C173.353 231.98 172.909 230.909 172.909 229.791C172.909 228.673 173.353 227.602 174.143 226.811C174.933 226.021 176.004 225.577 177.121 225.577H191.863C192.98 225.577 194.052 226.021 194.842 226.811C195.632 227.602 196.075 228.673 196.075 229.791C196.075 230.909 195.632 231.98 194.842 232.771C194.052 233.561 192.98 234.005 191.863 234.005Z"
        fill="#CCCCCC"
      />
      <path
        d="M344.474 293H270.764C270.624 293 270.49 292.944 270.391 292.846C270.293 292.747 270.237 292.613 270.237 292.473C270.237 292.334 270.293 292.2 270.391 292.101C270.49 292.002 270.624 291.947 270.764 291.947H344.474C344.613 291.947 344.747 292.002 344.846 292.101C344.945 292.2 345 292.334 345 292.473C345 292.613 344.945 292.747 344.846 292.846C344.747 292.944 344.613 293 344.474 293Z"
        fill="#3F3D56"
      />
    </Graphic>
  );
};

export default TicketGraphic;

import React, { useState } from 'react';

import { useQuery, useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import {
  PageWrapper,
  Panel,
  LeftHalfLayout,
  RightHalfLayout,
  TextInput,
  Button,
  Toggle,
  Explainer,
  Flex,
  useNotification,
  Label
} from '@ubisend/pulse-components';
import { AppWidget } from '@ubisend/pulse-bot';

import { updateFallback as updateFallbackApi } from '../api/index';
import { ThresholdSelect } from '../Components/index';

const messages = [
  {
    content: {
      text: "A message that can't be understood"
    },
    direction: 'toServer',
    type: 'standard'
  },
  {
    content: {
      text:
        "Sorry I didn't understand that, can you try rephrasing your question?"
    },
    direction: 'toClient',
    type: 'standard'
  },
  {
    content: {
      text: "Another message that can't be understood"
    },
    direction: 'toServer',
    type: 'standard'
  },
  {
    content: {
      text:
        "Sorry, it looks as though I'm having some issues understanding your question. Would you like some help from a human?",
      responses: [
        {
          label: 'Yes',
          postback: 'Yes'
        },
        {
          label: 'No',
          postback: 'No'
        }
      ]
    },
    direction: 'toClient',
    type: 'quick-reply'
  },
  {
    content: {
      text: 'Yes'
    },
    direction: 'toServer',
    type: 'standard'
  },
  {
    content: {
      text:
        "Ok, can you please tell me your email address, and one of my human's will get back to you."
    },
    direction: 'toClient',
    type: 'standard'
  },
  {
    content: {
      text: 'joe@bloggs.com'
    },
    direction: 'toServer',
    type: 'standard'
  },
  {
    content: {
      text:
        'Please leave a short message that I can pass on to someone for help.'
    },
    direction: 'toClient',
    type: 'standard'
  },
  {
    content: {
      text: 'I want to file a complaint with my line manager.'
    },
    direction: 'toServer',
    type: 'standard'
  },
  {
    content: {
      text: "Thanks, we'll get back to you soon."
    },
    direction: 'toClient',
    type: 'standard',
    composer: { type: 'hidden' }
  }
];

const Fallback = () => {
  const [fallback, setFallback] = useState();

  const { showSuccess } = useNotification();

  const queryClient = useQueryClient();
  useQuery('fallback', {
    onSuccess: ({ data }) => {
      if (!fallback) {
        setFallback(data);
      }
    }
  });
  const { mutateAsync: updateFallback } = useMutation(updateFallbackApi, {
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries('fallback');
      setFallback(data.data);
    }
  });

  const handleActiveChange = async () => {
    await updateFallback({
      active: !fallback.active,
      attempts: fallback.attempts,
      email: fallback.email,
      threshold: fallback.threshold
    });

    showSuccess(
      `Human fallback successfully ${
        !fallback.active ? 'enabled' : 'disabled'
      }.`
    );
  };

  const handleAttemptsChange = event => {
    const value = event.target.value;
    setFallback(fallback => ({
      ...fallback,
      attempts: value
    }));
  };

  const handleEmailChange = event => {
    const value = event.target.value;
    setFallback(fallback => ({
      ...fallback,
      email: value
    }));
  };

  const handleThresholdChange = ({ value }) => {
    setFallback(fallback => ({
      ...fallback,
      threshold: value
    }));
  };

  const handleFallbackSubmit = async () => {
    await updateFallback({
      active: fallback.active,
      attempts: fallback.attempts,
      email: fallback.email,
      threshold: fallback.threshold
    });

    showSuccess('Human fallback settings successfully updated.');
  };

  return (
    <PageWrapper
      header="Human Fallback"
      subheader="Manage your chatbot's handover to humans">
      <Flex>
        <LeftHalfLayout>
          <Panel
            loading={!fallback}
            loadingItems={1}
            divider
            header="Enable Human Fallback"
            actions={
              fallback && (
                <Toggle
                  on={fallback.active}
                  handleToggleChange={handleActiveChange}
                />
              )
            }>
            <Explainer mt>
              When human fallback is enabled conversations can be created to
              handle when the bot doesn&apos;t understand a question.
            </Explainer>
            {fallback?.active && (
              <Flex col mt ySpace>
                <div>
                  <Label htmlFor="attempts">User attempts</Label>
                  <TextInput
                    type="number"
                    min={1}
                    max={10}
                    id="attempts"
                    aria-label="fallback-attempts"
                    value={fallback.attempts}
                    onChange={handleAttemptsChange}
                  />
                </div>
                <div>
                  <Label htmlFor="email">Fallback email</Label>
                  <TextInput
                    id="email"
                    aria-label="fallback-email"
                    value={fallback.email}
                    onChange={handleEmailChange}
                  />
                </div>
                <div>
                  <Label htmlFor="threshold">Fallback</Label>
                  <ThresholdSelect
                    id="threshold"
                    value={fallback.threshold}
                    onChange={handleThresholdChange}
                  />
                </div>
                <div>
                  <Button onClick={handleFallbackSubmit} icon="save">
                    Save
                  </Button>
                </div>
              </Flex>
            )}
          </Panel>
        </LeftHalfLayout>
        <RightHalfLayout>
          <Flex middle>
            <AppWidget name={'Fallback Example'} messages={messages} />
          </Flex>
        </RightHalfLayout>
      </Flex>
    </PageWrapper>
  );
};

export default Fallback;

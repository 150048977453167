import PROVIDERS from '../Components/VideoProviders/index';

const useVideoProvider = provider => {
  const Frame = PROVIDERS[provider] || PROVIDERS.default;

  return Frame;
};

useVideoProvider.PROVIDERS = PROVIDERS;
export default useVideoProvider;

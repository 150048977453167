import React from 'react';

import { Converse, DeactivatedQueryProvider } from '@ubisend/pulse-volt';

const AppComposerWidget = props => (
  <DeactivatedQueryProvider>
    <Converse start="/window/body/messages" {...props} />
  </DeactivatedQueryProvider>
);

export default AppComposerWidget;

import MetricBlock from './MetricBlock';
import AddMetricBlock from './AddMetricBlock';
import EditMetricBlock from './EditMetricBlock';

const metric = {
  id: 'metric',
  name: 'Metric',
  icon: 'chartPie',
  colour: '#0CB0A9',
  permission: 'metrics',
  Block: MetricBlock,
  AddBlock: AddMetricBlock,
  EditBlock: EditMetricBlock
};

export default metric;

import styled from 'styled-components';

import { motion } from '@ubisend/framer-motion';

const FormGroup = styled(motion.div)`
  & > *:not(:last-child) {
    ${tw`mb-4`}
  }
`;

export default FormGroup;

import React from 'react';
import { Switch } from 'react-router-dom';

import { Placeholder, PageWrapper } from '@ubisend/pulse-components';
import { Route } from '@ubisend/pulse-auth';

import { useImports } from '../hooks/index';
import SectionRouter from './SectionRouter';
import { ImportPage, NoSelection } from '../SubPages/index';

const routes = [
  {
    path: '/knowledge-bases',
    component: NoSelection
  },
  {
    path: '/knowledge-bases/:importId',
    component: ImportPage
  },
  {
    path: '/knowledge-bases/:importId/sections/:sectionId',
    component: SectionRouter,
    exact: false
  }
];

const ImportRouter = () => {
  const { loading } = useImports();

  if (loading) {
    return (
      <PageWrapper>
        <Placeholder ySpace />
      </PageWrapper>
    );
  }

  return (
    <Switch>
      {routes.map((route, key) => (
        <Route
          exact={route.exact ?? true}
          public={false}
          key={key}
          path={route.path}
          canAccess={() => true}
          render={props => <route.component {...props} />}
        />
      ))}
    </Switch>
  );
};

export default ImportRouter;

import React from 'react';
import { Redirect } from 'react-router-dom';

const UnauthenticatedRedirect = () => {
  return (
    <Redirect
      to={{
        pathname: '/',
        state: {
          notification: {
            type: 'error',
            message:
              "You are not currently logged in so can't access that page."
          }
        }
      }}
    />
  );
};

export default UnauthenticatedRedirect;

import {
  useMutation as reactQueryUseMutation,
  useQueryClient
} from 'react-query';

import useAxios from './useAxios';

const isInTest = process.env.NODE_ENV === 'test';

const useMutation = (passedMutationFunction, options = {}) => {
  const {
    enabled: passedEnabled = true,
    baseUrl,
    token,
    clientId,
    browserUrl,
    ...useMutationOptions
  } = options;

  const axios = useAxios({ baseUrl, token, clientId, browserUrl });

  const mutationFunction = (...params) => {
    return passedMutationFunction(axios, ...params);
  };

  const queryClient = useQueryClient();
  const mutation = reactQueryUseMutation(
    isInTest ? passedMutationFunction : mutationFunction,
    useMutationOptions
  );

  const isGloballyDisabled = !queryClient.defaultOptions.mutations.enabled;

  const isEnabled = isGloballyDisabled ? false : passedEnabled;

  return {
    ...mutation,
    isEnabled,
    isDisabled: !isEnabled
  };
};

export default useMutation;

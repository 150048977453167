import React from 'react';
import styled from 'styled-components';

const Animation = styled.div`
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 10px;

  & > div {
    background: ${({ theme, colour }) =>
      colour ? theme[colour] : theme.primary};
    height: 100%;
    width: 5px;
    margin: 1px;
    display: inline-block;
    animation: sk-stretchdelay 1.4s infinite ease-in-out;
  }

  & .rect2 {
    animation-delay: -1.2s;
  }

  & .rect3 {
    animation-delay: -1.1s;
  }

  & .rect4 {
    animation-delay: -1s;
  }

  & .rect5 {
    animation-delay: -0.9s;
  }

  @keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
      transform: scaleY(0.5);
    }
    20% {
      transform: scaleY(1);
    }
  }
`;

const Pulse = () => (
  <Animation>
    <div className="rect1" />
    <div className="rect2" />
    <div className="rect3" />
    <div className="rect4" />
    <div className="rect5" />
  </Animation>
);

export default Pulse;

import React from 'react';
import PropTypes from 'prop-types';

import { useMutation } from '@ubisend/pulse-hooks';
import {
  Flex,
  useNotification,
  LeftHalfLayout,
  RightHalfLayout,
  Label
} from '@ubisend/pulse-components';
import {
  MultiComposer,
  MultiComposerSettings,
  MultiComposerWidget,
  MultiComposerSubmitButton,
  MultiComposerLanguageSelect,
  ComposerContext,
  useMultiComposerReducer
} from '@ubisend/pulse-composer';

import Modal from '../../../Modal';
import ComposerWidget from '../../../ComposerWidget';
import { useBuilder } from '../../../../hooks/index';
import { updateTransitionResponses } from '../../../../api/index';
import { composers } from '../../../../../../subjects/index';

const EditResponse = ({ handleClose, node }) => {
  const { showSuccess } = useNotification();
  const { dispatch } = useBuilder();
  const composer = useMultiComposerReducer({
    responses: node.base.responses,
    subjects: composers
  });

  const { isLoading, mutate } = useMutation(updateTransitionResponses, {
    onSuccess: ({ data }) => {
      dispatch({
        type: 'SYNC_TRANSITION_RESPONSES',
        stepId: node.meta.stepId,
        transitionId: node.id,
        newResponses: data.data,
        transitionType: 'validation'
      });
      showSuccess('Successfully updated responses');
      handleClose();
    }
  });

  const handleSubmit = event => {
    event.preventDefault();

    if (composer.valid && !isLoading) {
      mutate({
        stepId: node.meta.stepId,
        transitionId: node.id,
        responses: composer.form
      });
    }
  };

  return (
    <Modal header="Edit response" handleClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <Flex pad>
          <ComposerContext.Provider value={composer}>
            <LeftHalfLayout>
              <Flex between center mb>
                <Label mb={false}>Language</Label>
                <Flex>
                  <MultiComposerLanguageSelect />
                </Flex>
              </Flex>
              <MultiComposer />
              <Flex xSpace>
                <MultiComposerSettings />
                <MultiComposerSubmitButton
                  type="submit"
                  disabled={isLoading || !composer.valid}>
                  Save
                </MultiComposerSubmitButton>
              </Flex>
            </LeftHalfLayout>
            <RightHalfLayout>
              <Flex fat middle>
                <MultiComposerWidget as={ComposerWidget} />
              </Flex>
            </RightHalfLayout>
          </ComposerContext.Provider>
        </Flex>
      </form>
    </Modal>
  );
};

EditResponse.propTypes = {
  handleClose: PropTypes.func.isRequired,
  node: PropTypes.shape({
    id: PropTypes.number.isRequired,
    meta: PropTypes.shape({
      stepId: PropTypes.number.isRequired
    }).isRequired,
    base: PropTypes.shape({
      responses: PropTypes.array.isRequired
    }).isRequired
  }).isRequired
};

export default EditResponse;

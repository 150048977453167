import React from 'react';

import { motion } from '@ubisend/framer-motion';
import { Flex, ReviewGraphic } from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';

import { useImports } from '../hooks/index';
import { NewImportButton } from '../Components/index';

const NoSelection = () => {
  const { imports } = useImports();

  return (
    <Flex middle center fat ySpace col>
      <ReviewGraphic
        transition={{ ease: [0.16, 1, 0.3, 1], duration: 1 }}
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
      />
      <motion.div
        transition={{ delay: 0.2, ease: [0.16, 1, 0.3, 1], duration: 1 }}
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}>
        <Flex col center ySpace>
          <h1>
            {imports.length > 0
              ? 'Select or add a knowledge base to get started'
              : 'Upload a knowledge base to get started'}
          </h1>
          <PermissionFilter can="create knowledge bases">
            <NewImportButton variant="primary">
              + Knowledge base
            </NewImportButton>
          </PermissionFilter>
        </Flex>
      </motion.div>
    </Flex>
  );
};

export default NoSelection;

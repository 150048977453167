import React from 'react';

import {
  PageWrapper,
  LoadingContainer,
  useNotification
} from '@ubisend/pulse-components';
import {
  Drivers,
  useQueryClient,
  useQuery,
  useMutation
} from '@ubisend/pulse-hooks';
import { useAuth } from '@ubisend/pulse-auth';

import { updateSemanticDriver } from '../api/index';

const SemanticSearchDrivers = () => {
  const { showSuccess } = useNotification();
  const { hasPermission } = useAuth();

  const queryClient = useQueryClient();
  const allQuery = useQuery('semantic-search/all');
  const currentQuery = useQuery('semantic-search');
  const mutation = useMutation(updateSemanticDriver);

  const handleDriver = async driver => {
    await mutation.mutateAsync(driver.id);
    await queryClient.invalidateQueries('semantic-search');
    showSuccess(
      `Successfully made ${driver.name} your Semantic Search Provider.`
    );
  };

  const isLoading = allQuery.isLoading || currentQuery.isLoading;

  return (
    <PageWrapper
      header="Semantic Search"
      subheader="Configure your chosen Semantic Search Provider">
      {isLoading && <LoadingContainer />}
      {!isLoading && (
        <Drivers
          name="Semantic Search"
          type="single"
          required
          value={!isLoading && currentQuery.data?.data?.id}
          handleClick={handleDriver}
          drivers={allQuery.data.data}
          canEditDriver={() => false}
          canEnableDriver={() => hasPermission('edit nlp providers')}
          canDisableDriver={() => hasPermission('edit nlp providers')}
        />
      )}
    </PageWrapper>
  );
};

export default SemanticSearchDrivers;

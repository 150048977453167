import React from 'react';

const Apple = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32pt"
    height="32pt"
    viewBox="0 0 32 32"
    version="1.1"
    {...props}>
    <g id="surface1">
      <path d="M 24.769531 17.9375 C 23.886719 13.503906 28.09375 10.90625 28.09375 10.90625 C 28.09375 10.90625 26.453125 8.539062 23.703125 7.929688 C 20.949219 7.320312 19.574219 8.078125 18.199219 8.65625 C 16.824219 9.226562 16.136719 9.226562 16.136719 9.226562 C 14.152344 9.226562 12.699219 7.125 8.914062 8.085938 C 6.308594 8.742188 3.5625 11.75 3.066406 15.34375 C 2.570312 18.933594 3.640625 23.519531 5.703125 27.070312 C 7.769531 30.625 9.871094 31.960938 11.359375 32 C 12.851562 32.039062 14.339844 30.929688 16.136719 30.660156 C 17.933594 30.398438 19.035156 31.3125 20.800781 31.808594 C 22.558594 32.304688 23.171875 31.84375 25.191406 30.125 C 27.21875 28.40625 29.054688 23.472656 29.054688 23.472656 C 29.054688 23.472656 25.648438 22.371094 24.769531 17.9375 Z M 24.769531 17.9375 " />
      <path d="M 20.871094 5.0625 C 22.824219 3.074219 22.160156 0 22.160156 0 C 22.160156 0 19.421875 0.476562 17.589844 2.386719 C 15.753906 4.300781 16.058594 7.316406 16.058594 7.316406 C 16.058594 7.316406 18.921875 7.050781 20.871094 5.0625 Z M 20.871094 5.0625 " />
    </g>
  </svg>
);

export default Apple;

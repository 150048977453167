import React from 'react';
import { Switch } from 'react-router-dom';

import { Route, hasPermission } from '@ubisend/pulse-auth';

import { CreateCustomMetric, EditCustomMetric } from '../Pages/index';

const routes = [
  {
    name: 'Create custom metric',
    path: '/custom-metrics/create',
    component: CreateCustomMetric,
    canAccess: hasPermission('create custom metrics')
  },
  {
    name: 'Edit custom metric',
    path: '/custom-metrics/:id',
    component: EditCustomMetric,
    canAccess: hasPermission('edit custom metrics')
  }
];

const CustomMetricRouter = () => {
  return (
    <Switch>
      {routes.map((route, key) => (
        <Route
          exact={route.exact ?? true}
          public={false}
          key={key}
          path={route.path}
          canAccess={route.canAccess}
          render={props => <route.component {...props} />}
        />
      ))}
    </Switch>
  );
};

export default CustomMetricRouter;

import { useReducer, useMemo } from 'react';

const defaultParams = {
  file: null,
  name: '',
  description: '',
  groupIds: []
};

const TYPES = {
  UPLOAD_FILE: 'UPLOAD_FILE',
  UPDATE_NAME: 'UPDATE_NAME',
  UPDATE_DESCRIPTION: 'UPDATE_DESCRIPTION',
  UPDATE_GROUP_IDS: 'UPDATE_GROUP_IDS'
};

// https://stackoverflow.com/a/47956767
const removeFileExtension = name => {
  return name.substring(0, name.lastIndexOf('.')) || name;
};

const reducer = (state, { type, ...params }) => {
  switch (type) {
    // File details.
    case TYPES.UPLOAD_FILE:
      return {
        ...state,
        file: params.file,
        name: state.name || removeFileExtension(params.file.name)
      };
    case TYPES.UPDATE_NAME:
      return { ...state, name: params.name };
    case TYPES.UPDATE_DESCRIPTION:
      return { ...state, description: params.description };
    case TYPES.UPDATE_GROUP_IDS:
      return { ...state, groupIds: params.groupIds };

    // Errors
    default:
      throw new Error(`No event defined in useFileReducer for ${type}`);
  }
};

const useFileReducer = (initialParams = defaultParams) => {
  const [file, dispatch] = useReducer(reducer, {
    ...defaultParams,
    ...initialParams
  });

  const form = useMemo(() => {
    return {
      file: file.file,
      name: file.name,
      description: file.description,
      group_ids: file.groupIds
    };
  }, [file.file, file.name, file.description, file.groupIds]);

  const valid = useMemo(() => {
    return Boolean(file.file && file.name);
  }, [file.file, file.name]);

  return { file, form, valid, dispatch };
};

export default useFileReducer;

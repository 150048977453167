import React, { useState } from 'react';

import {
  TextInput,
  InputButton,
  FormGroup,
  Label,
  Flex,
  Checkbox,
  InputOverlay,
  Button,
  Heading1
} from '@ubisend/pulse-components';
import Icon from '@ubisend/pulse-icons';
import { usePulse, useQuerySearch, useMutation } from '@ubisend/pulse-hooks';

import { acceptInvite } from '../../../api/index';
import { useAuth } from '../../../hooks/index';
import {
  AuthLink,
  AnimatedAuthContainer,
  AuthErrorRedirect,
  AcceptedInviteRedirect,
  AuthenticatedRedirect
} from '../../../Components/index';

const defaultValues = {
  firstName: '',
  lastName: '',
  password: '',
  showPassword: false,
  policyAccepted: false
};

const EmailPasswordAcceptInvite = ({ location }) => {
  const [userData, setUserData] = useState(defaultValues);

  const { authenticated } = useAuth();
  const { platform } = usePulse();

  const mutation = useMutation(acceptInvite);

  const token = useQuerySearch(location, 'token');
  const email = useQuerySearch(location, 'email');

  const handleFirstNameChange = event => {
    const firstName = event.target.value;

    setUserData({ ...userData, firstName });
  };

  const handleLastNameChange = event => {
    const lastName = event.target.value;

    setUserData({ ...userData, lastName });
  };

  const handlePasswordChange = event => {
    const password = event.target.value;

    setUserData({ ...userData, password });
  };

  const handleShowPassword = () => {
    setUserData({ ...userData, showPassword: !userData.showPassword });
  };

  const handlePolicyChange = () => {
    setUserData({ ...userData, policyAccepted: !userData.policyAccepted });
  };

  const valid = Boolean(
    userData.firstName &&
      userData.lastName &&
      userData.password &&
      userData.policyAccepted
  );

  const handleSubmit = event => {
    event.preventDefault();

    if (valid && !mutation.isLoading) {
      mutation.mutate({
        email,
        token,
        first_name: userData.firstName,
        last_name: userData.lastName,
        password: userData.password,
        policy_accepted: userData.policyAccepted
      });
    }
  };

  if (authenticated) {
    return <AuthenticatedRedirect />;
  }

  if (!token || !email || mutation.isError) {
    return <AuthErrorRedirect />;
  }

  if (mutation.isSuccess) {
    return <AcceptedInviteRedirect />;
  }

  return (
    <AnimatedAuthContainer>
      <Heading1 style={{ fontSize: '2rem' }} mb textCenter>
        <span role="img" aria-label="party-popper=icon">
          🎉
        </span>{' '}
        You&apos;ve been invited!
      </Heading1>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Label htmlFor="email">Email</Label>
          <Flex relative>
            <TextInput id="email" placeholder="Email" readOnly value={email} />
            <InputOverlay right>
              <Icon
                type="mail"
                size="1.25rem"
                height="1.25rem"
                width="1.25rem"
              />
            </InputOverlay>
          </Flex>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="first_name">First name</Label>
          <Flex relative>
            <TextInput
              id="first_name"
              placeholder="First Name"
              value={userData.firstName}
              onChange={handleFirstNameChange}
            />
            <InputOverlay right>
              <Icon
                type="user"
                size="1.25rem"
                height="1.25rem"
                width="1.25rem"
              />
            </InputOverlay>
          </Flex>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="last_name">Last name</Label>
          <Flex relative>
            <TextInput
              id="last_name"
              placeholder="Last Name"
              value={userData.lastName}
              onChange={handleLastNameChange}
            />
            <InputOverlay right>
              <Icon
                type="user"
                size="1.25rem"
                height="1.25rem"
                width="1.25rem"
              />
            </InputOverlay>
          </Flex>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="password">Password</Label>
          <Flex relative>
            <TextInput
              id="password"
              type={userData.showPassword ? 'text' : 'password'}
              placeholder="New Password"
              value={userData.password}
              onChange={handlePasswordChange}
            />
            <InputButton
              aria-label={`${userData.showPassword ? 'hide' : 'show'} password`}
              onClick={handleShowPassword}>
              <Icon
                size="1.25rem"
                width="1.25rem"
                height="1.25rem"
                type={userData.showPassword ? 'eye' : 'eyeOff'}
              />
            </InputButton>
          </Flex>
        </FormGroup>
        <FormGroup>
          <Checkbox
            checked={userData.policyAccepted}
            onChange={handlePolicyChange}
            label={
              <a target="blank" rel="noreferrer" href={platform.policy_url}>
                {platform.policy_message}
              </a>
            }
            aria-label={platform.policy_message}
          />
        </FormGroup>
        <Flex ySpace center col pt>
          <Button
            type="submit"
            fat
            middle
            mt
            disabled={!valid}
            loading={mutation.isLoading}>
            {mutation.isLoading ? 'Submitting' : 'Create Account'}
          </Button>
          <Button variant="inline" as={AuthLink} to="/">
            Go to login
          </Button>
        </Flex>
      </form>
    </AnimatedAuthContainer>
  );
};

export default EmailPasswordAcceptInvite;

import { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useAuth } from '@ubisend/pulse-auth';

const isActive = (pathname, link) => {
  if (!link) {
    return false;
  }

  return pathname.slice(0, link.length) === link;
};

const hasActiveItem = (pathname, items) => {
  return Boolean(items?.find(item => isActive(pathname, item.to)));
};

const defaultCanSee = () => true;

const useSidebar = links => {
  const auth = useAuth();

  const location = useLocation();

  const filteredLinks = useMemo(() => {
    return (
      links
        // Only show route links that the user can see.
        .filter(({ canSee = defaultCanSee }) => canSee(auth))
        // Only show children links that the user can see.
        .map(({ roles, items = [], ...route }) => {
          return {
            ...route,
            items: items.filter(({ canSee = defaultCanSee }) => canSee(auth))
          };
        })
        // Only show root items that have a link or children.
        .filter(({ to, items = [] }) => to || items.length > 0)
    );
  }, [auth, links]);

  const [openLinkIndex, setOpenLinkIndex] = useState(
    filteredLinks.findIndex(
      link =>
        isActive(location.pathname, link.to) ||
        hasActiveItem(location.pathname, link.items)
    )
  );

  useEffect(() => {
    setOpenLinkIndex(
      filteredLinks.findIndex(
        link =>
          isActive(location.pathname, link.to) ||
          hasActiveItem(location.pathname, link.items)
      )
    );
  }, [location.pathname, filteredLinks]);

  const handleOpen = key => () => {
    if (openLinkIndex === key) {
      setOpenLinkIndex(false);
    } else {
      setOpenLinkIndex(key);
    }
  };

  return { filteredLinks, openLinkIndex, handleOpen };
};

export default useSidebar;
export { isActive, hasActiveItem };

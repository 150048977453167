// eslint-disable-next-line no-restricted-imports
import { createDomMotionComponent } from 'framer-motion';

import elements from './elements';

const motion = elements.reduce((all, element) => {
  all[element] = createDomMotionComponent(element);

  return all;
}, {});

export * from 'framer-motion'; // eslint-disable-line
export { motion }; // eslint-disable-line

import CustomInput from './CustomInput';

const custom = {
  key: 'custom',
  content: {},
  format: ({ key, variable }) => `${key}.get.${variable}`,
  Input: CustomInput,
  valid: () => true
};

export default custom;

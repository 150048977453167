const subject = {
  type: 'url'
};

const urlSubject = {
  subject,
  name: 'Use the current URL',
  Saver: () => null,
  toState: () => {
    return {
      type: 'url'
    };
  },
  toApi: () => {
    return {
      id: null,
      type: 'url'
    };
  }
};

export default urlSubject;

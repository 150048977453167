import styled from 'styled-components';
import chroma from 'chroma-js';

import { motion } from '@ubisend/framer-motion';

const BlockContainer = styled(motion.div)`
  ${tw`w-10 h-10 rounded flex items-center justify-center cursor-pointer`}
  background: ${props =>
    chroma.mix('white', props.colour || props.theme.primary, 0.25)};
  border: 1px solid
    ${props => chroma.mix('white', props.colour || props.theme.primary, 0.5)};
  & i {
    ${tw`flex`}
  }
  & svg,
  & path {
    stroke-width: 2px;
    stroke: ${props => props.colour};
  }
`;

export default BlockContainer;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Toggle, Tooltip, Flex, Span } from '@ubisend/pulse-components';

const ToggleContainer = ({
  text,
  children,
  initialOpen = false,
  tooltip,
  onOpenChange,
  ...props
}) => {
  const [open, setOpen] = useState(initialOpen);

  const toggleOpen = event => {
    event.preventDefault();
    onOpenChange(open);
    setOpen(open => !open);
  };

  return (
    <Flex col {...props}>
      <Flex between center mb>
        <Span>
          {text}
          {tooltip && (
            <Tooltip tooltip={tooltip}>
              <Tooltip.Icon>?</Tooltip.Icon>
            </Tooltip>
          )}
        </Span>
        <Toggle
          handleToggleChange={toggleOpen}
          on={open}
          options={['No', 'Yes']}
        />
      </Flex>
      {open && children}
    </Flex>
  );
};

ToggleContainer.propTypes = {
  text: PropTypes.string.isRequired,
  initialOpen: PropTypes.bool,
  onOpenChange: PropTypes.func,
  tooltip: PropTypes.string
};

export default ToggleContainer;

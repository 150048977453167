import React from 'react';
import PropTypes from 'prop-types';

import { Button, Flex } from '@ubisend/pulse-components';
import { FacebookIcon } from '@ubisend/pulse-facebook';

import {
  WORKPLACE_URL,
  WORKPLACE_APP_ID,
  WORKPLACE_REDIRECT_URL
} from '../constants';

const LoginButton = ({ loading }) => (
  <div style={{ maxWidth: '20rem' }}>
    <Button
      loading={loading}
      onClick={() => {
        window.open(
          `${WORKPLACE_URL}?app_id=${WORKPLACE_APP_ID}&redirect_uri=${WORKPLACE_REDIRECT_URL}&suggested_page_name=ChatbotConnect&permissions=message`,
          '_blank'
        );
      }}
      customColour="#1877f2"
      aria-label="fb-workplace-button"
      value="connect">
      <Flex center xSpace>
        <FacebookIcon style={{ marginRight: '0.5rem' }} inverted />
        Connect to Facebook Workplace
      </Flex>
    </Button>
  </div>
);

LoginButton.propTypes = {
  loading: PropTypes.bool
};

export default LoginButton;

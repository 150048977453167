import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';

import { ButtonSelect, Flex, Heading2 } from '@ubisend/pulse-components';

import { useChartColours, useMetric } from '../../../hooks/index';

const options = [
  {
    label: 'Daily',
    value: 'days'
  },
  {
    label: 'Weekly',
    value: 'weeks'
  },
  {
    label: 'Monthly',
    value: 'months'
  },
  {
    label: 'Quarterly',
    value: 'quarters'
  }
];

const LineGraphMultiMetric = () => {
  const [axisInterval, setAxisInterval] = useState('days');

  const { editing, metric } = useMetric();
  const colours = useChartColours();

  const handleChange = ({ value }) => {
    setAxisInterval(value);
  };

  return (
    <Flex col pad tall>
      <Flex between fat>
        <Heading2>{metric.data.label}</Heading2>
        {!editing && (
          <ButtonSelect
            defaultValue={{ ...options[0] }}
            onChange={handleChange}
            options={options}
          />
        )}
      </Flex>
      <Flex tall>
        <Line
          options={{ animations: false, maintainAspectRatio: false }}
          data={{
            ...metric.data.data[axisInterval],
            datasets: metric.data.data[axisInterval].datasets.map(
              (set, key) => ({
                ...set,
                borderColor: colours(
                  metric.data.data[axisInterval].length,
                  metric.data.data[axisInterval].datasets.length
                )[key]
              })
            )
          }}
        />
      </Flex>
    </Flex>
  );
};

export default LineGraphMultiMetric;

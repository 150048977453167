import React from 'react';
import PropTypes from 'prop-types';

import General from './General';

const Carousel = ({ content, index = 0 }) => {
  return <General content={content.items[index]} />;
};

Carousel.propTypes = {
  content: PropTypes.shape({
    items: PropTypes.array.isRequired
  }).isRequired,
  index: PropTypes.number
};

export default Carousel;

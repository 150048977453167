import React from 'react';
import { useTranslation } from 'react-i18next';

import BaseComposer from '../../BaseComposer';

const EmailComposer = props => {
  const { t } = useTranslation('bots');

  return (
    <BaseComposer
      showBurgerIcon={false}
      aria-label={t('auth_email_composer_label')}
      placeholder={t('auth_email_composer_label')}
      {...props}
    />
  );
};

export default EmailComposer;

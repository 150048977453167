import React from 'react';
import styled from 'styled-components';

import { useBot } from '../../../../hooks/index';
import CloseButton from './CloseButton';
import CallToActionText from './CallToActionText';

const PositionedCallToActionText = styled(CallToActionText)`
  ${tw`absolute`}
  bottom: 84px;
  right: 4px;
`;

const CallToAction = props => {
  const { ctaText, setShowCta } = useBot();

  return (
    <>
      <PositionedCallToActionText
        id="open-widget-cta"
        onTouchStart={() => setShowCta(false)}
        {...props}>
        {ctaText}
        <CloseButton />
      </PositionedCallToActionText>
    </>
  );
};

export default CallToAction;

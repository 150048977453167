import { useContext } from 'react';

import { BotContext } from '../Contexts/index';

const useBot = () => {
  const bot = useContext(BotContext);

  return bot;
};

export default useBot;

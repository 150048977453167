import React from 'react';

import BaseDeliveryProvider from '../../../Providers/DeliveryProvider';

const DeliveryProvider = ({ children, ...rest }) => (
  <BaseDeliveryProvider
    {...rest}
    demo={false}
    channelDriverName="Full Page"
    delayedBotOptions={{ messageDelay: 500 }}>
    {children}
  </BaseDeliveryProvider>
);

export default DeliveryProvider;

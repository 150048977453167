import { hasPermission } from '@ubisend/pulse-auth';

import { NlpDrivers, SemanticSearchDrivers } from './Pages/index';

const routes = [
  {
    path: '/nlp',
    component: NlpDrivers,
    name: 'NLP Providers',
    canAccess: hasPermission('view nlp providers')
  },
  {
    path: '/semantic-search',
    component: SemanticSearchDrivers,
    name: 'Semantic Search Providers',
    canAccess: hasPermission('view nlp providers')
  }
];

export default routes;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Flex, Heading2 } from '@ubisend/pulse-components';

const MessageFooter = ({
  selectedMessages,
  handleMarkAsCorrect,
  handleIgnore,
  handleExplore,
  handleClear
}) => {
  const [correctIsLoading, setCorrectIsLoading] = useState(false);
  const [ignoreIsLoading, setIgnoreIsLoading] = useState(false);

  const handleCorrect = () => {
    setCorrectIsLoading(true);
    handleMarkAsCorrect(selectedMessages);
  };

  const handleMarkAsIgnore = () => {
    setIgnoreIsLoading(true);
    handleIgnore(selectedMessages);
  };

  return (
    <Flex between>
      <Heading2>
        {`${selectedMessages.length} message${
          selectedMessages.length === 1 ? '' : 's'
        } selected.`}
      </Heading2>
      <Flex xSpace>
        <Button icon="check" onClick={handleCorrect} loading={correctIsLoading}>
          Correct
        </Button>
        <Button
          icon="archive"
          onClick={handleMarkAsIgnore}
          loading={ignoreIsLoading}>
          Ignore
        </Button>
        <Button icon="eye" onClick={handleExplore}>
          Explore
        </Button>
        <Button icon="x" colour="danger" onClick={handleClear}>
          Clear
        </Button>
      </Flex>
    </Flex>
  );
};

MessageFooter.propTypes = {
  selectedMessages: PropTypes.array.isRequired,
  handleMarkAsCorrect: PropTypes.func.isRequired,
  handleIgnore: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired,
  handleExplore: PropTypes.func.isRequired
};

export default MessageFooter;

import React, { useMemo } from 'react';

import { Content, Embed } from '../Components/index';
import { useMessage } from '../../../hooks/index';

const types = {
  content: Content,
  embed: Embed
};

const Message = () => {
  const message = useMessage();

  const Component = useMemo(() => types[message.type], [message.type]);

  return <Component />;
};

export default Message;

import React from 'react';

import { Flex } from '@ubisend/pulse-components';
import { Embed } from '@ubisend/pulse-volt';

const EmbedDemo = () => (
  <Flex middle relative grow style={{ zIndex: 0 }} data-testid="embed-demo">
    <Embed start="/window/body/messages" />
  </Flex>
);

export default EmbedDemo;

import axios from 'axios';

const getLookups = () => axios.get('lookups');

const getLookup = id => axios.get(`lookups/${id}`);

const createLookup = params => axios.post('lookups', params);

const editLookup = (id, params) => axios.put(`lookups/${id}`, params);

const deleteLookup = id => axios.delete(`lookups/${id}`);

export { getLookup, getLookups, createLookup, editLookup, deleteLookup };

import React from 'react';

import { Select } from '@ubisend/pulse-components';

import { useConditional } from '../hooks/index';
import { group } from '../utils/index';

const SubjectSelect = props => {
  const { formattedSubjects, events } = useConditional();

  return (
    <Select options={group([...formattedSubjects, ...events])} {...props} />
  );
};

export default SubjectSelect;

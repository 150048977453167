import React, { useEffect, useCallback, useState } from 'react';
import QRCode from 'qrcode';
import styled from 'styled-components';

import { useQrCode } from '../hooks/index';

const Image = styled.div`
  ${tw`w-full h-full`}
  max-height: 350px;
  ${props => (props.src ? tw`block` : tw`hidden`)}
  background-image: url('${props => props.src}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

const QrCode = () => {
  const { data, valid, options } = useQrCode();
  const [image, setImage] = useState(null);

  const generateImage = useCallback(async () => {
    const dataUrl = await QRCode.toDataURL(data, {
      color: options.color,
      margin: options.margin,
      type: `image/png`,
      rendererOpts: { quality: 1 }
    });

    setImage(dataUrl);
  }, [data, options.color, options.margin]);

  useEffect(() => {
    if (!valid) {
      setImage(null);
    } else {
      generateImage();
    }
  }, [valid, generateImage]);

  return <Image src={image} />;
};

export default QrCode;

import React from 'react';
import PropTypes from 'prop-types';

import { Select } from '@ubisend/pulse-components';

const options = [
  {
    label: 'Conversation',
    value: 'routing'
  },
  {
    label: 'NLP',
    value: 'nlp'
  },
  {
    label: 'Endpoint',
    value: 'endpoint'
  },
  {
    label: 'Spreadsheet',
    value: 'spreadsheet'
  }
];

const LogTypeSelect = ({ value, ...props }) => (
  <Select
    options={options}
    value={value.map(value => options.find(option => option.value === value))}
    isClearable
    isMulti
    {...props}
  />
);

LogTypeSelect.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default LogTypeSelect;

import React, { useState } from 'react';

import { FormGroup, Box, Button, Label } from '@ubisend/pulse-components';
import { FileSelect, types } from '@ubisend/pulse-files';

import { useGandalf } from '../../../hooks/index';

const UploadPdf = () => {
  const [showSelect, setShowSelect] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const { dispatch } = useGandalf();

  const handleFileUpload = file => {
    setSelectedFile(file);
    dispatch({ type: 'UPDATE_SETTINGS', settings: { path: file.file_name } });
    setShowSelect(false);
  };

  return (
    <FormGroup>
      <Box border="dashed" flex col ySpace middle center>
        {selectedFile && <Label>PDF #{selectedFile.id}</Label>}
        <Button variant="secondary" onClick={() => setShowSelect(!showSelect)}>
          {selectedFile ? `Change File` : `Select File`}
        </Button>
        {showSelect && (
          <FileSelect
            handleCancel={() => setShowSelect(false)}
            handleFileSelect={handleFileUpload}
            types={[types.pdf]}
          />
        )}
      </Box>
    </FormGroup>
  );
};

export default UploadPdf;

import { hasGlobalRole } from './hasGlobalRole';

const getPermissionsFromRole = (permissions, role) => {
  return permissions.concat(role.permissions);
};

const hasPermission = permission => auth => {
  if (!auth?.user) {
    return false;
  }

  if (hasGlobalRole('owner')(auth)) {
    return true;
  }

  return auth.user.permissions
    .concat(auth.user.roles.reduce(getPermissionsFromRole, []))
    .map(permission => `${permission.name} ${permission.resource.name}`)
    .includes(permission);
};

const hasAllPermissions = (...permissions) => auth => {
  if (hasGlobalRole('owner')(auth)) {
    return true;
  }

  return permissions.every(permission => {
    return hasPermission(permission)(auth);
  });
};

const hasSomePermissions = (...permissions) => auth => {
  if (hasGlobalRole('owner')(auth)) {
    return true;
  }

  return permissions.some(permission => {
    return hasPermission(permission)(auth);
  });
};

export { hasPermission, hasAllPermissions, hasSomePermissions };

import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@ubisend/pulse-components';

const OAuthLogin = ({ method }) => {
  return (
    <Button as="a" href={method.config.auth_url} fat middle variant="secondary">
      Log in with {method.config.name}
    </Button>
  );
};

OAuthLogin.propTypes = {
  method: PropTypes.shape({
    config: PropTypes.shape({
      auth_url: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default OAuthLogin;

import { useContext } from 'react';

import { ComposerContext } from '../Contexts/index';

const useComposer = () => {
  const context = useContext(ComposerContext);

  return context;
};

export default useComposer;

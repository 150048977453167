import React from 'react';
import PropTypes from 'prop-types';

import { useNotificationReducer } from '../reducers/index';
import { NotificationContext } from '../Contexts/index';

const NotificationProvider = ({ children, initialNotification }) => {
  const reducer = useNotificationReducer(initialNotification);

  return (
    <NotificationContext.Provider value={reducer}>
      {children}
    </NotificationContext.Provider>
  );
};

NotificationProvider.propTypes = {
  initialNotification: PropTypes.shape({
    names: PropTypes.arrayOf(
      PropTypes.shape({
        language_id: PropTypes.string.isRequired,
        content: PropTypes.shape({
          name: PropTypes.string.isRequired
        }).isRequired
      })
    ).isRequired,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        language_id: PropTypes.string.isRequired,
        content: PropTypes.shape({
          link: PropTypes.string.isRequired
        }).isRequired
      })
    ).isRequired
  })
};
export default NotificationProvider;

const getTicket = ticket => {
  return {
    id: ticket.id,
    name: ticket.name,
    description: ticket.description,
    status: ticket.status,
    bot_could_answer: ticket.bot_could_answer,
    assignee_id: ticket.assignee?.id,
    subscriber_id: ticket.subscriber?.id,
    groups: ticket.groups.map(ticket => ticket.id)
  };
};

export { getTicket };

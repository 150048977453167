import { useContext } from 'react';

import { BotSettingsContext } from '../Contexts/index';

const useBotSettings = () => {
  const context = useContext(BotSettingsContext);

  return context;
};

export default useBotSettings;

import React, { useState } from 'react';

import {
  Label,
  Flex,
  Checkbox,
  InnerPanel,
  Button
} from '@ubisend/pulse-components';
import { ImageSelect } from '@ubisend/pulse-files';
import {
  TemplatingTextInput,
  TemplatingTextArea
} from '@ubisend/pulse-templating';

import { useComposer } from '../../../../hooks/index';

const ImageInput = () => {
  const {
    response: { content },
    dispatch
  } = useComposer();
  const [selectImage, setSelectImage] = useState(false);

  const toggleSelectImage = () => setSelectImage(select => !select);

  const handleImageSelect = ({ link }) => {
    dispatch({
      type: 'UPDATE_CONTENT',
      newContent: { ...content, url: link }
    });
    setSelectImage(false);
  };

  const handleResponseChange = value => {
    dispatch({
      type: 'UPDATE_CONTENT',
      newContent: { ...content, text: value }
    });
  };

  const handleSizeChange = () => {
    dispatch({
      type: 'UPDATE_CONTENT',
      newContent: { ...content, fullSize: !content.fullSize }
    });
  };

  const handleImageChange = value => {
    dispatch({
      type: 'UPDATE_CONTENT',
      newContent: { ...content, url: value }
    });
  };

  const handleDescriptionChange = value => {
    dispatch({
      type: 'UPDATE_CONTENT',
      newContent: { ...content, image_description: value }
    });
  };

  return (
    <div>
      {selectImage && (
        <ImageSelect
          handleCancel={toggleSelectImage}
          handleFileSelect={handleImageSelect}
        />
      )}
      <Label htmlFor="standard">Image</Label>
      <InnerPanel ySpace mb>
        <Checkbox
          checked={content.fullSize}
          onChange={handleSizeChange}
          label="Full size"
        />
        <Flex xSpace>
          <TemplatingTextInput
            value={content.url}
            placeholder={'http://placehold.it/400x200'}
            onChange={handleImageChange}
          />
          <Button
            variant="secondary"
            onClick={toggleSelectImage}
            icon="document">
            Select File
          </Button>
        </Flex>
        <Label htmlFor="image_description">Image Description</Label>
        <TemplatingTextInput
          id="image_description"
          placeholder="Picture of a tree"
          value={content.image_description}
          onChange={handleDescriptionChange}
        />
      </InnerPanel>
      <Label htmlFor="standard">Message</Label>
      <TemplatingTextArea
        id="standard"
        name="standard"
        value={content.text}
        onChange={handleResponseChange}
      />
    </div>
  );
};

export default ImageInput;

import React from 'react';

import { Button } from '@ubisend/pulse-components';

import { useGandalf } from '../../hooks/index';

const WizardNextButton = props => {
  const { dispatch, canProgress } = useGandalf();

  const handleNext = () => {
    dispatch({ type: 'GO_NEXT' });
  };

  return (
    <Button disabled={!canProgress} onClick={handleNext} {...props}>
      Next
    </Button>
  );
};

export default WizardNextButton;

import { useContext } from 'react';

import { TriggerStepContext } from '../Contexts/index';

const useTriggerStep = () => {
  const context = useContext(TriggerStepContext);

  return context;
};

export default useTriggerStep;

import React from 'react';
import PropTypes from 'prop-types';

import { TextArea } from '@ubisend/pulse-components';
import { useJsonFormat } from '@ubisend/pulse-component-hooks';

const JsonPreview = ({ value }) => {
  const [json] = useJsonFormat(JSON.stringify(value));

  return <TextArea value={json} readOnly />;
};

JsonPreview.propTypes = {
  value: PropTypes.object.isRequired
};

export default JsonPreview;

import { useState, useEffect, useCallback } from 'react';

import useCanvas from './useCanvas';

const useNewLink = () => {
  const {
    nodeSizes,
    newLinkOffset,
    getMidpoint,
    calculatePath,
    linking,
    mousePositionX,
    mousePositionY,
    snapPoint
  } = useCanvas();
  const [path, setPath] = useState(null);
  // Generate path d attribute based on node and mouse position
  const getMouseCurve = useCallback(
    (node, cursorX, cursorY) => {
      const nodeSize = nodeSizes?.find(
        info => info.id === node.id && info.type === node.type
      );
      const x1 = node.x + newLinkOffset(node);
      const y1 = node.y + nodeSize.height / 2;
      const midPoint = getMidpoint(x1, y1, cursorX, cursorY);
      return calculatePath(x1, y1, cursorX, cursorY, midPoint);
    },
    [calculatePath, getMidpoint, newLinkOffset, nodeSizes]
  );

  const handleMouseMove = useCallback(() => {
    if (!linking.from) {
      return;
    }
    let x = mousePositionX.get();
    let y = mousePositionY.get();
    if (snapPoint) {
      x = snapPoint.x;
      y = snapPoint.y;
    }
    setPath(getMouseCurve(linking.from, x, y));
  }, [getMouseCurve, mousePositionX, mousePositionY, linking.from, snapPoint]);

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove, false);
    return () =>
      document.removeEventListener('mousemove', handleMouseMove, false);
  }, [path, handleMouseMove]);

  return path;
};

export default useNewLink;

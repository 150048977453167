const shouldShow = () => {
  return ['development', 'test'].includes(process.env.NODE_ENV);
};

const DevelopmentFlag = ({ children }) => {
  if (!shouldShow()) {
    return null;
  }

  return children;
};

export default DevelopmentFlag;

import React, { useState, useCallback, useEffect } from 'react';

import { NotificationContext } from '../Contexts/index';

const NOTIFICATION_DURATION = 2500;
const MAX_NOTIFICATIONS = 2;

const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const showNotification = useCallback(({ message, type = 'success' }) => {
    setNotifications(notifications => {
      return notifications
        .slice(Math.max(notifications.length - (MAX_NOTIFICATIONS - 1), 0))
        .concat({
          id:
            notifications.length === 0
              ? 1
              : notifications[notifications.length - 1].id + 1,
          message,
          type
        });
    });
  }, []);

  const showSuccess = useCallback(
    message => {
      showNotification({ message, type: 'success' });
    },
    [showNotification]
  );

  const showError = useCallback(
    message => {
      showNotification({ message, type: 'error' });
    },
    [showNotification]
  );

  const closeNotification = useCallback(id => {
    if (!id) {
      return;
    }

    setNotifications(notifications => {
      return notifications.filter(notification => notification.id !== id);
    });
  }, []);

  useEffect(() => {
    const timer = setTimeout(
      () => closeNotification(notifications[0]?.id),
      NOTIFICATION_DURATION
    );

    return () => clearTimeout(timer);
  }, [notifications, closeNotification]);

  const context = {
    notifications,
    showNotification,
    closeNotification,
    showSuccess,
    showError
  };

  return (
    <NotificationContext.Provider value={context}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;

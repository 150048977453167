import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { useImports } from '../hooks/index';
import { ImportSettings } from '../Components/index';

const ImportPage = () => {
  const { item, setOpen } = useImports();

  useEffect(() => {
    if (item) {
      setOpen(item.id);
    }
  }, [item, setOpen]);

  // Handle import deletion.
  if (!item) {
    return <Redirect to="/knowledge-bases" />;
  }

  return <ImportSettings />;
};

export default ImportPage;

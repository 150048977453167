import { hasPermission } from '@ubisend/pulse-auth';

import { TelegramSettings } from './Pages/index';

const routes = [
  {
    name: 'Telegram',
    path: '/telegram-link',
    component: TelegramSettings,
    canAccess: hasPermission('view telegram settings'),
    actions: [
      {
        title: 'Manage your Telegram channel',
        description: 'Connect, disconnect, or edit your Telegram integration.',
        icon: 'lightningBolt'
      }
    ]
  }
];

export default routes;

import React from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import {
  Button,
  Flex,
  Span,
  Heading3,
  NoResults,
  Placeholder
} from '@ubisend/pulse-components';

import types from '../../../Components/Types/index';

const AllMetrics = ({ activeMetrics = [], handleMetricClick }) => {
  const query = useQuery('metrics/all');

  if (query.isLoading) {
    return <Placeholder />;
  }

  const nonActiveMetrics = query.data.data.filter(
    metric => !activeMetrics.find(({ id }) => id === metric.id)
  );

  return (
    <Flex ySpace col>
      {nonActiveMetrics.length === 0 && <NoResults text="No unused metrics" />}
      {nonActiveMetrics.length !== 0 &&
        nonActiveMetrics.map(metric => (
          <Flex between center key={metric.id}>
            <Flex col>
              <Heading3>{metric.name}</Heading3>
              <Span light>{types[metric.type].name}</Span>
            </Flex>
            <Button onClick={() => handleMetricClick(metric)}>Add</Button>
          </Flex>
        ))}
    </Flex>
  );
};

AllMetrics.propTypes = {
  activeMetrics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.oneOf(Object.keys(types)).isRequired
    })
  ),
  handleMetricClick: PropTypes.func.isRequired
};

export default AllMetrics;

import React from 'react';

const PulseContext = React.createContext({
  versions: {
    ui: null,
    api: null
  },
  owner: {
    url: null,
    logo_url: null
  },
  platform: {
    account_manager_email: null,
    policy_message: null,
    policy_url: null,
    is_white_label: false
  }
});

export default PulseContext;

import React from 'react';
import PropTypes from 'prop-types';

import { Panel } from '../Panel/index';
import BuilderStepContainer from './BuilderStepContainer';
import BuilderTitle from './BuilderTitle';

const BuilderTriggerStep = ({ children, title, ...props }) => (
  <BuilderStepContainer>
    <Panel
      header={<BuilderTitle title={title} icon="lightningBolt" />}
      {...props}>
      {children}
    </Panel>
  </BuilderStepContainer>
);

BuilderTriggerStep.propTypes = {
  title: PropTypes.string.isRequired
};

export default BuilderTriggerStep;

import { useState, useEffect } from 'react';

import { useQuery } from '@ubisend/pulse-hooks';

const useClients = (params = {}) => {
  const [clients, setClients] = useState([]);

  const query = useQuery(['clients', params]);

  useEffect(() => {
    if (query.data) {
      setClients(clients => clients.concat(query.data.data));
    }
  }, [query.data]);

  return {
    clients,
    query
  };
};
export default useClients;

import React from 'react';

import { useTheme } from '../../../hooks/index';

const Background = () => {
  const { gradient } = useTheme();

  return (
    <svg
      style={{ height: '100%', width: '100%', marginBottom: -5 }}
      preserveAspectRatio="xMidYMid slice"
      viewBox="0 0 1440 900"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <rect width="1440" height="900" fill="white" />
        <rect
          x="942.726"
          y="1204.04"
          width="1171.45"
          height="1046.97"
          transform="rotate(-120 942.726 1204.04)"
          fill="url(#paint0_linear)"
        />
        <rect
          x="-234.289"
          y="520.848"
          width="681.695"
          height="844.746"
          transform="rotate(-30 -234.289 520.848)"
          fill="url(#paint1_linear)"
        />
        <rect
          x="-268.742"
          y="23.484"
          width="859.56"
          height="459"
          transform="rotate(-30 -268.742 23.484)"
          fill="url(#paint2_linear)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="1414.27"
          y1="909.645"
          x2="922.269"
          y2="1761.81"
          gradientUnits="userSpaceOnUse">
          <stop stopColor={gradient.from} />
          <stop offset="1" stopColor={gradient.to} />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="652.377"
          y1="598.773"
          x2="936.377"
          y2="106.87"
          gradientUnits="userSpaceOnUse">
          <stop stopColor={gradient.from} />
          <stop offset="1" stopColor={gradient.to} />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="-22.106"
          y1="-106.218"
          x2="1304.21"
          y2="659.533"
          gradientUnits="userSpaceOnUse">
          <stop stopColor={gradient.from} />
          <stop offset="1" stopColor={gradient.to} />
        </linearGradient>
        <clipPath id="clip0">
          <rect width="1440" height="900" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Background;

import { useContext } from 'react';

import { NotificationContext } from '../Contexts/index';

const useNotification = () => {
  const context = useContext(NotificationContext);

  return context;
};

export default useNotification;

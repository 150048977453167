import React from 'react';
import { PropTypes } from 'prop-types';

import { BuilderContext } from '../Contexts/index';
import { useBuilderReducer } from '../reducers/index';

const BuilderProvider = ({ children, initialConversation }) => {
  const builder = useBuilderReducer(initialConversation);

  return (
    <BuilderContext.Provider value={builder}>
      {children}
    </BuilderContext.Provider>
  );
};

BuilderProvider.propTypes = {
  initialConversation: PropTypes.object.isRequired
};

export default BuilderProvider;

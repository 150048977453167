import { hasPermission } from '@ubisend/pulse-auth';

import { Groups } from './Pages/index';

const routes = [
  {
    path: '/tags',
    component: Groups,
    name: 'Tags',
    canAccess: hasPermission('view tags'),
    actions: [
      {
        title: 'Manage your tags',
        description: "Update your chatbot content's tagging system.",
        icon: 'tag'
      }
    ]
  }
];

export default routes;

import { useTickets } from '../hooks/index';

const TicketFilter = ({ children }) => {
  const { hasTickets } = useTickets();

  if (!hasTickets) {
    return null;
  }

  return children;
};

export default TicketFilter;

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
  PageWrapper,
  OneHalfLayout,
  Flex,
  useNotification
} from '@ubisend/pulse-components';
import {
  useQuerySearch,
  useQueryClient,
  useMutation
} from '@ubisend/pulse-hooks';

import { generateAccessToken } from '../api/settings';
import { CreateWorkplaceLink } from '../Components/index';

const PendingTokenPage = ({ location }) => {
  const history = useHistory();

  const { showSuccess, showError } = useNotification();

  const code = useQuerySearch(location, 'code');

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(generateAccessToken, {
    onSuccess: () => {
      showSuccess('Successfully connected Facebook Workplace account.');
      queryClient.invalidateQueries('workplace/settings');
      history.push('/facebook-workplace');
    },
    onError: () => {
      showError(
        'Could not connect Facebook Workplace account, please try again.'
      );
      history.push('/facebook-workplace');
    }
  });

  useEffect(() => {
    if (code) {
      mutate({ code });
    }
  }, [code, mutate]);

  useEffect(() => {
    if (!code) {
      showError(
        'You need to pass a Facebook Workplace code as a URL parameter.'
      );
      history.push('/facebook-workplace');
    }
  }, [code, showError, history]);

  return (
    <PageWrapper header="Facebook Workplace">
      <Flex col center>
        <OneHalfLayout>
          <CreateWorkplaceLink loading={isLoading} />
        </OneHalfLayout>
      </Flex>
    </PageWrapper>
  );
};

export default PendingTokenPage;

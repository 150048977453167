import { useLocation } from 'react-router-dom';

const useDocLocation = () => {
  const location = useLocation();

  const baseUrl = location.pathname.split('docs')[0];

  return { ...location, base_url: baseUrl };
};

export default useDocLocation;

import React from 'react';
import PropTypes from 'prop-types';

import { Select, Flex, Span } from '@ubisend/pulse-components';

import MatchSelect from './MatchSelect';

const StepMatch = ({ text = 'To', ...props }) => (
  <Flex xSpace center mb>
    <Span style={{ width: '4rem', textAlight: 'right' }}>{text}</Span>
    <Select {...props} />
    <Span>when</Span>
    <MatchSelect />
    <Span>of the following conditions are met</Span>
  </Flex>
);

StepMatch.propTypes = {
  text: PropTypes.string
};

export default StepMatch;

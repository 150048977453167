import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useComposer, useDelivery } from '../../../../../hooks/index';
import { isValidMessage } from '../../../../../utilities/index';
import BaseComposer from '../BaseComposer';

const MAX_MESSAGE_LENGTH = 1000;
const MIN_MESSAGE_LIMIT = 1;

const isValid = message => {
  return isValidMessage(message, {
    min: MIN_MESSAGE_LIMIT,
    max: MAX_MESSAGE_LENGTH
  });
};

const NumericComposer = ({ content: passedContent }) => {
  const { reply, setReply } = useComposer();
  const { sendMessage } = useDelivery();

  const { t } = useTranslation('bots');

  const content = passedContent || {
    placeholder: t('numeric_composer_label'),
    disabled: false
  };

  const handleOnChange = event => {
    const value = event.target.value;

    if (value.length >= MAX_MESSAGE_LENGTH) {
      return;
    }

    setReply(value);
  };

  const handleSubmit = () => {
    const validatedMessage = isValid(reply);

    if (!validatedMessage) {
      return;
    }

    sendMessage(validatedMessage);
    setReply('');
  };

  return (
    <BaseComposer
      inputType="number"
      value={reply}
      onChange={handleOnChange}
      handleSubmit={handleSubmit}
      showBurgerIcon={false}
      aria-label={content.placeholder}
      placeholder={content.placeholder}
      disabled={content.disabled}
    />
  );
};

NumericComposer.propTypes = {
  content: PropTypes.shape({
    placeholder: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired
  })
};

export default NumericComposer;

import query from '../query';
import LineGraphMetric from './LineGraphMetric';

const lineGraph = {
  name: 'Line graph',
  Component: LineGraphMetric,
  columns: 6,
  rows: 3,
  query
};

export default lineGraph;

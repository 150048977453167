import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Span } from '@ubisend/pulse-components';
import { useQuery } from '@ubisend/pulse-hooks';

const PermissionDebug = ({ permission, children, hasPermission }) => {
  const { data, isLoading, isSuccess } = useQuery('permissions');

  if (isLoading) {
    return null;
  }

  return (
    <Flex style={{ background: 'red', color: 'white' }}>
      <Span>
        {permission} ({hasPermission ? 'has' : "don't have"})
      </Span>
      {children}
      {isSuccess &&
        !data.data
          .map(permission => `${permission.name} ${permission.resource.name}`)
          .includes(permission) &&
        new Error(`${permission} not valid`)}
    </Flex>
  );
};

PermissionDebug.propTypes = {
  permission: PropTypes.string.isRequired,
  hasPermission: PropTypes.bool.isRequired
};

export default PermissionDebug;

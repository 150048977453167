import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { Flex, Span, Button, useNotification } from '@ubisend/pulse-components';

import { useImports } from '../hooks/index';
import { createSectionContent } from '../api/index';

const EditorActions = ({ content, valid }) => {
  const { item, section, dispatch } = useImports();
  const { showSuccess } = useNotification();

  const handleSubmit = async () => {
    const { data } = await createSectionContent(item.id, section.id, content);

    dispatch({
      type: 'REVISE_SECTION_CONTENT',
      import: item,
      section,
      content: data.data
    });
    showSuccess(
      'Successfully submitted content revision. It will need to be approved before it goes live.'
    );
  };

  const author = section.content ? section.content.user : null;

  return (
    <Flex between xSpace center>
      <Span tinyText>
        Last edited{' '}
        {dayjs(
          section.content ? section.content.created_at : section.created_at
        ).from()}
        {author?.id ? ` by ${author.name}` : ` automatically`}
      </Span>
      <Flex xSpace>
        {section.other_content.length > 0 && (
          <Button
            as={Link}
            variant="secondary"
            to={`/knowledge-bases/${item.id}/sections/${section.id}/versions`}>
            Version history
          </Button>
        )}
        <Button disabled={!valid} onClick={handleSubmit} icon="save">
          Save
        </Button>
      </Flex>
    </Flex>
  );
};

EditorActions.propTypes = {
  content: PropTypes.shape({
    name: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired
  }).isRequired,
  valid: PropTypes.bool.isRequired
};

export default EditorActions;

import React from 'react';
import PropTypes from 'prop-types';

import { useQueryClient, useMutation } from '@ubisend/pulse-hooks';
import {
  Flex,
  ModalClose,
  ModalPortal,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalBody,
  useNotification
} from '@ubisend/pulse-components';

import { editSheet } from '../../api/index';
import SheetDetails from './SheetDetails';

const EditSheet = ({ handleClose, sheet }) => {
  const { showSuccess } = useNotification();

  const queryClient = useQueryClient();
  const mutation = useMutation(editSheet, {
    onSuccess: () => {
      queryClient.invalidateQueries('spreadsheets');
      showSuccess('Successfully updated spreadsheet integration');
      handleClose();
    }
  });

  const handleSubmit = form => {
    mutation.mutate({ id: sheet.id, ...form });
  };

  return (
    <ModalPortal>
      <ModalContainer>
        <ModalContent>
          <ModalHeader>
            Update spreadsheet integration
            <Flex between>
              <ModalClose onClick={handleClose} />
            </Flex>
          </ModalHeader>
          <ModalBody>
            <SheetDetails
              handleSubmit={handleSubmit}
              loading={mutation.isLoading}
              initialName={sheet.name}
              initialFile={sheet.file}
            />
          </ModalBody>
        </ModalContent>
      </ModalContainer>
    </ModalPortal>
  );
};

EditSheet.propTypes = {
  handleClose: PropTypes.func.isRequired,
  sheet: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    file: PropTypes.object.isRequired
  }).isRequired
};

export default EditSheet;

import React from 'react';
import styled from 'styled-components';

import { FullPage, DeactivatedQueryProvider } from '@ubisend/pulse-volt';

import { BotProvider } from '../../Providers/index';

const Sticky = styled.div`
  ${tw`sticky pin-t`}
  height: calc(100vh - 12rem);
`;

const FullPagePreview = () => (
  <DeactivatedQueryProvider>
    <BotProvider>
      <Sticky>
        <FullPage />
      </Sticky>
    </BotProvider>
  </DeactivatedQueryProvider>
);

export default FullPagePreview;

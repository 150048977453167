import { useContext } from 'react';

import { IntegrationsContext } from '../Contexts/index';

const useIntegrations = () => {
  const context = useContext(IntegrationsContext);

  return context;
};

export default useIntegrations;

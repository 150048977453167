import { useEffect, useState } from 'react';

const HOURS_BETWEEN_TIMESTAMPS = 6;

const useTimestamps = (message, previousMessage = null) => {
  const [showTimestamp, setShowTimestamp] = useState(false);

  /**
   * Compare the timestamp of the current message with the previous
   * and set whether not the timestamp should be shown.
   */
  useEffect(() => {
    if (!message.sentAt) {
      return setShowTimestamp(false);
    }

    // The first message.
    if (!previousMessage) {
      return setShowTimestamp(true);
    }

    if (!previousMessage.sentAt) {
      return setShowTimestamp(false);
    }

    // If the current message timestamp is after the defined number of hours
    // since the last message, show the timestamp.
    setShowTimestamp(
      message.sentAt.isAfter(
        previousMessage.sentAt.add(HOURS_BETWEEN_TIMESTAMPS, 'hours')
      )
    );
  }, [message, previousMessage]);

  return showTimestamp;
};

export default useTimestamps;

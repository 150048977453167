import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Tag } from '@ubisend/pulse-components';

const ActiveStatus = () => {
  return (
    <Tag colour="positive" background="positive">
      Active
    </Tag>
  );
};

const ExpiringStatus = () => {
  return (
    <Tag colour="warning" background="warning">
      Expiring
    </Tag>
  );
};

const ExpiredStatus = () => {
  return (
    <Tag colour="danger" background="danger">
      Expired
    </Tag>
  );
};

const statuses = {
  active: ActiveStatus,
  expiring: ExpiringStatus,
  expired: ExpiredStatus
};

const LicenseStatus = ({ status }) => {
  const Component = useMemo(() => statuses[status], [status]);

  return <Component />;
};

LicenseStatus.propTypes = {
  status: PropTypes.oneOf(Object.keys(statuses)).isRequired
};

export default LicenseStatus;

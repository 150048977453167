import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Label, TextInput, InnerPanel } from '@ubisend/pulse-components';
import { TemplatingTextInput } from '@ubisend/pulse-templating';

const GlobalPayDetails = ({
  settings,
  onInputChange,
  onVariableInputChange
}) => {
  return (
    <>
      <Flex mb fat>
        <InnerPanel>
          <h2>Global Pay Configuration</h2>
          <Flex mb col>
            <Label htmlFor="merchant_id">Merchant ID</Label>
            <TextInput
              name="merchant_id"
              onChange={onInputChange('merchant_id')}
              value={settings.merchant_id}
              id="merchant_id"
              placeholder="ABC123"
            />
          </Flex>
          <Flex mb col>
            <Label htmlFor="account_id">Account ID</Label>
            <TextInput
              name="account_id"
              onChange={onInputChange('account_id')}
              value={settings.account_id}
              id="account_id"
              placeholder="ABC123"
            />
          </Flex>
          <Flex mb col>
            <Label htmlFor="shared_secret">Shared Secret</Label>
            <TextInput
              name="shared_secret"
              onChange={onInputChange('shared_secret')}
              value={settings.shared_secret}
              id="shared_secret"
              placeholder="Leave empty if you do not wish to update"
            />
          </Flex>
          <Flex mb col>
            <Label htmlFor="currency">Currency</Label>
            <TemplatingTextInput
              id="currency"
              placeholder="EUR"
              value={settings.currency}
              onChange={onVariableInputChange('currency')}
            />
          </Flex>
          <Flex col>
            <Label htmlFor="total">Total</Label>
            <TextInput
              name="total"
              onChange={onInputChange('total')}
              value={settings.total}
              id="total"
              placeholder="100.00"
            />
          </Flex>
        </InnerPanel>
      </Flex>
      <Flex mb>
        <InnerPanel>
          <h2>Customer Details</h2>
          <Flex mb col>
            <Label htmlFor="email">Email</Label>
            <TextInput
              name="email"
              onChange={onInputChange('email')}
              value={settings.email}
              id="email"
              placeholder="test@example.com"
            />
          </Flex>
          <Flex mb col>
            <Label htmlFor="phone">Telephone</Label>
            <TextInput
              name="phone"
              onChange={onInputChange('phone')}
              value={settings.phone}
              id="phone"
              placeholder="0123456789"
            />
          </Flex>
        </InnerPanel>
      </Flex>
      <Flex>
        <InnerPanel>
          <h2>Billing Address</h2>
          <Flex mb col>
            <Label htmlFor="billing_address_1">Address Line 1</Label>
            <TextInput
              name="billing_address_1"
              onChange={onInputChange('billing_address_1')}
              value={settings.billing_address_1}
              id="billing_address_1"
              placeholder="1, A Street"
            />
          </Flex>
          <Flex mb col>
            <Label htmlFor="billing_address_2">Address Line 2</Label>
            <TextInput
              name="billing_address_2"
              onChange={onInputChange('billing_address_2')}
              value={settings.billing_address_2}
              id="billing_address_2"
              placeholder="A Place"
            />
          </Flex>
          <Flex mb col>
            <Label htmlFor="billing_city">City</Label>
            <TextInput
              name="billing_city"
              onChange={onInputChange('billing_city')}
              value={settings.billing_city}
              id="billing_city"
              placeholder="London"
            />
          </Flex>
          <Flex mb col>
            <Label htmlFor="billing_region">Region</Label>
            <TextInput
              name="billing_region"
              onChange={onInputChange('billing_region')}
              value={settings.billing_region}
              id="billing_region"
              placeholder="London"
            />
          </Flex>
          <Flex mb col>
            <Label htmlFor="billing_postcode">Postcode</Label>
            <TextInput
              name="billing_postcode"
              onChange={onInputChange('billing_postcode')}
              value={settings.billing_postcode}
              id="billing_postcode"
              placeholder="SW1A 2AA"
            />
          </Flex>
          <Flex col>
            <Label htmlFor="billing_country">Country</Label>
            <TextInput
              name="billing_country"
              onChange={onInputChange('billing_country')}
              value={settings.billing_country}
              id="billing_country"
              placeholder="United Kingdom"
            />
          </Flex>
        </InnerPanel>
      </Flex>
    </>
  );
};

GlobalPayDetails.propTypes = {
  settings: PropTypes.shape({
    merchant_id: PropTypes.string.isRequired,
    account_id: PropTypes.string.isRequired,
    shared_secret: PropTypes.string.isRequired,
    total: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    email: PropTypes.string,
    phone: PropTypes.string,
    billing_address_1: PropTypes.string,
    billing_address_2: PropTypes.string,
    billing_city: PropTypes.string,
    billing_region: PropTypes.string,
    billing_postcode: PropTypes.string,
    billing_country: PropTypes.string
  }).isRequired,
  onInputChange: PropTypes.func.isRequired,
  onVariableInputChange: PropTypes.func.isRequired
};

export default GlobalPayDetails;

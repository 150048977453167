import {
  LocationConditional,
  LocationPreview
} from '../Components/Subjects/index';

const subject = { id: null, type: 'location' };

const conditional = {
  subject,
  type: null,
  condition: null,
  flipped: false,
  case_sensitive: false,
  target: ''
};

const locationSubject = {
  subject,
  conditional,
  name: "User's location",
  Conditional: LocationConditional,
  Preview: LocationPreview,
  toState: () => subject,
  toApi: () => subject
};

export default locationSubject;

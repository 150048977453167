import duration from './Duration/index';
import lineGraph from './LineGraph/index';
import lineGraphMulti from './LineGraphMulti/index';
import liveValue from './LiveValue/index';
import pieChart from './PieChart/index';
import table from './Table/index';
import value from './Value/index';
import barChart from './BarChart/index';

const types = {
  duration,
  'line-graph': lineGraph,
  'line-graph-multi': lineGraphMulti,
  'live-value': liveValue,
  'pie-chart': pieChart,
  table,
  value,
  'bar-chart': barChart
};

export default types;

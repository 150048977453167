import React from 'react';
import PropTypes from 'prop-types';

import { Button, Flex, TextInput } from '@ubisend/pulse-components';

const Utterance = ({
  id,
  utterance,
  placeholder,
  handleUtteranceChange,
  handleUtteranceDelete
}) => {
  const handleChange = event => {
    const value = event.target.value;
    handleUtteranceChange(id, value);
  };

  const handleDelete = () => handleUtteranceDelete(id);

  return (
    <Flex xSpace>
      <TextInput
        aria-label={`utterance-${id}`}
        value={utterance}
        placeholder={placeholder}
        onChange={handleChange}
      />
      {handleUtteranceDelete && (
        <Button
          variant="secondary"
          colour="danger"
          icon="trash"
          onClick={handleDelete}>
          Delete
        </Button>
      )}
    </Flex>
  );
};

Utterance.propTypes = {
  id: PropTypes.number.isRequired,
  utterance: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  handleUtteranceChange: PropTypes.func.isRequired,
  handleUtteranceDelete: PropTypes.func
};

export default Utterance;

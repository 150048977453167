import styled from 'styled-components';

const NodeContent = styled.div`
  ${tw`flex flex-col items-start`}
  & p {
    ${tw`m-0`}
  }
`;

export default NodeContent;

import { hasPermission } from '@ubisend/pulse-auth';

import { Composers } from './Pages/index';

const routes = [
  {
    name: 'Composers',
    path: '/composers',
    component: Composers,
    canAccess: hasPermission('view composers'),
    actions: [
      {
        title: 'Create a new composer',
        description:
          'Help your chatbot users navigate through conversations with handy message composers.',
        icon: 'chat'
      },
      {
        title: 'Set a default composer',
        description:
          'Set a default composer to be used when creating new messages.',
        icon: 'chat'
      }
    ]
  }
];

export default routes;

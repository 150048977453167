import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from '@ubisend/pulse-icons';

import Flex from '../Layout/Flex';
import { useTheme } from '../../hooks/index';

const TabButton = styled.button`
  ${tw`p-6 w-full flex rounded-lg border-none flex items-center uppercase hover:bg-grey text-left font-poppins font-semibold tracking-wide text-xs`}
  ${props => (props.active ? tw`bg-grey` : tw`bg-white`)}
  ${props =>
    props.disabled ? tw`cursor-not-allowed` : tw`cursor-pointer`}
`;

const icons = {
  success: { type: 'checkCircle', colour: 'positive' },
  warning: { type: 'exclamationCircle', colour: 'warning' },
  error: { type: 'exclamationCircle', colour: 'danger' }
};

const PanelTab = ({ children, type = 'success', icon, ...props }) => {
  const theme = useTheme();

  const { type: iconType, colour } = useMemo(() => {
    // Support custom icons being passed.
    if (icon) {
      return icon;
    }

    // Use preset icons.
    return icons[type] || icons.success;
  }, [type, icon]);
  return (
    <TabButton type="button" {...props}>
      <Flex center xSpaceSm>
        <Icon
          size="1.5rem"
          width="1.5rem"
          height="1.5rem"
          type={iconType}
          colour={theme[colour]}
        />
        <span>{children}</span>
      </Flex>
    </TabButton>
  );
};

PanelTab.propTypes = {
  icon: PropTypes.shape({
    type: PropTypes.string,
    colour: PropTypes.string
  }),
  type: PropTypes.oneOf(['success', 'error', 'warning'])
};

export default PanelTab;

import axios from 'axios';

const getGroupsForFaq = faqId => axios.get(`faqs/${faqId}/groups`);

const syncGroupsForFaq = ({ faqId, groupIds }) => {
  return axios.post(`faqs/${faqId}/groups`, {
    groupIds
  });
};

export { getGroupsForFaq, syncGroupsForFaq };

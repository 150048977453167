import React from 'react';
import PropTypes from 'prop-types';

import { Select } from '@ubisend/pulse-components';

const types = [
  { value: 'regular', label: 'Standard' },
  { value: 'live-chat', label: 'Live Chat' }
];

const TicketTypeSelect = ({ value, ...props }) => (
  <Select
    isClearable
    isMulti
    options={types}
    value={
      value && value.map(value => types.find(type => type.value === value))
    }
    {...props}
  />
);

TicketTypeSelect.propTypes = {
  value: PropTypes.array
};

export default TicketTypeSelect;

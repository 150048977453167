import axios from 'axios';

const enableDriver = id => {
  return axios.post('owner/auth/', { id });
};

const disableDriver = id => {
  return axios.delete(`owner/auth/${id}`);
};

const updateOAuthSettings = settings => {
  return axios.put('owner/auth/oauth2/settings', settings);
};

export { enableDriver, disableDriver, updateOAuthSettings };

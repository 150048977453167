import { useQuery } from '@ubisend/pulse-hooks';

const useVariables = () => {
  const { data = [] } = useQuery('resolvers');

  return {
    variables: data.reduce((all, current) => all.concat(current.variables), []),
    groupedVariables: data
  };
};

export default useVariables;

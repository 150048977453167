import styled from 'styled-components';

const ArrowHead = styled.div`
  width: 10px;
  height: 10px;
  margin-left: -6px;
  margin-top: -3px;
  &::before {
    display: block;
    background: #888888;
    height: 2px;
    width: 10px;
    content: '';
    transform: rotate(45deg);
    border-radius: 5px;
  }
  &::after {
    display: block;
    background: #888888;
    height: 2px;
    width: 10px;
    content: '';
    transform: translate(6px, -2px) rotate(-45deg);
    border-radius: 5px;
  }
`;

export default ArrowHead;

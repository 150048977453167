import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useMutation } from '@ubisend/pulse-hooks';
import {
  Label,
  Flex,
  Button,
  useNotification,
  useModal
} from '@ubisend/pulse-components';
import { CustomVariableSelect } from '@ubisend/pulse-integrations';
import { useConditionalReducer } from '@ubisend/pulse-conditionals';

import Modal from '../../../Modal';
import { conditionals as conditionalSubjects } from '../../../../../../subjects/index';
import { useBuilder } from '../../../../hooks/index';
import {
  updateStepTransition as updateStepTransitionApi,
  updateStepTrigger as updateStepTriggerApi
} from '../../../../api/index';

const EditVariableBlock = ({ handleClose, node, block }) => {
  const [variable, setVariable] = useState(block.base.id);

  const { dispatch } = useBuilder();
  const { showModal, hideModal } = useModal();
  const { showSuccess } = useNotification();
  const conditionals = useConditionalReducer({
    subjects: conditionalSubjects,
    conditionals: {
      match: node.base.match,
      conditionals: node.base.conditionals
    }
  });

  const updateStepTransition = useMutation(updateStepTransitionApi, {
    onSuccess: ({ data }) => {
      showSuccess('Successfully updated variables');
      dispatch({
        type: 'UPDATE_TRANSITION',
        stepId: node.meta.stepId,
        transitionId: node.id,
        transitionDetails: data.data,
        transitionType: node.type
      });
      handleClose();
    }
  });
  const updateStepTrigger = useMutation(updateStepTriggerApi, {
    onSuccess: ({ data }) => {
      showSuccess('Successfully updated variables');
      dispatch({
        type: 'UPDATE_TRIGGER',
        stepId: node.meta.stepId,
        triggerId: node.id,
        triggerDetails: data.data
      });
      handleClose();
    }
  });

  const handleVariableChange = variable => {
    setVariable(variable ? variable.value : null);
  };

  const valid = useMemo(() => {
    return Boolean(variable);
  }, [variable]);

  const isLoading = useMemo(() => {
    return updateStepTransition.isLoading && updateStepTrigger.isLoading;
  }, [updateStepTransition.isLoading, updateStepTrigger.isLoading]);

  const updateTransitionVariables = callback => {
    const getDescription = () => {
      if (node.type === 'validation') {
        return `Validation for step #${node.base.destination.id}`;
      }

      return `Transition to step #${node.base.destination.id}`;
    };

    return {
      stepId: node.meta.stepId,
      transitionId: node.id,
      transition: {
        description: getDescription(),
        transition: {
          id: node.base.destination.id,
          ...conditionals.form,
          variables: callback(node.base.variables),
          metrics: node.base.metrics.map(metric => metric.id)
        }
      }
    };
  };

  const updateTriggerVariables = callback => {
    return {
      stepId: node.meta.stepId,
      triggerId: node.id,
      trigger: {
        description: `Trigger step #${node.meta.stepId}`,
        ...conditionals.form,
        variables: callback(node.base.variables),
        metrics: node.base.metrics.map(metric => metric.id)
      }
    };
  };

  const handleTriggerUpdate = () => {
    if (valid && !isLoading) {
      updateStepTrigger.mutate(
        updateTriggerVariables(variables => {
          return variables.map(({ id }) => {
            if (id === block.base.id) {
              return variable;
            }

            return id;
          });
        })
      );
    }
  };

  const handleTransitionUpdate = () => {
    if (valid && !isLoading) {
      updateStepTransition.mutate(
        updateTransitionVariables(variables => {
          return variables.map(({ id }) => {
            if (id === block.base.id) {
              return variable;
            }

            return id;
          });
        })
      );
    }
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (node.type === 'trigger') {
      handleTriggerUpdate();
    } else {
      handleTransitionUpdate();
    }
  };

  const handleTriggerDelete = () => {
    return updateStepTrigger.mutateAsync(
      updateTriggerVariables(variables => {
        return variables
          .filter(({ id }) => id !== block.base.id)
          .map(({ id }) => id);
      })
    );
  };

  const handleTransitionDelete = () => {
    return updateStepTransition.mutateAsync(
      updateTransitionVariables(variables => {
        return variables
          .filter(({ id }) => id !== block.base.id)
          .map(({ id }) => id);
      })
    );
  };

  const handleDelete = () => {
    showModal({
      header: 'Remove variable',
      body: `Are you sure you want to remove this variable?`,
      handleConfirm: async () => {
        try {
          if (node.type === 'trigger') {
            await handleTriggerDelete();
          } else {
            await handleTransitionDelete();
          }
        } finally {
          hideModal();
        }
      }
    });
  };

  return (
    <Modal
      header="Edit variable"
      handleClose={handleClose}
      actions={
        <Button
          variant="secondary"
          colour="danger"
          icon="trash"
          onClick={handleDelete}
          loading={isLoading}>
          Delete
        </Button>
      }>
      <form onSubmit={handleSubmit}>
        <Flex pad col>
          <Flex col mb>
            <Label htmlFor="variable">Variable</Label>
            <CustomVariableSelect
              id="variable"
              value={variable}
              onChange={handleVariableChange}
            />
          </Flex>
          <Flex right>
            <Button icon="save" type="submit" disabled={isLoading || !valid}>
              Save
            </Button>
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};

EditVariableBlock.propTypes = {
  handleClose: PropTypes.func.isRequired,
  node: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    meta: PropTypes.shape({
      stepId: PropTypes.number.isRequired
    }).isRequired,
    base: PropTypes.shape({
      match: PropTypes.string.isRequired,
      conditionals: PropTypes.array.isRequired,
      destination: PropTypes.shape({
        id: PropTypes.number.isRequired
      }).isRequired,
      metrics: PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired
      ).isRequired,
      variables: PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired
      ).isRequired
    }).isRequired
  }).isRequired,
  block: PropTypes.shape({
    base: PropTypes.shape({
      id: PropTypes.number.isRequired
    }).isRequired
  }).isRequired
};

export default EditVariableBlock;

import React from 'react';
import { Switch } from 'react-router-dom';

import { Route } from '@ubisend/pulse-auth';

import { ClientSidebar, OwnerSidebar, NoSidebar } from './Sidebars/index';

const sidebars = [
  {
    path: '/demo/public',
    component: NoSidebar,
    public: true
  },
  {
    path: '/accounts/:id/switch',
    component: NoSidebar
  },
  {
    path: '/owner',
    component: OwnerSidebar
  },
  {
    path: '/',
    component: ClientSidebar
  }
];

const Sidebar = () => {
  return (
    <Switch>
      {sidebars.map((sidebar, key) => (
        <Route
          exact={false}
          public={sidebar.public ?? false}
          key={key}
          path={sidebar.path}
          canAccess={() => true}
          render={props => <sidebar.component {...props} />}
        />
      ))}
    </Switch>
  );
};

export default Sidebar;

import React from 'react';
import PropTypes from 'prop-types';

import { TextInput } from '@ubisend/pulse-components';

const AttributeInput = ({ onChange, ...props }) => {
  const handleChange = event => {
    const value = event.target.value;

    onChange({ value, label: value });
  };

  return (
    <TextInput placeholder="user_name" onChange={handleChange} {...props} />
  );
};

AttributeInput.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default AttributeInput;

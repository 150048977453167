import React from 'react';
import { useHistory, Redirect } from 'react-router-dom';

import { useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import { PanelSlider } from '@ubisend/pulse-components';

import { createFileSource } from '../api/index';
import { FileSourceDetails } from '../Components/index';

const CreatedFileSourceRedirect = () => {
  return (
    <Redirect
      to={{
        pathname: '/files/storages',
        state: {
          notification: {
            type: 'success',
            message: 'Successfully created new file storage'
          }
        }
      }}
    />
  );
};

const CreateFileSource = () => {
  const history = useHistory();

  const queryClient = useQueryClient();
  const mutation = useMutation(createFileSource, {
    onSuccess: () => {
      queryClient.invalidateQueries('files/sources');
    }
  });

  const handleSubmit = form => {
    mutation.mutate(form);
  };

  return (
    <PanelSlider
      width={'40rem'}
      header={`Create file storage`}
      handleHide={() => history.push('/files/storages')}>
      {mutation.isSuccess && <CreatedFileSourceRedirect />}
      <FileSourceDetails
        handleSubmit={handleSubmit}
        loading={mutation.isLoading}
      />
    </PanelSlider>
  );
};

export default CreateFileSource;

import { useState, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';

const FooterPortal = ({ children }) => {
  const [root, setRoot] = useState();

  useLayoutEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      return;
    }

    setRoot(document.getElementById('chatbot-footer'));
  }, []);

  if (process.env.NODE_ENV === 'test') {
    return children;
  }

  if (!root) {
    return null;
  }

  return ReactDOM.createPortal(children, root);
};

export default FooterPortal;

import React from 'react';
import PropTypes from 'prop-types';

import { useQuery, useQueryClient, useMutation } from '@ubisend/pulse-hooks';
import { CreatableSelect, formatSelectOption } from '@ubisend/pulse-components';

import { createGroup } from '../api/index';

const GroupSelect = ({ value, onChange, for: scope = 'tickets', ...props }) => {
  const queryClient = useQueryClient();
  const { data, isLoading, isSuccess } = useQuery([
    'groups',
    { scoped_to: scope }
  ]);
  const { mutateAsync } = useMutation(createGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries(['groups', { scoped_to: scope }]);
    }
  });

  const handleGroupChange = async initialOptions => {
    const items = initialOptions || [];

    const groups = await Promise.all(
      items.map(async ({ __isNew__: isNew, ...item }) => {
        if (isNew) {
          const { data } = await mutateAsync({
            name: item.value,
            scoped_to: scope
          });
          return { value: data.data.id, label: data.data.name };
        }

        return item;
      })
    );

    onChange(groups);
  };

  return (
    <CreatableSelect
      closeMenuOnSelect
      isMulti
      isClearable
      isLoading={isLoading}
      options={isSuccess ? data.data.map(formatSelectOption) : []}
      onChange={handleGroupChange}
      placeholder="Tags"
      value={
        isSuccess &&
        value &&
        value.map(id => {
          const value = data.data.find(group => group.id === id);

          if (!value) {
            return null;
          }

          return formatSelectOption(value);
        })
      }
      {...props}
    />
  );
};

GroupSelect.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  for: PropTypes.oneOf(['faqs', 'tickets', 'conversations', 'links', 'files'])
};

export default GroupSelect;

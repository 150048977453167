import {
  SectionConditional,
  SectionPreview
} from '../../Components/Subjects/index';

const subject = {
  type: 'section',
  section_id: null,
  import_id: null
};

const conditional = {
  subject,
  type: null,
  condition: null,
  flipped: false,
  case_sensitive: false,
  target: ''
};

const sectionSubject = {
  subject,
  conditional,
  permission: 'view knowledge bases',
  name: 'Imported content',
  Conditional: SectionConditional,
  Preview: SectionPreview,
  toState: ({ subject }) => {
    return {
      type: 'section',
      section_id: subject.id,
      import_id: subject.import_id
    };
  },
  toApi: ({ subject }) => {
    return { id: subject.section_id, type: 'section' };
  }
};

export default sectionSubject;

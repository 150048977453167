import { useEffect, useCallback } from 'react';

import useSubscriptionContext from './useSubscriptionContext';

const useNotifySubscription = (event, handle) => {
  const { socket } = useSubscriptionContext();

  const scopedHandle = useCallback(
    ({ type, content }) => {
      if (type === event) {
        handle(content);
      }
    },
    [event, handle]
  );

  useEffect(() => {
    if (handle) {
      socket.on('notify', scopedHandle);
    }

    return () => {
      if (handle) {
        socket.off('notify', scopedHandle);
      }
    };
  }, [socket, handle, scopedHandle]);

  const emit = useCallback(params => socket.emit(event, params), [
    event,
    socket
  ]);

  return emit;
};

export default useNotifySubscription;

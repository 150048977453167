import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Panel, InnerPanel, Toggle, Flex } from '@ubisend/pulse-components';
import { Markdown } from '@ubisend/pulse-markdown';

const ImagePreview = styled.img`
  width: 100%;
`;

const PdfPanel = styled(InnerPanel)`
  ${tw`overflow-y-auto`}
  max-height: calc(100vh - 15rem);
`;

const PdfImportPreview = ({ value, image }) => {
  const [showPdfPreview, setShowPdfPreview] = useState(false);

  const togglePreviewType = () => setShowPdfPreview(!showPdfPreview);

  return (
    <Flex>
      <Panel
        header="Parsed Content"
        actions={
          image && (
            <Toggle
              handleToggleChange={togglePreviewType}
              on={showPdfPreview}
              options={['Content', 'Image']}
              neutral
            />
          )
        }>
        <Flex mt>
          <PdfPanel>
            {!showPdfPreview && <Markdown>{value}</Markdown>}
            {showPdfPreview && (
              <ImagePreview src={image.link} alt={`PDF Cover Image`} />
            )}
          </PdfPanel>
        </Flex>
      </Panel>
    </Flex>
  );
};

PdfImportPreview.propTypes = {
  value: PropTypes.string.isRequired,
  image: PropTypes.shape({
    link: PropTypes.string.isRequired
  })
};

export default PdfImportPreview;

import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { createBrowserHistory } from 'history';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import { NotificationProvider, ModalProvider } from '@ubisend/pulse-components';
import { DeviceProvider } from '@ubisend/pulse-component-hooks';
import { AuthProvider } from '@ubisend/pulse-auth';
import { SubscriptionProvider } from '@ubisend/pulse-subscribe';
import { LanguageProvider } from '@ubisend/pulse-hooks';

import './config/dayjs';
import './config/sentry';
import queryClient from './config/queryClient';
import Cms from './Cms';
import { ReportingProvider, PulseProvider } from './Providers/index';

import './styles/global.css';

const history = createBrowserHistory();

const render = (options = {}) => {
  ReactDOM.render(
    <ReportingProvider>
      <DeviceProvider>
        <QueryClientProvider client={queryClient}>
          <PulseProvider options={options}>
            <Router history={history}>
              <NotificationProvider>
                <ModalProvider>
                  <SubscriptionProvider
                    url={process.env.REACT_APP_SOCKET_SERVER}>
                    <AuthProvider>
                      <LanguageProvider>
                        <DndProvider backend={HTML5Backend}>
                          <Cms />
                        </DndProvider>
                      </LanguageProvider>
                    </AuthProvider>
                  </SubscriptionProvider>
                </ModalProvider>
              </NotificationProvider>
            </Router>
          </PulseProvider>
        </QueryClientProvider>
      </DeviceProvider>
    </ReportingProvider>,
    document.getElementById('root')
  );
};

const pulse = {
  render
};

export default pulse;

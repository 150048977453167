import axios from 'axios';

const createGroup = params => axios.post('groups', params);

const updateGroup = ({ id, ...params }) => {
  return axios.put(`groups/${id}`, params);
};

const deleteGroup = groupId => axios.delete(`groups/${groupId}`);

export { createGroup, updateGroup, deleteGroup };

import React, { useEffect } from 'react';

import { Flex, Heading1, Paragraph } from '@ubisend/pulse-components';

import { LoggedOutRedirect } from '../Components/index';
import { useAuth } from '../hooks/index';

const Logout = () => {
  const { authenticated, logoutUser } = useAuth();

  useEffect(() => {
    if (authenticated) {
      logoutUser();
    }
  }, [authenticated, logoutUser]);

  return (
    <Flex col fat yScreen center middle pad>
      {!authenticated && <LoggedOutRedirect />}
      <Heading1 style={{ fontSize: '2rem' }} mb textCenter>
        Logging you out
      </Heading1>
      <Paragraph secondary textCenter>
        Please wait and you will be logged out
      </Paragraph>
    </Flex>
  );
};

export default Logout;

import React from 'react';
import PropTypes from 'prop-types';

import { PaymentProvider } from '../../../../../Providers/index';
import { GlobalPay, NoProvider } from './Providers/index';
import PaymentPreview from './PaymentPreview';
import { useDelivery } from '../../../../../hooks/index';

const providers = {
  'global-pay': GlobalPay
};

const Payment = props => {
  const { hasChannel } = useDelivery();

  // Handle showing a payment provider outside of a conversation.
  // E.g. In the conversation builder or building a response via the Message Composer.
  if (!hasChannel) {
    return <PaymentPreview {...props} />;
  }

  const Provider = providers[props.content.processor] || NoProvider;

  return (
    <PaymentProvider processor={props.content.processor}>
      <Provider {...props} />
    </PaymentProvider>
  );
};

Payment.propTypes = {
  content: PropTypes.shape({
    processor: PropTypes.oneOf(['global-pay'])
  })
};

export default Payment;

import { hasPermission } from '@ubisend/pulse-auth';

import {
  Notifications,
  CreateNotification,
  EditNotification
} from './Pages/index';

const routes = [
  {
    path: '/notifications',
    component: Notifications,
    name: 'Notifications',
    canAccess: hasPermission('view notifications'),
    actions: [
      {
        title: 'Manage your notifications',
        description:
          'Create, manage, and delete notifications that your subscribers receive.',
        icon: 'users'
      }
    ]
  },
  {
    path: '/notifications/create',
    component: CreateNotification,
    name: 'Create Notification',
    canAccess: hasPermission('view notifications')
  },
  {
    path: '/notifications/:id',
    component: EditNotification,
    name: 'Edit Notification',
    canAccess: hasPermission('view notifications')
  }
];

export default routes;

import React from 'react';

import { TableCell } from '@ubisend/pulse-components';

const TicketGroupTableCell = ({ children }) => (
  <TableCell
    style={{
      maxWidth: '15vw',
      whiteSpace: 'nowrap',
      overflowY: 'auto'
    }}>
    {children}
  </TableCell>
);

export default TicketGroupTableCell;

import React from 'react';

import { Label, FormGroup } from '@ubisend/pulse-components';
import { TemplatingTextArea } from '@ubisend/pulse-templating';

import { useComposer } from '../../../../hooks/index';
import { ButtonComposer } from '../../../Buttons/index';

const ActionInput = () => {
  const {
    response: { content },
    dispatch
  } = useComposer();

  const handleResponseChange = value => {
    dispatch({
      type: 'UPDATE_CONTENT',
      newContent: { text: value, responses: content.responses }
    });
  };

  const handleResponsesChange = responses => {
    dispatch({
      type: 'UPDATE_CONTENT',
      newContent: { text: content.text, responses }
    });
  };

  return (
    <div>
      <FormGroup>
        <Label htmlFor="action">Message</Label>
        <TemplatingTextArea
          id="action"
          name="action"
          value={content.text}
          onChange={handleResponseChange}
        />
      </FormGroup>
      <ButtonComposer
        value={content.responses}
        onChange={handleResponsesChange}
      />
    </div>
  );
};

export default ActionInput;

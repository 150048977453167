import React from 'react';
import PropTypes from 'prop-types';

import { CreatableSelect } from '@ubisend/pulse-components';

const options = [
  'Cache-Control',
  'Host',
  'User-Agent',
  'Accept',
  'Accept-Encoding',
  'Connection',
  'Content-Type'
].map(option => ({ value: option, label: option }));

const HeaderSelect = ({ value, ...props }) => (
  <CreatableSelect
    {...props}
    closeMenuOnSelect
    isClearable
    value={options.find(option => option.value === value)}
    options={options}
  />
);

HeaderSelect.propTypes = {
  value: PropTypes.string
};

export default HeaderSelect;

import image from './Image/index';
import spreadsheet from './Spreadsheet/index';
import pdf from './Pdf/index';
import document from './Document/index';
import qrCode from './QrCode/index';

const types = {
  image,
  spreadsheet,
  pdf,
  document,
  'qr-code': qrCode
};

export default types;

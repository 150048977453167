import styled from 'styled-components';

import { motion } from '@ubisend/framer-motion';

import { colours } from '../styles';

const Label = styled(motion.label)`
  &,
  & * {
    ${tw`block text-xs uppercase tracking-wide font-semibold font-poppins text-black`}
    ${colours}
  }

  ${({ secondary = false }) => secondary && `opacity: 0.5;`}
  ${({ mb = true }) => mb && tw`mb-2`}
  ${colours}
`;

export default Label;

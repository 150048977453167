import {
  LanguageConditional,
  LanguagePreview
} from '../Components/Subjects/index';

const subject = { id: null, type: 'language' };

const conditional = {
  subject,
  type: null,
  condition: null,
  flipped: false,
  case_sensitive: false,
  target: ''
};

const languageSubject = {
  subject,
  conditional,
  name: "User's language",
  Conditional: LanguageConditional,
  Preview: LanguagePreview,
  toState: () => subject,
  toApi: () => subject
};

export default languageSubject;

import { useState, useEffect } from 'react';

const useScripts = (src, options = {}, variable) => {
  const [data, setData] = useState();

  // Store options as a string so React can easily diff the string,
  // rather than an object
  const wrappedOptions = JSON.stringify(options);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = src;

    const options = JSON.parse(wrappedOptions);

    for (const key in options) {
      script[key] = options[key];
    }

    if (variable) {
      script.onload = () => setData(window[variable]);
    }

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [variable, src, wrappedOptions]);

  return data;
};

export default useScripts;

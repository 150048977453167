import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useMutation } from '@ubisend/pulse-hooks';
import { AnimatePresence } from '@ubisend/framer-motion';
import {
  Flex,
  Divider,
  useModal,
  useNotification,
  useTheme,
  Tooltip,
  ActionMenu
} from '@ubisend/pulse-components';
import Icon from '@ubisend/pulse-icons';
import {
  defaultStepTrigger,
  formatStepTrigger,
  TriggerStepProvider
} from '@ubisend/pulse-demo';
import { PermissionFilter } from '@ubisend/pulse-auth';

import {
  useBuilder,
  useBank,
  useAllowedBlocks,
  useCanvas
} from '../../../../hooks/index';
import { isUnlinked, isSensitive } from '../../../../utils/index';
import { deleteStep as deleteStepApi } from '../../../../api/index';
import { nodeType } from '../../../../PropTypes/index';
import { Block, types } from '../../../Blocks/index';
import NodeHeader from '../../NodeHeader';
import NodeContent from '../../NodeContent';
import NodeFooter from '../../NodeFooter';
import EditStepNode from './EditStepNode';
import EditResponse from './EditResponse';
import AdvancedTestStepModal from './AdvancedTestStepModal';
import NodeButton from '../../NodeButton';

const StepNodeTooltip = ({ node }) => {
  const theme = useTheme();

  if (isUnlinked(node)) {
    return (
      <Tooltip
        tooltip={
          <Tooltip.Content>
            This Step is currently unreachable in this conversation. Add a
            trigger or link another step to connect it.
          </Tooltip.Content>
        }>
        <Icon
          stroke={theme.danger}
          size="1rem"
          width="1rem"
          height="1rem"
          type="exclamation"
          outline
          style={{ cursor: 'auto' }}
        />
      </Tooltip>
    );
  }

  if (isSensitive(node)) {
    return (
      <Tooltip
        tooltip={
          <Tooltip.Content>
            This step is marked as sensitive, no responses from the chatbot user
            will be stored.
          </Tooltip.Content>
        }>
        <Icon
          stroke={theme.warning}
          size="1rem"
          width="1rem"
          height="1rem"
          type="eyeOff"
          outline
          style={{ cursor: 'auto' }}
        />
      </Tooltip>
    );
  }

  return null;
};

StepNodeTooltip.propTypes = {
  node: nodeType
};

const StepNode = ({ node, handleEditBlock }) => {
  const [edit, setEdit] = useState(false);
  const [editResponse, setEditResponse] = useState(false);
  const [advancedTest, setAdvancedTest] = useState(false);

  const { conversation, dispatch: builderDispatch } = useBuilder();
  const blocks = useAllowedBlocks(node.blocks, types);
  const { dispatch: bankDispatch } = useBank();
  const { showModal, hideModal } = useModal();
  const { showSuccess } = useNotification();
  const { handleCanvasRender } = useCanvas();

  const deleteStep = useMutation(deleteStepApi, {
    onSuccess: () => {
      builderDispatch({ type: 'DELETE_STEP', stepId: node.id });
      showSuccess('Successfully deleted step');
    }
  });

  const handleDelete = () => {
    showModal({
      header: 'Delete step',
      body: `Are you sure you want to delete this step?`,
      handleConfirm: async () => {
        try {
          await deleteStep.mutateAsync({
            conversationId: conversation.id,
            stepId: node.id
          });
          handleCanvasRender();
        } finally {
          hideModal();
        }
      }
    });
  };

  const handleStepTest = step => {
    bankDispatch({
      type: 'TEST_STEP',
      demoTrigger: formatStepTrigger({
        ...defaultStepTrigger,
        step_id: step.id
      })
    });
  };

  const handleAdvancedStepTest = trigger => {
    bankDispatch({
      type: 'TEST_STEP',
      demoTrigger: trigger
    });
    setAdvancedTest(false);
  };

  return (
    <>
      <AnimatePresence>
        {edit && (
          <EditStepNode handleClose={() => setEdit(false)} node={node} />
        )}
        {editResponse && (
          <EditResponse
            handleClose={() => setEditResponse(false)}
            node={node}
          />
        )}
        {advancedTest && (
          <TriggerStepProvider
            initialTrigger={{
              ...defaultStepTrigger,
              step_id: node.base.id
            }}>
            <AdvancedTestStepModal
              handleClose={() => setAdvancedTest(false)}
              handleSubmit={handleAdvancedStepTest}
              loading={false}
            />
          </TriggerStepProvider>
        )}
      </AnimatePresence>
      <NodeHeader>
        <Flex center>
          <StepNodeTooltip node={node} />
          <ActionMenu
            buttonProps={{
              'aria-label': 'Toggle step menu',
              onMouseDown: event => event.stopPropagation(),
              onMouseUp: event => event.stopPropagation()
            }}>
            <PermissionFilter can="edit conversations">
              <>
                <NodeButton
                  variant="inline"
                  icon="pencilAlt"
                  onClick={() => {
                    setEdit(true);
                  }}>
                  Edit Step
                </NodeButton>
                <Divider mtNone mbNone />
              </>
            </PermissionFilter>
            {node.base.responses.length > 0 && (
              <>
                <PermissionFilter can="edit conversations">
                  <>
                    <NodeButton
                      variant="inline"
                      icon="pencilAlt"
                      onClick={() => {
                        setEditResponse(true);
                      }}>
                      Edit Response
                    </NodeButton>
                    <Divider mtNone mbNone />
                  </>
                </PermissionFilter>
                <NodeButton
                  variant="inline"
                  icon="chat"
                  onClick={() => {
                    handleStepTest(node.base);
                  }}>
                  Test Step
                </NodeButton>
                <Divider mtNone mbNone />
                <NodeButton
                  variant="inline"
                  icon="chatAlt"
                  onClick={() => {
                    setAdvancedTest(true);
                  }}>
                  Test Step (Advanced)
                </NodeButton>
                <Divider mtNone mbNone />
              </>
            )}
            <PermissionFilter can="delete conversations">
              <>
                <NodeButton
                  icon="trash"
                  variant="inline"
                  colour="danger"
                  onClick={handleDelete}>
                  Delete step
                </NodeButton>
              </>
            </PermissionFilter>
          </ActionMenu>
        </Flex>
      </NodeHeader>
      {node.base.responses.length > 0 && blocks.length > 0 ? (
        <NodeFooter>
          <Flex xScroll xSpaceSm>
            {blocks.map((block, key) => (
              <Block
                key={key}
                onClick={() => handleEditBlock(block)}
                {...block}
              />
            ))}
          </Flex>
        </NodeFooter>
      ) : (
        <NodeContent>
          <Flex xScroll xSpaceSm>
            {blocks.map((block, key) => (
              <Block
                key={key}
                onClick={() => handleEditBlock(block)}
                {...block}
              />
            ))}
          </Flex>
        </NodeContent>
      )}
    </>
  );
};

StepNode.propTypes = {
  node: PropTypes.shape({
    id: PropTypes.number.isRequired,
    numberOfAddons: PropTypes.number,
    numberOfParents: PropTypes.number,
    base: PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      responses: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string.isRequired,
          content: PropTypes.object.isRequired
        }).isRequired
      ).isRequired
    }).isRequired,
    blocks: PropTypes.arrayOf(PropTypes.object).isRequired
  }).isRequired,
  handleEditBlock: PropTypes.func.isRequired
};

export default StepNode;

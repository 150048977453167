import { useEffect } from 'react';

import { useTemplating } from '../../hooks/index';

const InputFocus = () => {
  const templating = useTemplating();

  useEffect(() => {
    if (
      templating.justAddedSnippet.current ||
      templating.justClosedVariables.current
    ) {
      templating.justAddedSnippet.current = false;
      templating.justClosedVariables.current = false;
      templating.input.current.selectionStart = templating.cursor.current;
      templating.input.current.selectionEnd = templating.cursor.current;
      templating.input.current.focus();
    }
  });

  return null;
};

export default InputFocus;

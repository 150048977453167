import React from 'react';

import { Flex } from '@ubisend/pulse-components';
import {
  MultiComposerMessageWrapper,
  MultiComposerGroupWrapper
} from '@ubisend/pulse-composer';
import { ConverseMessage, useBot } from '@ubisend/pulse-volt';

const ComposerWidget = () => {
  const { messages } = useBot();

  return (
    <Flex col fat ySpace>
      <MultiComposerGroupWrapper>
        {messages.map((message, key) => (
          <MultiComposerMessageWrapper key={key} index={key} message={message}>
            <ConverseMessage
              message={message}
              handleButtonClick={() => {}}
              groupIndex={0}
              group={[message]}
              groups={[[messages]]}
            />
          </MultiComposerMessageWrapper>
        ))}
      </MultiComposerGroupWrapper>
    </Flex>
  );
};

export default ComposerWidget;

import React from 'react';
import PropTypes from 'prop-types';

import { Flex, SecondarySidebarLink } from '@ubisend/pulse-components';

import { useDocLocation } from '../hooks/index';

const isActive = (pathname, link) => {
  if (!link) {
    return false;
  }

  const splitPath = pathname.split('docs')[1];
  const splitLink = link.split('docs')?.[1] || link;

  const cleanPath = splitPath[0] === '/' ? splitPath.slice(1) : splitPath;
  const cleanLink = splitLink[0] === '/' ? splitLink.slice(1) : splitLink;

  return cleanPath === cleanLink;
};

const SidebarItem = ({ items, label, to }) => {
  const location = useDocLocation();

  const hasItems = items?.length > 0;

  return (
    <Flex col ml>
      <SecondarySidebarLink active={isActive(location.pathname, to)} to={to}>
        {label}
      </SecondarySidebarLink>
      {hasItems &&
        open &&
        items.map((item, key) => (
          <>
            <SidebarItem
              key={key}
              items={item.children}
              label={item.label}
              to={`${location.base_url}docs/${item.url}`}
            />
          </>
        ))}
    </Flex>
  );
};

SidebarItem.propTypes = {
  items: PropTypes.array,
  label: PropTypes.string,
  to: PropTypes.string
};

export default SidebarItem;
export { isActive };

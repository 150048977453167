import React from 'react';

import {
  PageWrapper,
  useNotification,
  useModal
} from '@ubisend/pulse-components';
import {
  useLanguages,
  Drivers,
  useQuery,
  useMutation,
  useQueryClient
} from '@ubisend/pulse-hooks';
import { useAuth } from '@ubisend/pulse-auth';

import {
  associateLanguage as associateLanguageApi,
  removeLanguage as removeLanguageApi
} from '../api/index';

const Languages = () => {
  const { showModal, hideModal } = useModal();
  const { languages } = useLanguages();
  const { showSuccess } = useNotification();
  const { hasPermission } = useAuth();

  const queryClient = useQueryClient();
  const { data: allLanguages } = useQuery('languages/all');
  const { mutateAsync: associateLanguage } = useMutation(associateLanguageApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('languages');
    }
  });
  const { mutateAsync: removeLanguage } = useMutation(removeLanguageApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('languages');
    }
  });

  const handleLanguage = updatedLanguage => {
    if (languages.find(language => language.id === updatedLanguage.id)) {
      showModal({
        header: 'Remove Language',
        body: `Are you sure you want to deactivate this language? This will also delete all of your responses and utterances for this language.`,
        handleConfirm: async () => {
          try {
            await removeLanguage(updatedLanguage.id);
            showSuccess(
              `Successfully deactived the ${updatedLanguage.name} Language.`
            );
          } finally {
            hideModal();
          }
        }
      });
    } else {
      showModal({
        header: 'Add Language',
        body: `Are you sure you want to activate ${updatedLanguage.name}?`,
        handleConfirm: async () => {
          try {
            await associateLanguage(updatedLanguage.id);
            showSuccess(
              `Successfully activated the ${updatedLanguage.name} Language.`
            );
          } finally {
            hideModal();
          }
        }
      });
    }
  };

  return (
    <PageWrapper
      header="Languages"
      subheader="Choose which languages your bot can speak">
      <Drivers
        name="Language"
        type="multi"
        required
        value={languages.map(language => language.id)}
        drivers={
          allLanguages?.data.map(({ preview = false, ...language }) => ({
            ...language,
            preview,
            logo: 'globe'
          })) || []
        }
        handleClick={handleLanguage}
        canEditDriver={() => false}
        canEnableDriver={() => hasPermission('activate languages')}
        canDisableDriver={() => hasPermission('deactivate languages')}
      />
    </PageWrapper>
  );
};

export default Languages;

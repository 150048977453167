import ReactDOM from 'react-dom';

const ModalPortal = ({ children }) => {
  if (process.env.NODE_ENV === 'test') {
    return children;
  }

  return ReactDOM.createPortal(
    children,
    document.getElementById('modal-container')
  );
};

export default ModalPortal;

import React from 'react';

const FaqContext = React.createContext({
  faq: null,
  setFaq: () => {},
  fetchFaq: () => {},
  hasSharedFaqs: false,
  utterances: null,
  setUtterances: () => {},
  fetchUtterances: () => {},
  groups: null,
  setGroups: () => {},
  fetchGroups: () => {}
});

export default FaqContext;

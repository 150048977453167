import React, { useEffect, useState } from 'react';

import {
  Flex,
  Button,
  Placeholder,
  Divider,
  Span,
  useNotification
} from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';

import {
  getCallToActions,
  createCallToAction,
  updateCallToAction,
  deleteCallToAction
} from '../../api/index';
import CallToActionDetails from './CallToActionDetails';

const CallToActions = () => {
  const [callToActions, setCallToActions] = useState();
  const [create, setCreate] = useState(false);
  const [edit, setEdit] = useState(false);

  const { showSuccess } = useNotification();

  const fetchCallToActions = async () => {
    const { data } = await getCallToActions();

    setCallToActions(data.data);
  };

  useEffect(() => {
    fetchCallToActions();
  }, []);

  const handleCreate = () => setCreate(true);

  const stopCreating = () => setCreate(false);

  const stopEditing = () => setEdit(false);

  const handleCreateSubmit = async params => {
    const { data } = await createCallToAction(params);

    setCallToActions(callToActions => callToActions.concat(data.data));
    showSuccess('Successfully created a new call to action');
    stopCreating();
  };

  const handleUpdateSubmit = async params => {
    const { data } = await updateCallToAction(edit.id, params);

    setCallToActions(callToActions => {
      return callToActions.map(callToAction => {
        if (callToAction.id === edit.id) {
          return data.data;
        }

        return callToAction;
      });
    });
    showSuccess('Successfully updated your call to action');
    stopEditing();
  };

  const handleDeleteSubmit = async ({ id }) => {
    await deleteCallToAction(id);

    setCallToActions(callToActions => {
      return callToActions.filter(callToAction => callToAction.id !== id);
    });
    showSuccess('Successfully deleted your call to action');
  };

  if (!callToActions) {
    return <Placeholder items={1} subitems={3} />;
  }

  return (
    <Flex col ySpace>
      {create && (
        <CallToActionDetails
          handleSubmit={handleCreateSubmit}
          handleCancel={stopCreating}
        />
      )}
      {edit && (
        <CallToActionDetails
          handleSubmit={handleUpdateSubmit}
          handleCancel={stopEditing}
          initialDescription={edit.description}
          initialMessage={edit.message}
          initialConditionals={{
            conditionals: edit.conditionals,
            match: edit.match
          }}
        />
      )}
      {callToActions.length === 0 && (
        <Span light>No Call to Actions have been setup yet</Span>
      )}
      {callToActions.map((callToAction, key) => (
        <Flex between center key={key}>
          <Span>{callToAction.description}</Span>
          <Flex xSpace>
            <PermissionFilter can="edit converse settings">
              <Button variant="secondary" onClick={() => setEdit(callToAction)}>
                Edit
              </Button>
            </PermissionFilter>
            <PermissionFilter can="delete converse settings">
              <Button
                variant="secondary"
                colour="danger"
                icon="trash"
                onClick={() => handleDeleteSubmit(callToAction)}>
                Delete
              </Button>
            </PermissionFilter>
          </Flex>
        </Flex>
      ))}
      <PermissionFilter can="create converse settings">
        <Divider mtNone />
        <Flex right>
          <Button variant="secondary" onClick={handleCreate}>
            + Add new
          </Button>
        </Flex>
      </PermissionFilter>
    </Flex>
  );
};

export default CallToActions;

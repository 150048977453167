/* eslint-disable i18next/no-literal-string */
import React from 'react';

import { Flex } from '@ubisend/pulse-components';

import Bubble from './Bubble';

const Dynamic = ({ direction, ...props }) => {
  return (
    <Flex fat right={direction === 'recievedMessage'}>
      <Bubble direction={direction} {...props}>
        Dynamic message
      </Bubble>
    </Flex>
  );
};

Dynamic.propTypes = {
  direction: Bubble.propTypes.direction
};

export default Dynamic;

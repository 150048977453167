import React from 'react';

import { FormGroup, Label } from '@ubisend/pulse-components';
import { useComposer } from '@ubisend/pulse-composer';

import { IntegrationSelect, EndpointSelect } from '../../../Integrations/index';

const IntegrationComposer = () => {
  const {
    response: { subject },
    dispatch
  } = useComposer();

  const handleIntegrationChange = option => {
    dispatch({
      type: 'CHANGE_SUBJECT',
      newSubject: {
        ...subject,
        integration_id: option ? option.value : null,
        endpoint_id: null
      }
    });
  };

  const handleEndpointChange = ({ value }) => {
    dispatch({
      type: 'CHANGE_SUBJECT',
      newSubject: { ...subject, endpoint_id: value }
    });
  };

  return (
    <div>
      <FormGroup>
        <Label>Integration</Label>
        <IntegrationSelect
          aria-label={'integration'}
          value={subject.integration_id}
          onChange={handleIntegrationChange}
        />
      </FormGroup>
      <FormGroup>
        <Label>Endpoint</Label>
        <EndpointSelect
          aria-label="endpoint"
          integration={subject.integration_id}
          value={subject.endpoint_id}
          onChange={handleEndpointChange}
        />
      </FormGroup>
    </div>
  );
};

export default IntegrationComposer;

import React from 'react';
import { Redirect } from 'react-router-dom';

import { useQuery, useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import {
  OneHalfLayout,
  PageWrapper,
  Panel,
  useNotification,
  Placeholder
} from '@ubisend/pulse-components';
import { saveQrCode } from '@ubisend/pulse-qr-codes';
import { uploadFile } from '@ubisend/pulse-files';

import { createLink, updateLink } from '../api/index';
import { LinkDetails } from '../Components/index';

const createLinkAndQrCode = async ({ link, source }) => {
  const { data } = await createLink(link);
  const image = await saveQrCode(data.data.tracked_url, 'svg', {
    color: { dark: '#000000', light: '#ffffff' },
    margin: 0
  });
  const { data: file } = await uploadFile({
    endpoint: 'files',
    source_id: source.id,
    file: image,
    type: 'qr-code'
  });
  await updateLink({
    id: data.data.id,
    qr_code_id: file.data.id,
    ...link
  });

  return data;
};

const CreateLink = () => {
  const { showSuccess } = useNotification();

  const queryClient = useQueryClient();
  const query = useQuery('files/sources');
  const mutate = useMutation(createLinkAndQrCode, {
    onSuccess: () => {
      showSuccess(`Successfully created new tracked link.`);
      queryClient.invalidateQueries('links');
    }
  });

  const handleSubmit = form => {
    mutate.mutate({
      link: form,
      source: query.data.data.find(source => source.built_in)
    });
  };

  return (
    <>
      {mutate.isSuccess && <Redirect to={{ pathname: `/links` }} />}
      <PageWrapper header="Links" subheader="Create a trackable link">
        <OneHalfLayout>
          <Panel>
            {query.isLoading && <Placeholder items={1} subitems={3} />}
            {query.isSuccess && (
              <LinkDetails
                handleSubmit={handleSubmit}
                loading={mutate.isLoading}
              />
            )}
          </Panel>
        </OneHalfLayout>
      </PageWrapper>
    </>
  );
};

export default CreateLink;

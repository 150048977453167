import React from 'react';
import PropTypes from 'prop-types';

import { Label, TextInput, Flex } from '@ubisend/pulse-components';

const BasicAuth = ({ credentials, handleCredentialsChange }) => {
  const handleUserNameChange = event => {
    const username = event.target.value;

    handleCredentialsChange({ password: credentials.password, username });
  };

  const handlePasswordChange = event => {
    const password = event.target.value;

    handleCredentialsChange({ username: credentials.username, password });
  };

  return (
    <>
      <Flex col>
        <Label htmlFor="username">User Name</Label>
        <TextInput
          id="username"
          placeholder="john_smith"
          value={credentials.username}
          onChange={handleUserNameChange}
        />
      </Flex>
      <Flex col>
        <Label htmlFor="password">Password</Label>
        <TextInput
          id="password"
          type="password"
          placeholder="*************"
          value={credentials.password}
          onChange={handlePasswordChange}
        />
      </Flex>
    </>
  );
};

BasicAuth.propTypes = {
  credentials: PropTypes.shape({
    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired
  }).isRequired,
  handleCredentialsChange: PropTypes.func.isRequired
};

export default BasicAuth;

import axios from 'axios';

const createResource = (importId, sectionId, params) => {
  return axios.post(
    `imports/${importId}/sections/${sectionId}/resources`,
    params
  );
};

const deleteResource = (importId, sectionId, resourceId, sourceId) => {
  return axios.delete(
    `imports/${importId}/sections/${sectionId}/resources/${resourceId}`,
    { data: { source_id: sourceId } }
  );
};

export { createResource, deleteResource };

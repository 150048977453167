import { hasPermission } from '@ubisend/pulse-auth';

import { PendingTokenPage, LinkFacebookWorkplace } from './Pages/index';

const routes = [
  {
    name: 'Facebook Workplace',
    path: '/facebook-workplace',
    component: LinkFacebookWorkplace,
    canAccess: hasPermission('view facebook workplace settings'),
    actions: [
      {
        title: 'Manage your Workplace channel',
        description:
          'Connect, disconnect, or edit your Facebook Workplace integration.',
        icon: 'lightningBolt'
      }
    ]
  },
  {
    name: 'Facebook Workplace',
    path: '/facebook-workplace/pending',
    component: PendingTokenPage,
    canAccess: hasPermission('view facebook workplace settings')
  }
];

export default routes;

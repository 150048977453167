import React from 'react';

import { AnimatePresence, AnimateSharedLayout } from '@ubisend/framer-motion';

import Flex from '../Layout/Flex';
import Notification from './Notification';
import { useNotification } from '../../hooks/index';

const Notifications = () => {
  const { notifications, closeNotification } = useNotification();

  return (
    <Flex
      pinBottom
      absolute
      fat
      center
      ySpace
      col
      pad
      style={{ zIndex: 100, pointerEvents: 'none' }}>
      <AnimateSharedLayout>
        <AnimatePresence>
          {notifications.map(({ id, message, type }) => (
            <Notification
              key={id}
              title={`${type.charAt(0).toUpperCase()}${type.slice(1)}`}
              subtitle={message}
              type={type}
              handleClose={() => closeNotification(id)}
            />
          ))}
        </AnimatePresence>
      </AnimateSharedLayout>
    </Flex>
  );
};

export default Notifications;

import React from 'react';
import PropTypes from 'prop-types';

import { Select, formatSelectOption } from '@ubisend/pulse-components';
import { useLocations } from '@ubisend/pulse-hooks';

const LocationSelect = ({ value, isMulti = false, ...rest }) => {
  const { locations } = useLocations();

  const formatValue = () => {
    if (isMulti) {
      return value.map(value =>
        formatSelectOption(locations.find(({ id }) => id === value))
      );
    }

    return formatSelectOption(locations.find(({ id }) => id === value));
  };
  return (
    <Select
      aria-label="locations"
      isLoading={!locations}
      closeMenuOnSelect
      isClearable
      options={locations ? locations.map(formatSelectOption) : []}
      value={locations && value && formatValue()}
      isMulti={isMulti}
      {...rest}
    />
  );
};

LocationSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.number
  ]).isRequired,
  isMulti: PropTypes.bool
};

export default LocationSelect;

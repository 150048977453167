import axios from 'axios';

const getAllDrivers = () => axios.get(`/nlp/all`);

const getDriver = () => axios.get(`/nlp`);

const updateDriver = id => axios.put(`/nlp`, { id });

const updateSemanticDriver = id => axios.put(`/semantic-search`, { id });

export { getAllDrivers, getDriver, updateDriver, updateSemanticDriver };

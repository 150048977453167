import axios from 'axios';

const createClient = attributes => axios.post(`owner/clients`, attributes);

const updateClient = ({ id, ...params }) => {
  return axios.put(`owner/clients/${id}`, params);
};

const updateClientLogo = ({ id, ...params }) => {
  return axios.put(`owner/clients/${id}/image`, params);
};

const deleteClient = id => axios.delete(`owner/clients/${id}`);

const switchAccounts = id => {
  return axios.put(`clients/swap/${id}`);
};

export {
  createClient,
  updateClient,
  updateClientLogo,
  deleteClient,
  switchAccounts
};

import axios from 'axios';

const deleteVariable = id => axios.delete(`variables/${id}`);

const updateVariable = ({ id, ...params }) => {
  return axios.put(`variables/${id}`, params);
};

const createVariable = params => axios.post(`variables`, params);

export { deleteVariable, updateVariable, createVariable };

import React, { useCallback } from 'react';

import RecordTyping from './RecordTyping';
import {
  startTyping as startTypingApi,
  stopTyping as stopTypingApi
} from '../api/index';
import { useMutation } from '../hooks/index';

const RecordSubscriberTyping = () => {
  const { mutate: startTypingMutation, isEnabled } = useMutation(
    startTypingApi
  );
  const { mutate: stopTypingMutation } = useMutation(stopTypingApi);

  const startTyping = useCallback(() => {
    startTypingMutation();
  }, [startTypingMutation]);

  const stopTyping = useCallback(() => {
    stopTypingMutation();
  }, [stopTypingMutation]);

  return (
    <RecordTyping
      enabled={isEnabled}
      startTyping={startTyping}
      stopTyping={stopTyping}
    />
  );
};

export default RecordSubscriberTyping;

import React from 'react';
import { Bar } from 'react-chartjs-2';

import { Heading2, Flex } from '@ubisend/pulse-components';

import { useChartColours, useMetric } from '../../../hooks/index';

const BarChartMetric = () => {
  const { metric } = useMetric();
  const colours = useChartColours();

  return (
    <Flex pad col tall>
      <Heading2>{metric.data.label}</Heading2>
      <Flex tall>
        <Bar
          options={{
            animations: false,
            maintainAspectRatio: false,
            plugins: {
              legend: { display: false }
            },
            scales: {
              y: {
                ticks: {
                  callback: function (value) {
                    if (Number.isInteger(value)) {
                      return value;
                    }
                  }
                }
              }
            }
          }}
          data={{
            labels: metric.data.data.labels,
            datasets: metric.data.data.datasets.map(set => ({
              ...set,
              backgroundColor: set.data.map(
                (_, key) => colours(set.data.length)[key]
              )
            }))
          }}
        />
      </Flex>
    </Flex>
  );
};

export default BarChartMetric;

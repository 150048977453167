import React from 'react';

import { ColourPicker } from '@ubisend/pulse-components';

import { useQrCode } from '../hooks/index';

const LightColourInput = props => {
  const { colour, dispatch } = useQrCode();

  const handleColourChange = colour => {
    dispatch({ type: 'CHANGE_COLOUR', colour: { light: colour.hex } });
  };

  return (
    <ColourPicker
      {...props}
      color={colour.light}
      onChangeComplete={handleColourChange}
    />
  );
};

export default LightColourInput;

import IntegrationInput from './IntegrationInput';

const integration = {
  key: 'integration',
  content: {
    use_entire_response: true,
    target: ''
  },
  format: ({ key, variable, content }) => {
    if (content.use_entire_response) {
      return `${key}.${variable}`;
    }

    return `${key}.${variable}.${content.target}`;
  },
  Input: IntegrationInput,
  valid: content => {
    if (content.use_entire_response) {
      return true;
    }

    return content.target.length > 0;
  }
};

export default integration;

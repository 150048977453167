import ImagePreview from './ImagePreview';
import CreateImage from './CreateImage';

const image = {
  type: 'image',
  name: 'Images',
  icon: 'photograph',
  Preview: ImagePreview,
  Create: CreateImage
};

export default image;

import React from 'react';
import { Doughnut } from 'react-chartjs-2';

import { Flex, Heading2, Span } from '@ubisend/pulse-components';

import { useChartColours, useMetric } from '../../../hooks/index';

const PieChartMetric = () => {
  const { metric } = useMetric();
  const colours = useChartColours();

  const hasAtleastOneSlice = metric.data.data.data.some(segment => segment > 0);

  if (hasAtleastOneSlice) {
    return (
      <Flex pr mr col middle center style={{ height: '200px' }}>
        <Flex mbSm>
          <Heading2>{metric.data.label}</Heading2>
        </Flex>
        <Doughnut
          id="pie"
          redraw={false}
          options={{
            animations: false,
            plugins: {
              legend: { position: 'right', title: { text: 'Legend' } }
            },
            aspectRatio: 4,
            responsive: true,
            maintainAspectRatio: true
          }}
          data={{
            labels: metric.data.data.labels,
            datasets: [
              {
                backgroundColor: colours(metric.data.data.data.length),
                data: metric.data.data.data
              }
            ]
          }}
        />
      </Flex>
    );
  }

  return (
    <Flex col middle center>
      <Flex mbSm>
        <Heading2>{metric.data.label}</Heading2>
      </Flex>
      <Span light>No results, please check back later</Span>
    </Flex>
  );
};

export default PieChartMetric;

import React from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import {
  Flex,
  Label,
  Select,
  TextInput,
  Button
} from '@ubisend/pulse-components';

import { useFileSourceReducer } from '../reducers/index';
import { FileSourceContext } from '../Contexts/index';
import FileDriverContent from './FileDriverContent';

const FileDriverSelect = ({ value, ...props }) => {
  const query = useQuery('files/drivers');

  const format = driver => {
    return {
      label: driver.name,
      value: driver.name
    };
  };

  return (
    <Select
      value={
        value && query.isSuccess
          ? format(query.data.data.find(driver => driver.name === value))
          : null
      }
      options={query.isSuccess ? query.data.data.map(format) : []}
      isLoading={query.isLoading}
      {...props}
    />
  );
};

FileDriverSelect.propTypes = {
  value: PropTypes.string
};

const FileSourceDetails = ({ initialFileSource, loading, handleSubmit }) => {
  const reducer = useFileSourceReducer(initialFileSource);

  const onSubmit = event => {
    event.preventDefault();

    if (reducer.valid && !loading) {
      handleSubmit(reducer.form);
    }
  };

  const handleNameChange = event => {
    const value = event.target.value;

    reducer.dispatch({ type: 'UPDATE_NAME', name: value });
  };

  const handleDriverChange = option => {
    reducer.dispatch({
      type: 'UPDATE_DRIVER_NAME',
      fileDriverName: option.value
    });
  };

  return (
    <form onSubmit={onSubmit}>
      <Flex col mb>
        <Label htmlFor="name">Name</Label>
        <TextInput
          id="name"
          placeholder="Confidential file storage"
          value={reducer.source.name}
          onChange={handleNameChange}
        />
      </Flex>
      <Flex col mb>
        <Label htmlFor="source">Source</Label>
        <FileDriverSelect
          id="source"
          queryKey="files/sources"
          value={reducer.source.file_driver_name}
          onChange={handleDriverChange}
        />
      </Flex>
      <FileSourceContext.Provider value={reducer}>
        <FileDriverContent />
      </FileSourceContext.Provider>
      <Flex right>
        <Button type="submit" disabled={!reducer.valid || loading}>
          Save
        </Button>
      </Flex>
    </form>
  );
};

FileSourceDetails.propTypes = {
  initialFileSource: PropTypes.shape({
    file_driver_name: PropTypes.string,
    name: PropTypes.string,
    content: PropTypes.object
  }),
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired
};

export default FileSourceDetails;

import styled from 'styled-components';
import PropTypes from 'prop-types';

const Indicator = styled.span`
  ${tw`w-2 h-2 block rounded-full`}
  background-color: ${({ theme, colour }) => theme[colour]};
`;

Indicator.propTypes = {
  colour: PropTypes.oneOf(['danger', 'warning', 'positive'])
};

export default Indicator;

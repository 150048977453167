import React from 'react';

const Twitter = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32pt"
    height="32pt"
    viewBox="0 0 34 32"
    version="1.1"
    {...props}>
    <g id="surface1">
      <path d="M 0.25 27.53125 C 3.726562 27.84375 6.855469 26.96875 9.707031 24.832031 C 6.726562 24.570312 4.730469 23.082031 3.617188 20.292969 C 4.59375 20.414062 5.507812 20.445312 6.476562 20.179688 C 3.261719 19.179688 1.550781 17.082031 1.320312 13.695312 C 2.28125 14.144531 3.1875 14.46875 4.257812 14.480469 C 2.351562 13.050781 1.375 11.195312 1.414062 8.855469 C 1.429688 7.78125 1.71875 6.769531 2.289062 5.8125 C 5.820312 9.945312 10.25 12.230469 15.707031 12.613281 C 15.675781 12.375 15.65625 12.1875 15.632812 11.992188 C 15.179688 8.53125 17.429688 5.382812 20.867188 4.726562 C 23.023438 4.3125 24.929688 4.882812 26.542969 6.367188 C 26.792969 6.59375 27.007812 6.644531 27.320312 6.5625 C 28.574219 6.242188 29.769531 5.78125 30.925781 5.15625 C 30.480469 6.617188 29.570312 7.71875 28.332031 8.644531 C 28.613281 8.59375 28.898438 8.554688 29.179688 8.5 C 29.476562 8.4375 29.769531 8.367188 30.0625 8.289062 C 30.34375 8.210938 30.617188 8.125 30.894531 8.03125 C 31.175781 7.9375 31.457031 7.832031 31.789062 7.773438 C 31.625 8 31.46875 8.238281 31.292969 8.457031 C 30.570312 9.375 29.730469 10.179688 28.8125 10.898438 C 28.71875 10.976562 28.636719 11.136719 28.644531 11.25 C 28.695312 13.46875 28.382812 15.632812 27.664062 17.730469 C 26.25 21.867188 23.789062 25.21875 20.09375 27.613281 C 18.269531 28.792969 16.273438 29.570312 14.144531 30.019531 C 12.03125 30.460938 9.90625 30.542969 7.773438 30.292969 C 5.636719 30.042969 3.605469 29.414062 1.695312 28.425781 C 1.1875 28.167969 0.699219 27.882812 0.207031 27.605469 C 0.226562 27.582031 0.238281 27.554688 0.25 27.53125 Z M 0.25 27.53125 " />
    </g>
  </svg>
);

export default Twitter;

import React, { useState } from 'react';

import { useLanguages } from '@ubisend/pulse-hooks';

import { LanguageContext } from '../Contexts/index';

const LanguageProvider = ({ children }) => {
  const { defaultLanguage } = useLanguages();

  const [language, setLanguage] = useState(defaultLanguage.id);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;

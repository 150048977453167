import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { DateTimePicker } from '@ubisend/pulse-components';

/**
 * Display a date and time condition.
 * Stored in format:    YYYY-MM-DD HH:mm:ss
 * Displayed in format: DD-MM-YYYY HH:mm:ss
 */
const DateTimeCondition = ({ onChange, value, ...props }) => {
  const handleChange = ([date]) => {
    onChange(dayjs(date).format('YYYY-MM-DD HH:mm:ss'));
  };

  return (
    <DateTimePicker
      placeholder="01-01-2020 12:00:00"
      enableTime
      options={{
        noCalendar: false,
        dateFormat: 'd-m-Y H:i:S'
      }}
      onChange={handleChange}
      date={value ? dayjs(value).format('DD-MM-YYYY HH:mm:ss') : null}
      {...props}
    />
  );
};

DateTimeCondition.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default DateTimeCondition;

import React from 'react';

/**
 * Context to store handlers that interact with the bots
 * message delivery service.
 */
const DeliveryContext = React.createContext({
  sendMessage: (message, postback) => message || postback,
  resubscribe: () => {},
  channel: null,
  setChannel: null
});

export default DeliveryContext;

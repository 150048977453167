import OAuthLogin from './OAuthLogin';
import OAuthSettings from './OAuthSettings';
import OAuthInvite from './OAuthInvite';

const oAuth = {
  name: 'OAuth 2.0',
  Login: OAuthLogin,
  Settings: OAuthSettings,
  Invite: OAuthInvite
};

export default oAuth;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { TextInput, Button, Label, Flex } from '@ubisend/pulse-components';

const LocationDetails = ({ handleSubmit, initialName = '' }) => {
  const [name, setName] = useState(initialName);

  const handleNameChange = event => setName(event.target.value);

  const onSubmit = async event => {
    event.preventDefault();
    await handleSubmit({ name });
  };

  return (
    <form onSubmit={onSubmit}>
      <Flex col mb>
        <Label htmlFor="name">Name</Label>
        <TextInput
          id="name"
          placeholder="London"
          value={name}
          onChange={handleNameChange}
        />
      </Flex>
      <Flex right>
        <Button type="submit" icon="save">
          Save
        </Button>
      </Flex>
    </form>
  );
};

LocationDetails.propTypes = {
  initialName: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired
};

export default LocationDetails;

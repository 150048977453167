import { useContext } from 'react';

import { BuilderContext } from '../Contexts/index';

const useBuilder = () => {
  const context = useContext(BuilderContext);

  return context;
};

export default useBuilder;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AnimatePresence } from '@ubisend/framer-motion';
import {
  Flex,
  Button,
  Accordion,
  Explainer,
  Span,
  InlineNotification,
  Checkbox
} from '@ubisend/pulse-components';

import FormGroup from './FormGroup';
import SettingContainer from './SettingContainer';
import { useResubscribe, useMutation, useTheme } from '../../hooks/index';
import { deleteSubscriber } from '../../api/index';
import getErrorMessage from './errors';

const DeleteData = () => {
  const [confirmed, setConfirmed] = useState(false);

  const { t } = useTranslation('bots');

  const resubscribe = useResubscribe();
  const theme = useTheme();

  const mutation = useMutation(deleteSubscriber);

  const handleDelete = () => {
    mutation.mutate();
    resubscribe.resubscribe();
  };

  const isLoading = resubscribe.isLoading || mutation.isLoading;
  const isSuccess = resubscribe.isSuccess && mutation.isSuccess;
  const isError = mutation.isError;
  const isEnabled = mutation.isEnabled;

  const valid = Boolean(isEnabled && !isLoading && !isSuccess && !isError);

  return (
    <SettingContainer>
      <Accordion divider header={t('delete_setting_header')}>
        <FormGroup>
          <Explainer type="danger" hideLabel>
            <Span tinyText colour="black">
              {t('delete_setting_description')}
            </Span>
          </Explainer>
          <Flex col>
            <Flex col fat ySpace>
              <AnimatePresence>
                {isError && (
                  <InlineNotification
                    description={t(getErrorMessage(mutation.error))}
                    type="error"
                  />
                )}
              </AnimatePresence>
              <AnimatePresence>
                {isSuccess && (
                  <InlineNotification
                    description={t('delete_setting_success_message')}
                    type="success"
                  />
                )}
              </AnimatePresence>
              {valid && (
                <Checkbox
                  checked={confirmed}
                  onChange={() => setConfirmed(!confirmed)}
                  label={t('delete_setting_confirmation_message')}
                  colour={theme.sentMessage?.background || theme.gradient.from}
                />
              )}
              <Flex>
                <Button
                  icon="trash"
                  disabled={!valid || !confirmed}
                  onClick={handleDelete}
                  customTextColour={theme.sentMessage?.text || 'white'}>
                  {t('delete_setting_submit_button')}
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </FormGroup>
      </Accordion>
    </SettingContainer>
  );
};

export default DeleteData;

import IntegrationSubject from './IntegrationSubject';

const subject = {
  type: 'endpoint',
  endpoint_id: null,
  integration_id: null,
  target: ''
};

const integrationSubject = {
  subject,
  name: 'Use API response',
  permission: 'view integrations',
  Subject: IntegrationSubject,
  toState: (subject = {}) => {
    return {
      type: 'endpoint',
      endpoint_id: subject.id || null,
      integration_id: subject.integration_id || null,
      target: subject.target || ''
    };
  },
  toApi: subject => {
    return {
      type: 'endpoint',
      id: subject.endpoint_id,
      target: subject.target
    };
  }
};

export default integrationSubject;

import axios from 'axios';

const getFullPageSettings = () => axios.get('full-page/settings');

const updateFullPageSettings = (settings = {}) => {
  return axios.put('full-page/settings', settings);
};

const updateFullPageLogo = logo => {
  return axios.put('full-page/settings/images', {
    logo_id: logo.id,
    source_id: logo.source.id
  });
};

export { getFullPageSettings, updateFullPageSettings, updateFullPageLogo };

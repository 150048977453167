import React from 'react';
import PropTypes from 'prop-types';

import { TextInput, Label } from '@ubisend/pulse-components';

const DateInput = ({ content, onChange }) => {
  const handleChange = event => {
    const format = event.target.value;

    onChange({ format });
  };

  return (
    <div>
      <Label htmlFor="format">Date format</Label>
      <TextInput
        id="format"
        placeholder="D M Y"
        value={content.format}
        onChange={handleChange}
      />
    </div>
  );
};

DateInput.propTypes = {
  content: PropTypes.shape({
    format: PropTypes.string.isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired
};

export default DateInput;

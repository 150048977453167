import React from 'react';
import { Redirect } from 'react-router-dom';

const UnauthorizedRedirect = () => {
  return (
    <Redirect
      to={{
        pathname: '/home',
        state: {
          notification: {
            type: 'information',
            message:
              'You do not have access to that page, please contact your account manager to request access.'
          }
        }
      }}
    />
  );
};

export default UnauthorizedRedirect;

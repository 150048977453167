import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { VERSION } from './constants';

Sentry.init({
  dsn: process.env.REACT_APP_ERROR_TRACKING_API_KEY,
  environment: process.env.REACT_APP_ERROR_TRACKING_ENV || process.env.NODE_ENV,
  // Remove alpha version from sentry.
  // E.g. 1.8.4-alpha.1 will be stored as 1.8.4-alpha
  release: `pulse-ui@${VERSION.replace(/-alpha(\.\d)/, '-alpha')}`,
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  ignoreErrors: [
    // API errors
    /^Request failed with status code.*/,
    'Network Error',
    'Request aborted',
    // Browser errors
    'ResizeObserver loop limit exceeded',
    // Not supported browser errors
    "'window.requestAnimationFrame' is not a function",
    "undefined is not an object (evaluating 'window.webkit.messageHandlers')",
    // Extension errors
    /^safari-extension.*/,
    // Nothing useful errors
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
    'Error: SyntaxError: SyntaxError',
    'Env not inited yet',
    'this.ws.close is not a function',
    "Unable to get property 'entry' of undefined or null reference",
    "TypeError: undefined is not an object (evaluating 'p.manager')",
    'An event processor returned null, will not send event.'
  ],
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i
  ],
  tracesSampleRate: 0.01
});

import { useQueries as reactQueryUseQueries } from 'react-query';

import { formatQuery } from './useQuery';

const useQueries = (...params) => {
  const queries = reactQueryUseQueries(...params);

  return queries.map(formatQuery);
};

export default useQueries;

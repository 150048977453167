import url from './url';
import ip from './ip';
import browser from './browser';
import os from './os';
import language from './language';
import location from './location';

const conditionals = [url, ip, browser, os, language, location];

export default conditionals;

import React from 'react';
import PropTypes from 'prop-types';

import { Span } from '@ubisend/pulse-components';

const BooleanPreview = ({ value }) => {
  if (value) {
    return <Span colour="positive">True</Span>;
  }

  return <Span colour="danger">False</Span>;
};

BooleanPreview.propTypes = {
  value: PropTypes.bool.isRequired
};

export default BooleanPreview;

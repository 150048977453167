import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';

const ThemeProvider = ({ children, theme }) => (
  <StyledComponentsThemeProvider theme={theme}>
    {children}
  </StyledComponentsThemeProvider>
);

ThemeProvider.propTypes = {
  theme: PropTypes.shape({
    white: PropTypes.string.isRequired,
    black: PropTypes.string.isRequired,
    grey: PropTypes.string.isRequired,
    primary: PropTypes.string.isRequired,
    secondary: PropTypes.string.isRequired,
    positive: PropTypes.string.isRequired,
    danger: PropTypes.string.isRequired,
    warning: PropTypes.string.isRequired
  }).isRequired
};

const defaultColours = {
  // Core
  white: '#ffffff',
  black: '#1a1a1a',
  grey: '#908c97',

  // Brand
  primary: '#684b99',
  secondary: '#b15199',

  // Status
  positive: '#3bb6a1',
  danger: '#e62851',
  warning: '#ffc25d'
};

const DefaultThemeProvider = ({ children }) => (
  <StyledComponentsThemeProvider theme={defaultColours}>
    {children}
  </StyledComponentsThemeProvider>
);

export { ThemeProvider, DefaultThemeProvider };

import React from 'react';

const noop = () => undefined;

const defaultBotContext = {
  // Global
  resetOnClose: false,
  // Header
  name: '',
  logo: '',
  showName: false,
  showLogo: false,
  showClose: false,
  title: '',
  subtitle: '',
  // CTA
  showCta: false,
  setShowCta: noop,
  ctaText: '',
  setCtaText: noop,
  // Banner
  showBanner: false,
  // Body
  messages: [],
  setMessages: noop,
  MessageWrapper: ({ children }) => children,
  GroupWrapper: ({ children }) => children,
  showPoweredByUbisend: true,
  // Avatars
  showAvatars: false,
  botAvatarUrl: '',
  humanAvatarUrl: '',
  // Burger
  showBurgerIcon: false,
  showBurgerMenu: false,
  setShowBurgerMenu: () => {},
  buttons: [],
  // Lightbox
  lightBoxImage: '',
  setLightBoxImage: noop,
  // Handlers
  resetBot: false,
  // Event Hooks.
  onWidgetShow: noop,
  onWidgetOpen: noop,
  onWidgetClose: noop,
  onCtaHide: noop,
  onLightboxOpen: noop,
  onLightboxClose: noop
};

const BotContext = React.createContext(defaultBotContext);

export default BotContext;
export { defaultBotContext };

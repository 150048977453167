import React from 'react';
import PropTypes from 'prop-types';

import { Toggle, Flex, Span, ActionMenu } from '@ubisend/pulse-components';

const CaseSensitiveToggle = ({
  onChange,
  value,
  index,
  'aria-label': ariaLabel
}) => {
  return (
    <Flex center noGrow>
      <ActionMenu buttonProps={{ 'aria-label': ariaLabel }}>
        <Flex inline center xSpace pad>
          <Span uppercase data-testid={'casing-tooltip-text'}>
            Case Sensitive
          </Span>
          <Toggle
            title={'case-sensitive'}
            on={Boolean(value)}
            handleToggleChange={onChange}
            aria-label={`case-sensitive-toggle-${index}`}
          />
        </Flex>
      </ActionMenu>
    </Flex>
  );
};

CaseSensitiveToggle.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool,
  index: PropTypes.number.isRequired,
  'aria-label': PropTypes.stringg
};

export default CaseSensitiveToggle;

import React from 'react';

import { Label, Flex } from '@ubisend/pulse-components';
import { TemplatingTextInput } from '@ubisend/pulse-templating';

import { useVariable } from '../../../../hooks/index';

const SaveVariable = () => {
  const { variable, dispatch } = useVariable();

  const handleTargetChange = value => {
    dispatch({
      type: 'CHANGE_SUBJECT',
      newSubject: { target: value }
    });
  };

  return (
    <Flex col pl pr pt>
      <Label htmlFor="target">Variable</Label>
      <TemplatingTextInput
        id="target"
        placeholder="{{my_variable}}"
        value={variable.subject.target}
        onChange={handleTargetChange}
      />
    </Flex>
  );
};

export default SaveVariable;

import axios from 'axios';

const getAllChannels = () => axios.get(`channels/all`);

const getChannels = () => axios.get(`channels`);

const associateChannel = id => {
  return axios.post('channels', {
    id
  });
};

const removeChannel = id => axios.delete(`channels/${id}`);

export { getAllChannels, getChannels, associateChannel, removeChannel };

import axios from 'axios';

const createFileSource = params => {
  return axios.post('/files/sources', params);
};

const updateFileSource = ({ id, ...params }) => {
  return axios.put(`/files/sources/${id}`, params);
};

const deleteFileSource = id => {
  return axios.delete(`/files/sources/${id}`);
};

export { createFileSource, updateFileSource, deleteFileSource };

import { useState, useRef, useCallback, useLayoutEffect, useMemo } from 'react';

const defaultOptions = { targetParent: false };

const useInfiniteScroll = (callback, initialOptions = defaultOptions) => {
  const options = useMemo(() => {
    return {
      ...defaultOptions,
      ...initialOptions
    };
  }, [initialOptions]);

  const [fetchingResults, setFetchingResults] = useState(false);
  const containerRef = useRef();

  const handleScroll = useCallback(
    async event => {
      // If we are already loading more history, bail.
      if (fetchingResults) {
        return;
      }

      const { target } = event;

      // If we are at the bottom of the panel, fetch more results.
      if (target.scrollHeight - target.scrollTop === target.clientHeight) {
        setFetchingResults(true);
        await callback();
        setFetchingResults(false);
      }
    },
    [fetchingResults, callback]
  );

  useLayoutEffect(() => {
    if (!containerRef.current) {
      return;
    }

    // Some components are contained within a container that handles the scroll
    // (RightPanelSlider), so we can use that for the height instead.
    const ref = options.targetParent
      ? containerRef.current.parentNode
      : containerRef.current;

    ref.addEventListener('scroll', handleScroll);

    return () => {
      ref.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, options]);

  return { ref: containerRef, loading: fetchingResults };
};

export default useInfiniteScroll;

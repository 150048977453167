import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { motion, AnimatePresence } from '@ubisend/framer-motion';

import SplitText from '../SplitText';
import Actions from '../Actions';

const Subtitle = styled(motion.p)`
  ${tw`md:text-lg text-base text-center w-full`}
`;

const Container = styled.div`
  ${tw`px-4 md:p-0`}
`;

const General = ({ content }) => {
  return (
    <Container>
      <SplitText layout="position">{content.title}</SplitText>
      <AnimatePresence exitBeforeEnter>
        <Subtitle
          key={content.subtitle}
          layout
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { delay: 0.2 } }}>
          {content.subtitle}
        </Subtitle>
      </AnimatePresence>
      <Actions responses={content.responses} />
    </Container>
  );
};

General.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    responses: PropTypes.array.isRequired
  }).isRequired
};

export default General;

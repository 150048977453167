import React from 'react';
import PropTypes from 'prop-types';

import { Select } from '@ubisend/pulse-components';

const options = [
  {
    label: 'Sitemap Import',
    value: 'Website Import'
  },
  {
    label: 'PDF Import',
    value: 'PDF Import'
  },
  {
    label: 'Manual',
    value: 'Manual Import'
  }
];

const ImportTypeSelect = ({ value, ...props }) => {
  return (
    <Select
      options={options}
      value={value && options.find(option => option.value === value)}
      {...props}
    />
  );
};

ImportTypeSelect.propTypes = {
  value: PropTypes.string
};

export default ImportTypeSelect;

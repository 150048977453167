import React, { useEffect, useMemo } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import axios from 'axios';

import {
  Notifications,
  Modals,
  useNotification
} from '@ubisend/pulse-components';
import { useAuth } from '@ubisend/pulse-auth';
import { ErrorBoundary, useReporting } from '@ubisend/pulse-errors';

import { getApiErrorMessage } from './utils/index';
import { Routes } from './Components/index';

const App = () => {
  const { showError } = useNotification();
  const { user, client } = useAuth();
  const { report, setUser, setContext, clearScope } = useReporting();

  useEffect(() => {
    if (user) {
      setUser({ id: user.id });
    } else {
      clearScope();
    }
  }, [user, setUser, clearScope]);

  useEffect(() => {
    if (client) {
      setContext('client', {
        id: client.id,
        identifier: client.identifier
      });
    }
  }, [client, setContext]);

  useMemo(() => {
    axios.interceptors.response.use(
      config => config,
      error => {
        if (error.response) {
          showError(getApiErrorMessage(error));
        }

        return Promise.reject(error);
      }
    );
  }, [showError]);

  return (
    <>
      <ErrorBoundary handleError={report}>
        <Modals />
        <Notifications />
        <Routes />
      </ErrorBoundary>
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
};

export default App;

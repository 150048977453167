import React from 'react';
import dayjs from 'dayjs';

import { Panel, Label, Flex } from '@ubisend/pulse-components';
import { useAuth } from '@ubisend/pulse-auth';
import { ExportUser } from '@ubisend/pulse-compliance';

const User = () => {
  const { user } = useAuth();

  return (
    <Panel divider header="About you" actions={<ExportUser />}>
      <Flex col mt>
        <Label secondary>Name: {user.name}</Label>
        <Label secondary>Email: {user.email}</Label>
        <Label secondary>
          Signed up {dayjs(user.created_at).from(dayjs())}
        </Label>
      </Flex>
    </Panel>
  );
};

export default User;

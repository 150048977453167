import React from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import { Select } from '@ubisend/pulse-components';

const format = ({ id, name }) => ({ label: name, value: id });

const MetricSelect = ({ value, ...props }) => {
  const { data, isLoading, isSuccess } = useQuery('custom-metrics/all');

  return (
    <Select
      isLoading={isLoading}
      options={isSuccess ? data.data.map(format) : []}
      isClearable
      value={
        isSuccess &&
        value &&
        format(data.data.find(metric => metric.id === value))
      }
      {...props}
    />
  );
};

MetricSelect.propTypes = {
  value: PropTypes.arrayOf(PropTypes.number)
};

export default MetricSelect;

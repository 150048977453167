import styled from 'styled-components';

import { Flex } from '@ubisend/pulse-components';

const TopSection = styled(Flex)`
  ${tw`mb-2`}
  & > * {
    ${tw`m-0`}
  }
`;

export default TopSection;

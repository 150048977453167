import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { useIsMutating } from '@ubisend/pulse-hooks';
import Icon from '@ubisend/pulse-icons';
import { Flex, Panel, Heading1, Span } from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';

import { useBuilder } from '../hooks/index';
import EditConversation from './EditConversation';

const StyledLink = styled(Link)`
  ${tw`flex bg-transparent border-0 p-0 outline-none flex items-center justify-center cursor-pointer`}
  color: black;
  &:hover {
    opacity: 0.75;
  }
  &:active {
    opacity: 0.5;
  }
`;

const Container = styled(Panel)`
  ${tw`absolute flex w-auto items-center z-20`}
  box-sizing: border-box;
  top: 1rem;
  left: 1rem;
`;

const LastUpdatedAt = () => {
  const [lastUpdatedAt, setLastUpdatedAt] = useState(null);
  const isMutating = useIsMutating();

  const getText = () => {
    if (isMutating) {
      return 'Saving...';
    }

    if (!lastUpdatedAt) {
      return 'No updates made this session';
    }

    return `Last updated ${lastUpdatedAt.from()}`;
  };

  useEffect(() => {
    if (isMutating) {
      setLastUpdatedAt(dayjs());
    }
  }, [isMutating]);

  return (
    <Span light tinyText>
      {getText()}
    </Span>
  );
};

const TopBar = () => {
  const { conversation } = useBuilder();

  return (
    <Container>
      <Flex fat between center xSpace>
        <Flex center xSpaceSm>
          <StyledLink to="/builder" aria-label="Back to conversations">
            <Icon
              size="1.75rem"
              width="1.75rem"
              height="1.75rem"
              type="cheveronLeft"
            />
          </StyledLink>
          <Flex col>
            <Heading1>{conversation.title}</Heading1>
            <LastUpdatedAt />
          </Flex>
        </Flex>
        <Flex>
          <PermissionFilter can="edit conversations">
            <EditConversation />
          </PermissionFilter>
        </Flex>
      </Flex>
    </Container>
  );
};

export default TopBar;

import React from 'react';
import PropTypes from 'prop-types';

import { Flex, FormGroup, Button, Accordion } from '@ubisend/pulse-components';
import {
  AdvancedDetails,
  VariableDetails,
  useTriggerStep
} from '@ubisend/pulse-demo';

import Modal from '../../../Modal';

const AdvancedTestStepModal = ({ handleClose, handleSubmit, loading }) => {
  const { form, valid } = useTriggerStep();

  const onSubmit = event => {
    event.preventDefault();

    handleSubmit(form);
  };

  return (
    <Modal
      header="Test step"
      handleClose={handleClose}
      style={{
        maxHeight: '40rem',
        overflowY: 'auto'
      }}>
      <form onSubmit={onSubmit}>
        <Flex col pad>
          <AdvancedDetails />
          <FormGroup>
            <Accordion header="Variables">
              <VariableDetails />
            </Accordion>
          </FormGroup>
          <Flex right>
            <Button type="submit" disabled={!valid || loading}>
              Trigger
            </Button>
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};

AdvancedTestStepModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default AdvancedTestStepModal;

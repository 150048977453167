import React, { useMemo } from 'react';

import { Flex, Tag, Label } from '@ubisend/pulse-components';
import { useAuth } from '@ubisend/pulse-auth';

const ActiveStatus = () => (
  <Flex fat>
    <Flex fat growChildren>
      <Tag colour="positive" background="positive">
        <Flex middle>
          <Label colour="positive" mb={false}>
            Active License
          </Label>
        </Flex>
      </Tag>
    </Flex>
  </Flex>
);

const ExpiringStatus = () => (
  <Flex fat>
    <Flex fat growChildren>
      <Tag colour="warning" background="warning">
        <Flex middle>
          <Label mb={false}>License Expiring</Label>
        </Flex>
      </Tag>
    </Flex>
  </Flex>
);

const ExpiredStatus = () => (
  <Flex fat>
    <Flex fat growChildren>
      <Tag colour="danger" background="danger">
        <Flex middle>
          <Label mb={false}>Expired License</Label>
        </Flex>
      </Tag>
    </Flex>
  </Flex>
);

const NoLicense = () => (
  <Flex fat>
    <Flex fat growChildren>
      <Tag colour="danger" background="danger">
        <Flex middle>
          <Label mb={false}>No License</Label>
        </Flex>
      </Tag>
    </Flex>
  </Flex>
);

const statuses = {
  active: ActiveStatus,
  expiring: ExpiringStatus,
  expired: ExpiredStatus
};

const LicenseStatus = () => {
  const { client } = useAuth();

  const Component = useMemo(() => {
    const license = client.license;

    if (!license) {
      return NoLicense;
    }

    return statuses[license.status];
  }, [client]);

  return <Component />;
};

export default LicenseStatus;

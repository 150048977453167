import React from 'react';
import PropTypes from 'prop-types';

import Icon from '@ubisend/pulse-icons';

function homogeniseIconName(string) {
  // capitalise first letter of direction so correct icon is grabbed.
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const Cheveron = ({ direction = 'up', ...rest }) => (
  <Icon
    type={`cheveron${homogeniseIconName(direction)}`}
    colour={'black'}
    {...rest}
  />
);

Cheveron.propTypes = {
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right'])
};

export default Cheveron;

import dayjs from 'dayjs';

/**
 * Convert a date string to a readable format.
 * E.g. 30th Nov 2019 at 1 o'clock (in a month)
 */
const getFormattedDate = date => {
  const wrapped = dayjs(date);
  return `${wrapped.format('Do MMM YYYY')} at ${
    wrapped.hour() % 12 || 12
  } o'clock (${wrapped.from(dayjs())})`;
};

export { getFormattedDate };

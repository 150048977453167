import { useEffect, useMemo } from 'react';

const useKeyboardShortcuts = keyOptions => {
  const cmdHandlers = useMemo(() => {
    return keyOptions.map(({ keys, handler }) => {
      return event => {
        if (keys.includes(event.key) && (event.ctrlKey || event.metaKey)) {
          handler(event);
        }
      };
    });
  }, [keyOptions]);

  useEffect(() => {
    cmdHandlers.forEach(handler => {
      document.addEventListener('keydown', handler, false);
    });

    return () =>
      cmdHandlers.forEach(handler => {
        document.removeEventListener('keydown', handler, false);
      });
  }, [cmdHandlers]);
};

export default useKeyboardShortcuts;

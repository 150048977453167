import React from 'react';

import { TextInput, FormGroup, Label, Flex } from '@ubisend/pulse-components';

import ImportTypeSelect from '../ImportTypeSelect';
import PollingIntervalSelect from '../PollingIntervalSelect';
import { useGandalf } from '../../hooks/index';

const ChooseImportType = () => {
  const {
    name,
    type,
    polling_interval,
    dispatch,
    show_polling_interval
  } = useGandalf();

  const handleNameChange = event => {
    const value = event.target.value;
    dispatch({ type: 'UPDATE_NAME', name: value });
  };

  const handleTypeChange = ({ value }) => {
    dispatch({ type: 'UPDATE_TYPE', new_type: value });
  };

  const handlePollingIntervalChange = ({ value }) => {
    dispatch({ type: 'UPDATE_POLLING_INTERVAL', polling_interval: value });
  };

  return (
    <Flex ySpace col>
      <FormGroup>
        <Label htmlFor="name">Name</Label>
        <TextInput
          id="name"
          placeholder="Website Sitemap"
          value={name}
          onChange={handleNameChange}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="type">Type</Label>
        <ImportTypeSelect id="type" value={type} onChange={handleTypeChange} />
      </FormGroup>
      {show_polling_interval && (
        <FormGroup>
          <Label htmlFor="interval">When to refresh content</Label>
          <PollingIntervalSelect
            id="interval"
            value={polling_interval}
            onChange={handlePollingIntervalChange}
          />
        </FormGroup>
      )}
    </Flex>
  );
};

export default ChooseImportType;

import { useState } from 'react';

const JUMP_SCROLL_DISTANCE = 50;

const useJumpToBottom = () => {
  const [showJump, setShowJump] = useState(false);

  const handleScroll = event => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    const distanceToBottom = scrollHeight - (scrollTop + clientHeight);

    if (distanceToBottom <= JUMP_SCROLL_DISTANCE && showJump) {
      setShowJump(false);
    } else if (distanceToBottom > JUMP_SCROLL_DISTANCE && !showJump) {
      setShowJump(true);
    }
  };

  return { showJump, handleScroll };
};
export default useJumpToBottom;

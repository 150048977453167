import roundRect from '../roundRect';
import textBox from '../textBox';
import {
  RESPONSE_PREVIEW_PADDING,
  STEP_WIDTH,
  BUBBLE_BORDER_RADIUS,
  BUBBLE_PADDING_Y,
  PARAGRAPH_LINE_HEIGHT,
  BUBBLE_PADDING_X,
  PARAGRAPH_FONT_SIZE,
  BUBBLE_INNER_WIDTH,
  IMAGE_ASPECT_RATIO,
  IMAGE_HEIGHT,
  IMAGE_WIDTH
} from '../constants';
import { getLines } from '../util/index';

const renderImage = (ctx, x, y, response) => {
  const imageSrc = response.content.url;
  // Initialise key on window object
  if (!window.builderImages) {
    window.builderImages = {};
  }
  if (!window.builderImages[imageSrc]) {
    const img = new Image();
    // Store images against the window object, otherwise we have to load every render
    img.addEventListener(
      'load',
      () => {
        window.builderImages[imageSrc] = img;
      },
      false
    );
    img.src = imageSrc;
  }
  if (window.builderImages[imageSrc]) {
    // The position to place the image on the canvas
    const destinationX = x + RESPONSE_PREVIEW_PADDING;
    const destinationY = y + RESPONSE_PREVIEW_PADDING;
    // Coordinates inside the image, to crop to size
    let sourceX = 0;
    let sourceY = 0;
    const image = window.builderImages[imageSrc];

    let sourceWidth;
    let sourceHeight;

    // If image is wider than image frame aspect ratio
    if (image.height / image.width < IMAGE_ASPECT_RATIO) {
      sourceWidth = image.height / IMAGE_ASPECT_RATIO;
      sourceHeight = image.height;
      sourceX = image.width / 2 - sourceWidth / 2;
    } else {
      sourceWidth = image.width;
      sourceHeight = image.width * IMAGE_ASPECT_RATIO;
      sourceY = image.height / 2 - sourceHeight / 2;
    }

    ctx.drawImage(
      image,
      sourceX,
      sourceY,
      sourceWidth,
      sourceHeight,
      destinationX,
      destinationY,
      IMAGE_WIDTH,
      IMAGE_HEIGHT
    );
  }
};

/**
 * Function to draw a image message based on on a node object
 *
 * @param   {CanvasRenderingContext2D}  ctx current canvas rendering context
 * @param   {number}  x     x position of node
 * @param   {number}  y     y position of node
 * @param   {string}  fill  hex, rgba, or string preset colour value to fill the background
 * @param   {object}  response  response object
 *
 * @return  {number}        height of resulting draw object
 */
const image = (ctx, x, y, fill, response) => {
  const text = response.content.text;
  let textLines = [];
  if (text) {
    textLines = getLines(ctx, text, BUBBLE_INNER_WIDTH);
  }

  let containerHeight = textLines.length * PARAGRAPH_LINE_HEIGHT + IMAGE_HEIGHT;

  // only add padding if we need to show text, otherwise we want the box to be flush with the image
  if (text) {
    containerHeight += BUBBLE_PADDING_Y * 2;
  }

  roundRect(
    ctx,
    x + RESPONSE_PREVIEW_PADDING,
    y + RESPONSE_PREVIEW_PADDING,
    STEP_WIDTH - RESPONSE_PREVIEW_PADDING * 2,
    containerHeight,
    'transparent',
    fill,
    BUBBLE_BORDER_RADIUS
  );
  ctx.save();
  ctx.clip();
  renderImage(ctx, x, y, response);

  textBox(
    ctx,
    x + RESPONSE_PREVIEW_PADDING + BUBBLE_PADDING_X,
    y +
      RESPONSE_PREVIEW_PADDING +
      PARAGRAPH_FONT_SIZE +
      BUBBLE_PADDING_Y +
      IMAGE_HEIGHT,
    textLines
  );
  ctx.restore();
  return containerHeight;
};

export default image;
export { renderImage };

import React from 'react';
import PropTypes from 'prop-types';

import TableHeadCell from './TableHeadCell';
import { Button } from '../Button/index';

const OrderableTableHeadCell = ({
  row,
  handleOrderChange,
  handleDirectionChange,
  order,
  direction
}) => {
  // Blank row (E.g. actions)
  if (!row) {
    return <TableHeadCell />;
  }

  // Not sortable regular cell
  if (!row.sort) {
    return <TableHeadCell>{row.label}</TableHeadCell>;
  }

  // The current ordered cell
  if (row.sort === order) {
    return (
      <TableHeadCell style={{ paddingLeft: '0.25rem' }}>
        <Button
          icon={direction === 'ascending' ? 'chevronUp' : 'chevronDown'}
          variant="inline"
          colour="black"
          onClick={() => handleDirectionChange(row)}
          semiBold>
          {row.label}
        </Button>
      </TableHeadCell>
    );
  }

  // Not the current ordered cell
  return (
    <TableHeadCell style={{ paddingLeft: '0.25rem' }}>
      <Button
        icon="selector"
        variant="inline"
        colour="black"
        onClick={() => handleOrderChange(row)}
        semiBold>
        {row.label}
      </Button>
    </TableHeadCell>
  );
};

OrderableTableHeadCell.propTypes = {
  row: PropTypes.shape({
    label: PropTypes.string.isRequired,
    sort: PropTypes.string
  }),
  handleOrderChange: PropTypes.func.isRequired,
  handleDirectionChange: PropTypes.func.isRequired,
  order: PropTypes.func,
  direction: PropTypes.func
};

export default OrderableTableHeadCell;

import PropTypes from 'prop-types';
import dayjs from 'dayjs';

const message = {
  type: PropTypes.oneOf([
    'standard',
    'quick-reply',
    'action',
    'general',
    'carousel',
    'image',
    'video',
    'content',
    'embed',
    'progress',
    'payment',
    'event',
    'file'
  ]),
  direction: PropTypes.oneOf(['toClient', 'toServer']),
  content: PropTypes.object,
  highlight: PropTypes.bool,
  sentAt: PropTypes.instanceOf(dayjs)
};

export { message };

import { useQueryClient, useMutation } from '@ubisend/pulse-hooks';

import { uploadFile } from '../api/index';

const useUploadFile = (query, options = {}) => {
  const { onSuccess, ...restOfOptions } = options;

  const queryClient = useQueryClient();
  const mutation = useMutation(uploadFile, {
    onSuccess: result => {
      queryClient.invalidateQueries(query);

      if (onSuccess) {
        onSuccess(result);
      }
    },
    ...restOfOptions
  });

  return mutation;
};

export default useUploadFile;

import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Span } from '@ubisend/pulse-components';
import { TemplatingTextInput } from '@ubisend/pulse-templating';

import { useIntegrations } from '../../hooks/index';
import MethodSelect from './MethodSelect';

const Request = ({ method, setMethod, endpoint, setEndpoint }) => {
  const { viewEndpoints: integration } = useIntegrations();

  const handleEndpointChange = value => setEndpoint(value);

  const handleMethodChange = ({ value }) => {
    setMethod(value);
  };

  return (
    <Flex center xSpace fat>
      <span>Make</span>
      <MethodSelect value={method} onChange={handleMethodChange} />
      <Span noWrap>request to</Span>
      <Span noWrap>{integration.name}</Span>
      <span>at</span>
      <Flex fat>
        <TemplatingTextInput
          aria-label="endpoint"
          placeholder="/users"
          value={endpoint}
          onChange={handleEndpointChange}
        />
      </Flex>
    </Flex>
  );
};

Request.propTypes = {
  method: PropTypes.string,
  setMethod: PropTypes.func.isRequired,
  endpoint: PropTypes.string,
  setEndpoint: PropTypes.func
};

export default Request;

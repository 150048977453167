import styled from 'styled-components';

const MarkdownCode = styled.div`
  ${tw`inline text-sm font-normal border-none whitespace-pre-wrap p-2 rouned`}

  background-color: rgba(9, 30, 66, 0.08);
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
`;

export default MarkdownCode;

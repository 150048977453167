import React from 'react';
import PropTypes from 'prop-types';

import { TextInput, Label } from '@ubisend/pulse-components';

const UrlParamExtractor = ({ content, onChange }) => {
  const handleChange = event => {
    const value = event.target.value;

    onChange({ param: value });
  };

  return (
    <div>
      <Label htmlFor="param">URL parameter to extract</Label>
      <TextInput
        id="param"
        placeholder="token"
        value={content.param}
        onChange={handleChange}
      />
    </div>
  );
};

UrlParamExtractor.propTypes = {
  content: PropTypes.shape({
    param: PropTypes.string.isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired
};

export default UrlParamExtractor;

import standard from './standard';
import quickReply from './quickReply';
import action from './action';
import image from './image';
import general from './general';
import event from './event';
import content from './content';
import video from './video';
import carousel from './carousel';
import payment from './payment';
import dynamic from './dynamic';

const renderers = {
  standard,
  action,
  'quick-reply': quickReply,
  general,
  carousel,
  image,
  video,
  content,
  embed: content,
  payment,
  loading: standard,
  progress: standard,
  event,
  dynamic
};

const responses = (node, languageId, ctx, x, y, fill) => {
  // Some steps don't have a response if the response is built at conversation
  // time (E.g. FAQ's)
  if (node.base.responses.length === 0) {
    return standard(ctx, x, y, fill, {
      content: { text: 'No response preview available' }
    });
  }

  const response = node.base.responses.find(
    response => response.language.id === languageId
  );
  const renderer = renderers[response.type] || standard;

  return renderer(ctx, x, y, fill, response);
};

export default responses;

import { SaveIntegration } from '../../Components/Subjects/Variables/index';

const subject = {
  type: 'endpoint',
  endpoint_id: null,
  integration_id: null,
  target: ''
};

const integrationSubject = {
  subject,
  name: 'Store API response',
  permission: 'view integrations',
  Saver: SaveIntegration,
  toState: subject => {
    return {
      type: 'endpoint',
      endpoint_id: subject.id,
      integration_id: subject.integration_id,
      target: subject.target
    };
  },
  toApi: subject => {
    return {
      id: subject.endpoint_id,
      type: 'endpoint',
      target: subject.target
    };
  }
};

export default integrationSubject;

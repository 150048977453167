import React from 'react';
import PropTypes from 'prop-types';

import { Button, useNotification, Accordion } from '@ubisend/pulse-components';
import { useDelete, useRows, useQueryClient } from '@ubisend/pulse-hooks';

import EndpointDetails from './EndpointDetails';
import { editEndpoint, deleteEndpoint } from '../../api/index';
import { useIntegrations, useRequest } from '../../hooks/index';

const EditEndpoint = ({
  id,
  method,
  endpoint,
  parameters: initialParameters,
  body,
  name,
  open: initialOpen = false
}) => {
  const { showSuccess } = useNotification();
  const { viewEndpoints: integration } = useIntegrations();

  const parameters = useRows(initialParameters);
  const request = useRequest({ endpoint, method });

  const queryClient = useQueryClient();
  const { handleDelete } = useDelete(deleteEndpoint, {
    queries: ['integrations']
  });

  const handleEditEndpoint = async params => {
    await editEndpoint(id, integration.id, params);

    showSuccess(`Successfully edited endpoint for ${integration.name}`);
    queryClient.invalidateQueries('integrations');
  };

  const getMethodName = () => {
    if (name) {
      return name;
    }

    return `${method.toUpperCase()} ${endpoint}`;
  };

  return (
    <Accordion
      aria-label={`endpoint-chevron-${id}`}
      open={initialOpen}
      header={`Edit "${getMethodName()}"`}
      actions={
        <Button
          variant="inline"
          colour="danger"
          onClick={() => handleDelete(id)}
          icon={'trash'}>
          Delete
        </Button>
      }>
      <EndpointDetails
        handleSubmit={handleEditEndpoint}
        request={request}
        parameters={parameters}
        initialName={name || ''}
        initialBody={body || ''}
        initialUseBody={Boolean(body)}
      />
    </Accordion>
  );
};

EditEndpoint.propTypes = {
  id: PropTypes.number.isRequired,
  method: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,
  body: PropTypes.string,
  name: PropTypes.string,
  parameters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string
    })
  ),
  open: PropTypes.bool
};

export default EditEndpoint;

import React, { useState } from 'react';

import {
  Panel,
  Flex,
  OneQuarterLayout,
  InfoSection,
  Checkbox,
  Button
} from '@ubisend/pulse-components';
import { ImageSelect } from '@ubisend/pulse-files';

import { useBotSettings } from '../../hooks/index';

const AvatarSettings = () => {
  const { settings, setSettings, focusBot } = useBotSettings();

  const [botImage, setBotImage] = useState(false);
  const [humanImage, setHumanImage] = useState(false);
  const [useDefaultAvatars, setUseDefaultAvatars] = useState(
    !(settings.bot_avatar_url && settings.human_avatar_url)
  );

  const toggleBotImage = () => setBotImage(open => !open);

  const toggleHumanImage = () => setHumanImage(open => !open);

  const handleBotImage = ({ link }) => {
    setSettings({ ...settings, bot_avatar_url: link });
    toggleBotImage();
  };

  const handleHumanImage = ({ link }) => {
    setSettings({ ...settings, human_avatar_url: link });
    toggleHumanImage();
  };

  const handleDefaultAvatarChange = () => {
    const newUse = !useDefaultAvatars;
    focusBot();
    // If they switch to using the defaults, clear
    // the old bot url's.
    if (newUse) {
      setSettings({
        ...settings,
        bot_avatar_url: null,
        human_avatar_url: null
      });
    }

    setUseDefaultAvatars(newUse);
  };

  const handleAvatarEnabledChange = () => {
    focusBot();
    setSettings(settings => ({
      ...settings,
      avatars_enabled: !settings.avatars_enabled
    }));
  };

  return (
    <Flex>
      <OneQuarterLayout>
        <InfoSection
          title="Avatars"
          info="Show or hide avatar icons, and upload custom replacements."
        />
      </OneQuarterLayout>
      <Panel>
        <Flex col ySpace>
          <Checkbox
            checked={settings.avatars_enabled}
            onChange={handleAvatarEnabledChange}
            label="Show avatars"
          />
          <Checkbox
            checked={!useDefaultAvatars}
            onChange={handleDefaultAvatarChange}
            label="Use custom avatars"
            disabled={!settings.avatars_enabled}
          />
          <Flex xSpace center>
            <Button
              selfTop
              disabled={useDefaultAvatars}
              variant="secondary"
              onClick={toggleBotImage}>
              Choose chatbot image
            </Button>
            <Button
              selfTop
              disabled={useDefaultAvatars}
              variant="secondary"
              onClick={toggleHumanImage}>
              Choose human image
            </Button>
          </Flex>
          {botImage && (
            <ImageSelect
              handleCancel={toggleBotImage}
              handleFileSelect={handleBotImage}
            />
          )}
          {humanImage && (
            <ImageSelect
              handleCancel={toggleHumanImage}
              handleFileSelect={handleHumanImage}
            />
          )}
        </Flex>
      </Panel>
    </Flex>
  );
};

export default AvatarSettings;

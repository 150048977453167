import { useReducer } from 'react';

const TYPES = {
  // Conversation
  UPDATE_CONVERSATION: 'UPDATE_CONVERSATION',
  // Steps
  ADD_STEP: 'ADD_STEP',
  UPDATE_STEP: 'UPDATE_STEP',
  DELETE_STEP: 'DELETE_STEP',
  // Transitions
  ADD_TRANSITION: 'ADD_TRANSITION',
  UPDATE_TRANSITION: 'UPDATE_TRANSITION',
  DELETE_TRANSITION: 'DELETE_TRANSITION',
  // Triggers
  ADD_TRIGGER: 'ADD_TRIGGER',
  UPDATE_TRIGGER: 'UPDATE_TRIGGER',
  DELETE_TRIGGER: 'DELETE_TRIGGER',
  // Integrations
  SYNC_STEP_INTEGRATIONS: 'SYNC_STEP_INTEGRATIONS',
  // Emails
  SYNC_STEP_EMAILS: 'SYNC_STEP_EMAILS',
  // Actions
  SYNC_STEP_ACTIONS: 'SYNC_STEP_ACTIONS',
  // Responses
  SYNC_STEP_RESPONSES: 'SYNC_STEP_RESPONSES',
  SYNC_TRANSITION_RESPONSES: 'SYNC_TRANSITION_RESPONSES'
};

const reducer = (conversation, { type, ...params }) => {
  const updateConversation = callback => {
    return {
      ...conversation,
      ...callback(conversation)
    };
  };

  const updateSteps = callback => {
    return updateConversation(conversation => {
      return {
        steps: callback(conversation.steps)
      };
    });
  };

  const updateStep = callback => {
    return updateSteps(steps => {
      return steps.map(step => {
        if (step.id === params.stepId) {
          return {
            ...step,
            ...callback(step)
          };
        }

        return step;
      });
    });
  };

  const getTransitionType = () => {
    const types = {
      transition: 'transitions',
      validation: 'validation_transitions'
    };

    return types[params.transitionType || 'transition'];
  };

  switch (type) {
    // Conversation
    case TYPES.UPDATE_CONVERSATION:
      return updateConversation(() => params.conversationDetails);

    // Steps
    case TYPES.ADD_STEP:
      return updateSteps(steps => steps.concat(params.stepDetails));
    case TYPES.UPDATE_STEP:
      return updateStep(() => params.stepDetails);
    case TYPES.DELETE_STEP:
      return updateSteps(steps => {
        return steps
          .filter(step => step.id !== params.stepId)
          .map(step => {
            return {
              ...step,
              transitions: step.transitions.filter(transition => {
                return transition.destination.id !== params.stepId;
              })
            };
          });
      });

    // Transitions
    case TYPES.ADD_TRANSITION: {
      const transitionType = getTransitionType();

      return updateStep(step => {
        return {
          [transitionType]: step[transitionType].concat(
            params.transitionDetails
          )
        };
      });
    }
    case TYPES.UPDATE_TRANSITION: {
      const transitionType = getTransitionType();

      return updateStep(step => {
        return {
          [transitionType]: step[transitionType].map(transition => {
            if (transition.id === params.transitionId) {
              return params.transitionDetails;
            }

            return transition;
          })
        };
      });
    }
    case TYPES.DELETE_TRANSITION: {
      const transitionType = getTransitionType();

      return updateStep(step => {
        return {
          [transitionType]: step[transitionType].filter(
            transition => transition.id !== params.transitionId
          )
        };
      });
    }
    // Triggers
    case TYPES.ADD_TRIGGER:
      return updateStep(step => {
        return {
          incoming_triggers: step.incoming_triggers.concat(
            params.triggerDetails
          )
        };
      });
    case TYPES.UPDATE_TRIGGER:
      return updateStep(step => {
        return {
          incoming_triggers: step.incoming_triggers.map(trigger => {
            if (trigger.id === params.triggerId) {
              return params.triggerDetails;
            }

            return trigger;
          })
        };
      });
    case TYPES.DELETE_TRIGGER:
      return updateStep(step => {
        return {
          incoming_triggers: step.incoming_triggers.filter(
            trigger => trigger.id !== params.triggerId
          )
        };
      });

    // Integrations
    case TYPES.SYNC_STEP_INTEGRATIONS:
      return updateStep(() => {
        return {
          endpoints: params.newIntegrations
        };
      });

    // Emails
    case TYPES.SYNC_STEP_EMAILS:
      return updateStep(() => {
        return {
          emails: params.newEmails
        };
      });

    // Actions
    case TYPES.SYNC_STEP_ACTIONS:
      return updateStep(() => {
        return {
          actions: params.newActions
        };
      });

    // Responses
    case TYPES.SYNC_STEP_RESPONSES:
      return updateStep(() => {
        return {
          responses: params.newResponses
        };
      });
    case TYPES.SYNC_TRANSITION_RESPONSES: {
      const transitionType = getTransitionType();

      return updateStep(step => {
        return {
          [transitionType]: step[transitionType].map(transition => {
            if (transition.id === params.transitionId) {
              return {
                ...transition,
                responses: params.newResponses
              };
            }

            return transition;
          })
        };
      });
    }
    default:
      throw new Error(`No event defined in useBuilderReducer for ${type}`);
  }
};

const useBuilderReducer = initialConversation => {
  const [conversation, dispatch] = useReducer(reducer, initialConversation);

  return {
    conversation,
    initialConversation,
    dispatch
  };
};

export default useBuilderReducer;

import React from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import { Select } from '@ubisend/pulse-components';

import { formatIntent, defaultGroups, groupIntents } from './IntentSearcher';

const IntentSelect = ({ value, ...props }) => {
  const { data, isLoading, isSuccess } = useQuery('intents');

  return (
    <Select
      {...props}
      aria-label="intents"
      isClearable
      isLoading={isLoading}
      options={
        isSuccess
          ? data.data.map(formatIntent).reduce(groupIntents, defaultGroups)
          : []
      }
      value={
        isSuccess && value
          ? formatIntent(data.data.find(intent => intent.identifier === value))
          : null
      }
    />
  );
};

IntentSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default IntentSelect;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalClose,
  Label,
  ModalPortal,
  Button
} from '@ubisend/pulse-components';

import { useUploadFile, useFileSource } from '../../../hooks/index';
import { FileProvider } from '../../../Providers/index';
import FileDetails from '../../FileDetails';

const CreatePdf = ({ queryKey }) => {
  const [show, setShow] = useState(false);

  const source = useFileSource();

  const { mutate, isLoading } = useUploadFile(queryKey, {
    onSuccess: () => {
      setShow(false);
    }
  });

  const handleSubmit = form => {
    mutate({
      endpoint: queryKey,
      source_id: source.source.id,
      type: 'pdf',
      ...form
    });
  };

  const handleShowUpload = () => {
    setShow(true);
  };

  const handleHideUpload = () => {
    setShow(false);
  };

  return (
    <>
      <Button onClick={handleShowUpload} disabled={!source}>
        + File
      </Button>
      {show && (
        <ModalPortal>
          <ModalContainer>
            <ModalContent>
              <ModalHeader>
                <Label mb={false}>Upload PDF</Label>
                <ModalClose onClick={handleHideUpload} />
              </ModalHeader>
              <FileProvider>
                <FileDetails onSubmit={handleSubmit} loading={isLoading} />
              </FileProvider>
            </ModalContent>
          </ModalContainer>
        </ModalPortal>
      )}
    </>
  );
};

CreatePdf.propTypes = {
  queryKey: PropTypes.string.isRequired
};

export default CreatePdf;

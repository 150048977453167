import standard from './standard';

/**
 * Function to draw a standard message based on on a node object
 *
 * @param   {CanvasRenderingContext2D}  ctx current canvas rendering context
 * @param   {number}  x     x position of node
 * @param   {number}  y     y position of node
 * @param   {string}  fill  hex, rgba, or string preset colour value to fill the background
 * @param   {object}  response  response object
 *
 * @return  {number}        height of resulting drawn object
 */
const dynamic = (ctx, x, y, fill) => {
  return standard(ctx, x, y, fill, { content: { text: 'Dynamic message' } });
};

export default dynamic;

import React from 'react';
import PropTypes from 'prop-types';

import Label from '../Forms/Label';

const Version = ({ versions }) => (
  <Label secondary>
    Chatbot Management System ({versions.api && `${versions.api}, `}
    {versions.ui})
  </Label>
);

Version.propTypes = {
  versions: PropTypes.shape({
    api: PropTypes.string,
    ui: PropTypes.string.isRequired
  }).isRequired
};

export default Version;

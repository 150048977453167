import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Flex } from '@ubisend/pulse-components';

import BaseComposer from '../BaseComposer';
import {
  useComposer,
  useDelivery,
  useMutation
} from '../../../../../hooks/index';
import { isValidMessage } from '../../../../../utilities/index';
import { endLiveChat as endLiveChatApi } from '../../../../../api/index';
import {
  MAX_MESSAGE_LENGTH,
  MIN_MESSAGE_LIMIT
} from '../../../../../constants';
import SendButton from '../SendButton';
import FooterPortal from '../../FooterPortal';

const isValid = message => {
  return isValidMessage(message, {
    min: MIN_MESSAGE_LIMIT,
    max: MAX_MESSAGE_LENGTH
  });
};

const LiveChatComposer = () => {
  const { t } = useTranslation('bots');

  const { reply, setReply } = useComposer();
  const { sendMessage } = useDelivery();

  const mutation = useMutation(endLiveChatApi);

  const handleNewReply = () => {
    const validatedMessage = isValid(reply);

    if (!validatedMessage) {
      return;
    }

    sendMessage(validatedMessage);
    setReply('');
  };

  const handleKeyDown = event => {
    // Submit on enter.
    if (event.keyCode !== 13) {
      return;
    }

    handleNewReply();
  };

  const handleChange = event => {
    const value = event.target.value;

    if (value.length >= MAX_MESSAGE_LENGTH) {
      return;
    }

    setReply(value);
  };

  const handleEndLiveChat = () => {
    mutation.mutate();
  };

  return (
    <BaseComposer.Container>
      {mutation.isEnabled && (
        <FooterPortal>
          <Button
            variant="secondary"
            icon="logout"
            onClick={handleEndLiveChat}
            disabled={
              mutation.isDisabled || mutation.isLoading || mutation.isSuccess
            }>
            {t('end_live_chat_submit_button')}
          </Button>
        </FooterPortal>
      )}
      <Flex xSpace middle fat center pl pr>
        <BaseComposer.Input
          type="text"
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          aria-label={t('live_chat_composer_label')}
          placeholder={t('live_chat_composer_label')}
          value={reply}
        />
        <SendButton
          aria-label={t('send_button_label')}
          onClick={handleNewReply}
        />
      </Flex>
    </BaseComposer.Container>
  );
};

export default LiveChatComposer;

import React from 'react';
import PropTypes from 'prop-types';

import ComposerButton from './ComposerButton';
import { UploadFileInput } from '../../../../Components/index';

const UploadButton = ({ onChange, disabled, ...props }) => {
  return (
    <>
      <UploadFileInput id="file" disabled={disabled} onChange={onChange} />
      <ComposerButton as="label" htmlFor="file" disabled={disabled} {...props}>
        <svg
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg">
          <path
            className="secondary"
            fillRule="evenodd"
            d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
            clipRule="evenodd"
          />
        </svg>
      </ComposerButton>
    </>
  );
};

UploadButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default UploadButton;

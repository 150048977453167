import { useContext } from 'react';

import { FileContext } from '../Contexts/index';

const useFile = () => {
  const context = useContext(FileContext);

  return context;
};

export default useFile;

import { LookupComposer } from '../../Components/Subjects/Composers/index';

const subject = {
  spreadsheet_id: null,
  lookup_id: null,
  type: 'lookup'
};

const lookupSubject = {
  subject,
  name: 'Use spreadsheet values',
  permission: 'view spreadsheets',
  Composer: LookupComposer,
  toState: subject => {
    return {
      type: 'lookup',
      lookup_id: subject.id,
      spreadsheet_id: subject.spreadsheet_id
    };
  },
  toApi: subject => {
    return {
      type: 'lookup',
      id: subject.lookup_id
    };
  }
};

export default lookupSubject;

import styled from 'styled-components';

const LeftUpConnector = styled.div`
  ${tw`h-4 w-3`}
  border-bottom-right-radius: 2rem;
  border-bottom: 2px solid #888888;
  border-right: 2px solid #888888;
`;

export default LeftUpConnector;

import React, { useMemo } from 'react';

import responses from './responses';
import { useComposer } from '../../hooks/index';

const ComposerContent = () => {
  const { response } = useComposer();

  const Input = useMemo(() => {
    return responses.find(({ id }) => id === response.type).Input;
  }, [response.type]);

  return <Input />;
};

export default ComposerContent;

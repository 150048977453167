import { hasPermission } from '@ubisend/pulse-auth';

import { Dashboard, CustomMetrics } from './Pages/index';

const routes = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    exact: false,
    component: Dashboard,
    canAccess: hasPermission('view metrics'),
    actions: [
      {
        title: 'Measure chatbot performance',
        description: 'Track the KPIs that matter with custom chatbot metrics.',
        icon: 'chartBar'
      }
    ]
  },
  {
    name: 'Custom Metrics',
    path: '/custom-metrics',
    exact: false,
    component: CustomMetrics,
    canAccess: hasPermission('view custom metrics'),
    actions: [
      {
        title: 'Manage your custom metrics',
        description: 'Create, update and delete your custom metrics.',
        icon: 'chartBar'
      }
    ]
  }
];

export default routes;

import React from 'react';
import PropTypes from 'prop-types';

import { Span } from '@ubisend/pulse-components';

const FeedbackCategoryScope = ({ content }) => {
  return <Span>{content.name}</Span>;
};

FeedbackCategoryScope.propTypes = {
  content: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired
};

export default FeedbackCategoryScope;

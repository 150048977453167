import React from 'react';
import PropTypes from 'prop-types';

import { FileContext } from '../Contexts/index';
import { useFileReducer } from '../reducers/index';

const FileProvider = ({ initialFile, children }) => {
  const reducer = useFileReducer(initialFile);

  return (
    <FileContext.Provider value={reducer}>{children}</FileContext.Provider>
  );
};

FileProvider.propTypes = {
  initialFile: PropTypes.shape({
    file: PropTypes.instanceOf(File),
    name: PropTypes.string,
    description: PropTypes.string,
    groupIds: PropTypes.arrayOf(PropTypes.number)
  })
};

export default FileProvider;

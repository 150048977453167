import React from 'react';
import { Redirect } from 'react-router-dom';

const LoggedInRedirect = () => {
  return (
    <Redirect
      to={{
        pathname: '/home',
        state: {
          notification: {
            type: 'success',
            message: 'You have been successfully logged In.'
          }
        }
      }}
    />
  );
};

export default LoggedInRedirect;

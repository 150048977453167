import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, I18nextProvider } from 'react-i18next';

import { useI18nInstance } from '@ubisend/pulse-translations';

import { LanguageContext } from '../Contexts/index';

const LanguageListener = ({ initialLanguage, children }) => {
  const [language, setLanguage] = useState(initialLanguage);

  const { i18n } = useTranslation();

  useEffect(() => {
    if (i18n.language !== language.country_code) {
      i18n.changeLanguage(language.country_code);
    }
  }, [i18n, language.country_code]);

  const context = {
    language,
    setLanguage
  };

  return (
    <LanguageContext.Provider value={context}>
      {children}
    </LanguageContext.Provider>
  );
};

LanguageListener.propTypes = {
  initialLanguage: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    country_code: PropTypes.string
  })
};

const defaultLanguage = { id: null, name: 'English', country_code: 'en' };

const LanguageProvider = ({ children, initialLanguage = defaultLanguage }) => {
  const instance = useI18nInstance(initialLanguage.country_code, {
    namespaces: ['cms']
  });

  if (!instance) {
    return null;
  }

  return (
    <I18nextProvider i18n={instance}>
      <LanguageListener initialLanguage={initialLanguage}>
        {children}
      </LanguageListener>
    </I18nextProvider>
  );
};

LanguageProvider.propTypes = {
  initialLanguage: LanguageListener.propTypes.initialLanguage
};

export default LanguageProvider;

import {
  LookupConditional,
  LookupPreview
} from '../../Components/Subjects/Conditionals/index';

const subject = {
  spreadsheet_id: null,
  lookup_id: null,
  type: 'lookup'
};

const conditional = {
  subject,
  type: null,
  condition: null,
  flipped: false,
  case_sensitive: false,
  target: ''
};

const lookupSubject = {
  subject,
  conditional,
  permission: 'view spreadsheets',
  name: 'Looking up',
  Conditional: LookupConditional,
  Preview: LookupPreview,
  toState: ({ subject }) => {
    return {
      type: 'lookup',
      lookup_id: subject.id,
      spreadsheet_id: subject.spreadsheet_id
    };
  },
  toApi: ({ subject }) => {
    return {
      type: 'lookup',
      id: subject.lookup_id
    };
  }
};

export default lookupSubject;

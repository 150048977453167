import React from 'react';

import { Flex, LeftHalfLayout } from '@ubisend/pulse-components';

import { ContentPreview, SectionActions } from '../Components/index';
import { useImports } from '../hooks/index';

const Preview = () => {
  const { section } = useImports();

  return (
    <>
      <SectionActions />
      <Flex>
        <LeftHalfLayout>
          <ContentPreview value={section.content.content} />
        </LeftHalfLayout>
      </Flex>
    </>
  );
};

export default Preview;

import PdfPreview from './PdfPreview';
import CreatePdf from './CreatePdf';

const pdf = {
  type: 'pdf',
  name: "PDF's",
  icon: 'document',
  Preview: PdfPreview,
  Create: CreatePdf
};

export default pdf;

import React from 'react';

import { Placeholder, Panel } from '@ubisend/pulse-components';

const BuilderLoadingAnimation = () => {
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%'
      }}>
      <Panel
        style={{ width: 400, position: 'absolute', top: '1rem', left: '1rem' }}>
        <Placeholder items={1} subitems={1} />
      </Panel>
      <Panel
        style={{
          width: 60,
          position: 'absolute',
          top: '1rem',
          right: '1rem'
        }}>
        <Placeholder items={1} subitems={1} />
      </Panel>
      <Panel
        style={{
          width: 150,
          position: 'absolute',
          bottom: '1rem',
          left: '1rem'
        }}>
        <Placeholder items={1} subitems={1} />
      </Panel>
      <Panel
        style={{
          width: 288,
          position: 'absolute',
          left: 'calc(50% - 192px)',
          top: 150
        }}>
        <Placeholder items={1} subitems={3} />
      </Panel>
      <Panel
        style={{
          width: 288,
          position: 'absolute',
          left: 'calc(50% - 192px)',
          top: 350
        }}>
        <Placeholder items={1} subitems={3} />
      </Panel>
      <Panel
        style={{
          width: 288,
          position: 'absolute',
          left: 'calc(50% - 192px)',
          top: 550
        }}>
        <Placeholder items={1} subitems={3} />
      </Panel>
    </div>
  );
};

export default BuilderLoadingAnimation;

import axios from 'axios';

const updateStepResponses = ({ stepId, responses }) => {
  return axios.put(
    `conversations/builder/steps/${stepId}/responses`,
    responses
  );
};

export { updateStepResponses };

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  Panel,
  Explainer,
  WizardProgress
} from '@ubisend/pulse-components';

import FacebookLogin from './FacebookLogin';
import SelectPage from './SelectPage';

const SetupFacebookComponent = ({ handlePageSelect }) => {
  const [user, setUser] = useState();

  const handleNewUser = newUser => setUser(newUser);

  return (
    <Panel
      divider
      header="Link your Facebook account for Messenger"
      actions={<WizardProgress steps={3} current={user ? 1 : 0} />}>
      <Flex col ySpace mt>
        {!user && (
          <Explainer>
            Clicking the button below will open a Facebook login page to begin
            the connection process.
          </Explainer>
        )}
        {user ? (
          <SelectPage user={user} handlePageSelect={handlePageSelect} />
        ) : (
          <FacebookLogin user={user} handleNewUser={handleNewUser} />
        )}
      </Flex>
    </Panel>
  );
};

SetupFacebookComponent.propTypes = {
  handlePageSelect: PropTypes.func.isRequired
};

export default SetupFacebookComponent;

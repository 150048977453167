import QrCodePreview from './QrCodePreview';
import CreateQrCode from './CreateQrCode';

const qrCode = {
  type: 'qr-code',
  name: 'QR Codes',
  icon: 'photograph',
  Preview: QrCodePreview,
  Create: CreateQrCode
};

export default qrCode;

import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@ubisend/pulse-components';

import { useButtons } from '../../hooks/index';

const DeleteButton = ({ index }) => {
  const { handleButtonDelete } = useButtons();

  return (
    <Button
      variant="secondary"
      colour="danger"
      icon="trash"
      onClick={() => handleButtonDelete(index)}>
      Delete
    </Button>
  );
};

DeleteButton.propTypes = {
  index: PropTypes.number.isRequired
};

export default DeleteButton;

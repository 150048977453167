import React from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import { Select } from '@ubisend/pulse-components';

const format = ({ id, name }) => ({ label: name, value: id });

const IntegrationSelect = ({ value, ...props }) => {
  const { data, isLoading, isSuccess } = useQuery('integrations');

  return (
    <Select
      isLoading={isLoading}
      isClearable
      options={
        isSuccess
          ? data.data
              .filter(({ endpoints }) => endpoints.length > 0)
              .map(format)
          : []
      }
      value={
        isSuccess &&
        value &&
        format(data.data.find(integration => integration.id === value))
      }
      {...props}
    />
  );
};

IntegrationSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default IntegrationSelect;

import styled, { css } from 'styled-components';
import chroma from 'chroma-js';

const pseudoStyles = css`
  outline: 0;
  transition: border-color 0.3s cubic-bezier(0.16, 1, 0.3, 1),
    box-shadow 0.3s cubic-bezier(0.16, 1, 0.3, 1),
    opacity 0.3s cubic-bezier(0.16, 1, 0.3, 1),
    background-color 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  &:not(:disabled) {
    &:focus {
      border: 1px solid
        ${({ theme, colour, customColour }) => {
          const bgColour = customColour || theme[colour] || theme.primary;
          return chroma(bgColour).alpha(0.5);
        }};
      box-shadow: 0 0 0 0.2rem
        ${({ theme, colour, customColour }) => {
          const bgColour = customColour || theme[colour] || theme.primary;
          return chroma(bgColour).alpha(0.25);
        }};
    }
    &:active {
      box-shadow: none;
    }
  }
`;

const inputStyles = css`
  ${tw`rounded outline-none bg-grey-light border border-grey-light border-solid`}
  box-sizing: border-box;
  font-size: 0.875rem;
  padding: calc(0.5rem - 1px) calc(0.75rem - 1px);
  &::placeholder {
    ${tw`text-grey-dark`}
  }
  &:disabled {
    ${tw`cursor-not-allowed opacity-75`}
  }
  &:not(:disabled) {
    &:hover {
      box-shadow: 0 0 0 0.2rem
        ${({ theme, colour, customColour }) => {
          const bgColour = customColour || theme[colour] || theme.primary;
          return chroma(bgColour).alpha(0.25);
        }};
    }
  }
  ${props => props.readOnly && tw`p-0 bg-transparent border-none`}
`;

const TextInput = styled.input`
  ${tw`w-full block`}
  ${inputStyles}
  ${pseudoStyles}
`;

export default TextInput;
export { inputStyles, pseudoStyles };

import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import {
  Placeholder,
  Checkbox,
  Flex,
  Label,
  Divider,
  InnerPanel,
  Accordion
} from '@ubisend/pulse-components';

const PermissionDescription = styled.p`
  ${tw`text-xs m-0 w-1/2 flex-no-shrink`}
`;

const AccordionLabel = styled(Label)`
  ${tw`flex-grow m-0`}
`;

const LabelContainer = styled.div`
  ${tw`flex w-full`}
  box-sizing: border-box;
`;

const PermissionList = styled(InnerPanel)`
  & > div:not(:first-child) {
    ${tw`mt-4`}
  }
`;

const PermissionResourceType = ({ name, resources, permissions, onChange }) => {
  const handlePermissionCheck = (permission, checked) => {
    if (checked) {
      return onChange(permissions.filter(({ id }) => id !== permission.id));
    }
    onChange(permissions.concat(permission));
  };

  const handleResourceCheck = (resource, checked) => () => {
    const filteredPermissions = permissions.filter(
      permission => permission.resource.name !== resource.name
    );
    if (checked) {
      return onChange(filteredPermissions);
    }
    onChange(filteredPermissions.concat(resource.permissions));
  };

  return (
    <Accordion header={name}>
      <Flex col>
        {resources.map((resource, i) => {
          const checked = resource.permissions.every(permission => {
            return permissions.find(({ id }) => id === permission.id);
          });
          const disabled = resource.permissions.every(permission => {
            return permissions.find(
              ({ id, disabled }) => id === permission.id && disabled
            );
          });

          return (
            <React.Fragment key={i}>
              {i > 0 && <Divider fullWidth />}
              <Flex col ySpaceSm key={i}>
                <Flex key={i}>
                  <LabelContainer>
                    <Checkbox
                      checked={checked}
                      onChange={handleResourceCheck(resource, checked)}
                      label={resource.name}
                      disabled={disabled}
                    />
                  </LabelContainer>
                  <PermissionDescription>All Below</PermissionDescription>
                </Flex>
                {resource.permissions.map(permission => {
                  const checked = permissions.find(
                    ({ id }) => id === permission.id
                  );

                  return (
                    <Flex key={permission.id}>
                      <LabelContainer style={{ paddingLeft: 24 }}>
                        <Checkbox
                          checked={checked}
                          onChange={() => {
                            handlePermissionCheck(permission, checked);
                          }}
                          label={permission.name}
                          disabled={permissions.find(({ id, disabled }) => {
                            return id === permission.id && disabled;
                          })}
                          aria-label={`${permission.name} ${resource.name}`}
                        />
                      </LabelContainer>
                      <PermissionDescription>{`Can ${permission.name} ${resource.name}`}</PermissionDescription>
                    </Flex>
                  );
                })}
              </Flex>
            </React.Fragment>
          );
        })}
      </Flex>
    </Accordion>
  );
};

PermissionResourceType.propTypes = {
  name: PropTypes.string,
  permissions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string
    })
  ),
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      permissions: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          id: PropTypes.string
        })
      ),
      allPermissions: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          id: PropTypes.string
        })
      )
    })
  ),
  onChange: PropTypes.func.isRequired
};

const initialisePermissions = permissions => {
  return [
    ...new Set(permissions.map(permission => permission.resource.type))
  ].map(name => ({
    name,
    resources: [
      ...new Set(
        permissions
          .filter(permission => permission.resource.type === name)
          .map(permission => permission.resource.name)
      )
    ].map(resource => ({ name: resource, permissions: [] }))
  }));
};

const groupPermissions = (resourceTypes, permission) => {
  return resourceTypes.map(type => {
    if (type.name === permission.resource.type) {
      return {
        ...type,
        resources: type.resources.map(resource => {
          if (resource.name === permission.resource.name) {
            return {
              ...resource,
              permissions: resource.permissions.concat(permission)
            };
          }
          return resource;
        })
      };
    }
    return type;
  });
};

const Permissions = ({ value, onChange }) => {
  const { data, isLoading, isSuccess } = useQuery('permissions');

  const initialPermissions = useMemo(() => {
    if (!data) {
      return null;
    }
    return initialisePermissions(data.data);
  }, [data]);

  const permissions = useMemo(() => {
    if (!initialPermissions) {
      return null;
    }
    return data.data.reduce(groupPermissions, initialPermissions);
  }, [data, initialPermissions]);

  if (isLoading) {
    return <Placeholder ySpace />;
  }

  if (!isSuccess) {
    return null;
  }

  return (
    <Flex col ySpace>
      {permissions.map((group, i) => (
        <PermissionResourceType
          key={i}
          permissions={value}
          allPermissions={data.data}
          onChange={onChange}
          {...group}
        />
      ))}
    </Flex>
  );
};

Permissions.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      disabled: PropTypes.bool.isRequired
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired
};

export default Permissions;

export {
  AccordionLabel,
  LabelContainer,
  PermissionList,
  PermissionDescription
};

import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { Span, Flex } from '@ubisend/pulse-components';

const SessionScope = ({ content }) => {
  return (
    <Flex col>
      <Span>
        Started {dayjs(content.started_at).format('DD-MM-YYYY HH:mm:ss')}
      </Span>
      {content.ended_at && (
        <Span>
          Ended {dayjs(content.ended_at).format('DD-MM-YYYY HH:mm:ss')}
        </Span>
      )}
    </Flex>
  );
};

SessionScope.propTypes = {
  content: PropTypes.shape({
    started_at: PropTypes.string.isRequired,
    ended_at: PropTypes.string.isRequired
  }).isRequired
};

export default SessionScope;

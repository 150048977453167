import cms from './cms.json';
import bots from './bots.json';
import converse from './converse.json';
import embed from './embed.json';
import fullPage from './full_page.json';

const translations = {
  cms,
  bots,
  converse,
  embed,
  full_page: fullPage
};

export default translations;

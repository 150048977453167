import React from 'react';
import PropTypes from 'prop-types';

import { useMutation } from '@ubisend/pulse-hooks';
import { Button, useNotification, useModal } from '@ubisend/pulse-components';
import { useAuth } from '@ubisend/pulse-auth';

import { exportImport } from '../../api/index';

const ExportImport = ({ item }) => {
  const { showSuccess } = useNotification();
  const { showModal, hideModal } = useModal();
  const { user } = useAuth();

  const mutation = useMutation(exportImport, {
    onSuccess: () => {
      showSuccess(
        'You will receive an email shortly with details on accessing your export.'
      );
    }
  });

  const handleSubmit = () => {
    showModal({
      header: 'Export import',
      body: `After clicking confirm you will receive an email at "${user.email}" with a link to download the import.`,
      handleConfirm: async () => {
        try {
          await mutation.mutateAsync(item.id);
        } finally {
          hideModal();
        }
      }
    });
  };

  return (
    <Button variant="secondary" icon="download" onClick={handleSubmit}>
      Export
    </Button>
  );
};

ExportImport.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
};

export default ExportImport;

import {
  TemplatingTextArea,
  TemplatingTextInput,
  TemplatingCodeEditor,
  tags
} from './Components/index';

export default TemplatingCodeEditor;
export { TemplatingTextArea, TemplatingTextInput, TemplatingCodeEditor, tags };
export * from './hooks/index';

import StepNode from './StepNode';
import AddStepNode from './AddStepNode';

const blocks = ['action', 'email', 'integration', 'trigger', 'validation'];

const step = {
  id: 'step',
  name: 'Step',
  icon: 'puzzle',
  colour: 'grey',
  permission: 'conversations',
  Node: StepNode,
  AddNode: AddStepNode,
  blocks,
  canDrop: block => {
    return blocks.includes(block.id);
  }
};

export default step;

import { useTheme } from '@ubisend/pulse-components';

/**
 * Return an array of colours using the number of colours needed and a selection
 * of colours to pick from. If there's more colours needed than colours available
 * colours will be duplicated.
 */
const generateColours = (count, colours) => {
  return [...new Array(count)].map((_, key) => colours[key % colours.length]);
};

const useChartColours = () => {
  const theme = useTheme();

  const getColours = count => {
    return generateColours(count, [
      theme.primary,
      theme.secondary,
      theme.danger,
      theme.success,
      theme.warning
    ]);
  };

  return getColours;
};

export default useChartColours;

import React from 'react';
import PropTypes from 'prop-types';
import chroma from 'chroma-js';

import { Flex, useTheme } from '@ubisend/pulse-components';
import Icon from '@ubisend/pulse-icons';

import FilePreviewDetails from '../../FilePreviewDetails';

const PdfPreview = ({ file }) => {
  const theme = useTheme();

  return (
    <Flex col tall>
      <Flex grow middle center>
        <Icon
          type="document"
          width="5rem"
          height="5rem"
          stroke={chroma.mix('white', theme.primary, 0.25)}
          fill={chroma.mix('white', theme.primary, 0.25)}
        />
      </Flex>
      <FilePreviewDetails file={file} />
    </Flex>
  );
};

PdfPreview.propTypes = {
  file: PropTypes.object.isRequired
};

export default PdfPreview;

import { useContext } from 'react';

import { BankContext } from '../Contexts/index';

const useBank = () => {
  const context = useContext(BankContext);

  return context;
};

export default useBank;

import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  Button,
  FormHeading,
  FormGroup,
  Divider,
  Toggle,
  TextInput,
  Label
} from '@ubisend/pulse-components';
import { Rows } from '@ubisend/pulse-hooks';
import TemplatingCodeEditor, { tags } from '@ubisend/pulse-templating';

import Request from './Request';
import AttributeInput from './AttributeInput';

const defaultBody = `{
  
}`;

const EndpointDetails = ({
  handleSubmit,
  request,
  parameters,
  initialBody = defaultBody,
  initialName = '',
  initialUseBody = false
}) => {
  const [name, setName] = useState(initialName);
  const [useBody, setUseBody] = useState(initialUseBody);
  const [body, setBody] = useState(initialBody);

  const valid = useMemo(() => {
    const passes = request.valid;

    if (!passes) {
      return false;
    }

    return useBody ? Boolean(body) : parameters.valid;
  }, [request.valid, useBody, body, parameters.valid]);

  const handleNameChange = event => {
    setName(event.target.value);
  };

  const handleBodyChange = body => {
    setBody(body);
  };

  const onSubmit = event => {
    event.preventDefault();

    if (!valid) {
      return;
    }

    if (useBody) {
      return handleSubmit({ ...request.form, name, body });
    }

    handleSubmit({ ...request.form, name, parameters: parameters.rows });
  };

  return (
    <form onSubmit={onSubmit}>
      <FormHeading>Request</FormHeading>
      <FormGroup>
        <Label htmlFor="name">Name</Label>
        <TextInput
          id="name"
          placeholder="Get users"
          value={name}
          onChange={handleNameChange}
        />
      </FormGroup>
      <Request {...request} />
      <Divider />
      <Flex between top>
        <FormHeading>Request Body</FormHeading>
        <Toggle
          neutral
          options={['Params', 'JSON']}
          on={useBody}
          handleToggleChange={() => setUseBody(!useBody)}
        />
      </Flex>
      <FormGroup>
        {useBody ? (
          <TemplatingCodeEditor
            aria-label="Body json"
            value={body}
            onChange={handleBodyChange}
            tags={[tags.if, tags.for]}
          />
        ) : (
          <Rows {...parameters} KeyInput={AttributeInput} />
        )}
      </FormGroup>
      <Flex right>
        <Button disabled={!valid} type="submit" icon="trash">
          Save
        </Button>
      </Flex>
    </form>
  );
};

EndpointDetails.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  request: PropTypes.object,
  parameters: PropTypes.object,
  initialBody: PropTypes.string,
  initialName: PropTypes.string,
  initialUseBody: PropTypes.bool
};

export default EndpointDetails;

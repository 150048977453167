import styled from 'styled-components';

const TableRow = styled.tr`
  &:not(:last-of-type) {
    ${tw`border-0 border-b border-solid border-grey-medium`}
  }
  &:nth-of-type(odd) {
    ${props => (!props.active ? tw`bg-grey-light` : '')}
  }

  & th {
    ${tw`bg-white`}
  }

  ${({ theme, colour, active }) => `color: ${
    colour ? theme[colour] : theme.black
  };
  ${
    active &&
    `background-color: rgba(104, 75, 153, 0.125); color: ${theme.primary}`
  }
  `}
`;

export default TableRow;

import React from 'react';
import { Link } from 'react-router-dom';

import { getIntentLink, getIntentName } from '../utils/index';

const IntentName = props => (
  <Link to={getIntentLink(props)} target="_blank">
    {getIntentName(props)}
  </Link>
);

export default IntentName;

import React from 'react';

import { Span, Indicator, Flex } from '@ubisend/pulse-components';

const Submitted = () => (
  <Flex xSpaceSm center>
    <Indicator colour="danger" />
    <Span>Submitted</Span>
  </Flex>
);

const InProgress = () => (
  <Flex xSpaceSm center>
    <Indicator colour="warning" />
    <Span>In Progress</Span>
  </Flex>
);

const Resolved = () => (
  <Flex xSpaceSm center>
    <Indicator colour="positive" />
    <Span>Resolved</Span>
  </Flex>
);

const statuses = {
  submitted: Submitted,
  in_progress: InProgress,
  resolved: Resolved
};

const TicketStatus = ({ children: status }) => {
  const Status = statuses[status];

  return <Status />;
};

export default TicketStatus;

const TYPES = ['content', 'embed'];

const useHasContentMessage = message => {
  if (!message) {
    return false;
  }

  return TYPES.includes(message.type);
};

useHasContentMessage.TYPES = TYPES;
export default useHasContentMessage;

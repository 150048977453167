import styled from 'styled-components';

import { styles, hoverStyles } from './SidebarItem';

const SidebarSubItem = styled.div`
  & > a {
    ${styles}
    ${hoverStyles}
    ${tw`normal-case text-sm ml-3`}
  }
`;

export default SidebarSubItem;

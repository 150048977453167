import TriggerNode from './TriggerNode';
import AddTriggerNode from './AddTriggerNode';

const blocks = ['metric', 'variable'];

const trigger = {
  id: 'trigger',
  name: 'Trigger',
  icon: 'lightningBolt',
  colour: 'grey',
  permission: 'conversations',
  Node: TriggerNode,
  AddBlock: AddTriggerNode,
  blocks,
  canDrop: block => {
    return blocks.includes(block.id);
  }
};

export default trigger;

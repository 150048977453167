import NumberFormatInput from './NumberFormatInput';

const numberFormat = {
  name: 'Number format',
  Input: NumberFormatInput,
  content: {
    decimal_places: 0
  },
  format: (variable, content) => {
    return `${variable}|number_format(${content.decimal_places})`;
  },
  valid: () => true
};

export default numberFormat;

import React from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import { Select, formatSelectOption } from '@ubisend/pulse-components';

const AssigneeSelect = ({ value, isMulti = false, ...props }) => {
  const { isLoading, isSuccess, data } = useQuery('tickets/ubidesk/agents/all');

  const getSingleValue = () => {
    return formatSelectOption(data.data.find(agent => agent.id === value));
  };

  const getMultiValue = () => {
    return value.map(value => {
      return formatSelectOption(data.data.find(agent => agent.id === value));
    });
  };

  const getValue = () => {
    if (isMulti) {
      return getMultiValue();
    }

    return getSingleValue();
  };

  return (
    <Select
      isMulti={isMulti}
      isClearable
      isLoading={isLoading}
      options={isSuccess ? data.data.map(formatSelectOption) : []}
      value={isSuccess && value && getValue()}
      {...props}
    />
  );
};

AssigneeSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  isMulti: PropTypes.bool
};

export default AssigneeSelect;

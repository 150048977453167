import React from 'react';

const Whatsapp = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32pt"
    height="32pt"
    viewBox="0 0 34 32"
    version="1.1"
    {...props}>
    <g id="surface1">
      <path d="M 32 15.589844 C 32 24.195312 24.96875 31.175781 16.289062 31.175781 C 13.535156 31.175781 10.949219 30.472656 8.695312 29.234375 L 0 32 L 2.835938 23.636719 C 1.40625 21.289062 0.582031 18.53125 0.582031 15.589844 C 0.582031 6.980469 7.613281 0 16.289062 0 C 24.96875 0 32 6.980469 32 15.589844 Z M 16.289062 2.484375 C 9.007812 2.484375 3.082031 8.363281 3.082031 15.589844 C 3.082031 18.457031 4.019531 21.109375 5.597656 23.269531 L 3.949219 28.136719 L 9.023438 26.527344 C 11.109375 27.894531 13.609375 28.691406 16.292969 28.691406 C 23.574219 28.691406 29.5 22.816406 29.5 15.589844 C 29.5 8.363281 23.574219 2.484375 16.289062 2.484375 Z M 24.222656 19.179688 C 24.125 19.019531 23.871094 18.921875 23.484375 18.730469 C 23.101562 18.542969 21.207031 17.617188 20.855469 17.488281 C 20.5 17.363281 20.242188 17.296875 19.988281 17.679688 C 19.730469 18.0625 18.992188 18.921875 18.769531 19.179688 C 18.542969 19.433594 18.320312 19.464844 17.933594 19.273438 C 17.546875 19.082031 16.308594 18.679688 14.835938 17.378906 C 13.691406 16.367188 12.917969 15.117188 12.695312 14.734375 C 12.472656 14.351562 12.671875 14.144531 12.863281 13.953125 C 13.039062 13.78125 13.25 13.507812 13.441406 13.285156 C 13.636719 13.0625 13.699219 12.902344 13.828125 12.648438 C 13.957031 12.390625 13.890625 12.167969 13.792969 11.976562 C 13.699219 11.785156 12.925781 9.90625 12.605469 9.140625 C 12.285156 8.375 11.964844 8.503906 11.742188 8.503906 C 11.515625 8.503906 11.257812 8.472656 11.003906 8.472656 C 10.746094 8.472656 10.328125 8.566406 9.976562 8.949219 C 9.621094 9.332031 8.628906 10.257812 8.628906 12.136719 C 8.628906 14.015625 10.007812 15.832031 10.199219 16.085938 C 10.394531 16.34375 12.863281 20.324219 16.777344 21.855469 C 20.691406 23.386719 20.691406 22.875 21.398438 22.8125 C 22.105469 22.746094 23.675781 21.886719 24 20.996094 C 24.320312 20.101562 24.320312 19.335938 24.222656 19.179688 Z M 24.222656 19.179688 " />
    </g>
  </svg>
);

export default Whatsapp;

import { IpConditional, IpPreview } from '../Components/Subjects/index';

const subject = { id: null, type: 'ip' };

const conditional = {
  subject,
  type: null,
  condition: null,
  flipped: false,
  case_sensitive: false,
  target: ''
};

const ipSubject = {
  subject,
  conditional,
  name: "User's IP Address",
  Conditional: IpConditional,
  Preview: IpPreview,
  toState: () => subject,
  toApi: () => subject
};

export default ipSubject;

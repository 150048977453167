import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { Span, Flex } from '@ubisend/pulse-components';

import {
  NotificationWrapper,
  NotificationContainer,
  Notification
} from '../Components/index';
import {
  useReadNotifications,
  useNotifications,
  useTheme
} from '../../../hooks/index';

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05
    }
  }
};

const item = {
  hidden: { opacity: 0, x: -100 },
  show: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.4,
      ease: [0.25, 1, 0.5, 1]
    }
  }
};

const Notifications = () => {
  const { t } = useTranslation('bots');

  const theme = useTheme();
  const { notifications } = useNotifications();

  useReadNotifications();

  return (
    <>
      <NotificationContainer
        data-testid="notifications"
        initial="hidden"
        animate="show"
        variants={container}>
        {notifications.length === 0 && (
          <Flex middle mt>
            <Span>{t('no_notifications_message')}</Span>
          </Flex>
        )}
        {notifications.map((notification, i) => (
          <NotificationWrapper {...notification} key={i}>
            <Notification variants={item} read {...theme}>
              <p>{notification.body}</p>
              <p>{dayjs().to(dayjs(notification.created_at))}</p>
            </Notification>
          </NotificationWrapper>
        ))}
      </NotificationContainer>
    </>
  );
};

export default Notifications;

import axios from 'axios';

const createIntegration = integration => {
  return axios.post('integrations', integration);
};

const editIntegration = (integrationId, integration) => {
  return axios.put(`integrations/${integrationId}`, integration);
};

const deleteIntegration = integrationId => {
  return axios.delete(`integrations/${integrationId}`);
};

export { createIntegration, editIntegration, deleteIntegration };

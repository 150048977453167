import { useContext } from 'react';

import { EmailsContext } from '../Contexts/index';

const useEmails = () => {
  const context = useContext(EmailsContext);

  return context;
};

export default useEmails;

import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  TextInput,
  FormGroup,
  Label,
  Button,
  Toggle,
  Span
} from '@ubisend/pulse-components';

const Agent = ({
  initialFirstName = '',
  initialLastName = '',
  initialSoundEnabled = false,
  handleSubmit,
  disabled = false,
  loading = false
}) => {
  const [firstName, setFirstName] = useState(initialFirstName);
  const [lastName, setLastName] = useState(initialLastName);
  const [soundEnabled, setSoundEnabled] = useState(initialSoundEnabled);

  const handleFirstNameChange = event => {
    const value = event.target.value;

    setFirstName(value);
  };

  const handleLastNameChange = event => {
    const value = event.target.value;

    setLastName(value);
  };

  const handleSoundEnabledChange = () => {
    setSoundEnabled(enabled => !enabled);
  };

  const valid = useMemo(() => {
    if (disabled || loading) {
      return false;
    }

    return Boolean(firstName && lastName);
  }, [firstName, lastName, disabled, loading]);

  const onSubmit = event => {
    event.preventDefault();

    if (valid) {
      handleSubmit({
        first_name: firstName,
        last_name: lastName,
        sound_enabled: soundEnabled
      });
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <FormGroup>
        <Label htmlFor="first name">First name</Label>
        <TextInput
          id="first name"
          disabled={disabled}
          value={firstName}
          onChange={handleFirstNameChange}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="last name">Last name</Label>
        <TextInput
          id="last name"
          disabled={disabled}
          value={lastName}
          onChange={handleLastNameChange}
        />
      </FormGroup>
      <Flex between middle center xSpace mb>
        <Flex col>
          <Label htmlFor="sound_enabled">Notification sound</Label>
          <Span>
            When enabled, a sound will play when new live chat messages are
            recieved.
          </Span>
        </Flex>
        <Toggle
          id="sound_enabled"
          disabled={disabled}
          on={soundEnabled}
          handleToggleChange={handleSoundEnabledChange}
        />
      </Flex>
      {!disabled && (
        <Flex right>
          <Button
            variant="secondary"
            type="submit"
            icon="save"
            disabled={!valid}>
            Save
          </Button>
        </Flex>
      )}
    </form>
  );
};

Agent.propTypes = {
  initialFirstName: PropTypes.string,
  initialLastName: PropTypes.string,
  initialSoundEnabled: PropTypes.bool,
  handleSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool
};

export default Agent;

import React, { useState, useEffect } from 'react';

import { useMutation, useQuery, useQueryClient } from '@ubisend/pulse-hooks';
import {
  PageWrapper,
  OneHalfLayout,
  Flex,
  TextInput,
  Panel,
  Label,
  FormGroup,
  Button,
  Explainer,
  useNotification
} from '@ubisend/pulse-components';

import { updateTeamsSettings } from '../api/index';
import { ExistingSettings } from '../Components/index';

const TeamsSettings = () => {
  const { showSuccess, showError } = useNotification();
  const queryClient = useQueryClient();
  const { isSuccess, data: initialSettings } = useQuery('teams/settings');

  const [editing, setEditing] = useState(false);
  const [settings, setSettings] = useState({
    tenantId: ''
  });

  useEffect(() => {
    if (isSuccess) {
      setSettings({
        tenantId: initialSettings.data.tenant_id || ''
      });
    }
  }, [isSuccess, initialSettings]);

  const updateSettings = useMutation(updateTeamsSettings, {
    onSuccess: () => {
      showSuccess('Successfully updated Microsoft Teams Settings.');
      queryClient.invalidateQueries('teams/settings');
      setEditing(false);
    }
  });

  const handleTenantIdChange = e => {
    const id = e.target.value;

    setSettings(settings => ({
      ...settings,
      tenantId: id
    }));
  };

  const formatTenantId = id => {
    if (id.includes('tenantId')) {
      return id.split('tenantId=')[1];
    }

    return false;
  };

  const handleSubmit = () => {
    const tenant_id = formatTenantId(settings.tenantId);

    if (!tenant_id) {
      return showError(
        "That link doesnt contain a tenant ID, check you've copied the right link and try again."
      );
    }

    updateSettings.mutate({
      tenant_id: formatTenantId(settings.tenantId)
    });
  };

  const valid = settings?.tenantId;
  const hasSettings = Boolean(initialSettings?.data?.tenant_id);
  const showUpdateSettings = Boolean(!hasSettings || editing);

  const toggleEdit = () => {
    setSettings({
      tenantId: null
    });
    setEditing(true);
  };

  return (
    <PageWrapper center header="Microsoft Teams">
      <Flex col center>
        <OneHalfLayout>
          {!editing && hasSettings && (
            <ExistingSettings
              settings={initialSettings?.data?.tenant_id}
              toggleEdit={toggleEdit}
            />
          )}
          {showUpdateSettings && (
            <Panel divider header="Link your Microsoft Teams account">
              <FormGroup>
                <Explainer mb>
                  Enter the share link from your Team, you can find more
                  information on how to find this by visiting this
                  documentation.
                </Explainer>
                <Label>Team share link</Label>
                <TextInput
                  aria-label="tenant-id"
                  placeholder="https://teams.microsoft.com/l/team/&tenantId=123456"
                  value={settings.tenantId || ''}
                  onChange={handleTenantIdChange}
                />
              </FormGroup>
              <Flex xSpace>
                <Button
                  loading={updateSettings.isLoading}
                  type="submit"
                  disabled={!valid}
                  onClick={handleSubmit}>
                  Save
                </Button>
                {editing && (
                  <Button variant="secondary" onClick={() => setEditing(false)}>
                    Cancel
                  </Button>
                )}
              </Flex>
            </Panel>
          )}
        </OneHalfLayout>
      </Flex>
    </PageWrapper>
  );
};

export default TeamsSettings;

import React from 'react';

import { Flex, NoResults } from '@ubisend/pulse-components';

const NoFile = () => {
  return (
    <Flex middle fat>
      <NoResults
        text="No file selected"
        subtitle="Select a file to see a detailed breakdown"
      />
    </Flex>
  );
};

export default NoFile;

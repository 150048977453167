import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getId } from './utils';

const Vimeo = ({ url, ...props }) => {
  const { t } = useTranslation('bots');

  return (
    <iframe
      title={t('vimeo_title')}
      src={`https://player.vimeo.com/video/${getId(url)}`}
      allowFullScreen
      frameBorder="0"
      {...props}
    />
  );
};

Vimeo.propTypes = {
  url: PropTypes.string
};

export default Vimeo;

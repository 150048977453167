import { hasPermission } from '@ubisend/pulse-auth';

import { Links, CreateLink, EditLink } from './Pages/index';

const routes = [
  {
    path: '/links',
    component: Links,
    name: 'Links',
    canAccess: hasPermission('view links'),
    actions: [
      {
        title: 'View trackable links',
        description: 'Manage your trackable links and track their usage.',
        icon: 'link'
      }
    ]
  },
  {
    path: '/links/create',
    component: CreateLink,
    name: 'Create link',
    canAccess: hasPermission('create links'),
    actions: [
      {
        title: 'Create a link',
        description: 'Create a trackable link',
        icon: 'link'
      }
    ]
  },
  {
    path: '/links/:linkId/edit',
    component: EditLink,
    name: 'Edit link',
    canAccess: hasPermission('edit links'),
    actions: [
      {
        title: 'Edit a link',
        description: 'Edit a trackable link',
        icon: 'link'
      }
    ]
  }
];

export default routes;

import React from 'react';

import { useQueryClient, useMutation } from '@ubisend/pulse-hooks';
import {
  Flex,
  useNotification,
  Panel,
  Button,
  InnerPanel,
  Label
} from '@ubisend/pulse-components';
import { FacebookIcon } from '@ubisend/pulse-facebook';

import { removeSettings } from '../api/index';

const UpdateWorkplaceLink = () => {
  const { showSuccess, showError } = useNotification();

  const queryClient = useQueryClient();
  const { mutate: deleteSettings } = useMutation(removeSettings, {
    onSuccess: () => {
      showSuccess(`Successfully unlinked Workplace integration`);
      queryClient.invalidateQueries('workplace/settings');
    },
    onError: () => {
      showError(`Something went wrong whilst unlinking Workplace integration`);
    }
  });

  const handleDeleteSettings = () => deleteSettings();

  return (
    <Panel header="Update Facebook Workplace settings">
      <InnerPanel>
        <Flex center>
          <FacebookIcon style={{ marginRight: '1rem' }} size={48} />
          <Label mb={false}>Connected to Workplace</Label>
        </Flex>
      </InnerPanel>
      <Flex mt>
        <Button colour="danger" onClick={handleDeleteSettings}>
          Remove connection
        </Button>
      </Flex>
    </Panel>
  );
};

export default UpdateWorkplaceLink;

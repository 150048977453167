import { useContext } from 'react';

import { CanvasContext } from '../Contexts/index';

const useCanvas = () => {
  const context = useContext(CanvasContext);

  return context;
};

export default useCanvas;

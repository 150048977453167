import React from 'react';
import { Redirect } from 'react-router-dom';

import { useQuery, useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import { PanelSlider, Placeholder } from '@ubisend/pulse-components';

import { updateFileSource } from '../api/index';
import { FileSourceDetails } from '../Components/index';

const EditedFileSourceRedirect = () => {
  return (
    <Redirect
      to={{
        pathname: '/files/storages',
        state: {
          notification: {
            type: 'success',
            message: 'Successfully updated file storage'
          }
        }
      }}
    />
  );
};

const EditFileSource = ({ match, history }) => {
  const queryClient = useQueryClient();
  const query = useQuery(`files/sources/${match.params.id}`);
  const mutation = useMutation(updateFileSource, {
    onSuccess: () => {
      queryClient.invalidateQueries('files/sources');
      queryClient.invalidateQueries(`files/sources/${match.params.id}`);
    }
  });

  const handleSubmit = form => {
    mutation.mutate({ id: match.params.id, ...form });
  };

  return (
    <PanelSlider
      width={'40rem'}
      header={`Edit file storage`}
      handleHide={() => history.push('/files/storages')}>
      {mutation.isSuccess && <EditedFileSourceRedirect />}
      {query.isLoading && <Placeholder />}
      {query.isSuccess && (
        <FileSourceDetails
          handleSubmit={handleSubmit}
          loading={mutation.isLoading}
          initialFileSource={{
            file_driver_name: query.data.data.driver.name,
            name: query.data.data.name,
            content: query.data.data.content
          }}
        />
      )}
    </PanelSlider>
  );
};

export default EditFileSource;

import React from 'react';
import styled from 'styled-components';

import { Panel, Flex, Button } from '@ubisend/pulse-components';

import { useCanvas } from '../hooks/index';

const Container = styled(Panel)`
  ${tw`absolute flex w-auto items-center z-20 p-3 pl-4`}
  box-sizing: border-box;
  bottom: 1rem;
  left: 1rem;
  & i {
    ${tw`flex items-center`}
  }
`;

const ZoomPercentage = styled.span`
  ${tw`font-poppins font-semibold text-sm w-10`}
`;

const LayoutControls = () => {
  const { percentageRef, handleZoomInClick, handleZoomOutClick } = useCanvas();

  return (
    <Container xSpaceSm>
      <Flex xSpaceSm center>
        <ZoomPercentage ref={percentageRef}>100%</ZoomPercentage>
        <Button
          aria-label="Zoom out"
          variant="inline"
          icon="minus"
          onClick={handleZoomOutClick}
        />
        <Button
          aria-label="Zoom in"
          variant="inline"
          icon="plus"
          onClick={handleZoomInClick}
        />
      </Flex>
    </Container>
  );
};

export default LayoutControls;

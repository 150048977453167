import React, { useState } from 'react';

import { AnimateSharedLayout } from '@ubisend/framer-motion';
import {
  Flex,
  Tooltip,
  Panel,
  Button,
  TextInput,
  Checkbox,
  OneQuarterLayout,
  InfoSection,
  TextArea,
  Label,
  ProgressArc,
  Divider,
  TabButton
} from '@ubisend/pulse-components';
import { ImageSelect } from '@ubisend/pulse-files';
import { useLanguages, LanguagesConsumer } from '@ubisend/pulse-hooks';

import { useBotSettings } from '../../hooks/index';

const ConverseGeneralSettings = () => {
  const [selectImage, setSelectImage] = useState();

  const {
    settings,
    setSettings,
    language,
    setLanguage,
    name,
    bannerTitle,
    bannerSubtitle,
    focusBanner,
    focusBot,
    handleNewImage
  } = useBotSettings();
  const { languages } = useLanguages();

  const showSelectImage = () => setSelectImage(true);

  const hideSelectImage = () => setSelectImage(false);

  const handleResetBotOnClose = () => {
    setSettings(settings => ({
      ...settings,
      reset_on_close: !settings.reset_on_close
    }));
  };

  const handleShowNameToggle = () => {
    setSettings(settings => ({
      ...settings,
      name_enabled: !settings.name_enabled
    }));
  };

  const handleNameChange = event => {
    const newName = event.target.value;

    setSettings(settings => ({
      ...settings,
      names: settings.names.map(name => {
        if (name.language_id === language.id) {
          return {
            language_id: language.id,
            content: { name: newName }
          };
        }

        return name;
      })
    }));
  };

  const handleShowLogoToggle = () => {
    setSettings(settings => ({
      ...settings,
      logo_enabled: !settings.logo_enabled
    }));
  };

  const handleImageChange = file => {
    handleNewImage(file);
    hideSelectImage();
  };

  const handleBannerTitleChange = event => {
    const newTitle = event.target.value;

    setSettings(settings => ({
      ...settings,
      banner_titles: settings.banner_titles.map(title => {
        if (title.language_id === language.id) {
          return {
            language_id: language.id,
            content: { banner_title: newTitle }
          };
        }

        return title;
      })
    }));
  };

  const handleBannerSubtitleChange = event => {
    const newSubtitle = event.target.value;

    setSettings(settings => ({
      ...settings,
      banner_subtitles: settings.banner_subtitles.map(subtitle => {
        if (subtitle.language_id === language.id) {
          return {
            language_id: language.id,
            content: { banner_subtitle: newSubtitle }
          };
        }

        return subtitle;
      })
    }));
  };

  return (
    <Flex>
      <OneQuarterLayout>
        <InfoSection
          title="Main Settings"
          info="Adjust the copy displayed inside your chatbot, and add a logo."
        />
      </OneQuarterLayout>
      <Panel>
        <Flex col ySpace>
          <LanguagesConsumer>
            <Flex>
              <AnimateSharedLayout>
                {languages.map(languageTab => (
                  <TabButton
                    onClick={() => setLanguage(languageTab)}
                    active={languageTab.id === language.id}
                    key={languageTab.id}>
                    {languageTab.name}
                  </TabButton>
                ))}
              </AnimateSharedLayout>
            </Flex>
            <Divider fullWidth mtNone />
          </LanguagesConsumer>
          {selectImage && (
            <ImageSelect
              handleCancel={hideSelectImage}
              handleFileSelect={handleImageChange}
            />
          )}
          <Flex xSpace center>
            <Flex col ySpaceSm grow>
              <Checkbox
                checked={settings.name_enabled}
                onChange={handleShowNameToggle}
                label="Chatbot name"
              />
              <TextInput
                disabled={!settings.name_enabled}
                aria-label="bot-name"
                value={name}
                onChange={handleNameChange}
                onClick={focusBot}
                maxLength="255"
              />
            </Flex>
            <Flex col ySpaceSm>
              <Checkbox
                checked={settings.logo_enabled}
                onChange={handleShowLogoToggle}
                label="Chatbot logo"
              />
              <Button
                middle
                fat
                textCenter
                disabled={!settings.logo_enabled}
                variant="secondary"
                onClick={showSelectImage}>
                Change Logo
              </Button>
            </Flex>
          </Flex>
          <Flex col ySpaceSm>
            <Label>Banner title</Label>
            <TextInput
              aria-label="bot-banner-title"
              value={bannerTitle}
              onChange={handleBannerTitleChange}
              onClick={focusBanner}
              maxLength="255"
            />
          </Flex>
          <Flex col ySpaceSm>
            <Flex xSpace between>
              <Label>Banner subtitle</Label>
              <Flex xSpaceSm center>
                <ProgressArc
                  progress={'settings.banner_subtitle'.length / 500}
                />
                <Label mb={false} secondary>
                  {'settings.banner_subtitle'.length} / 500
                </Label>
              </Flex>
            </Flex>
            <TextArea
              rows={8}
              aria-label="bot-banner-subtitle"
              value={bannerSubtitle}
              onChange={handleBannerSubtitleChange}
              onClick={focusBanner}
              maxLength="500"
            />
          </Flex>
          <Divider mtNone />
          <Flex center>
            <Checkbox
              checked={settings.reset_on_close}
              onChange={handleResetBotOnClose}
              label="Reset chatbot on close"
            />
            <Tooltip
              tooltip={
                <Tooltip.Content>
                  Reset the chatbot conversation back to the start when the
                  widget is closed
                </Tooltip.Content>
              }>
              <Tooltip.Icon>?</Tooltip.Icon>
            </Tooltip>
          </Flex>
        </Flex>
      </Panel>
    </Flex>
  );
};

export default ConverseGeneralSettings;

import axios from 'axios';

const approveSectionContent = (importId, sectionId, contentId) => {
  return axios.put(
    `imports/${importId}/sections/${sectionId}/content/${contentId}`,
    {
      approved: true
    }
  );
};

export { approveSectionContent };

import React, { useState, useEffect } from 'react';

import { useQuery } from '@ubisend/pulse-hooks';
import {
  PageWrapper,
  OneHalfLayout,
  Flex,
  Panel,
  Placeholder
} from '@ubisend/pulse-components';

import {
  UpdateTelegramSettings,
  CreateTelegramLink
} from '../Components/index';

const TelegramSettings = () => {
  const { isLoading, isSuccess, data: initialSettings } = useQuery(
    'telegram/settings'
  );

  const [existingSettings, setExistingSettings] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      setExistingSettings(initialSettings?.data.has_auth_token);
    }
  }, [isSuccess, initialSettings]);

  return (
    <PageWrapper center header="Telegram">
      <Flex col center>
        <OneHalfLayout>
          {isLoading && (
            <Panel>
              <Placeholder items={1} subitems={3} center />
            </Panel>
          )}
          {isSuccess && (
            <Flex>
              {!existingSettings && <CreateTelegramLink />}
              {existingSettings && <UpdateTelegramSettings />}
            </Flex>
          )}
        </OneHalfLayout>
      </Flex>
    </PageWrapper>
  );
};

export default TelegramSettings;

import React from 'react';

import { Flex, Label, Toggle } from '@ubisend/pulse-components';

import { useVariable } from '../../../../hooks/index';

const BooleanDefaultValueInput = () => {
  const variable = useVariable();

  const handleDefaultValueChange = () => {
    variable.dispatch({
      type: variable.TYPES.UPDATE_DEFAULT_VALUE,
      defaultValue: !variable.variable.default_value
    });
  };

  return (
    <Flex between center>
      <Label mb={false} htmlFor="default">
        Default value
      </Label>
      <Toggle
        options={['False', 'True']}
        on={variable.variable.default_value}
        handleToggleChange={handleDefaultValueChange}
      />
    </Flex>
  );
};

export default BooleanDefaultValueInput;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { ConverseDemo, FullPageDemo, EmbedDemo } from './Bots/index';
import { DemoProvider } from '../Providers/index';

const bots = {
  converse: ConverseDemo,
  embed: EmbedDemo,
  'full-page': FullPageDemo
};

const BASE_URL =
  process.env.NODE_ENV === 'test'
    ? 'https://localhost:3000'
    : process.env.REACT_APP_API_URL.substring(
        0,
        process.env.REACT_APP_API_URL.lastIndexOf('/api')
      );

const Demo = ({ bot, client, token, isPublic }) => {
  const Component = useMemo(() => bots[bot], [bot]);

  return (
    <DemoProvider bot={bot} client={client} base_url={BASE_URL} token={token}>
      <Component isPublic={isPublic} />
    </DemoProvider>
  );
};

Demo.propTypes = {
  isPublic: PropTypes.bool.isRequired.isRequired,
  bot: PropTypes.oneOf(Object.keys(bots)),
  token: PropTypes.string,
  client: PropTypes.string.isRequired
};

export default Demo;
export { bots };

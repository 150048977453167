import PropTypes from 'prop-types';

const nodeType = PropTypes.shape({
  id: PropTypes.number,
  type: PropTypes.string,
  base: PropTypes.object,
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string
    })
  )
});

export default nodeType;

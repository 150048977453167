import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Flex, Span } from '@ubisend/pulse-components';

import { useBot } from '../../../../hooks/index';
import Bubble from './Bubble';

const ImageContainer = styled.div`
  ${tw`bg-cover w-full cursor-pointer`};
  background-position: 50% 50%;
  padding-bottom: 56.25%;
`;

const FullSizeImage = styled.img`
  ${tw`w-full`}
`;

const ImageWrapper = ({ fullSize = false, url, ...props }) => {
  return fullSize ? (
    <FullSizeImage src={url} {...props} />
  ) : (
    <ImageContainer
      style={{
        backgroundImage: `url('${url}')`
      }}
      {...props}
    />
  );
};

ImageWrapper.propTypes = {
  fullSize: PropTypes.bool,
  url: PropTypes.string
};

const Image = ({ content, direction, ...props }) => {
  const { setLightBoxImage, onLightboxOpen } = useBot();
  const { url, text, fullSize = false, image_description } = content;

  const handleImageClick = () => {
    setLightBoxImage(url);
    onLightboxOpen(url);
  };

  return (
    <Flex fat right={direction === 'recievedMessage'}>
      <Bubble
        direction={direction}
        style={{
          padding: 0,
          width: '75%'
        }}
        {...props}>
        <ImageWrapper
          fullSize={fullSize}
          data-testid="clickable-image"
          aria-label={image_description}
          onClick={handleImageClick}
          url={url}
        />
        {text && (
          <Flex padSm>
            <Span fat>{text}</Span>
          </Flex>
        )}
      </Bubble>
    </Flex>
  );
};

Image.propTypes = {
  content: PropTypes.shape({
    url: PropTypes.string.isRequired,
    text: PropTypes.string,
    fullSize: PropTypes.bool,
    image_description: PropTypes.string
  }).isRequired,
  direction: Bubble.propTypes.direction,
  type: PropTypes.string
};

export default Image;
export { ImageContainer };

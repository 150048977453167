import axios from 'axios';

const createNewSection = ({ id, ...params }) => {
  return axios.post(`imports/${id}/sections`, params);
};

const createSectionContent = (importId, sectionId, params) => {
  return axios.put(`imports/${importId}/sections/${sectionId}`, params);
};

const deleteSection = ({ importId, sectionId }) => {
  return axios.delete(`imports/${importId}/sections/${sectionId}`);
};

const exportSection = ({ importId, sectionId }) => {
  return axios.post(`imports/${importId}/sections/${sectionId}/export`);
};

const updateSectionOrder = ({ importId, ...params }) => {
  return axios.put(`imports/${importId}/sections/order`, params);
};

export {
  createSectionContent,
  createNewSection,
  deleteSection,
  exportSection,
  updateSectionOrder
};

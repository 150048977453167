import axios from 'axios';

const changeMappedIntent = (messageIds, intentIdentifier) => {
  return axios.put(`training/update`, {
    message_ids: messageIds,
    new_intent_identifier: intentIdentifier
  });
};

const markAsCorrect = messageIds => {
  return axios.put('training/correct', { messageIds });
};

const ignore = messageIds => {
  return axios.put('training/ignore', { messageIds });
};

export { changeMappedIntent, markAsCorrect, ignore };

import textBox from '../textBox';
import {
  RESPONSE_PREVIEW_PADDING,
  PARAGRAPH_LINE_HEIGHT,
  PARAGRAPH_FONT_SIZE,
  BUBBLE_INNER_WIDTH
} from '../constants';
import { getLines } from '../util/index';

/**
 * Function to draw a event message based on on a node object
 *
 * @param   {CanvasRenderingContext2D}  ctx current canvas rendering context
 * @param   {number}  x     x position of node
 * @param   {number}  y     y position of node
 * @param   {undefined}  _  dummy argument as a placeholder for the 'fill' argument to match the signature of other response render functions
 * @param   {object}  response  response object
 *
 * @return  {number}        height of resulting draw object
 */
const event = (ctx, x, y, _, response) => {
  const text = response.content.text;
  if (!text) {
    return 0;
  }
  const textLines = getLines(ctx, text, BUBBLE_INNER_WIDTH);
  const height = textLines.length * PARAGRAPH_LINE_HEIGHT;

  ctx.save();
  textBox(
    ctx,
    x + RESPONSE_PREVIEW_PADDING,
    y + RESPONSE_PREVIEW_PADDING + PARAGRAPH_FONT_SIZE,
    textLines,
    'black'
  );
  ctx.restore();
  return height;
};

export default event;

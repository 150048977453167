import React from 'react';

import { ChannelProvider } from '../Providers/index';
import { Channels } from '../Components/index';

const ChannelsPage = () => (
  <ChannelProvider>
    <Channels />
  </ChannelProvider>
);

export default ChannelsPage;

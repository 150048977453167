import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Button, Flex } from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';

const SubscriberLink = ({ subscriber }) => {
  if (!subscriber) {
    return 'N/A';
  }

  return (
    <PermissionFilter can="view messages" fallback="N/A">
      <Flex start>
        <Button
          as={Link}
          variant="secondary"
          to={`/conversations/${subscriber.id}`}
          icon="user">
          Subscriber {subscriber.id}
        </Button>
      </Flex>
    </PermissionFilter>
  );
};

SubscriberLink.propTypes = {
  subscriber: PropTypes.shape({
    id: PropTypes.number.isRequired
  })
};

export default SubscriberLink;

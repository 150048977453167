import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

const RouteChanger = ({ path }) => {
  if (!path) {
    return null;
  }

  return <Redirect to={path} />;
};

RouteChanger.propTypes = {
  path: PropTypes.string
};

export default RouteChanger;

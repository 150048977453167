import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Flex from '../Layout/Flex';
import Version from './Version';
import GradientBackground from '../Graphics/GradientBackground';

const Logo = styled.img`
  ${tw`w-32`}
`;

const Auth = ({ children, owner, versions }) => (
  <Flex style={{ height: '100vh', background: 'white' }}>
    <Flex
      col
      noShrink
      top
      between
      style={{ width: '30rem', padding: '2rem 3rem' }}>
      <Flex initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <a href={owner.url} target="_blank" rel="noreferrer noopener">
          <Logo src={owner.logo_url} />
        </a>
      </Flex>
      <Flex col ySpace fat middle>
        {children}
      </Flex>
      <Flex initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <Version versions={versions} />
      </Flex>
    </Flex>
    <Flex xHidden yHidden>
      <GradientBackground
        style={{ originX: '50%', originY: '50%' }}
        initial={{ opacity: 0, scale: 1.25 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          opacity: {
            duration: 0.6
          },
          scale: {
            type: 'spring',
            stiffness: 200,
            damping: 80,
            restDelta: 0.001
          }
        }}
      />
    </Flex>
  </Flex>
);

Auth.propTypes = {
  owner: PropTypes.shape({
    url: PropTypes.string.isRequired,
    logo_url: PropTypes.string.isRequired
  }).isRequired,
  versions: PropTypes.shape({
    api: PropTypes.string,
    ui: PropTypes.string.isRequired
  }).isRequired
};

export default Auth;

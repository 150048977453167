import React from 'react';

import { TextInput } from '@ubisend/pulse-components';

import { useQrCode } from '../hooks/index';

const MarginInput = props => {
  const { margin, dispatch } = useQrCode();

  const handleMarginChange = event => {
    const margin = event.target.value;

    dispatch({ type: 'CHANGE_MARGIN', margin });
  };

  return (
    <TextInput
      {...props}
      min={0}
      type="number"
      value={margin}
      onChange={handleMarginChange}
    />
  );
};

export default MarginInput;

import { hasPermission, hasGlobalRole } from '@ubisend/pulse-auth';

import { Docs } from './Pages/index';

const routes = [
  {
    name: 'Documentation',
    path: '/docs*',
    component: Docs,
    canAccess: hasPermission('view documentation'),
    actions: [
      {
        title: 'Become a platform power user',
        description:
          'Learn the ins and outs of every feature, big or small, of the platform.',
        icon: 'bookOpen'
      }
    ]
  },
  {
    name: 'Documentation',
    path: '/owner/docs*',
    component: Docs,
    canAccess: hasGlobalRole('owner'),
    disableBreadCrumbs: true
  }
];

export default routes;

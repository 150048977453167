import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { LoadingContainer, useNotification } from '@ubisend/pulse-components';
import { useLanguages } from '@ubisend/pulse-hooks';

import { BotSettingsContext } from '../Contexts/index';

const BotSettingsProvider = ({
  getBotSettings,
  updateBotLogo,
  updateBotSettings,
  children
}) => {
  const { defaultLanguage } = useLanguages();
  const { showSuccess } = useNotification();

  const [language, setLanguage] = useState(defaultLanguage);
  const [settings, setSettings] = useState();
  const [path, setPath] = useState('/window/body/messages');

  const { i18n } = useTranslation();

  const fetchBotSettings = useCallback(async () => {
    const {
      data: { data: settings }
    } = await getBotSettings();
    setSettings(settings);
  }, [getBotSettings]);

  useEffect(() => {
    fetchBotSettings();
  }, [fetchBotSettings]);

  const focusBanner = () => setPath('/window/banner');

  const focusBot = () => setPath('/window/body/messages');

  const focusLauncher = () => setPath('/');

  const focusSettings = () => setPath('/window/body/settings');

  const handleTextChange = (key, charLimit) => event => {
    const val = event.target.value;

    if (charLimit && val.length > charLimit) {
      return;
    }

    setSettings(settings => ({
      ...settings,
      [key]: val
    }));
  };

  const handleColourChange = key => chosenColour => {
    setSettings(settings => ({
      ...settings,
      styles: {
        ...settings.styles,
        [key]: chosenColour.hex
      }
    }));
  };

  const handleColourChangeNested = (key, subkey) => chosenColour => {
    setSettings(settings => ({
      ...settings,
      styles: {
        ...settings.styles,
        [key]: {
          ...settings.styles[key],
          [subkey]: chosenColour.hex
        }
      }
    }));
  };

  const handleNewImage = async file => {
    const { data } = await updateBotLogo(file);

    setSettings(settings => ({
      ...settings,
      logo: data.data.logo
    }));
    showSuccess('Successfully updated your chatbot logo.');
  };

  const handleSubmit = async () => {
    const { styles } = settings;
    const {
      background,
      gradient,
      recievedMessage,
      sentMessage,
      textColour,
      ctaBackgroundColour,
      launcherBackgroundColour
    } = styles;

    // Only pull out the settings that are changeable by the user.
    // Passing anymore will cause API to 403.
    await updateBotSettings({
      ...settings,
      styles: {
        background,
        gradient,
        recievedMessage,
        sentMessage,
        textColour,
        ctaBackgroundColour,
        launcherBackgroundColour
      }
    });
    showSuccess('Successfully updated your chatbot settings.');
  };

  useEffect(() => {
    i18n.changeLanguage(language.country_code);
  }, [i18n, language]);

  if (!settings) {
    return <LoadingContainer />;
  }

  const name = settings.names?.find(name => name.language_id === language.id);
  const bannerTitle = settings.banner_titles?.find(
    title => title.language_id === language.id
  );
  const bannerSubtitle = settings.banner_subtitles?.find(
    subtitle => subtitle.language_id === language.id
  );
  const buttons = settings.buttons?.filter(
    button => button.language_id === language.id
  );

  const context = {
    // Settings
    settings,
    setSettings,
    // Language
    language,
    setLanguage,
    // Translated params
    name: name ? name.content.name : null,
    bannerTitle: bannerTitle ? bannerTitle.content.banner_title : null,
    bannerSubtitle: bannerSubtitle
      ? bannerSubtitle.content.banner_subtitle
      : null,
    buttons,
    // Preview
    path,
    setPath,
    focusBanner,
    focusBot,
    focusLauncher,
    focusSettings,
    // Handlers
    handleTextChange,
    handleNewImage,
    handleColourChange,
    handleColourChangeNested,
    // Form
    handleSubmit,
    valid: true
  };

  return (
    <BotSettingsContext.Provider value={context}>
      {children}
    </BotSettingsContext.Provider>
  );
};

BotSettingsProvider.propTypes = {
  getBotSettings: PropTypes.func.isRequired,
  updateBotLogo: PropTypes.func.isRequired,
  updateBotSettings: PropTypes.func.isRequired
};

export default BotSettingsProvider;

import styled from 'styled-components';

import { hexToRgba } from '@ubisend/pulse-component-hooks';

const DiffPart = styled.span`
  ${({ theme: { danger }, removed }) =>
    removed &&
    `
    background: ${hexToRgba(danger, 0.25)};
    &:before{
      content: "-";
      color: ${danger};
    }
  `}
  ${({ theme: { positive }, added }) =>
    added &&
    `
    background: ${hexToRgba(positive, 0.25)};
    &:before{
      content: "+";
      color: ${positive};
    }
  `}
  ${({ removed, added }) => {
    return (added || removed) && tw`whitespace-normal px-1 rounded-sm mx-1`;
  }}
`;

export default DiffPart;

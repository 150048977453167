import React from 'react';
import PropTypes from 'prop-types';

import { Flex } from '@ubisend/pulse-components';
import { AnimatePresence } from '@ubisend/framer-motion';

import ResponsePreview from './ResponsePreview';
import { useLanguage } from '../hooks/index';

const ResponsesPreview = ({ responses }) => {
  const { language } = useLanguage();

  return (
    <AnimatePresence initial={false}>
      <Flex col ySpace fat yScroll pad relative style={{ maxHeight: 200 }}>
        {responses
          .filter(response => {
            if (response.language) {
              return response.language.id === language;
            }

            return response.language_id === language;
          })
          .map((response, key) => (
            <ResponsePreview
              key={key}
              type={response.type}
              content={response.content}
            />
          ))}
      </Flex>
    </AnimatePresence>
  );
};

ResponsesPreview.propTypes = {
  responses: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      content: PropTypes.object.isRequired,
      language: PropTypes.shape({
        id: PropTypes.number.isRequired
      }).isRequired
    }).isRequired
  ).isRequired
};

export default ResponsesPreview;

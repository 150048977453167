import React, { useMemo } from 'react';

import drivers from './FileDrivers/index';
import { useFileSource } from '../hooks/index';

const NoneSelected = () => {
  return null;
};

const FileDriverContent = () => {
  const { source } = useFileSource();

  const Component = useMemo(() => {
    if (!source.file_driver_name) {
      return NoneSelected;
    }

    return drivers[source.file_driver_name].Content;
  }, [source.file_driver_name]);

  return <Component />;
};

export default FileDriverContent;

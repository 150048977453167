import { subjects as defaultSubjects } from '@ubisend/pulse-conditionals';
import { subjects as integrationSubjects } from '@ubisend/pulse-integrations';
import { subjects as importSubjects } from '@ubisend/pulse-import';

const composers = [
  defaultSubjects.event,
  ...defaultSubjects.subjects,
  integrationSubjects.conditionals.integration,
  integrationSubjects.conditionals.lookup,
  integrationSubjects.conditionals.variable,
  importSubjects.conditionals.section
];

export default composers;

import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useMutation } from '@ubisend/pulse-hooks';
import { Flex, Span, Button, useNotification } from '@ubisend/pulse-components';
import { IntegrationSelect, EndpointSelect } from '@ubisend/pulse-integrations';

import Modal from '../../../Modal';
import { syncStepEndpoints } from '../../../../api/index';
import { useBuilder } from '../../../../hooks/index';

const AddIntegrationBlock = ({ handleClose, node }) => {
  const [integration, setIntegration] = useState(null);
  const [endpoint, setEndpoint] = useState(null);

  const { showSuccess } = useNotification();
  const { dispatch } = useBuilder();

  const { mutate, isLoading } = useMutation(syncStepEndpoints, {
    onSuccess: ({ data }) => {
      showSuccess('Successfully added a new integration');
      dispatch({
        type: 'SYNC_STEP_INTEGRATIONS',
        stepId: node.id,
        newIntegrations: data.data
      });
      handleClose();
    }
  });

  const handleIntegrationChange = integration => {
    setIntegration(integration ? integration.value : null);
  };

  const handleEndpointChange = endpoint => {
    setEndpoint(endpoint ? endpoint.value : null);
  };

  const valid = useMemo(() => {
    return Boolean(endpoint);
  }, [endpoint]);

  const handleSubmit = event => {
    event.preventDefault();

    if (valid && !isLoading) {
      mutate({
        stepId: node.id,
        endpoints: node.base.endpoints
          .map(endpoint => endpoint.id)
          .concat(endpoint)
      });
    }
  };

  return (
    <Modal header="Add integration" handleClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <Flex col pad>
          <Flex xSpace center mb>
            <Span noWrap>Make request to</Span>
            <IntegrationSelect
              value={integration}
              onChange={handleIntegrationChange}
            />
            <Span noWrap>at</Span>
            <EndpointSelect
              value={endpoint}
              integration={integration}
              onChange={handleEndpointChange}
            />
          </Flex>
          <Flex right>
            <Button icon="save" type="submit" disabled={!valid || isLoading}>
              Save
            </Button>
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};

AddIntegrationBlock.propTypes = {
  handleClose: PropTypes.func.isRequired,
  node: PropTypes.shape({
    id: PropTypes.number.isRequired,
    base: PropTypes.shape({
      endpoints: PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired
      ).isRequired
    }).isRequired
  }).isRequired
};

export default AddIntegrationBlock;

import { NotFound, Home } from './Pages/index';
import plugins from './plugins';

const routes = [
  {
    name: 'Home',
    path: '/home',
    component: Home
  },
  ...plugins,
  {
    name: 'Not Found',
    path: null,
    component: NotFound
  }
];

export default routes;

import React from 'react';

import GlobalPayProvider from './GlobalPayProvider';
import GlobalPayForm from './GlobalPayForm';

const GlobalPay = () => (
  <GlobalPayProvider>
    <GlobalPayForm />
  </GlobalPayProvider>
);

export default GlobalPay;

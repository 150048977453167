import React from 'react';

import { useQueryClient } from '@ubisend/pulse-hooks';
import { Accordion, useNotification } from '@ubisend/pulse-components';

import LookupDetails from './LookupDetails';
import { useSheets } from '../../hooks/index';
import { createLookup } from '../../api/index';

const CreateLookup = () => {
  const { showSuccess } = useNotification();
  const { viewLookups: sheet } = useSheets();

  const queryClient = useQueryClient();

  const handleSubmit = async params => {
    await createLookup({ ...params, spreadsheet_id: sheet.id });

    showSuccess('Successfully created new spreadsheet lookup');
    queryClient.invalidateQueries('spreadsheets');
  };

  return (
    <Accordion aria-label={`create-endpoint-chevron`} header={'Add New Lookup'}>
      {open && <LookupDetails handleSubmit={handleSubmit} sheet={sheet} />}
    </Accordion>
  );
};

export default CreateLookup;

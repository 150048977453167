import { useState, useEffect, useMemo, useRef } from 'react';

const useTicketMonitor = (tickets, callback) => {
  const [seenTickets, setSeenTickets] = useState([]);

  const hasGotFirstTickets = useRef(false);

  const hasSeenAllTickets = useMemo(() => {
    // Tickets are loading
    if (!tickets) {
      return true;
    }

    // First render of tickets, don't notify the user.
    if (!hasGotFirstTickets.current) {
      hasGotFirstTickets.current = true;
      setSeenTickets(tickets.map(ticket => ticket.id));
      return true;
    }

    // Has the user seen every ticket.
    return tickets.every(ticket => {
      return seenTickets.find(seenTicket => seenTicket === ticket.id);
    });
  }, [tickets, seenTickets]);

  useEffect(() => {
    if (!hasSeenAllTickets) {
      const newTickets = tickets.filter(ticket => {
        return !seenTickets.find(seenTicket => seenTicket === ticket.id);
      });

      callback(newTickets);
      setSeenTickets(tickets.map(ticket => ticket.id));
    }
  }, [hasSeenAllTickets, seenTickets, tickets, callback]);
};

export default useTicketMonitor;

import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  FormGroup,
  Label,
  Button,
  TextInput
} from '@ubisend/pulse-components';

import PermissionFilter from '../../PermissionFilter';
import Roles from '../../Roles';
import Permissions from '../../Permissions';
import { useRolesAndPermissions } from '../../../hooks/index';

const OAuthInvite = ({ handleSubmit, loading }) => {
  const [email, setEmail] = useState('');
  const [identifier, setIdentifier] = useState('');
  const {
    roles,
    setRoles,
    permissions,
    setPermissions,
    formattedPermissions
  } = useRolesAndPermissions();

  const handleRolesChange = roles => {
    setRoles(roles);
  };

  const handlePermissionsChange = permissions => {
    setPermissions(permissions);
  };

  const handleEmailChange = event => {
    const value = event.target.value;

    setEmail(value);
  };

  const handleIdentifierChange = event => {
    const value = event.target.value;

    setIdentifier(value);
  };

  const onSubmit = event => {
    event.preventDefault();

    handleSubmit({
      email,
      identifier,
      roles: roles.map(role => role.id),
      permissions: permissions.map(permission => permission.id)
    });
  };

  const valid = useMemo(() => {
    return email.length > 0;
  }, [email]);

  return (
    <form onSubmit={onSubmit}>
      <FormGroup>
        <Label htmlFor="emails">Email</Label>
        <TextInput
          id="emails"
          value={email}
          onChange={handleEmailChange}
          placeholder="john@smith.com"
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="identifier">Identifier</Label>
        <TextInput
          id="identifier"
          value={identifier}
          onChange={handleIdentifierChange}
        />
      </FormGroup>
      <PermissionFilter can="view roles">
        <FormGroup>
          <Label htmlFor="roles">Roles</Label>
          <Roles onChange={handleRolesChange} value={roles} />
        </FormGroup>
      </PermissionFilter>
      <PermissionFilter can="view permissions">
        <FormGroup>
          <Label htmlFor="roles">Extra Permissions</Label>
          <Permissions
            onChange={handlePermissionsChange}
            value={formattedPermissions}
          />
        </FormGroup>
      </PermissionFilter>
      <Flex right>
        <Button icon="save" type="submit" loading={loading} disabled={!valid}>
          Invite
        </Button>
      </Flex>
    </form>
  );
};

OAuthInvite.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default OAuthInvite;

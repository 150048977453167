import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
  Flex,
  OneHalfLayout,
  Panel,
  Divider,
  Placeholder
} from '@ubisend/pulse-components';

import { useDiff } from '../hooks/index';
import DiffPart from './DiffPart';

const Container = styled(Panel)`
  ${tw`whitespace-pre-wrap`}
`;

const ContentDiff = ({ before, after }) => {
  const { diff, loading } = useDiff(before, after);

  return (
    <Flex between xSpace>
      <OneHalfLayout>
        <Container header="Before">
          {loading && <Placeholder ySpace />}
          {!loading && (
            <>
              <Divider />
              {diff.map((part, i) => {
                return (
                  !part.added && (
                    <DiffPart key={i} removed={part.removed}>
                      {part.value}
                    </DiffPart>
                  )
                );
              })}
            </>
          )}
        </Container>
      </OneHalfLayout>
      <OneHalfLayout>
        <Container header="After">
          {loading && <Placeholder ySpace />}
          {!loading && (
            <>
              <Divider />
              {diff.map((part, i) => {
                return (
                  !part.removed && (
                    <DiffPart key={i} added={part.added}>
                      {part.value}
                    </DiffPart>
                  )
                );
              })}
            </>
          )}
        </Container>
      </OneHalfLayout>
    </Flex>
  );
};

ContentDiff.propTypes = {
  before: PropTypes.string.isRequired,
  after: PropTypes.string.isRequired
};

export default ContentDiff;

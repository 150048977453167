import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { Span, Flex, NumberFormatter } from '@ubisend/pulse-components';

const getDuration = duration => {
  if (duration === 0) {
    return null;
  }

  return (
    <>
      <NumberFormatter>{duration}</NumberFormatter> millisecond
      {duration > 1 ? 's' : ''}
    </>
  );
};

const DurationScope = ({ content }) => {
  return (
    <Flex col>
      <Span>
        {dayjs.duration({ milliseconds: content.duration }).humanize()}
      </Span>
      <Span tinyText light>
        {getDuration(content.duration)}
      </Span>
    </Flex>
  );
};

DurationScope.propTypes = {
  content: PropTypes.shape({
    duration: PropTypes.number.isRequired
  }).isRequired
};

export default DurationScope;

import React from 'react';

import { useCanvas } from '../hooks/index';
import Canvas from './Canvas/index';
import Nodes from './Nodes';

/**
 * The builder is made up of two layers:
 * - The Canvas layer manually renders all visual elements into one Dom element
 * - The DOM layer is mostly invisible and sits on top of the canvas layer, so
 *   we can still use DOM events.
 */
const Conversation = () => {
  const { nodeSizes } = useCanvas();

  return (
    <>
      {nodeSizes && <Canvas />}
      <Nodes />
    </>
  );
};

export default Conversation;

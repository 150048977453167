import types from '../Components/FileDrivers/index';

const formatFileSource = source => {
  return {
    source: source,
    driver: types[source.driver.name]
  };
};

export { formatFileSource };

import React from 'react';
import PropTypes from 'prop-types';

import { Flex, InputButton } from '@ubisend/pulse-components';
import Icon from '@ubisend/pulse-icons';

import { useTemplatingReducer } from '../../../reducers/index';
import { TemplatingContext } from '../../../Contexts/index';
import TextInput from './TextInput';
import VariableModal, {
  filters as allFilters
} from '../../VariableModal/index';
import ExternalStateSync from '../ExternalStateSync';
import InputFocus from '../InputFocus';

const TemplatingTextInput = ({
  value: externalValue,
  onChange: setExternalValue,
  filters = allFilters.default,
  ...props
}) => {
  const templating = useTemplatingReducer({
    value: externalValue
  });

  const handleShowVariables = () => {
    templating.dispatch({ type: templating.TYPES.SHOW_VARIABLES });
  };

  return (
    <TemplatingContext.Provider value={templating}>
      <ExternalStateSync
        externalValue={externalValue}
        setExternalValue={setExternalValue}
      />
      <InputFocus />
      {templating.showVariables && <VariableModal filters={filters} />}
      <Flex relative fat>
        <TextInput {...props} />
        <InputButton
          aria-label="Add variable"
          title="Add variable"
          onClick={handleShowVariables}>
          <Icon
            size="1.25rem"
            width="1.25rem"
            height="1.25rem"
            type="variable"
          />
        </InputButton>
      </Flex>
    </TemplatingContext.Provider>
  );
};

TemplatingTextInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.object)
};

export default TemplatingTextInput;

import React from 'react';

import { useTheme } from '../../hooks/index';
import SmallGraphic from './SmallGraphic';

const NoSubcriberGraphic = () => {
  const { primary } = useTheme();
  return (
    <SmallGraphic
      xmlns="http://www.w3.org/2000/svg"
      id="b02f0796-e5a2-4f93-9b0b-1e5532738db1"
      data-name="Layer 1"
      width="948.74194"
      height="658.32602"
      viewBox="0 0 948.74194 658.32602">
      <ellipse
        cx="701.12549"
        cy="653.84448"
        rx="41.70382"
        ry="4.34415"
        fill="#e6e6e6"
      />
      <ellipse
        cx="824.4993"
        cy="653.84448"
        rx="41.70382"
        ry="4.34415"
        fill="#e6e6e6"
      />
      <ellipse
        cx="920.07056"
        cy="653.84448"
        rx="28.67138"
        ry="2.9866"
        fill="#e6e6e6"
      />
      <path
        d="M1053.90456,751.39987a79.3004,79.3004,0,0,1-3.70433,21.92115c-.05161.16469-.1057.32692-.15977.49162h-13.82425c.01474-.14749.02951-.31218.04426-.49162.92178-10.59679,6.23614-75.09189-.118-86.12131C1036.698,688.09445,1054.97628,717.72907,1053.90456,751.39987Z"
        transform="translate(-125.62903 -120.83699)"
        fill="#e6e6e6"
      />
      <path
        d="M1052.86726,773.321c-.11554.16469-.236.32938-.35889.49162h-10.37064c.07866-.14011.16961-.3048.2753-.49162,1.71329-3.09226,6.7843-12.3371,11.49153-21.92115,5.05873-10.29937,9.69959-20.98956,9.30875-24.85857C1063.33375,727.41391,1066.83406,754.01525,1052.86726,773.321Z"
        transform="translate(-125.62903 -120.83699)"
        fill="#e6e6e6"
      />
      <path
        d="M763.11243,536.74666H133.21106a7.59073,7.59073,0,0,1-7.582-7.582V128.41853a7.59031,7.59031,0,0,1,7.582-7.58154H763.11243a7.59031,7.59031,0,0,1,7.582,7.58154v400.7461A7.59073,7.59073,0,0,1,763.11243,536.74666ZM133.21106,123.837a4.587,4.587,0,0,0-4.582,4.58154v400.7461a4.58744,4.58744,0,0,0,4.582,4.582H763.11243a4.58745,4.58745,0,0,0,4.582-4.582V128.41853a4.587,4.587,0,0,0-4.582-4.58154Z"
        transform="translate(-125.62903 -120.83699)"
        fill="#e6e6e6"
      />
      <path
        d="M599.95477,179.91775H266.2127a6.18856,6.18856,0,0,0-6.1823,6.18237V471.48331a6.18855,6.18855,0,0,0,6.1823,6.18237H599.95477a6.1886,6.1886,0,0,0,6.18236-6.18237V186.10012A6.18861,6.18861,0,0,0,599.95477,179.91775Zm3.70478,291.56556a3.71813,3.71813,0,0,1-3.70478,3.71638H266.2127a3.71067,3.71067,0,0,1-3.70471-3.71638V186.10012a3.71066,3.71066,0,0,1,3.70471-3.71637H599.95477a3.71813,3.71813,0,0,1,3.70478,3.71637Z"
        transform="translate(-125.62903 -120.83699)"
        fill="#3f3d56"
      />
      <path
        d="M343.24884,426.92187c0,.44-.01159.87989-.03476,1.30825a25.17464,25.17464,0,0,1-50.28069,0c-.02318-.42836-.0347-.8683-.0347-1.30825a25.17508,25.17508,0,1,1,50.35015,0Z"
        transform="translate(-125.62903 -120.83699)"
        fill={primary}
      />
      <path
        d="M574.02141,410.13462H376.2097a4.19682,4.19682,0,0,0,0,8.39363H574.02141a4.19682,4.19682,0,0,0,0-8.39363Z"
        transform="translate(-125.62903 -120.83699)"
        fill={primary}
      />
      <path
        d="M461.32685,435.31549H376.2097a4.191,4.191,0,0,0,0,8.382h85.11715a4.191,4.191,0,1,0,0-8.382Z"
        transform="translate(-125.62903 -120.83699)"
        fill="#e6e6e6"
      />
      <path
        d="M679.2442,378.99648H345.50458a7.05718,7.05718,0,0,1-7.04906-7.04948V225.48472a7.05709,7.05709,0,0,1,7.04906-7.04906H679.2442a7.05709,7.05709,0,0,1,7.049,7.04906V371.947A7.05718,7.05718,0,0,1,679.2442,378.99648Z"
        transform="translate(-125.62903 -120.83699)"
        fill={primary}
      />
      <path
        d="M413.47059,270.355a4.19591,4.19591,0,0,0,0,8.39182H611.278a4.19591,4.19591,0,0,0,0-8.39182Z"
        transform="translate(-125.62903 -120.83699)"
        fill="#fff"
      />
      <path
        d="M413.47059,294.66756a4.19592,4.19592,0,0,0,0,8.39183H611.278a4.19592,4.19592,0,0,0,0-8.39183Z"
        transform="translate(-125.62903 -120.83699)"
        fill="#fff"
      />
      <path
        d="M413.47059,318.6853a4.19592,4.19592,0,0,0,0,8.39183H498.5877a4.19592,4.19592,0,0,0,0-8.39183Z"
        transform="translate(-125.62903 -120.83699)"
        fill="#fff"
      />
      <circle cx="26.91368" cy="21.33649" r="5.21298" fill={primary} />
      <circle cx="45.15911" cy="21.33649" r="5.21298" fill={primary} />
      <circle cx="63.40453" cy="21.33649" r="5.21298" fill={primary} />
      <path
        d="M896.87615,558.52033l-4.27324,23.69112a12.23847,12.23847,0,0,0,13.7257,14.29482h0a12.23848,12.23848,0,0,0,10.23845-14.896L911.146,558.31258,930.5315,495.5467l-4.46693-67.17626-27.77168,9.60242L910.8467,489.336Z"
        transform="translate(-125.62903 -120.83699)"
        fill="#ffb8b8"
      />
      <circle cx="759.87806" cy="225.161" r="23.22149" fill="#ffb8b8" />
      <path
        d="M875.74369,412.393l45.38571-.70915c-9.737-23.56512-17.62907-45.81427-15.60134-60.98705l-31.91183,4.96406C877.39433,377.21312,879.22435,392.73678,875.74369,412.393Z"
        transform="translate(-125.62903 -120.83699)"
        fill="#ffb8b8"
      />
      <path
        d="M839.577,469.83431l60.987,11.34643c20.56055-37.66789,31.90317-71.68079,12.76473-93.608L877.162,385.44526c-23.10907,24.71177-42.654,48.6936-29.37439,65.95355Z"
        transform="translate(-125.62903 -120.83699)"
        fill={primary}
      />
      <path
        d="M893.88568,440.25725c13.95736,5.33979,26.91064.69878,38.80124-14.52063l-17.62248-33.99844-22.4547,4.4502Z"
        transform="translate(-125.62903 -120.83699)"
        fill={primary}
      />
      <path
        d="M833.90373,752.0767l24.11114-1.24018,15.60136-163.283,79.425,162.39574,24.82031-.70915-47.51316-142.5395c-1.87537-47.55614-3.83489-94.98843-24.11116-115.59173l-7.09152-14.89218L842.41356,464.1611l-7.80067,14.89218C816.52709,521.28462,827.14567,641.955,833.90373,752.0767Z"
        transform="translate(-125.62903 -120.83699)"
        fill="#2f2e41"
      />
      <path
        d="M820.22348,776.41093l28.14349,2.69869a11.40108,11.40108,0,0,0,12.42536-12.55509L858.724,747.11264c-7.79824,2.03067-16.12177,1.55551-24.82031-.70916l-10.63727,9.219-5.04144,1.57545a9.85324,9.85324,0,0,0-6.87724,8.55115v0A9.85327,9.85327,0,0,0,820.22348,776.41093Z"
        transform="translate(-125.62903 -120.83699)"
        fill="#2f2e41"
      />
      <path
        d="M939.361,776.41093l28.14349,2.69869a11.40108,11.40108,0,0,0,12.42536-12.55509l-2.06829-19.44189c-7.79824,2.03067-16.12177,1.55551-24.82031-.70916l-10.63727,9.219-5.04144,1.57545a9.85324,9.85324,0,0,0-6.87724,8.55115v0A9.85327,9.85327,0,0,0,939.361,776.41093Z"
        transform="translate(-125.62903 -120.83699)"
        fill="#2f2e41"
      />
      <path
        d="M858.01489,425.15775c9.93436,11.16377,23.5703,13.00431,41.1308,4.96406V391.82762l-21.9837-6.38236Z"
        transform="translate(-125.62903 -120.83699)"
        fill={primary}
      />
      <path
        d="M806.24682,531.53051l-14.69621,19.067a12.23848,12.23848,0,0,0,5.60769,19.00765h0a12.23848,12.23848,0,0,0,15.94488-8.51343l5.90834-23.17888,46.09486-46.804,26.94777-61.6962L862.979,425.15775l-19.85625,53.89553Z"
        transform="translate(-125.62903 -120.83699)"
        fill="#ffb8b8"
      />
      <path
        d="M876.628,311.73539c10.66451-5.36371,23.4139-6.96,34.7505-3.22062s20.93092,13.11452,23.82942,24.69468c2.191,8.75366.60852,17.93691-.16423,26.92746s-.52636,18.70679,4.6549,26.09474c3.24687,4.6297,8.09874,7.84072,12.87794,10.86323s9.70619,6.05777,13.23654,10.47511,5.436,10.595,3.30578,15.83322c-2.71966,6.68753-10.55509,9.41-17.5027,11.37216-9.90375,2.7971-19.93977,5.61469-30.22885,5.82079s-21.03149-2.48836-28.59175-9.47051c-10.284-9.49764-12.75286-25.03168-10.80335-38.89406s7.59073-26.90389,11.146-40.4437c1.37371-5.23166,2.17088-11.54325-1.63385-15.38789-3.8645-3.905-10.41922-3.1396-15.22978-5.79332a11.664,11.664,0,0,1,3.27933-21.63686"
        transform="translate(-125.62903 -120.83699)"
        fill="#2f2e41"
      />
    </SmallGraphic>
  );
};

export default NoSubcriberGraphic;

import axios from 'axios';

const createRole = params => axios.post(`roles`, params);

const updateRole = ({ roleId, ...params }) => {
  return axios.put(`roles/${roleId}`, params);
};

const deleteRole = roleId => axios.delete(`roles/${roleId}`);

const updateUserRoles = ({ userId, ...params }) => {
  return axios.put(`users/${userId}/roles`, params);
};

export { createRole, updateRole, deleteRole, updateUserRoles };

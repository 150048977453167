import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, I18nextProvider } from 'react-i18next';

import { useNotifySubscription } from '@ubisend/pulse-subscribe';
import { useI18nInstance } from '@ubisend/pulse-translations';

import { LanguageContext } from '../Contexts/index';
import { useExternallyChangableState, useAuth } from '../hooks/index';

const LanguageListener = ({ initialLanguage, children }) => {
  const [language, setLanguage] = useExternallyChangableState(initialLanguage);

  const { i18n } = useTranslation();

  const { query } = useAuth();

  const refetch = query.refetch;

  const handleSubscriberChangeLanguage = useCallback(
    ({ language }) => {
      setLanguage(language);
      refetch();
    },
    [setLanguage, refetch]
  );

  useNotifySubscription(
    'SUBSCRIBER_CHANGED_LANGUAGE',
    handleSubscriberChangeLanguage
  );

  useEffect(() => {
    if (i18n.language !== language.country_code) {
      i18n.changeLanguage(language.country_code);
    }
  }, [i18n, language.country_code]);

  const context = {
    language,
    setLanguage
  };

  return (
    <LanguageContext.Provider value={context}>
      {children}
    </LanguageContext.Provider>
  );
};

LanguageListener.propTypes = {
  initialLanguage: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    country_code: PropTypes.string.isRequired
  }).isRequired
};

const LanguageProvider = ({ children, initialLanguage, namespaces }) => {
  const instance = useI18nInstance(initialLanguage.country_code, {
    namespaces
  });

  if (!instance) {
    return null;
  }

  return (
    <I18nextProvider i18n={instance}>
      <LanguageListener initialLanguage={initialLanguage}>
        {children}
      </LanguageListener>
    </I18nextProvider>
  );
};

LanguageProvider.propTypes = {
  initialLanguage: LanguageListener.propTypes.initialLanguage,
  namespaces: PropTypes.arrayOf(PropTypes.string)
};

export default LanguageProvider;

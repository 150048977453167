import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { TimePicker as MaterialTimePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';

import { useComposer, useDelivery } from '../../../../../hooks/index';
import { DateProvider } from '../../../../../Providers/index';
import { DateWrapper } from '../../../../../Components/index';
import { getPickerProps, DateInput } from './Date/index';

const isValid = date => dayjs(date, 'HH:mm').isValid();

const TimeComposer = props => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation('bots');

  const handleInputClick = () => setOpen(open => !open);

  const { reply, setReply } = useComposer();
  const { sendMessage } = useDelivery();

  const handleChange = date => {
    setReply(dayjs(date).format('HH:mm'));
  };

  const handleSend = () => {
    if (!isValid(reply)) {
      return;
    }

    sendMessage(reply);
    setReply('');
  };

  return (
    <DateProvider>
      <DateWrapper>
        {open && (
          <MaterialTimePicker
            {...getPickerProps(props.variant, { open, setOpen, handleChange })}
            onChange={handleChange}
            value={reply ? dayjs(reply, 'HH:mm').toDate() : null}
            minutesStep={5}
          />
        )}
        <DateInput
          placeholder={t('time_composer_label')}
          aria-label={t('time_composer_label')}
          value={reply}
          onClick={handleInputClick}
          handleSubmit={handleSend}
        />
      </DateWrapper>
    </DateProvider>
  );
};

TimeComposer.propTypes = {
  variant: PropTypes.oneOf(['dialog', 'static'])
};

export default TimeComposer;

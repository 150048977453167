import { useReducer } from 'react';

const defaultParams = {
  show: false,
  tab: 'BLOCKS'
};

const TYPES = {
  SHOW_BANK: 'SHOW_BANK',
  HIDE_BANK: 'HIDE_BANK',
  SHOW_BLOCKS: 'SHOW_BLOCKS',
  SHOW_SETTINGS: 'SHOW_SETTINGS',
  SHOW_DEMO: 'SHOW_DEMO',
  TEST_STEP: 'TEST_STEP'
};

const reducer = (state, { type, ...params }) => {
  switch (type) {
    case TYPES.SHOW_BANK:
      return { ...state, show: true };
    case TYPES.HIDE_BANK:
      return { ...state, show: false };
    case TYPES.SHOW_BLOCKS:
      return { ...state, show: true, tab: 'BLOCKS' };
    case TYPES.SHOW_SETTINGS:
      return { ...state, show: true, tab: 'SETTINGS' };
    case TYPES.SHOW_DEMO:
      return { ...state, show: true, tab: 'DEMO' };
    case TYPES.TEST_STEP:
      return {
        ...state,
        show: true,
        tab: 'DEMO',
        demo_trigger: params.demoTrigger
      };
    default:
      throw new Error(`No event defined in useBankReducer for ${type}`);
  }
};

const useBankReducer = (initialParams = defaultParams) => {
  const [bank, dispatch] = useReducer(reducer, {
    ...defaultParams,
    ...initialParams
  });

  return { ...bank, bank, dispatch };
};

export default useBankReducer;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTimestamp = styled.div`
  ${tw`text-xs text-black text-center mb-2`}
`;

const Timestamp = ({ stamp }) => <StyledTimestamp>{stamp}</StyledTimestamp>;

Timestamp.propTypes = {
  stamp: PropTypes.string.isRequired
};

export default Timestamp;

import styled from 'styled-components';

const MessageContainer = styled.div`
  & > * {
    ${tw`mb-3`}
  }

  & > *:last-child {
    ${tw`mb-0`}
  }
`;

export default MessageContainer;

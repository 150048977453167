import React from 'react';

import { FormGroup, Label } from '@ubisend/pulse-components';
import { useComposer } from '@ubisend/pulse-composer';

import SectionSelect from '../../../SectionSelect';
import ImportSelect from '../../../ImportSelect';

const SectionComposer = () => {
  const {
    response: { subject },
    dispatch
  } = useComposer();

  const handleImportSelect = option => {
    dispatch({
      type: 'CHANGE_SUBJECT',
      newSubject: {
        ...subject,
        import_id: option ? option.value : null,
        section_id: null
      }
    });
  };

  const handleSectionSelect = ({ value }) => {
    dispatch({
      type: 'CHANGE_SUBJECT',
      newSubject: { ...subject, section_id: value }
    });
  };

  return (
    <div>
      <FormGroup>
        <Label htmlFor="import">Import</Label>
        <ImportSelect
          id="import"
          value={subject.import_id}
          onChange={handleImportSelect}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="section">Section</Label>
        <SectionSelect
          import={subject.import_id}
          value={subject.section_id}
          onChange={handleSectionSelect}
        />
      </FormGroup>
    </div>
  );
};

export default SectionComposer;

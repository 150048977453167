import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  TextInput,
  FormGroup,
  Label,
  Divider,
  Flex,
  FormHeading
} from '@ubisend/pulse-components';
import {
  useConditional,
  Conditionals,
  Match,
  AddConditionalButton
} from '@ubisend/pulse-conditionals';
import { GroupSelect } from '@ubisend/pulse-groups';

const ConversationDetails = ({
  handleSubmit,
  initialTitle = '',
  initialDescription = '',
  initialGroups = [],
  showConditional = true,
  loading = false
}) => {
  const [title, setTitle] = useState(initialTitle);
  const [description, setDescription] = useState(initialDescription);
  const [groups, setGroups] = useState(initialGroups);
  const conditional = useConditional();

  const handleTitleChange = event => setTitle(event.target.value);

  const handleDescriptionChange = event => setDescription(event.target.value);

  const handleGroupsChange = groups => {
    setGroups(groups ? groups.map(group => group.value) : []);
  };

  const valid = showConditional
    ? title.length > 3 && description.length > 3 && conditional.valid
    : title.length > 3 && description.length > 3;

  const onSubmit = event => {
    event.preventDefault();

    if (!valid || loading) {
      return;
    }

    handleSubmit({
      title,
      description,
      groups,
      ...(showConditional && {
        ...conditional.form,
        metrics: [],
        variables: []
      })
    });
  };

  return (
    <form onSubmit={onSubmit}>
      <FormGroup>
        <Label htmlFor="name">Name *</Label>
        <TextInput
          id="name"
          value={title}
          onChange={handleTitleChange}
          placeholder="My new conversation"
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="description">Description *</Label>
        <TextInput
          id="description"
          value={description}
          onChange={handleDescriptionChange}
          placeholder="This conversation..."
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="tags">Tags</Label>
        <GroupSelect
          aria-label="Conversation tags"
          id="tags"
          for="conversations"
          value={groups}
          onChange={handleGroupsChange}
        />
      </FormGroup>
      {showConditional && (
        <>
          <FormHeading>Trigger Conversation</FormHeading>
          <Match />
          <Divider />
          <Conditionals />
        </>
      )}
      <Flex right xSpace>
        {showConditional && <AddConditionalButton />}
      </Flex>
      {showConditional && (
        <>
          <Divider />
          <Flex center xSpace bottom>
            <Flex xSpace fat right bottom>
              <Button icon="save" disabled={!valid || loading} type="submit">
                Save
              </Button>
            </Flex>
          </Flex>
        </>
      )}
      {!showConditional && (
        <Flex right>
          <Button icon="save" disabled={!valid || loading} type="submit">
            Save
          </Button>
        </Flex>
      )}
    </form>
  );
};

ConversationDetails.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialTitle: PropTypes.string,
  initialDescription: PropTypes.string,
  initialGroups: PropTypes.arrayOf(PropTypes.number),
  showConditional: PropTypes.bool,
  loading: PropTypes.bool
};

export default ConversationDetails;

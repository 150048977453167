import React, { useState } from 'react';

import { Button } from '@ubisend/pulse-components';

import { Wizard } from './Wizard/index';

const NewImportButton = ({ children, ...props }) => {
  const [show, setShow] = useState(false);

  const handleWizardStart = () => setShow(true);

  const handleWizardEnd = () => setShow(false);

  return (
    <>
      <Button onClick={handleWizardStart} {...props}>
        {children}
      </Button>
      {show && <Wizard handleClose={handleWizardEnd} />}
    </>
  );
};

export default NewImportButton;

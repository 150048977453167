import React from 'react';
import PropTypes from 'prop-types';

import { Placeholder, Checkbox, Flex } from '@ubisend/pulse-components';
import { useQuery } from '@ubisend/pulse-hooks';

import {
  PermissionList,
  LabelContainer,
  PermissionDescription
} from './Permissions';

const Roles = ({ value, onChange }) => {
  const { data, isLoading, isSuccess } = useQuery('roles/all');

  if (isLoading) {
    return <Placeholder ySpace />;
  }

  if (!isSuccess) {
    return null;
  }

  const handleRoleCheck = (role, checked) => {
    if (checked) {
      return onChange(value.filter(({ id }) => id !== role.id));
    }
    onChange(value.concat(role));
  };

  return (
    <PermissionList>
      {data.data.map(role => {
        const checked = value.find(userRole => userRole.id === role.id);
        return (
          <Flex key={role.id}>
            <LabelContainer>
              <Checkbox
                checked={checked}
                onChange={() => handleRoleCheck(role, checked)}
                label={role.name}
              />
            </LabelContainer>
            <PermissionDescription>{role.description}</PermissionDescription>
          </Flex>
        );
      })}
    </PermissionList>
  );
};

Roles.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired
};

export default Roles;

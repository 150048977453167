import { useLayoutEffect, useRef, useCallback } from 'react';

import { useCanvas } from '../hooks/index';

const AUTOFIT_MARGIN = 120;
const SIDEBAR_WIDTH = 12 * 16;

const AutoZoom = () => {
  const hasAutoZoomed = useRef(false);
  const { nodeSizes, nodes, panX, panY, zoomAmount } = useCanvas();

  const autofit = useCallback(() => {
    // Get bounds of conversation
    const conversationBounds = {
      left: Math.min(...nodes.map(node => node.x)),
      right: Math.max(...nodes.map(node => node.x)),
      top: Math.min(...nodes.map(node => node.y)),
      bottom: Math.max(...nodes.map(node => node.y))
    };
    // Get height and width of conversation based on bounds
    conversationBounds.width = Math.abs(
      conversationBounds.right - conversationBounds.left
    );
    conversationBounds.height = Math.abs(
      conversationBounds.bottom - conversationBounds.top
    );
    const canvasDimensions = {
      width: window.innerWidth - SIDEBAR_WIDTH,
      height: window.innerHeight
    };
    // Get difference in conversation bounds to current viewport
    // E.g if the conversation was 4x taller than the canvas the yZoomRatio would be 25%
    const yZoomRatio =
      (canvasDimensions.height - AUTOFIT_MARGIN) / conversationBounds.height;
    const xZoomRatio =
      (canvasDimensions.width - AUTOFIT_MARGIN) / conversationBounds.height;
    // Take the smallest zoomRatio, capping at 100%
    const zoomRatio = Math.min(1, yZoomRatio, xZoomRatio);
    const conversationCenterX =
      (conversationBounds.left + conversationBounds.width / 2) * zoomRatio;
    const conversationCenterY =
      (conversationBounds.top + conversationBounds.height / 2) * zoomRatio;

    panX.set(canvasDimensions.width / 2 - conversationCenterX);
    panY.set(canvasDimensions.height / 2 - conversationCenterY);
    zoomAmount.set(zoomRatio);
  }, [nodes, panX, panY, zoomAmount]);

  useLayoutEffect(() => {
    if (!hasAutoZoomed.current && nodeSizes.length > 0) {
      autofit();
      hasAutoZoomed.current = true;
    }
  }, [nodes, nodeSizes, panX, panY, zoomAmount, autofit]);

  return null;
};

export default AutoZoom;

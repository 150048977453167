import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

import Icon from '@ubisend/pulse-icons';

import { useTheme } from '../../hooks/index';
import { Flex } from '../Layout/index';

const LanguageOption = ({ data, ...rest }) => {
  const { positive, danger } = useTheme();

  return (
    <components.Option {...rest}>
      <Flex center between>
        {data.label}
        {data.valid ? (
          <Icon height="1rem" width="1rem" colour={positive} type={'check'} />
        ) : (
          <Icon height="1rem" width="1rem" colour={danger} type={'x'} />
        )}
      </Flex>
    </components.Option>
  );
};

LanguageOption.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string,
    valid: PropTypes.bool
  })
};

export default LanguageOption;

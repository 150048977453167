import React from 'react';

import {
  Converse,
  StickyWidget,
  DeactivatedQueryProvider
} from '@ubisend/pulse-volt';

import { useBotSettings } from '../../hooks/index';
import { BotProvider } from '../../Providers/index';

const WidgetPreview = () => {
  const { path } = useBotSettings();

  return (
    <StickyWidget>
      <DeactivatedQueryProvider>
        <BotProvider>
          <Converse path={path} />
        </BotProvider>
      </DeactivatedQueryProvider>
    </StickyWidget>
  );
};

export default WidgetPreview;

import React from 'react';
import { Redirect } from 'react-router-dom';

import { useQuery, useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import {
  PageWrapper,
  OneHalfLayout,
  Placeholder,
  Panel
} from '@ubisend/pulse-components';

import { updateNotification } from '../api/index';
import { NotificationProvider } from '../Providers/index';
import { NotificationDetails } from '../Components/index';

const EditNotification = ({ match }) => {
  const queryClient = useQueryClient();
  const query = useQuery(`template-notifications/${match.params.id}`);
  const mutation = useMutation(updateNotification, {
    onSuccess: () => {
      queryClient.invalidateQueries('template-notifications');
      queryClient.invalidateQueries(
        `template-notifications/${match.params.id}`
      );
    }
  });

  const handleSubmit = form => {
    mutation.mutate({ id: match.params.id, ...form });
  };

  return (
    <PageWrapper header="Notifications" subheader="Update a notification">
      {mutation.isSuccess && (
        <Redirect
          to={{
            pathname: '/notifications',
            state: {
              notification: {
                type: 'success',
                message: 'Successfully updated notification.'
              }
            }
          }}
        />
      )}
      <OneHalfLayout>
        <Panel>
          {query.isLoading && <Placeholder items={2} />}
          {query.isSuccess && (
            <NotificationProvider initialNotification={query.data.data}>
              <NotificationDetails
                onSubmit={handleSubmit}
                loading={mutation.isLoading}
              />
            </NotificationProvider>
          )}
        </Panel>
      </OneHalfLayout>
    </PageWrapper>
  );
};

export default EditNotification;

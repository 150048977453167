import React, { useEffect } from 'react';

import { usePayment } from '../../../../../../../hooks/index';
import useGlobalPay from './useGlobalPay';
import GlobalPayFrame, { frameId } from './GlobalPayFrame';

const payUrl = 'https://pay.sandbox.realexpayments.com/pay';

const GlobalPayForm = () => {
  const pay = useGlobalPay();
  const json = usePayment();

  useEffect(() => {
    pay.setHppUrl(payUrl);
    pay.init(frameId, json);
  }, [pay, json]);

  return <GlobalPayFrame />;
};

export default GlobalPayForm;

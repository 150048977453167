import React from 'react';

import { Accordion, useNotification } from '@ubisend/pulse-components';
import { useRows, useQueryClient } from '@ubisend/pulse-hooks';

import EndpointDetails from './EndpointDetails';
import { useIntegrations, useRequest } from '../../hooks/index';
import { createEndpoint } from '../../api/index';

const CreateEndpoint = () => {
  const { showSuccess } = useNotification();
  const { viewEndpoints: integration } = useIntegrations();

  const queryClient = useQueryClient();

  const { setRows, ...parameters } = useRows([]);
  const request = useRequest();

  const handleCreateEndpoint = async params => {
    await createEndpoint(integration.id, params);

    showSuccess(`Successfully created new endpoint for ${integration.name}`);
    setRows([]);
    request.resetRequest();
    queryClient.invalidateQueries('integrations');
  };

  return (
    <Accordion
      aria-label={`create-endpoint-chevron`}
      header={'Add New Endpoint'}>
      <EndpointDetails
        handleSubmit={handleCreateEndpoint}
        parameters={parameters}
        request={request}
      />
    </Accordion>
  );
};

export default CreateEndpoint;

import React from 'react';

const WeChat = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32pt"
    height="32pt"
    viewBox="0 0 30 32"
    version="1.1"
    {...props}>
    <g id="surface1">
      <path d="M 16.007812 2.480469 C 8.554688 2.480469 2.492188 8.546875 2.492188 16 C 2.492188 23.453125 8.554688 29.519531 16.007812 29.519531 C 23.464844 29.519531 29.527344 23.453125 29.527344 16 C 29.527344 8.546875 23.464844 2.480469 16.007812 2.480469 Z M 16.007812 28.183594 C 9.289062 28.183594 3.824219 22.71875 3.824219 16 C 3.824219 9.28125 9.289062 3.816406 16.007812 3.816406 C 22.726562 3.816406 28.195312 9.28125 28.195312 16 C 28.195312 22.71875 22.726562 28.183594 16.007812 28.183594 Z M 16.007812 28.183594 " />
      <path d="M 19.195312 12.703125 C 19.34375 12.703125 19.488281 12.707031 19.632812 12.714844 C 18.921875 10.496094 16.476562 8.859375 13.574219 8.859375 C 10.109375 8.859375 7.300781 11.1875 7.300781 14.058594 C 7.300781 15.753906 8.285156 17.261719 9.804688 18.210938 L 9.339844 19.980469 L 11.5625 18.980469 C 12.054688 19.117188 12.578125 19.207031 13.113281 19.238281 C 13.007812 18.847656 12.945312 18.4375 12.945312 18.019531 C 12.945312 15.085938 15.75 12.703125 19.195312 12.703125 Z M 15.578125 11.425781 C 16.109375 11.425781 16.542969 11.855469 16.542969 12.386719 C 16.542969 12.921875 16.109375 13.351562 15.578125 13.351562 C 15.046875 13.351562 14.617188 12.921875 14.617188 12.386719 C 14.617188 11.855469 15.046875 11.425781 15.578125 11.425781 Z M 11.480469 13.351562 C 10.949219 13.351562 10.515625 12.921875 10.515625 12.386719 C 10.515625 11.855469 10.949219 11.425781 11.480469 11.425781 C 12.011719 11.425781 12.441406 11.855469 12.441406 12.386719 C 12.441406 12.921875 12.011719 13.351562 11.480469 13.351562 Z M 11.480469 13.351562 " />
      <path d="M 19.164062 13.542969 C 16.15625 13.542969 13.71875 15.5625 13.71875 18.050781 C 13.71875 20.542969 16.15625 22.5625 19.164062 22.5625 C 19.914062 22.5625 20.628906 22.4375 21.28125 22.207031 L 23.117188 23.140625 L 22.710938 21.46875 C 23.871094 20.640625 24.605469 19.417969 24.605469 18.050781 C 24.605469 15.5625 22.167969 13.542969 19.164062 13.542969 Z M 17.542969 17.371094 C 17.035156 17.371094 16.625 16.960938 16.625 16.453125 C 16.625 15.949219 17.035156 15.539062 17.542969 15.539062 C 18.046875 15.539062 18.457031 15.949219 18.457031 16.453125 C 18.457031 16.960938 18.046875 17.371094 17.542969 17.371094 Z M 21.078125 17.371094 C 20.574219 17.371094 20.164062 16.960938 20.164062 16.453125 C 20.164062 15.949219 20.574219 15.539062 21.078125 15.539062 C 21.585938 15.539062 21.996094 15.949219 21.996094 16.453125 C 21.996094 16.960938 21.585938 17.371094 21.078125 17.371094 Z M 21.078125 17.371094 " />
    </g>
  </svg>
);

export default WeChat;

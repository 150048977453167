import { useState } from 'react';

import { useTransform } from '@ubisend/framer-motion';

import useCanvas from './useCanvas';

const useDragPlaceholder = (node, mouseOffset) => {
  const {
    nodeSizes,
    mousePositionX,
    mousePositionY,
    compareNodes
  } = useCanvas();
  const nodeSize = nodeSizes?.find(n => compareNodes(n, node)) || {
    height: 0,
    width: 0
  };

  const [dragging, setDragging] = useState(false);

  const placeholderX = useTransform(
    mousePositionX,
    x => x - nodeSize.width * 0.5 + mouseOffset.x
  );
  const placeholderY = useTransform(
    mousePositionY,
    y => y - nodeSize.height * 0.5 + mouseOffset.y
  );

  const placeholderStyle = {
    x: placeholderX,
    y: placeholderY,
    width: nodeSize.width,
    height: nodeSize.height
  };

  return { placeholderStyle, setDragging, dragging };
};

export default useDragPlaceholder;

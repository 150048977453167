import React from 'react';

import { Modal } from './Modal';
import { useModal } from '../../hooks/index';

const Modals = () => {
  const { modal, hideModal } = useModal();

  return (
    <div id={'modal-container'}>
      {modal && (
        <Modal
          cancelText="Cancel"
          confirmText="Confirm"
          handleCancel={hideModal}
          {...modal}
        />
      )}
    </div>
  );
};

export default Modals;

import { useEffect, useCallback } from 'react';

import useSubscriptionContext from './useSubscriptionContext';

const useSubscription = (event, handle) => {
  const { socket } = useSubscriptionContext();

  useEffect(() => {
    if (handle) {
      socket.on(event, handle);
    }

    return () => {
      if (handle) {
        socket.off(event, handle);
      }
    };
  }, [socket, event, handle]);

  const emit = useCallback(params => socket.emit(event, params), [
    event,
    socket
  ]);

  return emit;
};

export default useSubscription;

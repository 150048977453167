import auth from './Auth/index';
import date from './Date/index';
import dateTime from './DateTime/index';
import deactivated from './Deactivated/index';
import feedback from './Feedback/index';
import fuzzy from './Fuzzy/index';
import hidden from './Hidden/index';
import loading from './Loading/index';
import message from './Message/index';
import numeric from './Numeric/index';
import time from './Time/index';

const composers = [
  auth,
  date,
  dateTime,
  deactivated,
  feedback,
  fuzzy,
  hidden,
  loading,
  message,
  numeric,
  time
];

export default composers;

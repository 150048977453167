import styled from 'styled-components';

import { motion } from '@ubisend/framer-motion';

const Notification = styled(motion.div)`
  ${tw`p-6 border-grey border-0 border-b border-solid relative`};
  ${props => props.read && tw`bg-grey-light`}
  & > p {
    ${tw`m-0`};
  }
  & > p:nth-child(2) {
    ${tw`text-xs mt-2 tracking-wider`};
  }
  & > p:first-child:after {
    ${tw`w-2 h-2 bg-primary block absolute rounded-full`};
    background: ${props => props.theme.bot.gradient.to || 'red'};
    ${props => props.read && tw`invisible`}
    content: '';
    top: 1rem;
    right: 1rem;
    animation: fadein 0.1s;
    animation-timing-function: ease-out;
    animation-fill-mode: both;
    animation-delay: 0.2s;
  }

  @keyframes fadein {
    from {
      opacity: 0;
      transform: scale(0);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

export default Notification;

import React from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import { Select } from '@ubisend/pulse-components';

const SourceSelect = ({ value, queryKey, ...props }) => {
  const query = useQuery(queryKey);

  const format = source => {
    return {
      label: source.name,
      value: source.id
    };
  };

  return (
    <Select
      value={
        value && query.isSuccess
          ? format(query.data.data.find(source => source.id === value))
          : null
      }
      options={query.isSuccess ? query.data.data.map(format) : []}
      isLoading={query.isLoading}
      {...props}
    />
  );
};

SourceSelect.propTypes = {
  queryKey: PropTypes.string.isRequired,
  value: PropTypes.number
};

export default SourceSelect;

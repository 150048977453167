import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import { Select } from '@ubisend/pulse-components';

const format = threshold => {
  return {
    value: `${threshold}`,
    label:
      threshold > 0
        ? `When confidence is less than ${threshold * 100}%`
        : 'Never'
  };
};

const ThresholdSelect = ({ value, ...props }) => {
  const { data, isLoading, isSuccess } = useQuery('thresholds');
  const options = useMemo(() => {
    if (!isSuccess) {
      return null;
    }

    return Object.values(data).map(([value]) => format(value));
  }, [data, isSuccess]);

  return (
    <Select
      aria-label="thresholds"
      isLoading={isLoading}
      value={options && value && options.find(option => option.value === value)}
      options={options || []}
      {...props}
    />
  );
};

ThresholdSelect.propTypes = {
  value: PropTypes.string
};

export default ThresholdSelect;

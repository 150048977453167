import React from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  TextInput,
  Select,
  Label,
  InnerPanel,
  Accordion
} from '@ubisend/pulse-components';

import { useFileSource } from '../../../hooks/index';
import types from '../../../../../Components/Types/index';

const RegionSelect = ({ value, ...props }) => {
  const regions = [
    'eu-west-1',
    'eu-west-2',
    'eu-south-1',
    'eu-west-3',
    'eu-north-1',
    'us-east-2',
    'eu-central-1',
    'us-east-1',
    'us-west-1',
    'us-west-2',
    'af-south-1',
    'ap-east-1',
    'ap-south-1',
    'ap-northeast-3',
    'ap-northeast-2',
    'ap-southeast-1',
    'ap-southeast-2',
    'ap-northeast-1',
    'ca-central-1',
    'me-south-1',
    'sa-east-1'
  ];

  const format = region => {
    return {
      value: region,
      label: region
    };
  };

  return (
    <Select
      value={value ? format(regions.find(region => region === value)) : null}
      options={regions.map(format)}
      {...props}
    />
  );
};

RegionSelect.propTypes = {
  value: PropTypes.string
};

const S3Content = () => {
  const { dispatch, source } = useFileSource();

  const handleRegionChange = option => {
    const region = option.value;

    dispatch({ type: 'UPDATE_CONTENT', content: { region } });
  };

  const handleBucketChange = event => {
    const bucket = event.target.value;

    dispatch({ type: 'UPDATE_CONTENT', content: { bucket } });
  };

  const handleAccessKeyIdChange = event => {
    const accessKeyId = event.target.value;

    dispatch({
      type: 'UPDATE_CONTENT',
      content: { access_key_id: accessKeyId }
    });
  };

  const handleSecretAccessKeyChange = event => {
    const secretAccessKey = event.target.value;

    dispatch({
      type: 'UPDATE_CONTENT',
      content: { secret_access_key: secretAccessKey }
    });
  };

  const handlePrefixChange = prefix => event => {
    const prefixValue = event.target.value;

    dispatch({
      type: 'UPDATE_CONTENT',
      content: {
        prefixes: { ...source.content.prefixes, [prefix]: prefixValue }
      }
    });
  };

  return (
    <Flex col mb>
      <InnerPanel header="Bucket settings">
        <Flex mb col>
          <Label htmlFor="region">Region</Label>
          <RegionSelect
            id="region"
            value={source.content.region}
            onChange={handleRegionChange}
          />
        </Flex>
        <Flex mb col>
          <Label htmlFor="bucket">Bucket name</Label>
          <TextInput
            id="bucket"
            placeholder="my-bucket-name"
            value={source.content.bucket}
            onChange={handleBucketChange}
          />
        </Flex>
        <Flex mb col>
          <Label htmlFor="access-key">Access key ID</Label>
          <TextInput
            id="access-key"
            value={source.content.access_key_id}
            onChange={handleAccessKeyIdChange}
          />
        </Flex>
        <Flex mb col>
          <Label htmlFor="secret-access-key">Secret access key</Label>
          <TextInput
            id="secret-access-key"
            type="password"
            value={source.content.secret_access_key}
            onChange={handleSecretAccessKeyChange}
          />
        </Flex>
        <Accordion header="Type prefixes">
          <Flex xSpace mb>
            <Flex fat>
              <Label mb={false}>File type</Label>
            </Flex>
            <Flex fat>
              <Label mb={false}>Prefix</Label>
            </Flex>
          </Flex>
          <Flex col ySpace>
            {Object.values(types).map(type => (
              <Flex xSpace center key={type.type}>
                <Flex fat>
                  <Label mb={false} htmlFor={type.type}>
                    {type.name}
                  </Label>
                </Flex>
                <Flex fat>
                  <TextInput
                    id={type.type}
                    value={source.content.prefixes[type.type]}
                    onChange={handlePrefixChange(type.type)}
                  />
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Accordion>
      </InnerPanel>
    </Flex>
  );
};

export default S3Content;

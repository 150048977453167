import React from 'react';
import PropTypes from 'prop-types';

import { useMutation } from '@ubisend/pulse-hooks';
import { Button, useNotification, useModal } from '@ubisend/pulse-components';
import { useAuth } from '@ubisend/pulse-auth';

import { exportSection } from '../../api/index';

const ExportSection = ({ item, section }) => {
  const { showSuccess } = useNotification();
  const { showModal, hideModal } = useModal();
  const { user } = useAuth();

  const mutation = useMutation(exportSection, {
    onSuccess: () => {
      showSuccess(
        'You will receive an email shortly with details on accessing your export.'
      );
    }
  });

  const handleSubmit = () => {
    showModal({
      header: 'Export section',
      body: `After clicking confirm you will receive an email at "${user.email}" with a link to download the section.`,
      handleConfirm: async () => {
        try {
          await mutation.mutateAsync({
            importId: item.id,
            sectionId: section.id
          });
        } finally {
          hideModal();
        }
      }
    });
  };

  return (
    <Button variant="secondary" icon="download" onClick={handleSubmit}>
      Export
    </Button>
  );
};

ExportSection.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  section: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
};

export default ExportSection;

import React from 'react';

import { Button, Flex } from '@ubisend/pulse-components';

import { useBotSettings } from '../../hooks/index';

const SaveSettings = () => {
  const { valid, handleSubmit } = useBotSettings();

  return (
    <Flex right>
      <Button disabled={!valid} onClick={handleSubmit}>
        Save
      </Button>
    </Flex>
  );
};

export default SaveSettings;

import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { Flex, Span } from '@ubisend/pulse-components';

const FilePreviewDetails = ({ file }) => {
  return (
    <Flex borderTop col ySpaceSm padSm>
      <Flex xScroll col>
        {file.name && (
          <Span tinyText noWrap>
            {file.name}
          </Span>
        )}
        {file.created_at && (
          <Span tinyText light>
            {dayjs(file.created_at).format('DD/MM/YYYY')}
          </Span>
        )}
      </Flex>
    </Flex>
  );
};

FilePreviewDetails.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string,
    created_at: PropTypes.string.isRequired
  })
};

export default FilePreviewDetails;

import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { Flex, Span } from '@ubisend/pulse-components';

const TicketCreatedAt = ({ date }) => (
  <Flex col>
    {date && (
      <>
        <Span>{dayjs(date).format('HH:mm')}</Span>
        <Span light>{dayjs(date).format('DD/MM/YYYY')}</Span>
      </>
    )}
    {!date && 'N/A'}
  </Flex>
);

TicketCreatedAt.propTypes = {
  date: PropTypes.string.isRequired
};

export default TicketCreatedAt;

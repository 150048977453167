import axios from 'axios';

const inviteUser = ({ emails, ...params }) => {
  return axios.post('invites', { emails, ...params });
};

const revokeInvite = id => axios.delete(`invites/${id}`);

const acceptInvite = params => {
  return axios.put(`auth/email/invites`, params);
};

export { inviteUser, revokeInvite, acceptInvite };

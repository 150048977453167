import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Flex } from '../Layout/index';

const ActiveDot = styled.div`
  border-radius: 9999px;
  height: 8px;
  width: 8px;
  background: black;
`;

const Dot = styled.div`
  border-radius: 9999px;
  height: 6px;
  width: 6px;
  border: 1px solid rgba(0, 0, 0, 0.5);
`;

const WizardProgress = ({ steps = 3, current = 0 }) => (
  <Flex xSpace>
    {[...new Array(steps)].map((_, key) =>
      key === current ? <ActiveDot key={key} /> : <Dot key={key} />
    )}
  </Flex>
);

WizardProgress.propTypes = {
  steps: PropTypes.number,
  current: PropTypes.number
};

export default WizardProgress;

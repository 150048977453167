const TYPES = ['general', 'carousel', 'image', 'video'];

const useHasMediaMessage = message => {
  if (!message) {
    return false;
  }

  return TYPES.includes(message.type);
};

useHasMediaMessage.TYPES = TYPES;
export default useHasMediaMessage;

import EmailPasswordLogin from './EmailPasswordLogin';
import EmailPasswordInvite from './EmailPasswordInvite';

const emailPassword = {
  name: 'Email & Password',
  Login: EmailPasswordLogin,
  Settings: false,
  Invite: EmailPasswordInvite
};

export default emailPassword;

import React from 'react';
import { Redirect } from 'react-router-dom';

// TODO: change for a nice looking page.
const NotFound = () => (
  <Redirect
    to={{
      pathname: '/home',
      state: {
        notification: {
          type: 'information',
          message: 'That page does not exist'
        }
      }
    }}
  />
);

export default NotFound;

import React from 'react';
import PropTypes from 'prop-types';

import Icon from '@ubisend/pulse-icons';

import BlockContainer from '../../BlockContainer';

const EmailBlock = ({ icon, colour, ...props }) => {
  return (
    <BlockContainer colour={colour} {...props}>
      <Icon outline type={icon} />
    </BlockContainer>
  );
};

EmailBlock.propTypes = {
  icon: PropTypes.string.isRequired,
  colour: PropTypes.string.isRequired
};

export default EmailBlock;

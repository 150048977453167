import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';

import Placeholder, { Item as PlaceholderItem } from '../Loading/Placeholder';
import TableBody from '../Table/TableBody';
import TableRow from '../Table/TableRow';
import TableCell from '../Table/TableCell';

const StyledTable = styled.table`
  &:not(:only-child),
  &:not(:last-child) {
    ${tw`border-0 border-b border-solid border-grey-medium`}
  }
  ${tw`w-full bg-white`}
  border-collapse: collapse;
  border-spacing: 0;
  & tr:nth-child(odd) {
    & ${PlaceholderItem} {
      width: 70%;
    }
  }
  & tr:nth-child(even) {
    & ${PlaceholderItem} {
      width: 80%;
    }
  }
  & tr:nth-child(3n) {
    & ${PlaceholderItem} {
      width: 90%;
    }
  }
  & tr:nth-child(4n) {
    & ${PlaceholderItem} {
      width: 60%;
    }
  }
`;

const Table = ({
  loading = false,
  loadingRows = 8,
  loadingColumns = 3,
  children,
  ...props
}) => {
  if (!loading) {
    return <StyledTable {...props}>{children}</StyledTable>;
  }

  return (
    <StyledTable>
      {children ? children[0] : null}
      <TableBody>
        {[...Array(loadingRows)].map((_, loadingKey) => (
          <TableRow key={loadingKey}>
            {[...Array(loadingColumns)].map((_, columnKey) => (
              <TableCell key={columnKey}>
                <Placeholder subitems={1} items={1} />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </StyledTable>
  );
};

Table.propTypes = {
  loading: PropTypes.bool,
  loadingRows: PropTypes.number,
  loadingColumns: PropTypes.number
};

export default Table;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { motion } from '@ubisend/framer-motion';
import { pseudoStyles } from '@ubisend/pulse-components';

import styles from './styles';

const StyledButton = styled(motion.button)`
  ${styles}
  ${pseudoStyles}
`;

const Postback = ({ response, handleButtonClick, ...props }) => {
  const handleClick = () => {
    handleButtonClick(response.label, response.action);
  };

  return (
    <StyledButton {...props} onClick={handleClick} type="button">
      {response.label}
    </StyledButton>
  );
};

Postback.propTypes = {
  response: PropTypes.shape({
    label: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired
  }).isRequired,
  handleButtonClick: PropTypes.func.isRequired
};

export default Postback;

import { useContext } from 'react';

import { ConditionalContext } from '../Contexts/index';

const useConditional = () => {
  const context = useContext(ConditionalContext);

  return context;
};

export default useConditional;

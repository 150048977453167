import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';

import { LanguagesContext } from '../Contexts/index';

const LanguagesProvider = ({ children, client }) => {
  const { data, isLoading } = useQuery('languages', {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });

  if (isLoading) {
    return null;
  }

  const context = {
    languages: data.data,
    defaultLanguage: client.default_language
  };

  return (
    <LanguagesContext.Provider value={context}>
      {children}
    </LanguagesContext.Provider>
  );
};

LanguagesProvider.propTypes = {
  client: PropTypes.shape({
    default_language: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired
  })
};

export default LanguagesProvider;

import { useContext } from 'react';

import { TicketsContext } from '../Contexts/index';

const useTickets = () => {
  const tickets = useContext(TicketsContext);

  return tickets;
};

export default useTickets;
